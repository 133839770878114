import * as React from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import {
  AddCircle,
  AddCircleOutline,
  BusinessCenterOutlined,
  BusinessCenterRounded,
  CalendarMonth,
  CalendarMonthOutlined,
  ContactPageRounded,
  DashboardOutlined,
  DashboardRounded,
  Groups,
  GroupsOutlined,
  LocationCityOutlined,
  LocationCityRounded,
  ManageSearch,
  ManageSearchOutlined,
  Message,
  Notifications,
  SnippetFolderOutlined,
  StorageOutlined,
  StorageRounded,
  Troubleshoot,
  CheckCircleOutline,
  CheckCircleOutlineRounded,
  CheckCircleRounded,
  PersonSearchOutlined,
  PersonSearchRounded,
  LinkedIn,
} from "@mui/icons-material";
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

import { useState } from "react";
import {
  Avatar,
  Badge,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Tooltip,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Key from "../../clientVariables.json";
import moment from "moment";
import axios from "axios";
import Empgeneralsearch from "./Empgeneralsearch";
import "./Styles/muiNavBarStyles.css";
import { useLocation } from "react-router-dom";
import userService from "../../services/user.service";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.9),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.6),
  },
  color: alpha(theme.palette.common.black, 0.9),
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "rgba(0,0,0,1)",
    },
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerStyle = {
  ".css-12i7wg6-MuiPaper-root-MuiDrawer-paper": {
    background: "#f0f0f0 !important",
    // width: 'calc(54px + 1px)'
  },
};
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // width: drawerWidth,

  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ITEM_HEIGHT = 48;

export default function Empnavbar({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selectNavItem, setSelectNavItem] = useState("Dashboard");
  const [openMobile, setOpenMobile] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const openNotification = Boolean(anchorElNotification);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [formOpen, setFormOpen] = useState(false);
  const [sideNavItemIndex, setSideNavItemIndex] = useState(0);
  const location = useLocation();
  const currentUser = useSelector((state) => state.UserReducer.user);

  console.log("use type get", sessionStorage.getItem("usertype"));

  const handleClose = (v) => {
    console.log(v);
    setFormOpen(v);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpenMobile((prevState) => !prevState);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const accountHandler = () => {
    setAnchorElUser(null);
  };
  const navItemOnClickHandler = (title, index) => {
    setSelectNavItem(title);
    setSideNavItemIndex(index);
  };
  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null);
  };
  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  React.useEffect(() => {
    getNotifications();
  }, []);

  const logoutHandler = () => {
    setAnchorElUser(null);
    userService.logout();
  };

  const getNotifications = () => {
    axios
      .post(`${Key.domain}/Notifications/get_notifications`, {
        user_id: sessionStorage.getItem("id"),
      })
      .then((response) => {
        console.log(response.data.data);
        setNotifications(response.data.data.notifications);
        setNotificationsCount(response.data.data.notificationsCount);
      });
    console.log(notifications);
  };

  const updateNotificationReadStatus = (
    notificationid,
    job_id,
    notification_type
  ) => {
    // props.showLoader();
    console.log("notification1" , notification_type, job_id)
    axios
      .post(`${Key.domain}/Notifications/update_notification_readstatus`, {
        user_id: sessionStorage.getItem("id"),
        notificationId: notificationid,
      })
      .then((response) => {
        console.log(response.data.data);
        getNotifications();

        axios
          .post(`${Key.domain}/Jobs/get_job_details`, { id: job_id })
          .then((response) => {
            console.log(response);

            if (
              response &&
              response.data !== undefined &&
              response.data !== null &&
              response.data.data !== null &&
              response.data.data !== undefined &&
              response.data.data[0] !== null &&
              response.data.data[0] !== undefined &&
              response.data.data[0].jobDetails
            ) {
              // props.hideLoader();

              // setTimeout(() => {
                console.log("notification" , notification_type)
                if (notification_type === "crm_notes") {
                  sessionStorage.setItem("crm_notes","true")
                  //sessionStorage.setItem('notificationsDisplayedBefore', 'true');
                   history.push("/Empaccountsettings");
                   handleCloseNotificationMenu();
                } else {
                  history.push({
                    pathname: "/myJobs",
                    state: {
                      myJobs: 'fromNotification',
                      jobid: job_id,
                      jobDetails: response.data.data[0].jobDetails,
                    },
                  });
                  handleCloseNotificationMenu();
                }
              // }, 1000);
            }
          });
      });
  };
  const viewAllNotifications = () => {
    history.push("/Notificationslist");
  };
  const viewAllNotificationsHandler = () => {
    handleCloseNotificationMenu();
    history.push("/Notificationslist");
  };
  const pushtohome = () => {
    history.push("/home");
    sessionStorage.removeItem("resumeTitle");
    sessionStorage.removeItem("cityname");
  };
  const dashboardClicked = () => {
    sessionStorage.removeItem("resumeTitle");
    sessionStorage.removeItem("cityname");
    sessionStorage.removeItem("inputResumeTitle");
    sessionStorage.removeItem("skillNDescriptionSearch");
    sessionStorage.removeItem("resumeCityName")
  };
  const navItemsEmp = [
    // {title:'Home', to:'/', icon1:<HomeRounded />, icon2:<HomeOutlined />},
    {
      title: "Dashboard",
      to: "/Empdashboard",
      icon1: (
        <DashboardRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/Empdashboard" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <DashboardOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    // {
    //   title: "Create",
    //   to: "/postJob",
    //   icon1: <AddCircle sx={{fontSize:'20px', color: sideNavItemIndex === 1 ? '#2196f3' : '#9e9e9e' }} />,
    //   icon2: <AddCircleOutline sx={{fontSize:'20px', color: '#9e9e9e'}} />,
    // },
    {
      title: "AI Search",
      to: "/employer/ai-search",
      icon1: (
        <ManageSearch
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/ai-search"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <ManageSearchOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Jobs",
      to: "/myJobs",
      icon1: (
        <BusinessCenterRounded
          sx={{
            fontSize: "20px",
            color: location.pathname === "/myJobs" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <BusinessCenterOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Contacts",
      to: "/employer/js-contacts",
      icon1: (
        <PersonSearchRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/js-contacts"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <PersonSearchOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Company",
      to:
        sessionStorage.getItem("companyid") &&
        parseInt(sessionStorage.getItem("companyid")) !== 0 &&
        sessionStorage.getItem("companyid") !== null &&
        sessionStorage.getItem("companyid") !== "null" &&
        sessionStorage.getItem("companyid") !== undefined &&
        sessionStorage.getItem("companyid") !== "undefined"
          ? "/company/view/comp_" + sessionStorage.getItem("companyid")
          : "/mycompanies",
      icon1: (
        <LocationCityRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname ===
              `${
                sessionStorage.getItem("companyid") &&
                parseInt(sessionStorage.getItem("companyid")) !== 0 &&
                sessionStorage.getItem("companyid") !== null &&
                sessionStorage.getItem("companyid") !== "null" &&
                sessionStorage.getItem("companyid") !== undefined &&
                sessionStorage.getItem("companyid") !== "undefined"
                  ? "/company/view/comp_" + sessionStorage.getItem("companyid")
                  : "/mycompanies"
              }`
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <LocationCityOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Recruiters",
      to: "/EmpRecruiters",
      icon1: (
        <Groups
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/EmpRecruiters" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <GroupsOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    // {
    //   title: "Resumes",
    //   to: "/role-resumes",
    //   icon1: <LinkedIn sx={{fontSize:'20px', color: (location.pathname === "/EmpRecruiters") ? '#2196f3' : '#9e9e9e'}} />,
    //   icon2: <LinkedIn sx={{fontSize:'20px', color: '#9e9e9e'}} />,
    // },
    {
      title: "Calendar",
      to: "/calendar",
      icon1: (
        <CalendarMonth
          sx={{
            fontSize: "20px",
            color: location.pathname === "/calendar" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <CalendarMonthOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },

    {
      title: "Todo",
      to: "/todo",
      icon1: (
        <CalendarMonth
          sx={{
            fontSize: "20px",
            color: location.pathname === "/todo" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <CalendarMonthOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Saved",
      to: parseInt(currentUser?.usertype) === 2 && "/employer/saved-resumes",
      icon1: (
        <CheckCircleRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/saved-resumes"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: <CheckCircleOutline sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    {
      title: "My Contacts",
      to: parseInt(currentUser?.usertype) === 2 && "/employer/my-contacts",
      icon1: (
        <PeopleAltRoundedIcon
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/my-contacts"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: <PeopleAltOutlinedIcon sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
  ];
  const navItemsRecruiter = [
    // {title:'Home', to:'/', icon1:<HomeRounded />, icon2:<HomeOutlined />},
    {
      title: "Dashboard",
      to: "/Empdashboard",
      icon1: (
        <DashboardRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/Empdashboard" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <DashboardOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    // {
    //   title: "Create",
    //   to: "/postJob",
    //   icon1: <AddCircle sx={{fontSize:'20px', color: sideNavItemIndex === 1 ? '#2196f3' : '#9e9e9e' }} />,
    //   icon2: <AddCircleOutline sx={{fontSize:'20px', color: '#9e9e9e'}} />,
    // },
    {
      title: "AI Search",
      to: "/employer/ai-search",
      icon1: (
        <ManageSearch
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/ai-search"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <ManageSearchOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Jobs",
      to: "/myJobs",
      icon1: (
        <BusinessCenterRounded
          sx={{
            fontSize: "20px",
            color: location.pathname === "/myJobs" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <BusinessCenterOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Contacts",
      to: "/employer/js-contacts",
      icon1: (
        <PersonSearchRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/js-contacts"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <PersonSearchOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Calendar",
      to: "/calendar",
      icon1: (
        <CalendarMonth
          sx={{
            fontSize: "20px",
            color: location.pathname === "/calendar" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <CalendarMonthOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Todo",
      to: "/todo",
      icon1: (
        <CalendarMonth
          sx={{
            fontSize: "20px",
            color: location.pathname === "/todo" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <CalendarMonthOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Saved",
      to: parseInt(currentUser?.usertype) === 4 && "/recruiter/saved-resumes",
      icon1: (
        <CheckCircleRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/recruiter/saved-resumes"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: <CheckCircleOutline sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
  ];

  const navItems =
    parseInt(currentUser?.usertype) === 2 ? navItemsEmp : navItemsRecruiter;
  React.useEffect(() => {
    const currentPath = location.pathname;
    const selectedIndex = navItems.findIndex((item) => item.to === currentPath);
    setSideNavItemIndex(selectedIndex !== -1 ? selectedIndex : null);
    if (location.pathname === "/Empdashboard") {
      dashboardClicked();
    }
  }, [location.pathname, sideNavItemIndex]);

  console.log(navItems);
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ py: 1, bgcolor:'#406882' }}>
        <Link to="/">
          <img
            src="/static/media/jobsnProfiles.54d22dc53e022b59b8b7.png"
            width={120}
          />
        </Link>
      </Typography>
      <Divider />
      <List sx={{ }}>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <Link to={item.to}>
              <ListItemButton>
                <ListItemIcon>{item.icon1}</ListItemIcon>
                <ListItemText
                  primary={item.title}
                  sx={{ color: "rgba(0, 0, 0, 0.7)" }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ boxShadow: "none", background: "#406882" }}
      >
        <Toolbar sx={{ minHeight: "48px !important" }}>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img
              src="/static/media/jobsnProfiles.54d22dc53e022b59b8b7.png"
              alt="logo"
              style={{ cursor: "pointer", width: "100px" }}
            />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }} />
          <Search onClick={() => setFormOpen(true)} sx={{ flexGrow: 1 }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search for resume title, skills, location and more"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <Box sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }} />

          {/* <Box sx={{ display: { xs: "block", md: "none" }, flexGrow: 1 }} /> */}
          <Box sx={{ display: { xs: "flex", md: "flex" } }}>
            {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <Mail />
              </Badge>
            </IconButton> */}
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleOpenNotificationMenu}
            >
              <Badge badgeContent={notificationsCount} color="error">
                <Notifications />
              </Badge>
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorElNotification}
              open={openNotification}
              onClose={handleCloseNotificationMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 5.5,
                  width: "40ch",
                  // padding: "4px",
                },
              }}
              // title="Notifications"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "4px",
                }}
              >
                <p>Notificationsssdd</p>
                <p
                  onClick={viewAllNotificationsHandler}
                  style={{ cursor: "pointer", color: "#406882" }}
                >
                  View All
                </p>
              </div>
              <Divider sx={{ margin: 0 }} />
              {notifications.map((notify) => (
                <MenuItem
                  key={notify.id}
                  onClick={() =>
                    notify.notification_subject !==
                    "Your recruiter deleted a Job"
                      ? updateNotificationReadStatus(notify.id, notify.job_id ,notify.notification_type )
                      : ""
                  }
                >
                  <Stack direction="row" spacing={1}>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={notify.notification_read.includes(
                        sessionStorage.getItem("id")
                      )}
                    >
                      <Message sx={{ color: "rgba(0,0,0,0.4)" }} />
                    </Badge>
                    <Stack direction="column" spacing={0}>
                    <p style={{ overflowWrap: "break-word", whiteSpace: "normal", margin: 0 ,fontSize:"14px"}}>
                        {notify.notification_subject}
                      </p>
                      <small>{moment(notify.created).fromNow()}</small>
                    </Stack>
                  </Stack>
                </MenuItem>
              ))}
            </Menu>

            {/* <Box sx={{ flexGrow: 0 }}> */}
            <Tooltip title="My Account">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={currentUser?.full_name}
                  src={
                    sessionStorage.getItem("profileimg") &&
                    sessionStorage.getItem("profileimg") !== "null" &&
                    `${Key.s3_files_url}/user_${sessionStorage.getItem(
                      "id"
                    )}/profileimages/${sessionStorage.getItem("profileimg")}`
                  }
                  sx={{ height: "34px", width: "34px" }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))} */}
              <MenuItem key="account" onClick={accountHandler}>
                <Typography textAlign="center">
                  <Link to="/Empaccountsettings">Account</Link>
                </Typography>
              </MenuItem>
              <MenuItem key="logout" onClick={logoutHandler}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
            {/* </Box> */}
          </Box>
        </Toolbar>
        <Modal open={formOpen} onClose={() => setFormOpen(false)}>
          <Empgeneralsearch formOpen={formOpen} handleClose={handleClose} />
        </Modal>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{ ...DrawerStyle, display: { xs: "none", md: "block" } }}
      >
        {/* <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader> */}
        {/* <Divider /> */}
        <List sx={{ pt: "50px" }}>
          {navItems.map((item, index) => (
            <ListItem key={item} disablePadding sx={{ display: "block" }}>
              <Tooltip title={item?.title} placement="right">
                <Link to={item?.to}>
                  <ListItemButton
                    onClick={() => navItemOnClickHandler(item?.title, index)}
                    key={index}
                    selected={sideNavItemIndex === index}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      //   px: 2.5,
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "10px",
                      color: "#9e9e9e",
                      borderLeft:
                        sideNavItemIndex === index
                          ? "2px solid #2196f3"
                          : "none",
                      marginLeft: "5px",
                      padding: "5px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : 'auto',
                        justifyContent: "center",
                      }}
                    >
                      {sideNavItemIndex === index ? item.icon1 : item.icon2}
                    </ListItemIcon>
                    {/* <ListItemText primary={text} sx={{fontSize:'10px'}}
                // sx={{ opacity: open ? 1 : 0 }}
                 /> */}
                    {item.title}
                  </ListItemButton>
                </Link>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        {/* <Divider /> */}
      </Drawer>
      <MuiDrawer
        // anchor={anchor}
        open={openMobile}
        onClose={handleDrawerToggle}
        sx={{zIndex:1301}}
      >
        {drawer}
      </MuiDrawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // background:'#f8f8f8',
          // minHeight:'100vh'
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
