import React, { useState, useEffect, useContext, useRef } from "react";
import "../../Styles/Jsdashboard.css";
import { Container, Grid } from "@mui/material";
import Key from "../../clientVariables.json";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { showLoader } from "../../Components/Actions/loader.actions";
import { hideLoader } from "../../Components/Actions/loader.actions";
import SearchContext from "../../../src/resumeBuilder/SearchContext";
import { BiSearchAlt } from "react-icons/bi";
import { postOptions } from "../../utils/httpConfig";
import request from "../../utils/request";
import { ENDPOINT } from "../../utils/endpoint";
import AISearchResumesListView from "../searchResumes/AISearchResumesListView";
import { toast } from "react-toastify";
import ToastMessage from "../../Components/ToastMessage";
import { useTheme, useMediaQuery } from "@mui/material";
function Jssearchblock(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large
  const history = useHistory();
  const searchResContext = useContext(SearchContext);

  const [resumes, setResumes] = useState([]);

  const [jobtitle, setJobtitle] = useState("");
  const [solrUrlAI, setsolrUrlAI] = useState("");

  const [openAImessage, setOpenAImessage] = useState(
    sessionStorage.getItem("OpenAIMessage")
      ? sessionStorage.getItem("OpenAIMessage")
      : ""
  );
  const [stateName, setStateName] = useState("");
  const [cityname, setCityName] = useState("");
  const [jobtitleValue, setJobtitleValue] = useState("");
  const [Skills, setSkills] = useState("");
  const [fulltime, setFullTime] = useState(false);
  const [contract, setContract] = useState(false);
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [titleSuggestions, setTitleSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showTitleSuggestions, setShowTitleSuggestions] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);

  const [AISearchShow, setAISearchShow] = useState(false);
  const [response1, setresponse1] = useState();

  const [jobtitle1, setjobtitle1] = useState();

  const [location1, setlocation1] = useState();

  const [fulltime1, setfulltime1] = useState();

  const [contract1, setcontract1] = useState();

  const [homepageJobtypesChecked1, sethomepageJobtypesChecked1] = useState();

  const [searchSkills1, setsearchSkills1] = useState();

  const [skills1, setskills1] = useState();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const suggestionListRef = useRef(null);
  const [activeSuggestion, setActiveSuggestion] = useState(0);

  useEffect(() => {
    if (props.searchFromMenu === true) {
      submit();
    }

    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/Jsdashboard" &&
      props.onSubmit !== undefined
    ) {
      props.onSubmit(resumes);
    }
    setJobtitle(
      sessionStorage.getItem("jobtitle")
        ? sessionStorage.getItem("jobtitle")
        : sessionStorage.getItem("resumeTitle")
        ? sessionStorage.getItem("resumeTitle")
        : ""
    );
    setCityName(
      sessionStorage.getItem("resumeCityName") &&
        sessionStorage.getItem("resumeCityName") !== null &&
        sessionStorage.getItem("resumeCityName") !== "null"
        ? sessionStorage.getItem("resumeCityName").replace(/"/g, "")
        : ""
    );
    setSkills(
      sessionStorage.getItem("skillNDescriptionSearch") &&
        sessionStorage.getItem("skillNDescriptionSearch") !== null &&
        sessionStorage.getItem("skillNDescriptionSearch") !== "null"
        ? sessionStorage.getItem("skillNDescriptionSearch").replace(/"/g, "")
        : ""
    );
    console.log(props.jobtitle, sessionStorage.getItem("jobtitle"));
  }, [resumes]);

  const showAlternateResults = async (jobtype, skillstoHighlight) => {
    let title = sessionStorage.getItem("resumeTitle").replace(/['"]/g, "");
    const searchResumesUrl = `${Key.Solr_url}/${
      Key.solrResumes_collection
    }/select?q=application_title:(${title})&fq=res_stateName:(${
      sessionStorage.getItem("resumeCityName") !== undefined &&
      sessionStorage.getItem("resumeCityName") !== ""
        ? sessionStorage.getItem("resumeCityName")
        : "*:*"
    })&fq=res_stateName:(*:*)&fq=res_cityName:(*:*)&fq=res_shortRegion:(*:*)&fq=jobtype:(*:*)&fq=search_flag:(1)&rows=${10}&start=${0}&sort=lastmodified%20desc&wt=json`;
    const result = await request(
      `${ENDPOINT["SolrResumes"]}`,
      postOptions({
        url: searchResumesUrl,
      })
    );

    history.push({
      pathname: "/ResumeSearch_Listview",
      state: {
        response: result.data.response.docs,
        jobtitle: true,
        location: false,
        fulltime: fulltime,
        contract: contract,
        homepageJobtypesChecked: jobtype,
        skills: Skills,
        skillstoHighlight: skillstoHighlight,
      },
    });

    sessionStorage.setItem("resumesCount", result.data.response.numFound);
  };

  const clearSessionValues = () => {
    sessionStorage.removeItem("OpenAIMessage");
    sessionStorage.removeItem("OpenAIresponse");
    sessionStorage.removeItem("resumeMappedDates");
    sessionStorage.removeItem("currentsearchpage");
    sessionStorage.removeItem("resumefiltered-jobtypes");
    localStorage.removeItem("resumejobtypeFlages");
    sessionStorage.removeItem("resumefiltered-visatypes");
    localStorage.removeItem("resumevisatypeFlages");
    sessionStorage.removeItem("resumefiltered-experience");
    localStorage.removeItem("resumeExpFlages");
    sessionStorage.removeItem("resumefiltered-country");
    localStorage.removeItem("resumeQualificationFlages");
    sessionStorage.removeItem("resumefiltered-qualification");
    localStorage.removeItem("resumeCountryFlages");
    sessionStorage.setItem("currentsearchpage", 1);
    localStorage.removeItem("resumeSortBy");
    localStorage.setItem("resumeFilterResetValue", 1);
    localStorage.removeItem("resumeSortByDate");
    localStorage.removeItem("resumeFileterChecked");
  };

  const submit = async (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    props.showLoader();
    clearSessionValues();

    var skillstoHighlight = [];

    if (
      jobtitle &&
      (jobtitle.includes(" AND ") ||
        jobtitle.includes(" OR ") ||
        jobtitle.includes(","))
    ) {
      if (jobtitle.includes(" AND ")) {
        var application_title = jobtitle.split("AND", 1);
        var str = jobtitle;
        var skills = str.substring(str.indexOf("AND") + 3);
        sessionStorage.setItem("skillNDescriptionSearch", skills);

        const filtereAND = jobtitle.replaceAll("AND", ",");
        const filtered = filtereAND.replaceAll(" ", ",");
        skillstoHighlight = filtered.split(",");

        sessionStorage.setItem("skillstoHighlight", skillstoHighlight);
      } else if (jobtitle.includes(" OR ")) {
        application_title = jobtitle.split(" OR ", 1);
        str = jobtitle;
        var skills = str.substring(str.indexOf(" OR ") + 4);

        const titleTwoContainskeyword =
          skills.toLowerCase().includes("developer") ||
          skills.toLowerCase().includes("programmer") ||
          skills.toLowerCase().includes("architect") ||
          skills.toLowerCase().includes("designer") ||
          skills.toLowerCase().includes("consultant") ||
          skills.toLowerCase().includes("administrator") ||
          skills.toLowerCase().includes("coach") ||
          skills.toLowerCase().includes("lead") ||
          skills.toLowerCase().includes("manager") ||
          skills.toLowerCase().includes("master") ||
          skills.toLowerCase().includes("specalist") ||
          skills.toLowerCase().includes("modeler") ||
          skills.toLowerCase().includes("director") ||
          skills.toLowerCase().includes("hacker") ||
          skills.toLowerCase().includes("engineer") ||
          skills.toLowerCase().includes("analyst") ||
          skills.toLowerCase().includes("dba") ||
          skills.toLowerCase().includes("tester");

        var roleBasedTwoTitles = "";

        if (titleTwoContainskeyword === true) {
          var roleBasedVariabe = jobtitle.replace(" OR ", '" OR "'); // Eg: Java developer" OR "Python developer
          roleBasedTwoTitles = '"' + roleBasedVariabe + '"'; // Eg: "Java developer" OR "Python developer"
          console.log(jobtitle);
          console.log(roleBasedTwoTitles);
          skills = "";
        } else {
          sessionStorage.setItem("skillNDescriptionSearch", skills);

          const filtereAND = jobtitle.replaceAll(" OR ", ",");
          const filtered = filtereAND.replaceAll(" ", ",");
          skillstoHighlight = filtered.split(",");

          sessionStorage.setItem("skillstoHighlight", skillstoHighlight);
        }
      } else if (jobtitle.includes(",")) {
        application_title = jobtitle.split(",", 1);
        str = jobtitle;
        var skills = str.substring(str.indexOf(",") + 1);
        sessionStorage.setItem("skillNDescriptionSearch", skills);

        const filtereAND = jobtitle.replaceAll(",", ",");
        const filtered = filtereAND.replaceAll(" ", ",");
        skillstoHighlight = filtered.split(",");

        sessionStorage.setItem("skillstoHighlight", skillstoHighlight);
      }

      if (roleBasedTwoTitles === undefined || roleBasedTwoTitles === "") {
        var resume_title = application_title[0];
        console.log(application_title[0]);
        console.log(skills);

        var inputjobtitle = resume_title;

        const string = inputjobtitle;
        const res =
          string.toLowerCase().includes("developer") ||
          string.toLowerCase().includes("programmer") ||
          string.toLowerCase().includes("architect") ||
          string.toLowerCase().includes("designer") ||
          string.toLowerCase().includes("consultant") ||
          string.toLowerCase().includes("administrator") ||
          string.toLowerCase().includes("coach") ||
          string.toLowerCase().includes("lead") ||
          string.toLowerCase().includes("manager") ||
          string.toLowerCase().includes("master") ||
          string.toLowerCase().includes("specalist") ||
          string.toLowerCase().includes("modeler") ||
          string.toLowerCase().includes("director") ||
          string.toLowerCase().includes("hacker") ||
          string.toLowerCase().includes("engineer") ||
          string.toLowerCase().includes("analyst") ||
          string.toLowerCase().includes("dba") ||
          string.toLowerCase().includes("tester");

        if (res === true) {
          inputjobtitle = '"' + string.replace(/"/g, "") + '"';
        }
      } else {
        inputjobtitle = roleBasedTwoTitles;
      }
    } else {
      var jobtitle =
        sessionStorage.getItem("inputResumeTitle") !== "" &&
        sessionStorage.getItem("inputResumeTitle") !== null &&
        sessionStorage.getItem("inputResumeTitle") !== undefined
          ? sessionStorage.getItem("inputResumeTitle")
          : "";

      inputjobtitle =
        jobtitle !== "" && jobtitle !== undefined && jobtitle !== null
          ? '"' + jobtitle + '"'
          : "";

      if (typeof parseInt(jobtitle) === "number") {
        //sessionStorage.removeItem("skillNDescriptionSearch");
      }
      if (Skills !== undefined && Skills !== null && Skills !== "") {
        sessionStorage.setItem("skillNDescriptionSearch", Skills);
      }

      sessionStorage.setItem("skillNDescriptionSearch", Skills); //for setting this sessionvalue in listview for fq=
      console.log("job", jobtitle);

      const filtereAND = jobtitle ? jobtitle.replaceAll(" ", ",") : "";
      const filtered = filtereAND.replaceAll(" ", ",");
      skillstoHighlight = filtered.split(",");

      sessionStorage.setItem("skillstoHighlight", skillstoHighlight);
      const skills = Skills.replaceAll(" AND ", ",");
      console.log(skills);
      const splittedSkills = skills.split(",");
      console.log(splittedSkills);
      skillstoHighlight = filtered.split(",");
      const mergeSkills = [...skillstoHighlight, ...splittedSkills];
      skillstoHighlight = mergeSkills;

      sessionStorage.setItem("skillstoHighlight", skillstoHighlight);

      const string = inputjobtitle !== null ? inputjobtitle : "";
      const res =
        string.toLowerCase().includes("developer") ||
        string.toLowerCase().includes("programmer") ||
        string.toLowerCase().includes("architect") ||
        string.toLowerCase().includes("designer") ||
        string.toLowerCase().includes("consultant") ||
        string.toLowerCase().includes("administrator") ||
        string.toLowerCase().includes("coach") ||
        string.toLowerCase().includes("lead") ||
        string.toLowerCase().includes("manager") ||
        string.toLowerCase().includes("master") ||
        string.toLowerCase().includes("specalist") ||
        string.toLowerCase().includes("modeler") ||
        string.toLowerCase().includes("director") ||
        string.toLowerCase().includes("hacker") ||
        string.toLowerCase().includes("engineer") ||
        string.toLowerCase().includes("analyst") ||
        string.toLowerCase().includes("dba") ||
        string.toLowerCase().includes("tester");

      if (res === true) {
        inputjobtitle = '"' + string.replace(/"/g, "") + '"';
        const modifiedString = replaceSpecialCharacters(
          inputjobtitle,
          replacements
        );
        console.log(modifiedString);
        inputjobtitle = modifiedString;
      } else {
        if (typeof parseInt(inputjobtitle) === "number") {
          inputjobtitle = inputjobtitle;
        } else if (inputjobtitle.includes("#")) {
          inputjobtitle = inputjobtitle.replace("#", "%23");
        } else if (inputjobtitle.includes("++")) {
          inputjobtitle = inputjobtitle.replace("++", "%2B%2B");
        } else if (inputjobtitle.includes("&")) {
          inputjobtitle = inputjobtitle.replace("&", "%26");
        } else if (inputjobtitle.includes("/")) {
          inputjobtitle = inputjobtitle.replace(" / ", "/");
        } else {
          inputjobtitle = "(*%3A*)";
        }
      }

      sessionStorage.setItem("skillstoHighlight", skillstoHighlight);
    }

    if (
      inputjobtitle === null ||
      inputjobtitle === undefined ||
      inputjobtitle === ""
    ) {
      sessionStorage.removeItem("jobtitle");
      localStorage.removeItem("jobtitle");
      sessionStorage.removeItem("inputjobtitle");
    }

    const inputlocationcity = cityname;

    if (inputlocationcity.includes(",")) {
      const location =
        inputlocationcity !== "" ? inputlocationcity.split(/[,]+/) : "";

      if (location[1].replace(" ", "").length === 2) {
        var cityName = location[0];
        var stateName = "";
        var shortRegion = location[1];
      } else {
        cityName = location[0];
        stateName = location[1];
      }
    } else if (inputlocationcity.length === 2) {
      shortRegion = inputlocationcity;
    } else {
      stateName = inputlocationcity; // This will be called when user have typed only one single word that is either state or city --> that will defaultly go to statename variable
    }

    if (fulltime === true && contract === false) {
      var jobtype = "1";
    } else if (contract === true && fulltime === false) {
      jobtype = "10";
    } else if (fulltime === true && contract === true) {
      jobtype = "1 OR 10";
    }

    var skillNDescriptionSearch = sessionStorage.getItem(
      "skillNDescriptionSearch"
    );

    if (skillNDescriptionSearch && skillNDescriptionSearch.includes("#")) {
      skillNDescriptionSearch = skillNDescriptionSearch.replace("#", "%23");
    } else if (
      skillNDescriptionSearch &&
      skillNDescriptionSearch.includes("++")
    ) {
      skillNDescriptionSearch = skillNDescriptionSearch.replace("++", "%2B%2B");
    } else if (
      skillNDescriptionSearch &&
      skillNDescriptionSearch.includes("&")
    ) {
      skillNDescriptionSearch = skillNDescriptionSearch.replace("&", "%26");
    } else if (
      skillNDescriptionSearch &&
      skillNDescriptionSearch.includes("/")
    ) {
      skillNDescriptionSearch = skillNDescriptionSearch.replace(" / ", "/");
    }

    const searchResumesUrl = `${Key.Solr_url}/${
      Key.solrResumes_collection
    }/select?q=application_title:(${
      inputjobtitle ? inputjobtitle : "*%3A*"
    }) OR id:(${inputjobtitle ? inputjobtitle : "*%3A*"})&fq=res_stateName:(${
      stateName !== undefined && stateName !== "" && stateName !== null
        ? stateName
        : "*:*"
    })&fq=res_cityName:(${
      cityName !== undefined && cityName !== "" && cityName !== null
        ? cityName
        : "*:*"
    })&fq=res_shortRegion:(${
      shortRegion !== undefined && shortRegion !== "" && shortRegion !== null
        ? shortRegion
        : "*:*"
    })&fq=${skillNDescriptionSearch}&fq=search_flag:(1)&rows=${10}&start=${0}&fq=jobtype:(${
      jobtype ? jobtype : "*:*"
    })&sort=lastmodified%20desc&wt=json`;

    await fetch(`${Key.domain}/solr/solr_resumes`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: searchResumesUrl }),
    })
      .then((response) => {
        response.json().then((resdata) => {
          if (inputjobtitle !== null) {
            sessionStorage.setItem("resumeTitle", inputjobtitle);
          }
          sessionStorage.setItem("resumeCityName", inputlocationcity);
          if (!resdata.data.error) {
            sessionStorage.setItem(
              "resumesCount",
              resdata.data.response.numFound
            );
          } else {
            props.hideLoader();
            let msg = "Invaild Response, Try Changing the given inputs.";
            toast(<ToastMessage message={msg} />);
          }
          // if (resdata.data.response.docs.length === 0) {
          //   //showAlternateResults(jobtype, skillstoHighlight);
          // }

          if (jobtitle !== "") {
            fetch(`${Key.domain}/empdashboard/insert_recent_searches`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                user_id: props.currentUser.id,
                session_token: props.currentUser.session_token,
                search_title: jobtitle,
                skills: Skills !== "* TO *" ? Skills : "",
                search_location:
                  sessionStorage.getItem("resumeCityName") !== null
                    ? sessionStorage.getItem("resumeCityName")
                    : "",
              }),
            }).then((response) => {
              console.log(response);
            });
          }
          props.handleClose(false);
          history.push({
            pathname: "/ResumeSearch_Listview",
            state: {
              response: resdata.data.response.docs,
              numFound: resdata.data.response.numFound,
              jobtitle: true,
              location: true,
              fulltime: fulltime,
              contract: contract,
              homepageJobtypesChecked: jobtype,
              skills: Skills,
            },
          });
        });
      })
      .catch(() => {
        props.hideLoader();
        let msg = "Invaild Response, Try Changing the given inputs.";
        toast(<ToastMessage message={msg} />);
      });
  };

  function replaceSpecialCharacters(inputString, replacementMap) {
    let modifiedString = inputString;

    for (const [specialChar, replacement] of Object.entries(replacementMap)) {
      const regex = new RegExp(escapeRegExp(specialChar), "g");
      modifiedString = modifiedString.replace(regex, replacement);
    }

    return modifiedString;
  }
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
  const replacements = {
    "#": "%23",
    "++": "%2B%2B",
    "&": "%26",
    " / ": "/",
  };

  const getCityNames = (value) => {
    setShowSuggestions(true);
    axios
      .post(`${Key.domain}/Jobs/get_joblocations`, {
        statename: value,
      })
      .then((response) => {
        if (response.data.data.length === 0) {
          // setErrorLocation(true);
          setShowSuggestions(false);
        } else {
          setErrorLocation(false);
          setCitySuggestions(
            response.data.data.map((res) =>
              res.cityName.concat(" , ", res.stateName)
            )
          );
        }
      });
  };

  const getJobtitles = (value) => {
    setShowTitleSuggestions(true);
    axios
      .post(`${Key.domain}/Jobs/get_jobtitles`, {
        jobtitle: value,
      })
      .then((response) => {
        console.log(response.data.data);
        setTitleSuggestions(response.data.data.map((res) => res.title));
      });
  };

  /* const pushAdvancedResume = () => {
     sessionStorage.removeItem("advancedSearch-skills")
     localStorage.removeItem("skillFlages")
 
     sessionStorage.removeItem("advanceresumefiltered-jobtypes")
     sessionStorage.removeItem("advanceresumefiltered-visatypes")
     sessionStorage.removeItem("advanceresumefiltered-qualifications")
     sessionStorage.removeItem("advanceresumefiltered-exptypes")
     sessionStorage.removeItem("inputResumeTitle")
     sessionStorage.removeItem("resumeTitle");
     sessionStorage.removeItem("resumeCityName");
     sessionStorage.removeItem("technology_jsondata");
     sessionStorage.removeItem("AdvanceResumeIds")
     sessionStorage.removeItem("currenttab")
     sessionStorage.removeItem("Selectedjsondata")
 
     const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=*%3A*&rows=10&start=0&sort=created%20desc&wt=json`
     localStorage.setItem("resumeQuery", searchResumesUrl)
 
     fetch(`${Key.domain}/solr/solr_resumes`, {
       method: 'POST',
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json'
       },
       body: JSON.stringify({ url: searchResumesUrl }),
     })
       .then((response) => {
         response.json().then((resdata) => {
 
           sessionStorage.setItem("resumesCount", resdata.data.response.numFound)
 
           history.push({
             pathname: "/AdvancedResumeSearch",
             state: {
               filterChecked: false,
               response: [],
               jobtitle: false, location: true,
               getPagination: true,
               resumesCount: resdata.data.response.numFound
             }
           });
 
         })
 
       })
 
   }*/

  const scrollToIndex = (index) => {
    if (suggestionListRef.current) {
      const suggestionItemHeight = 30; // Adjust this value as needed
      suggestionListRef.current.scrollTop = index * suggestionItemHeight;
    }
  };

  return (
    <div>
      <div className="Search__bg1">
        {(isDesktopLarge || isDesktopSmall) && (
          <Container>
            <div className="jsmain_body">
              <Grid container spacing={2} md={12} lg={12} width={"80vw"}>
                <Grid item sm={8} md={8} lg={8} xs={8}>
                  <h4 className="js_title">2,000,000 + RESUMES</h4>
                </Grid>
                <Grid item sm={4} md={4} lg={4} xs={4}>
                  {/* <span>Advanced Search</span> */}
                </Grid>
                <div class="Searchblocks">
                  <form autocomplete="on">
                    <Grid container spacing={2} lg={12} md={12} id="searchblock__">
                      <Grid item sm={12}  lg={12} md={12} width={"80vw"}>
                        <div
                          class="input-group double-input"
                          style={{
                            height: "61px",
                            backgroundColor: "#FFF",
                            borderRadius: "180px"                          }}
                        >
                          <input
                            id="textradius"
                            type="text"
                            class="form-control"
                            style={{ margin: "6px" }}
                            value={
                              jobtitle !== "" ? jobtitle.replace(/"/g, "") : ""
                            }
                            placeholder="Resume title"
                            onBlur={(e) => {
                              setTimeout(() => {
                                setShowTitleSuggestions(false);
                              }, [300]);
                            }}
                            onChange={(e) => {
                              setJobtitle(e.target.value);
                              getJobtitles(e.target.value);
                              sessionStorage.setItem(
                                "inputResumeTitle",
                                e.target.value
                              );
                              searchResContext.updateselectedFromSuggestions(
                                true
                              );
                              setCurrentIndex(-1);
                            }}
                            onKeyDown={(e) => {
                              if (titleSuggestions.length > 0) {
                                if (e.key === "ArrowUp" && currentIndex > 0) {
                                  setCurrentIndex(currentIndex - 1);
                                  scrollToIndex(currentIndex - 1);
                                  e.preventDefault();
                                } else if (
                                  e.key === "ArrowDown" &&
                                  currentIndex < titleSuggestions.length - 1
                                ) {
                                  setCurrentIndex(currentIndex + 1);
                                  scrollToIndex(currentIndex + 1);
                                  e.preventDefault();
                                } else if (
                                  e.key === "Enter" &&
                                  currentIndex !== -1
                                ) {
                                  // Handle Enter key press (select suggestion)
                                  setJobtitle(
                                    '"' + titleSuggestions[currentIndex] + '"'
                                  );
                                  setShowTitleSuggestions(false);
                                  sessionStorage.setItem(
                                    "inputResumeTitle",
                                    '"' + titleSuggestions[currentIndex] + '"'
                                  );
                                }
                              }
                            }}
                          />
                          {showTitleSuggestions &&
                          titleSuggestions.length > 0 ? (
                            <ul
                              className="sm-4 col-md-4 col-lg-3 suggestionList "
                              ref={suggestionListRef}
                              style={{
                                maxHeight: "150px",
                                maxwidth: "300px",
                                overflowY: "auto",
                                marginTop: "60px",
                              }}
                            >
                              {titleSuggestions.map((suggestion, index) => (
                                <li
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      index === currentIndex ? "#406882" : "",
                                    color:
                                      index === currentIndex
                                        ? "white"
                                        : "black",
                                  }}
                                  onClick={() => {
                                    setJobtitle('"' + suggestion + '"');
                                    setShowTitleSuggestions(false);
                                    sessionStorage.setItem(
                                      "inputResumeTitle",
                                      '"' + suggestion + '"'
                                    );
                                    setCurrentIndex(-1);
                                    scrollToIndex(-1);
                                  }}
                                >
                                  {suggestion}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}
                          <input
                            id="textradius"
                            type="text"
                            style={{ margin: "6px" }}
                            class="form-control"
                            value={Skills}
                            placeholder="Skills"
                            onChange={(e) => {
                              setSkills(e.target.value);
                              sessionStorage.setItem(
                                "skillNDescriptionSearch",
                                e.target.value
                              );
                            }}
                          />
                          <input
                            id="textradius"
                            type="text"
                            style={{ margin: "6px" }}
                            class="form-control"
                            value={cityname}
                            placeholder="Location"
                            onBlur={(e) => {
                              setTimeout(() => {
                                setShowSuggestions(false);
                              }, [300]);
                            }}
                            onChange={(e) => {
                              setCityName(e.target.value);
                              getCityNames(e.target.value);
                              sessionStorage.setItem(
                                "resumeCityName",
                                e.target.value
                              );
                              setActiveSuggestion(-1);
                            }}
                            onKeyDown={(e) => {
                              console.log("aaa");
                              if (citySuggestions.length > 0) {
                                console.log("bb");
                                if (
                                  e.key === "ArrowDown" &&
                                  activeSuggestion < citySuggestions.length - 1
                                ) {
                                  console.log("cc");
                                  setActiveSuggestion(activeSuggestion + 1);
                                  scrollToIndex(activeSuggestion + 1);
                                } else if (
                                  e.key === "ArrowUp" &&
                                  activeSuggestion > 0
                                ) {
                                  console.log("dd");
                                  setActiveSuggestion(activeSuggestion - 1);
                                  scrollToIndex(activeSuggestion - 1);
                                } else if (
                                  e.key === "Enter" &&
                                  activeSuggestion >= 0 &&
                                  activeSuggestion < citySuggestions.length
                                ) {
                                  // Handle Enter key press to select a suggestion
                                  console.log("ee");
                                  setCityName(
                                    citySuggestions[activeSuggestion]
                                  );
                                  setShowSuggestions(false);
                                  sessionStorage.setItem(
                                    "resumeCityName",
                                    citySuggestions[activeSuggestion]
                                  );
                                }
                              }
                            }}
                          />

                          {showSuggestions && citySuggestions.length > 0 ? (
                            <ul
                              className="sm-4 col-md-4 col-lg-3 suggestionList "
                              style={{
                                float: "right",
                                marginLeft: "780px",
                                maxwidth: "300px",
                                maxHeight: "150px",
                                overflowY: "auto",
                                marginTop: "60px",
                              }}
                              ref={suggestionListRef}
                            >
                              {citySuggestions.map((suggestion, index) => (
                                <li
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      index === activeSuggestion
                                        ? "#406882"
                                        : "",
                                    color:
                                      index === activeSuggestion
                                        ? "white"
                                        : "black",
                                  }}
                                  onClick={() => {
                                    setCityName(suggestion);
                                    console.log("city", suggestion);
                                    setShowSuggestions(false);
                                    sessionStorage.setItem(
                                      "resumeCityName",
                                      suggestion
                                    );
                                    setActiveSuggestion(-1);
                                    scrollToIndex(-1);
                                  }}
                                >
                                  {suggestion}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}

                          <button
                            type="button"
                            class="btn btn-button input-group-btn "
                            id="_button"
                            onClick={submit}
                            style={{
                              borderBottomRightRadius: "39px",
                              borderTopRightRadius: "39px",
                            }}
                          >
                            Find Resumes
                          </button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Grid>
            </div>
          </Container>
        )}
      </div>
      <div>
        {isTablet && (
          <Container>
            <div className="jsmain_body">
              <Grid contianer spacing={1}>
                <Grid item sm={12} xs={12}>
                  <h3 className="js_title">2,000,000 + RESUMES</h3>
                </Grid>

                <div class="Searchblocks">
                  <form autocomplete="on">
                    <Grid container id="searchblock__">
                      <Grid item sm={4} md={4}>
                        <input
                          type="text"
                          class="form-control"
                          value={
                            jobtitle !== "" ? jobtitle.replace(/"/g, "") : ""
                          }
                          placeholder="Resume title"
                          onBlur={(e) => {
                            setTimeout(() => {
                              setShowTitleSuggestions(false);
                            }, [300]);
                          }}
                          onChange={(e) => {
                            setJobtitle(e.target.value);
                            getJobtitles(e.target.value);
                            sessionStorage.setItem(
                              "inputResumeTitle",
                              e.target.value
                            );
                            searchResContext.updateselectedFromSuggestions(
                              true
                            );
                          }}
                        />
                        {showTitleSuggestions && titleSuggestions.length > 0 ? (
                          <ul className="sm-12 col-md-4 col-lg-3 suggestionList">
                            {titleSuggestions.map((suggestion) => (
                              <li
                                onClick={() => {
                                  setJobtitle('"' + suggestion + '"');
                                  setShowTitleSuggestions(false);
                                  sessionStorage.setItem(
                                    "inputResumeTitle",
                                    '"' + suggestion + '"'
                                  );
                                }}
                              >
                                {suggestion}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <input
                          type="text"
                          class="form-control"
                          value={Skills}
                          placeholder="Skills"
                          onChange={(e) => {
                            setSkills(e.target.value);
                            sessionStorage.setItem(
                              "skillNDescriptionSearch",
                              e.target.value
                            );
                          }}
                        />
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <input
                          type="text"
                          class="form-control"
                          value={cityname}
                          id="exampleInputPassword1"
                          placeholder="Location"
                          onBlur={(e) => {
                            setTimeout(() => {
                              setShowSuggestions(false);
                            }, [300]);
                          }}
                          onChange={(e) => {
                            setCityName(e.target.value);
                            getCityNames(e.target.value);
                            sessionStorage.setItem(
                              "resumeCityName",
                              e.target.value
                            );
                          }}
                        />
                        {errorLocation === true ? (
                          <em style={{ color: "white" }}>
                            Please enter the correct location
                          </em>
                        ) : (
                          ""
                        )}

                        {showSuggestions && citySuggestions.length > 0 ? (
                          <ul className="sm-12 col-md-4 col-lg-3 suggestionList">
                            {citySuggestions.map((suggestion) => (
                              <li
                                onClick={() => {
                                  setCityName(suggestion);
                                  setShowSuggestions(false);
                                  sessionStorage.setItem(
                                    "resumeCityName",
                                    suggestion
                                  );
                                }}
                              >
                                {suggestion}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid item sm={12} md={12}>
                        <button
                          type="button"
                          class="btn btn-button "
                          id="_button"
                          onClick={submit}
                        >
                          Find Resumes
                        </button>
                      </Grid>
                    </Grid>
                    <Grid></Grid>
                  </form>
                </div>
              </Grid>
            </div>
          </Container>
        )}

        {isMobile && (
          <Container>
            <div className="jsmain_body">
              <Grid container>
                <Grid item sm={12} xs={12}>
                  <h3 className="js_title">2,000,000 + RESUMES</h3>
                </Grid>

                <div class="Searchblocks">
                  <form autocomplete="on">
                    <Grid container spacing={1} id="searchblock__">
                      <Grid item sm={12} xs={12}>
                        <input
                          type="text"
                          class="form-control"
                          value={
                            jobtitle !== "" ? jobtitle.replace(/"/g, "") : ""
                          }
                          placeholder="Resume title"
                          onBlur={(e) => {
                            setTimeout(() => {
                              setShowTitleSuggestions(false);
                            }, [300]);
                          }}
                          onChange={(e) => {
                            setJobtitle(e.target.value);
                            getJobtitles(e.target.value);
                            sessionStorage.setItem(
                              "inputResumeTitle",
                              e.target.value
                            );
                            searchResContext.updateselectedFromSuggestions(
                              true
                            );
                          }}
                        />
                        {showTitleSuggestions && titleSuggestions.length > 0 ? (
                          <ul className="sm-12 col-md-4 col-lg-3 suggestionList">
                            {titleSuggestions.map((suggestion) => (
                              <li
                                onClick={() => {
                                  setJobtitle('"' + suggestion + '"');
                                  setShowTitleSuggestions(false);
                                  sessionStorage.setItem(
                                    "inputResumeTitle",
                                    '"' + suggestion + '"'
                                  );
                                }}
                              >
                                {suggestion}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid item sm={12} md={3} lg={3}>
                        <input
                          type="text"
                          class="form-control"
                          value={Skills}
                          placeholder="Skills"
                          onChange={(e) => {
                            setSkills(e.target.value);
                            sessionStorage.setItem(
                              "skillNDescriptionSearch",
                              e.target.value
                            );
                          }}
                        />
                      </Grid>

                      <Grid item sm={12} md={3} lg={3}>
                        <input
                          type="text"
                          class="form-control"
                          value={cityname}
                          id="exampleInputPassword1"
                          placeholder="Location"
                          onBlur={(e) => {
                            setTimeout(() => {
                              setShowSuggestions(false);
                            }, [300]);
                          }}
                          onChange={(e) => {
                            setCityName(e.target.value);
                            getCityNames(e.target.value);
                            sessionStorage.setItem(
                              "resumeCityName",
                              e.target.value
                            );
                          }}
                        />
                        {errorLocation === true ? (
                          <em style={{ color: "white" }}>
                            Please enter the correct location
                          </em>
                        ) : (
                          ""
                        )}

                        {showSuggestions && citySuggestions.length > 0 ? (
                          <ul className="sm-12 col-md-4 col-lg-3 suggestionList">
                            {citySuggestions.map((suggestion) => (
                              <li
                                onClick={() => {
                                  setCityName(suggestion);
                                  setShowSuggestions(false);
                                  sessionStorage.setItem(
                                    "resumeCityName",
                                    suggestion
                                  );
                                }}
                              >
                                {suggestion}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid item sm={12} md={2} lg={1}>
                        <button
                          type="button"
                          class="btn btn-button "
                          id="_button"
                          onClick={submit}
                        >
                          Find Resumes
                        </button>
                      </Grid>
                    </Grid>
                    <Grid></Grid>
                  </form>
                </div>
              </Grid>
            </div>
          </Container>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Jssearchblock)
);
