import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useMediaQuery } from '@mui/material';

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
    
  const isMatchLGL = useMediaQuery("(min-width:1025px)");
  const handlePageChange = (event, newPage) => {
    onPageChange(newPage);
  };

  return (
    <Stack direction="row" justifyContent="center">
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        variant="text"
        shape="circular"
        size="small"
        boundaryCount={isMatchLGL ? 2 : 1} 
  siblingCount={0} 
        sx={{
          '& .MuiPaginationItem-page': {
            fontSize: isMatchLGL ? '0.65rem' : '0.55rem',
            
          },
            '& .MuiPaginationItem-page.Mui-selected': {
                bgcolor:'#406882',
                color:'#fff',
                fontSize: isMatchLGL ? '0.75rem' : '0.65rem',
            }
        }}
      />
    </Stack>
  );
};

export default PaginationComponent;
// import * as React from 'react';
// import TablePagination from '@mui/material/TablePagination';

// export default function PaginationComponent({ currentPage, totalPages, onPageChange, rowsPerPage, onRowsChange }) {
//   const [page, setPage] = React.useState(1);
//   const [rowsPerPage1, setRowsPerPage1] = React.useState(10);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//     onPageChange(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage1(parseInt(event.target.value, 10));
//     setPage(0);
//     onRowsChange(event.target.value);
//   };

//   return (
//     <TablePagination
//       component="div"
//       count={totalPages}
//       page={page}
//       onPageChange={handleChangePage}
//       rowsPerPage={rowsPerPage1}
//       onRowsPerPageChange={handleChangeRowsPerPage}
//     />
//   );
// }