import {
  BusinessCenterRounded,
  EmailRounded,
  FmdGoodOutlined,
  MoreVertRounded,
  PhoneRounded,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputBase,
  List,
  ListItemButton,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar";
import moment from "moment";
import Key from "../../clientVariables.json";
import { useLocation, useHistory } from "react-router-dom";
import { hideLoader, showLoader } from "../Actions/loader.actions";
import axios from "axios";
import { FaListCheck, FaUserGraduate } from "react-icons/fa6";
import { MdFlight, MdOutlineWorkHistory } from "react-icons/md";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { styled, alpha } from "@mui/material/styles";
import { TiSocialLinkedin } from "react-icons/ti";
import JobFilters from "../Jobseeker/JobFilters";
import { solrJobsFilterData } from "../Jobseeker/js_solr_filters_data";
import { pageChangeActions } from "../Actions/pageStartActions";
import JobRowsSelect from "../Jobseeker/JobRowsSelect";
import PaginationComponent from "../Jobseeker/PaginationComponent";
import { lowercasedUsStatesArray } from "../Jobseeker/UsStates";
import {
  clearURLjobSearchUrlActions,
  setURLjobSearchUrlActions,
} from "../Actions/jobSearchUrlActions";
import { buildSolrQuery } from "../../utils/solrQueryBuilder";
import { toast } from "react-toastify";

const Bullet = styled("span")({
  display: "inline-block",
  width: "3px",
  height: "3px",
  borderRadius: "50%",
  backgroundColor: "currentColor",
});

const SearchJobs_list = () => {
  const location = useLocation();
  // const {
  //   response: jobsData,
  //   jobtitle: jobTitleFromHome,
  //   location: jobLocation,
  //   // url: url_
  // } = location.state;
  const currentUser = useSelector((state) => state.UserReducer.user);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [similarJobs, setSimilarJobs] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [filters, setFilters] = useState({});
  const [selectedJobCompanyDetails, setSelectedJobCompanyDetails] = useState(
    {}
  );
  const [selectedJobRecruiterDetails, setSelectedJobRecruiterDetails] =
    useState({});
  const [showToolbar, setShowToolbar] = useState(false);
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const history = useHistory();
  const [clearInputs, setClearInputs] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageStartNum, setPageStartNum] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [totalJobsCount, setTotalJobsCount] = useState();
  const redirectionSearchJobsUrl = useSelector(
    (state) => state.jobSearchUrlReducer
  );
  const pageStartValue = useSelector(
    (state) => state.pageStartValReducer.pageStartValue
  );
  const [redirecturl, setRedirectUrl] = useState(
    redirectionSearchJobsUrl?.redirectTo
  );

  const [jobtitleInputValue, setJobtitleInputValue] = useState(
   ""
  );
  const [statenameInputValue, setStateNameInputValue] = useState("");
  const [cityNameInputValue, setCityNameInputValue] = useState(
     ""
  );
  const [jobtitles, setJobtitles] = useState([]);
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [titleSuggestions, setTitleSuggestions] = useState([]);
  const [showTitleSuggestions, setShowTitleSuggestions] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const suggestionListRef = useRef(null);

  const isMatchLGS = useMediaQuery("(min-width:801px)");
  const isMatchLGL = useMediaQuery("(min-width:1025px)");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops


  useEffect(() => {
    setJobtitleInputValue(sessionStorage.getItem("inputjobtitle_home"));
    setCityNameInputValue(sessionStorage.getItem("cityname_home"));
    sessionStorage.removeItem("inputjobtitle_home");
    sessionStorage.removeItem("cityname_home");
    if (gridRef.current) {
      gridRef.current.addEventListener("scroll", handleToolbarScroll);
    }

    return () => {
      if (gridRef.current) {
        gridRef.current.removeEventListener("scroll", handleToolbarScroll);
      }
    };
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        console.log("filters", filters);

        if(Object.keys(filters).length !== 0){
          await fetchData(filters);
        }else if (pageStartNum || currentPage || rowsPerPage || redirectionSearchJobsUrl?.shouldRedirect){
          await fetchData(filters);
        }
        
        
        // setRedirectUrl(redirectionSearchJobsUrl?.redirectTo);
        // if (redirectionSearchJobsUrl?.redirectTo !== redirecturl) {
        //   handleClearFilters();
        // }
        console.log("start numf", pageStartNum);
      } catch (error) {
        console.error(error);
      }
    };
    fetch();
    console.log("redirectionSearchJobsUrl", redirectionSearchJobsUrl);
  }, [filters, pageStartNum, currentPage, rowsPerPage]);

  useEffect(() => {
    setCurrentPage(1);
    setPageStartNum(0);
    dispatch(pageChangeActions(0));
  }, [totalPages]);
  

  const handleToolbarScroll = () => {
    const yOffset = gridRef.current.scrollTop;
    const triggerScroll = 200; // Adjust as needed

    if (yOffset > triggerScroll) {
      setShowToolbar(true);
    } else {
      setShowToolbar(false);
    }
  };

  
  useEffect(() => {
    if(clearInputs){
     
       setClearInputs((prevClearInputs) => !prevClearInputs);
      //  setSelectedLocation(null);
    }
  }, [clearInputs])

  const handleFilterChange = (filterCategory, selectedValues) => {
    setFilters({ ...filters, [filterCategory]: selectedValues });
  };
  const handleClearFilters = () => {
    setFilters({});
    setJobs([]);
    setClearInputs(!clearInputs); // Toggle clearInputs state to trigger input clearing
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    let startNum = rowsPerPage * newPage - rowsPerPage + 1;
    setPageStartNum(startNum);
    dispatch(pageChangeActions(startNum));
  };
  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  // const currentJobs = jobs.slice(indexOfFirstItem, indexOfLastItem);

  const handleRowsChange = (selectedRows) => {
    setRowsPerPage(selectedRows);
    setCurrentPage(1);
  };

  const onTextChanged = (value) => {
    setJobtitleInputValue(value);
  };

  const onCityChanged = (value) => {
    console.log("onCityChange", value);
    setCityNameInputValue(value);
    var cityAndState = value.split(",");
    var cityName = cityAndState[0];
    var StateName = cityAndState[1];
  };

  const getCityNames = (value) => {
    setShowSuggestions(true);
    axios
      .post(`${Key.domain}/Jobs/get_joblocations`, {
        statename: value,
      })
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data?.length === 0) {
          //setErrorLocation(true);
          setShowSuggestions(false);
        } else {
          setErrorLocation(false);
          setCitySuggestions(
            response.data.data.map((res) =>
              res.cityName.concat(" , ", res.stateName)
            )
          );
        }
      });
  };

  const getJobtitles = (value) => {
    setShowTitleSuggestions(true);
    axios
      .post(`${Key.domain}/Jobs/get_jobtitles`, {
        jobtitle: value,
      })
      .then((response) => {
        console.log(response.data.data);
        setTitleSuggestions(response.data.data.map((res) => res.title));
      });
  };

  const fetchData = async (filterValues
    // , pageStartNum, rowsPerPage, redirectionSearchJobsUrl, primaryResumeDetails, cityNameInputValue, jobtitleInputValue
    ) => {
    console.log("filterValues", filterValues, redirectionSearchJobsUrl);
    setJobs([]);
    setSelectedJob({});
    setSelectedJobRecruiterDetails({});
    setSimilarJobs([]);
    dispatch(showLoader());
  
    try {
      let searchUrl =await null;

      const searchText =await redirectionSearchJobsUrl?.searchText;
      const skills =await searchText && typeof searchText === 'string'
        ? searchText
            .trim()
            .split(/[ ,]+/)
            .filter((skill) => skill.trim().length > 0)
        : null;
  
    const role = redirectionSearchJobsUrl?.searchText || null;
  

      searchUrl =await buildSolrQuery({
        role: role ? role : null,
        company: null,
        skills: skills,
        id: null,
        location: null, 
        filterValues: filterValues,
        pageStartNum: pageStartNum, 
        rowsPerPage: rowsPerPage, 
        redirectionSearchJobsUrl: redirectionSearchJobsUrl,
        primaryResumeDetails: null, 
        searchType: redirectionSearchJobsUrl?.searchType,
      });
  
      console.log("searchUrl", searchUrl);
  
      if(searchUrl){
      const response = await axios.post(`${Key.domain}/solr/solr_jobs`, {
        url: searchUrl,
      });
  
      if(response.data.data){
        console.log("response.data.data.response?.docs", response.data.data.response?.docs);
        setJobs(response.data.data.response?.docs);
      getJobDetails(response.data.data.response?.docs[0]?.id);
      getSimilarJobs(response.data.data.response?.docs[0]);
      setTotalJobsCount(response.data.data.response?.numFound);
      let totalPages = Math.ceil(response.data.data.response?.numFound / rowsPerPage);
      setTotalPages(totalPages);
      }else if(response.data.data.response?.numFound === 0){
        toast.warning("Data Not Found.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000, 
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }else{
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000, 
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      }
      else{
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000, 
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
      dispatch(hideLoader());
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(hideLoader());
      toast.error("Something went wrong.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  
    dispatch(hideLoader());
  };
  
  const jobCardClickHandler = async (job, index) => {
    const jobData = jobs.filter((data) => job.id === data.id);
    getJobDetails(job.id);
    getSimilarJobs(job);
    console.log("job data", jobData);
    console.log("job id", job.id);
    setSelectedJob(jobData[0]);
  };

  const getJobDetails = async (jobId) => {
    console.log("i am called");
    dispatch(showLoader());
    setSelectedJob({});
    setSelectedJobCompanyDetails({});
    setSelectedJobRecruiterDetails({});
    try {
      const response = await axios.post(`${Key.domain}/Jobs/get_job_details`, {
        id: jobId,
      });
      if (response.status === 200) {
        console.log(response.data.data[0]?.jobDetails);
        console.log(response.data.data[0]?.jobDetails?.companyDetails[0]);
        setSelectedJob(response.data.data[0]?.jobDetails);
        if (response.data.data[0].jobDetails?.companyDetails[0]) {
          console.log("i am called companyDetails");
          setSelectedJobCompanyDetails(
            response.data.data[0].jobDetails.companyDetails[0]
          );

          console.log(
            "after",
            response.data.data[0].jobDetails.companyDetails[0]
          );
          if (
            response.data.data[0].jobDetails.companyDetails[0]?.recruiterdetails
          ) {
            console.log("i am called recruiterdetails");
            setSelectedJobRecruiterDetails(
              response.data.data[0].jobDetails.companyDetails[0]
                .recruiterdetails
            );
          }
        }
        //checkApplySaveJob(props.jobDetails.id);
        // checkApplySave(jobId);

        dispatch(hideLoader());
      } else {
        console.log("response.status === 200 false get_job_details");
        dispatch(hideLoader());
      }
    } catch (error) {
      console.error(error);
      dispatch(hideLoader());
    }
    dispatch(hideLoader());

    // setSelectedJob()
  };

  const getSimilarJobs = async (selectedJob) => {
    console.log("getSimilarJobs", selectedJob);
    let role =
      selectedJob?.title !== null &&
      selectedJob?.title !== undefined &&
      selectedJob.title !== ""
        ? selectedJob.title
        : "*:*";
    console.log("role", role);
    const searchUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=${
      role ? '"' + role + '"' : "*:*"
    }OR companyName:(${ role ? '"' + role + '"' : "*:*"}) OR id:(${ role ? '"' + role + '"' : "*:*"}) &rows=10&start=0&fq=status:(1)&sort=modified%20desc&wt=json`;
    try {
      const response = await axios.post(`${Key.domain}/solr/solr_jobs`, {
        url: searchUrl,
      });
      if (response.status === 200) {
        console.log(response.data.data.response.docs);
        setSimilarJobs(response.data.data.response.docs);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const redirectToCompanyView = (compId) => {
    console.log("redirectToCompanyView", compId);
    history.push("/company/view/comp_" + compId);
  };

  const redirectToJobView = (job) => {
    console.log("redirectToJobViewcalled");
    sessionStorage.setItem("job_Name", job?.title);
    history.push({
      pathname: "/job/view/Job_" + job?.id,
      state: {
        Jobs: "yes",
      },
    });
  };

  const replacements = {
    "#": "%23",
    "++": "%2B%2B",
    "&": "%26",
    " / ": "/",
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function replaceSpecialCharacters(inputString, replacementMap) {
    let modifiedString = inputString;

    for (const [specialChar, replacement] of Object.entries(replacementMap)) {
      const regex = new RegExp(escapeRegExp(specialChar), "g");
      modifiedString = modifiedString.replace(regex, replacement);
    }

    return modifiedString;
  }


  const findJobsHandler = async (title, loca) => {
    await handleClearFilters();

    // await dispatch(clearURLjobSearchUrlActions());

    console.log("jobtitleInputValue", title, loca);

    // if(jobtitleInputValue && jobtitleInputValue !== "" && cityNameInputValue && cityNameInputValue !== ''){
    //   dispatch(setURLjobSearchUrlActions("", jobtitleInputValue, cityNameInputValue, null));
    // }else if(jobtitleInputValue && jobtitleInputValue !== ""){
    //   dispatch(setURLjobSearchUrlActions("", jobtitleInputValue, null, 'title'));
    // }else if (cityNameInputValue && cityNameInputValue !== "") {
    //   dispatch(setURLjobSearchUrlActions("", null, cityNameInputValue, 'location'));
    // }

    
    // await setRedux(title, loca);
    

    try {
      await fetchData(filters);
    } catch (error) {
      console.error("Error fetching solr data:", error);
      toast.error("Failed to fetch data", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

  };

  const scrollToIndex = (index) => {
    if (suggestionListRef.current) {
      const suggestionItemHeight = 30; // Adjust this value as needed
      suggestionListRef.current.scrollTop = index * suggestionItemHeight;
    }
  };

  return (
    <>
      <Navbar />
      <Grid container spacing={1} sx={{ bgcolor: "#f2f6fd" }}>
        <Grid item xs={12}>
          <Paper elevation={1} sx={{ p: "10px", bgcolor: "#f2f6fd" }}>
            <Paper
              elevation={0}
              component="form"
              sx={{
                p: "10px 10px",
                display: "flex",
                alignItems: "center",
                borderRadius: "6px",
                // boxShadow: "0px 18px 40px rgba(25, 15, 9, 0.2)",
                // mt: 4,
                zIndex: 1100,
              }}
            >
              <Search sx={{ color: "#ACB6C1" }} />
              <InputBase
                sx={{ ml: 1, flex: 1, color: "#444444" }}
                placeholder="Job Title, Skills, Company..."
                inputProps={{ "aria-label": "search google maps" }}
                onBlur={(e) => {
                  setTimeout(() => {
                    setShowTitleSuggestions(false);
                  }, [300]);
                }}
                value={
                  jobtitleInputValue &&
                  jobtitleInputValue !== "" &&
                  jobtitleInputValue !== undefined &&
                  jobtitleInputValue !== null
                    ? jobtitleInputValue?.replace(/"/g, "")
                    : ""
                }
                onChange={(e) => {
                  onTextChanged(e.target.value);
                  getJobtitles(e.target.value);
                  sessionStorage.setItem("inputjobtitle", e.target.value);
                  setCurrentIndex(-1);
                  dispatch(setURLjobSearchUrlActions("", e.target.value, redirectionSearchJobsUrl?.location, null));
                }}
                onKeyDown={(e) => {
                  if (titleSuggestions.length > 0) {
                    if (e.key === "ArrowUp" && currentIndex > 0) {
                      setCurrentIndex(currentIndex - 1);
                      scrollToIndex(currentIndex - 1);
                    } else if (
                      e.key === "ArrowDown" &&
                      currentIndex < titleSuggestions.length - 1
                    ) {
                      setCurrentIndex(currentIndex + 1);
                      scrollToIndex(currentIndex + 1);
                    } else if (e.key === "Enter" && currentIndex !== -1) {
                      e.preventDefault();

                      let entertitle =
                        '"' + titleSuggestions[currentIndex] + '"';
                      setJobtitleInputValue(entertitle);
                      console.log("enter", entertitle);
                      setShowTitleSuggestions(false);
                      sessionStorage.setItem(
                        "inputjobtitle",
                        '"' + titleSuggestions[currentIndex] + '"'
                      );
                      dispatch(setURLjobSearchUrlActions("", titleSuggestions[currentIndex], redirectionSearchJobsUrl?.location, null));

                    }
                  }
                }}
              />
              {showTitleSuggestions && titleSuggestions.length > 0 ? (
                <ul
                  className="col-3 suggestionList "
                  ref={suggestionListRef}
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    marginBottom: "-150px",
                  }}
                >
                  {titleSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      style={{
                        backgroundColor:
                          index === currentIndex ? "#406882" : "",
                        color: index === currentIndex ? "white" : "black",
                      }}
                      onClick={() => {
                        setJobtitleInputValue(suggestion);
                        sessionStorage.setItem("inputjobtitle", suggestion);
                        setShowTitleSuggestions(false);
                        setCurrentIndex(-1);
                        scrollToIndex(-1);
                        dispatch(setURLjobSearchUrlActions("", suggestion, redirectionSearchJobsUrl?.location, null));
                      }}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
              <Box sx={{ height: 22, m: 0.5, border: "1px solid #B4C0E1" }} />
              <FmdGoodOutlined sx={{ color: "#ACB6C1" }} />
              <InputBase
                sx={{ ml: 1, flex: 1, color: "#444444" }}
                placeholder="Location"
                value={cityNameInputValue}
                inputProps={{ "aria-label": "search google maps" }}
                onChange={(e) => {
                  onCityChanged(e.target.value);
                  getCityNames(e.target.value);
                  setActiveSuggestion(-1);
                  dispatch(setURLjobSearchUrlActions("", redirectionSearchJobsUrl?.searchText, e.target.value, null));
                }}
                onKeyDown={(e) => {
                  if (citySuggestions.length > 0) {
                    if (
                      e.key === "ArrowDown" &&
                      activeSuggestion < citySuggestions.length - 1
                    ) {
                      setActiveSuggestion(activeSuggestion + 1);
                      scrollToIndex(activeSuggestion + 1);
                      e.preventDefault();
                    } else if (e.key === "ArrowUp" && activeSuggestion > 0) {
                      setActiveSuggestion(activeSuggestion - 1);
                      scrollToIndex(activeSuggestion - 1);
                      e.preventDefault();
                    } else if (
                      e.key === "Enter" &&
                      activeSuggestion >= 0 &&
                      activeSuggestion < citySuggestions.length
                    ) {
                      e.preventDefault();

                      onCityChanged(citySuggestions[activeSuggestion]);
                      setShowSuggestions(false);
                      sessionStorage.setItem(
                        "cityname",
                        citySuggestions[activeSuggestion]
                      );
                      dispatch(setURLjobSearchUrlActions("", redirectionSearchJobsUrl?.searchText, citySuggestions[activeSuggestion], null));
                    }
                  }
                }}
                onBlur={(e) => {
                  setTimeout(() => {
                    setShowSuggestions(false);
                  }, [300]);
                }}
              />
              {errorLocation === true ? (
                <em style={{ color: "white" }}>
                  Please enter the correct location
                </em>
              ) : (
                ""
              )}
              {showSuggestions && citySuggestions.length > 0 ? (
                <ul
                  className="col-3 suggestionList "
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    marginBottom: "-150px",
                    marginLeft: isDesktopLarge ? "700px" : "420px",
                  }}
                  //sx={{ mb: 0, flex: 1, color: "#444444" }}
                  ref={suggestionListRef}
                >
                  {citySuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      style={{
                        backgroundColor:
                          index === activeSuggestion ? "#406882" : "",
                        color: index === activeSuggestion ? "white" : "black",
                      }}
                      onClick={() => {
                        setCityNameInputValue(suggestion);
                        setShowSuggestions(false);
                        setActiveSuggestion(-1);
                        scrollToIndex(-1);
                        dispatch(setURLjobSearchUrlActions("", redirectionSearchJobsUrl?.searchText, suggestion, null));
                      }}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
              <Button
                variant="contained"
                size="large"
                sx={{ bgcolor: "#F68606", "&:hover": { bgcolor: "#3C65F6" } }}
                onClick={() => findJobsHandler(jobtitleInputValue, cityNameInputValue)}
              >
                Find Jobs
              </Button>
            </Paper>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={2}
            sx={{
              display: "flex",
              padding: "8px",
              bgcolor: "rgba(64, 104, 130, 0.1)",
              alignItems: "center",
              height: "50px",
            }}
          >
            <Typography paragraph sx={{ margin: 0, fontSize: "14px" }}>
              Filter By :{" "}
            </Typography>
            <JobFilters
              label="Sort By"
              options={solrJobsFilterData.sortBy.map((option) => ({
                value: option.value,
                text: option.text,
              }))}
              isMultiple={false}
              onFilterChange={handleFilterChange}
              clearInputs={clearInputs}
            />
            <JobFilters
              label="Job Type"
              options={solrJobsFilterData.jobTypes.map((option) => ({
                value: option.value,
                text: option.text,
              }))}
              isMultiple={true}
              onFilterChange={handleFilterChange}
              clearInputs={clearInputs}
            />
            <JobFilters
              label="Posted Date"
              options={solrJobsFilterData.postedDate.map((option) => ({
                value: option.value,
                text: option.text,
              }))}
              isMultiple={false}
              onFilterChange={handleFilterChange}
              clearInputs={clearInputs}
            />
            <JobFilters
              label="Visa Type"
              options={solrJobsFilterData.visaType.map((option) => ({
                value: option.value,
                text: option.text,
              }))}
              isMultiple={true}
              onFilterChange={handleFilterChange}
              clearInputs={clearInputs}
            />
            <JobFilters
              label="Work Mode"
              options={solrJobsFilterData.workMode.map((option) => ({
                value: option.value,
                text: option.text,
              }))}
              isMultiple={false}
              onFilterChange={handleFilterChange}
              clearInputs={clearInputs}
            />
            <JobFilters
              label="Travel"
              options={solrJobsFilterData.travel.map((option) => ({
                value: option.value,
                text: option.text,
              }))}
              isMultiple={true}
              onFilterChange={handleFilterChange}
              clearInputs={clearInputs}
            />
            <Button
              variant="outlined"
              size="small"
              sx={{
                textTransform: "capitalize",
                borderRadius: "30px",
                padding: "0px 20px opx 20px",
                border: "1px solid #e97d44",
                color: "#e97d44",
              }}
              onClick={handleClearFilters}
            >
              Clear Filters
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={isMobile || isTablet ? 12:3}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper
                elevation={2}
                sx={{
                  height: "600px",
                  overflow: "scroll",
                  borderRadius: "8px",
                }}
              >
                <List>
                  {jobs?.length > 0 ? (
                    jobs.map(
                      (job, index) => (
                        // <ListItem sx={{borderBottom:'1px solid #ddd'}}>
                        <ListItemButton
                          // onClick={() => setSelectedItem(index)}
                          key={index}
                          selected={selectedItem === index}
                          disableRipple
                          sx={{ borderBottom: "1px solid #ddd" }}
                          onClick={() => {
                            jobCardClickHandler(job, index);
                            setSelectedItem(index);
                          }}
                        >
                          <Grid container spacing={0}>
                            <Grid item xs={isMatchLGL ? 2 : 2.3}>
                              <Avatar
                                src={
                                  job?.companyid
                                    ? `${Key.domain}/company/company_logo/${job?.companyid}/getcompanynamebyquery`
                                    : ""
                                }
                                sx={{
                                  height: isDesktopSmall ? "30px" : "40px",
                                  width: isDesktopSmall ? "30px" : "40px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={isMatchLGL ? 10 : 9.7}>
                              <Stack direction="column" sx={{ width: "100%" }}>
                                <Typography
                                  paragraph
                                  sx={{
                                    margin: 0,
                                    color: "rgba(0,0,0,0.7)",
                                    fontWeight: 600,
                                    fontSize: isDesktopLarge
                                      ? "0.85rem"
                                      : "0.75rem",
                                    maxWidth: isDesktopLarge
                                      ? "200px"
                                      : "150px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {job?.title}
                                </Typography>
                                <Typography
                                  paragraph
                                  sx={{
                                    margin: 0,
                                    color: "rgba(0, 0, 0, 0.56)",
                                    fontWeight: 400,
                                    fontSize: isDesktopLarge
                                      ? "0.75rem"
                                      : "0.65rem",
                                    textTransform: "capitalize",
                                    maxWidth: isDesktopLarge
                                      ? "200px"
                                      : "150px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {job?.companyName}
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    paragraph
                                    sx={{
                                      margin: 0,
                                      color: "rgba(0,0,0,0.5)",
                                      fontSize: isDesktopLarge
                                        ? "0.75rem"
                                        : "0.65rem",
                                      maxWidth: isDesktopLarge
                                        ? "170px"
                                        : "100px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {`${
                                      job?.cityName &&
                                      job?.cityName !== "N/A" &&
                                      job?.cityName !== null &&
                                      job?.stateName &&
                                      job?.stateName !== "N/A" &&
                                      job?.stateName !== null &&
                                      job?.shortRegion &&
                                      job?.shortRegion !== "N/A" &&
                                      job?.shortRegion !== null
                                        ? job?.cityName +
                                          // ", " +
                                          // job?.stateName +
                                          ", " +
                                          job?.shortRegion
                                        : "(Remote)"
                                    }`}
                                    {/* {`${
                              job?.cityName &&
                              job?.cityName !== "N/A" &&
                              job?.cityName !== null
                                ? job?.cityName + ", "
                                : ""
                            } ${
                              job?.stateName &&
                              job?.stateName !== "N/A" &&
                              job?.stateName !== null
                                ? job?.stateName + ", "
                                : ""
                            } ${
                              job?.shortRegion &&
                              job?.shortRegion !== "N/A" &&
                              job?.shortRegion !== null
                                ? job?.shortRegion
                                : "(Remote)"
                            }`} */}
                                  </Typography>
                                  <Typography
                                    paragraph
                                    sx={{
                                      margin: 0,
                                      color: "rgba(0,0,0,0.5)",
                                      fontWeight: 500,
                                      fontSize: isMatchLGL
                                        ? "0.75rem"
                                        : "0.65rem",
                                    }}
                                  >
                                    {
                                      job.modified !== undefined &&
                                      job.modified !== null
                                        ? // ? moment(job.modified).fromNow() !==
                                          //   "Invalid date"
                                          moment(job.modified).fromNow()
                                        : moment(job.created).fromNow()
                                      //   : moment(
                                      //       "2022-03-31T06:26:04.138Z"
                                      //     ).fromNow()
                                      // : moment(
                                      //     "2022-03-31T06:26:04.138Z"
                                      //   ).fromNow()
                                    }
                                  </Typography>
                                </Box>
                              </Stack>
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      )
                      // </ListItem>
                    )
                  ) : (
                    <></>
                  )}
                </List>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="column" component={Card} sx={{ p: 1 }} gap={2}>
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    paragraph
                    sx={{
                      margin: 0,
                      fontSize: isMatchLGL ? "0.75rem" : "0.65rem",
                    }}
                  >
                    Page <strong>{currentPage}</strong> of{" "}
                    <strong>{totalJobsCount}</strong> Jobs
                  </Typography>
                  <JobRowsSelect
                    rowsPerPage={rowsPerPage}
                    onRowsChange={handleRowsChange}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isMobile || isTablet ? 12:5}>
          <Box
            sx={{
              maxHeight: "700px",
              overflowY: "scroll",
              position: "relative",
            }}
            ref={gridRef}
          >
            {showToolbar && Object.keys(selectedJob)?.length !== 0 && (
              <Toolbar
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 3,
                  backgroundColor: "#fff",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <Card sx={{ boxShadow: "none", width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Stack direction="column">
                      <Typography
                        // onClick={() => redirectToJobView(selectedJob)}
                        component="body"
                        paragraph
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: 600,
                          cursor: "pointer",
                          margin: 0,
                          fontSize: isMatchLGL ? "16px" : "14px",
                          "&:hover": {
                            textDecoration: "underline",
                            textDecorationColor: "#2196f3",
                          },
                        }}
                      >
                        {selectedJob?.title}
                      </Typography>
                      <Breadcrumbs
                        separator={<Bullet />}
                        aria-label="breadcrumb"
                      >
                        {selectedJob?.companyname && (
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 400,
                              textTransform: "capitalize",
                              fontSize: isMatchLGL ? "12px" : "10px",
                              cursor: "pointer",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                            onClick={() =>
                              redirectToCompanyView(selectedJob?.companyid)
                            }
                          >
                            {selectedJob?.companyname}
                          </Typography>
                        )}
                        <Typography
                          paragraph
                          sx={{
                            margin: 0,
                            fontWeight: 400,
                            textTransform: "capitalize",
                            fontSize: isMatchLGL ? "12px" : "10px",
                          }}
                        >{`${
                          selectedJob?.cityname &&
                          selectedJob?.cityname !== "N/A" &&
                          selectedJob?.cityname !== null &&
                          selectedJob?.statename &&
                          selectedJob?.statename !== "N/A" &&
                          selectedJob?.statename !== null &&
                          selectedJob?.shortRegion &&
                          selectedJob?.shortRegion !== "N/A" &&
                          selectedJob?.shortRegion !== null
                            ? selectedJob?.cityname +
                              ", " +
                              selectedJob?.statename +
                              ", " +
                              selectedJob?.shortRegion
                            : "(Remote)"
                        }`}</Typography>
                        {/* <Typography
                        paragraph
                        sx={{
                          margin: 0,
                          color: "#757575",
                          fontWeight: 500,
                     gridRefApply to     fontSize: "14px",
                        }}
                      >
                        {selectedJob.created !== undefined &&
                        selectedJob.created !== null
                          ? moment(selectedJob.created).fromNow() !==
                            "Invalid date"
                            ? moment(selectedJob.created).fromNow()
                            : moment("2022-03-31T06:26:04.138Z").fromNow()
                          : moment("2022-03-31T06:26:04.138Z").fromNow()}
                      </Typography> */}
                      </Breadcrumbs>
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                      }}
                    >
                      <Stack direction="row" gap={1}>
                        <Button
                          variant="contained"
                          disableElevation
                          size="small"
                          sx={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            bgcolor: "#53778e",
                            textTransform: "capitalize",
                            "&:hover": {
                              backgroundColor: "#406882 !important",
                              color: "white !important", // Replace with the hover color you want
                            },
                          }}
                          onClick={() => history.push("/Login")}
                          // onClick={() => handleOpenJobApplyProcess()}
                          // disabled={apply}
                        >
                          Apply
                        </Button>
                        <Button
                          onClick={() => history.push("/Login")}
                          variant="outlined"
                          size="small"
                          // disabled={save}
                          sx={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            textTransform: "capitalize",
                            borderColor: "#53778e",
                            color: "#53778e",
                          }}
                        >
                          Save
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </Card>
              </Toolbar>
            )}
            {Object.keys(selectedJob)?.length !== 0 ? (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      borderRadius: "8px",
                      boxShadow: "none",
                      padding: "10px",
                    }}
                  >
                    <Stack direction="column" gap={2}>
                      <Stack direction="column">
                        <Typography
                          component="body"
                          variant={isMatchLGL ? "h5" : "h6"}
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: 500,
                            cursor: "pointer",
                            "&:hover": {
                              textDecoration: "underline",
                              textDecorationColor: "#2196f3",
                            },
                          }}
                          // onClick={() => redirectToJobView(selectedJob)}
                        >
                          {selectedJob?.title}&nbsp;#{selectedJob?.id}
                        </Typography>
                        <Breadcrumbs
                          separator={<Bullet />}
                          aria-label="breadcrumb"
                        >
                          {selectedJob?.companyname && (
                            <Typography
                              paragraph
                              sx={{
                                margin: 0,
                                fontWeight: 400,
                                textTransform: "capitalize",
                                fontSize: isMatchLGL ? "0.85rem" : "0.75rem",
                                cursor: "pointer",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={() =>
                                redirectToCompanyView(selectedJob?.companyid)
                              }
                            >
                              {selectedJob?.companyname}
                            </Typography>
                          )}
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 400,
                              textTransform: "capitalize",
                              fontSize: isMatchLGL ? "0.85rem" : "0.75rem",
                            }}
                          >{`${
                            selectedJob?.cityname &&
                            selectedJob?.cityname !== "N/A" &&
                            selectedJob?.cityname !== null &&
                            selectedJob?.statename &&
                            selectedJob?.statename !== "N/A" &&
                            selectedJob?.statename !== null
                              ? // &&
                                // selectedJob?.shortRegion &&
                                // selectedJob?.shortRegion !== "N/A" &&
                                // selectedJob?.shortRegion !== null
                                selectedJob?.cityname +
                                ", " +
                                selectedJob?.statename
                              : //  +
                                // ", " +
                                // selectedJob?.shortRegion
                                "(Remote)"
                          }`}</Typography>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              color: "#757575",
                              fontWeight: 500,
                              fontSize: isMatchLGL ? "0.85rem" : "0.75rem",
                            }}
                          >
                            {selectedJob.modified !== undefined &&
                            selectedJob.modified !== null
                              ? moment(selectedJob.modified).fromNow()
                              : moment(selectedJob.created).fromNow()}
                          </Typography>
                        </Breadcrumbs>
                      </Stack>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Stack direction="column" gap={1}>
                          <Tooltip
                            title={
                              <Typography>
                                {/* Your complete text here */}
                                <BusinessCenterRounded
                                  sx={{
                                    height: isMatchLGL ? "20px" : "18px",
                                    width: isMatchLGL ? "20px" : "18px",
                                    color: "#757575",
                                    marginRight: "5px",
                                  }}
                                />
                                {selectedJob.jobtypetitle !== undefined &&
                                selectedJob.jobtypetitle !== null
                                  ? selectedJob.jobtypetitle
                                  : "Full Time"}
                                &nbsp;&nbsp;
                                {selectedJob.noofjobs !== undefined &&
                                selectedJob.noofjobs !== null &&
                                parseInt(selectedJob.noofjobs) !== 0
                                  ? `(Positions : ${selectedJob.noofjobs})`
                                  : "(Positions : 1)"}
                              </Typography>
                            }
                            followCursor
                            // arrow
                            // placement="top"
                            enterTouchDelay={500}
                          >
                            <Typography
                              paragraph
                              sx={{
                                margin: 0,
                                display: "flex",
                                alignItems: "end",
                                gap: "2px",
                                fontSize: isMatchLGL ? "0.85rem" : "0.75rem",
                                minWidth: "80px",
                                maxWidth: isMatchLGL ? "400px" : "200px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {/* Your abbreviated content here */}
                              <BusinessCenterRounded
                                sx={{
                                  height: isMatchLGL ? "20px" : "18px",
                                  width: isMatchLGL ? "20px" : "18px",
                                  color: "#757575",
                                  marginRight: "5px",
                                }}
                              />
                              {selectedJob.jobtypetitle !== undefined &&
                              selectedJob.jobtypetitle !== null
                                ? selectedJob.jobtypetitle
                                : "Full Time"}
                              &nbsp;&nbsp;
                              {selectedJob.noofjobs !== undefined &&
                              selectedJob.noofjobs !== null &&
                              parseInt(selectedJob.noofjobs) !== 0
                                ? `(Positions : ${selectedJob.noofjobs})`
                                : "(Positions : 1)"}
                            </Typography>
                          </Tooltip>

                          <Tooltip
                            title={
                              selectedJob.visatypeName !== undefined &&
                              selectedJob.visatypeName !== null
                                ? selectedJob.visatypeName
                                : "H1"
                            }
                            followCursor
                            // arrow
                            // placement="top"
                            enterTouchDelay={500}
                          >
                            <Typography
                              paragraph
                              sx={{
                                margin: 0,
                                fontSize: isMatchLGL ? "0.85rem" : "0.75rem",
                                display: "flex",
                                alignItems: "end",
                                gap: "2px",
                                maxWidth: isMatchLGL ? "400px" : "200px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <MdFlight
                                style={{
                                  height: isMatchLGL ? "20px" : "18px",
                                  width: isMatchLGL ? "20px" : "18px",
                                  color: "#757575",
                                  marginRight: "5px",
                                }}
                              />
                              {selectedJob.visatypeName !== undefined &&
                              selectedJob.visatypeName !== null
                                ? selectedJob.visatypeName
                                : "H1"}
                            </Typography>
                          </Tooltip>

                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontSize: isMatchLGL ? "0.85rem" : "0.75rem",
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <FaUserGraduate
                              style={{
                                height: isMatchLGL ? "20px" : "18px",
                                width: isMatchLGL ? "20px" : "18px",
                                color: "#757575",
                                marginRight: "5px",
                              }}
                            />
                            {selectedJob.qualifications !== undefined &&
                            selectedJob.qualifications !== null &&
                            selectedJob.qualifications !== ""
                              ? selectedJob.qualifications
                              : "Bachelor's Of Degree"}
                          </Typography>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontSize: isMatchLGL ? "0.85rem" : "0.75rem",
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <MdOutlineWorkHistory
                              style={{
                                height: isMatchLGL ? "20px" : "18px",
                                width: isMatchLGL ? "20px" : "18px",
                                color: "#757575",
                                marginRight: "5px",
                              }}
                            />
                            {selectedJob?.experience === "0"
                              ? "0 - 3 Years"
                              : selectedJob?.experience}
                          </Typography>
                          <Tooltip
                            title={selectedJob?.ai_skills}
                            followCursor
                            // arrow
                            // placement="top"
                            enterTouchDelay={500}
                          >
                            <Typography
                              paragraph
                              sx={{
                                margin: 0,
                                fontSize: isMatchLGL ? "0.85rem" : "0.75rem",
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                                cursor: "pointer",
                                maxWidth: isMatchLGL ? "400px" : "200px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                              // onClick={scrollToSkillMatch}
                            >
                              <FaListCheck
                                style={{
                                  height: isMatchLGL ? "20px" : "18px",
                                  width: isMatchLGL ? "20px" : "18px",
                                  color: "#757575",
                                  marginRight: "5px",
                                }}
                              />
                              Skills : {selectedJob?.ai_skills}
                            </Typography>
                          </Tooltip>
                        </Stack>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "end",
                          }}
                        >
                          <Stack direction="row" gap={2}>
                            {parseInt(selectedJob.jobapplylink) === 1 ? (
                              <Button
                                variant="contained"
                                disableElevation
                                size="small"
                                sx={{
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                  "&:hover": {
                                    backgroundColor: "#406882 !important",
                                    color: "white !important", // Replace with the hover color you want
                                  },
                                  bgcolor: "#53778e",
                                  textTransform: "capitalize",
                                }}
                                onClick={() => history.push("/Login")}
                                // target="_blank"
                                // href={selectedJob.joblink}
                              >
                                Apply
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                disableElevation
                                size="small"
                                sx={{
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                  bgcolor: "#53778e",
                                  color: " white",
                                  textTransform: "capitalize",
                                }}
                                onClick={() => history.push("/Login")}
                                // onClick={() => handleOpenJobApplyProcess()}
                              >
                                Apply
                              </Button>
                            )}

                            <Button
                              onClick={() => history.push("/Login")}
                              // disabled={save}
                              variant="outlined"
                              size="small"
                              sx={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                textTransform: "capitalize",
                                borderColor: "#53778e",
                                color: "#53778e",
                              }}
                            >
                              Save
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={{ boxShadow: "none", padding: "10px" }}>
                    <Typography paragraph sx={{ fontWeight: 500 }}>
                      About the job
                    </Typography>
                    <Typography paragraph>
                      {selectedJob.description !== null &&
                      selectedJob.description !== undefined &&
                      selectedJob.description
                        ? !selectedJob.description.includes("ahref") &&
                          !selectedJob.description.includes("divclass") &&
                          !selectedJob.description.includes("aclass") &&
                          !selectedJob.description.includes("pclass") &&
                          !selectedJob.description.includes("liclass")
                          ? parse(selectedJob.description)
                          : selectedJob.description.replace(/<[^>]+>/g, "")
                        : ""}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <Card>No Data Found...</Card>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={isMobile || isTablet ? 12:4}
          sx={{ height: window.innerHeight, overflow: "scroll" }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {Object.keys(selectedJobRecruiterDetails)?.length !== 0 ? (
                <Card
                  sx={{
                    boxShadow: "none",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                >
                  <CardHeader
                    title="Recruiter Details"
                    sx={{
                      pb: 0,
                      "& .MuiCardHeader-title": {
                        fontSize: "1.2rem",
                        fontWeight: 500,
                      },
                    }}
                    /* action={
                  <MoreVertRounded
                    sx={{ color: "#757575", height: "20px", width: "20px" }}
                  />
                } */
                  />
                  <Divider />
                  <CardContent sx={{ p: 0 }}>
                    <Box
                      sx={{
                        position: "relative",
                        padding: "4px",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            borderLeft: "10px solid rgba(64, 104, 130, 0.8)",
                            borderTop: "4px solid rgba(64, 104, 130, 0.8)",
                            borderBottom: "4px solid rgba(64, 104, 130, 0.8)",
                            borderRadius: "50%",
                          }}
                        >
                          <Tooltip
                            title={`Recruiter id: ${selectedJobRecruiterDetails.id}`}
                            arrow
                            placement="top"
                          >
                            <Box
                              sx={{
                                borderLeft: "6px solid #fff",
                                borderTop: "3px solid #fff",
                                borderBottom: "3px solid #fff",
                                borderRadius: "50%",
                              }}
                            >
                              <Avatar
                                // src="https://img.freepik.com/free-photo/horizontal-portrait-smiling-happy-young-pleasant-looking-female-wears-denim-shirt-stylish-glasses-with-straight-blonde-hair-expresses-positiveness-poses_176420-13176.jpg?w=996&t=st=1702271653~exp=1702272253~hmac=6df403e4dd80c55861bc496ced5b75e6bdc1f83019152ab036ed6467fcd8174a"
                                src={
                                  selectedJobRecruiterDetails.image
                                    ? `${Key.files_domain}/dashboard/profile_image/` +
                                      selectedJobRecruiterDetails.id +
                                      "/" +
                                      selectedJobRecruiterDetails.image
                                    : ""
                                }
                                sx={{ height: "100px", width: "100px" }}
                              />
                            </Box>
                          </Tooltip>
                        </Box>
                      </Box>
                      {selectedJob?.contactphone !== "N/A" && (
                        <Box
                          sx={{
                            position: "absolute",
                            left: "115px",
                            top: "15px",
                            ".phone-text": {
                              margin: 0,
                              fontSize: "11px",
                              color: "rgba(0,0,0,0.7)",
                              fontWeight: 500,
                              cursor: "pointer",
                              textTransform: "lowercase",
                            },
                            "&:hover": {
                              ".phone-text": {
                                color: "#406882",
                                textDecoration: "underline",
                              },
                            },
                          }}
                        >
                          {isMatchLGL &&
                          selectedJob?.contactphone !== "" &&
                          selectedJob?.contactphone !== null &&
                          selectedJob?.contactphone !== undefined ? (
                            <Typography
                              variant="button"
                              paragraph
                              className="phone-text"
                              //  onClick={handlemessageShow}
                            >
                              <PhoneRounded
                                sx={{
                                  height: "12px",
                                  width: "12px",
                                  mr: "3px",
                                }}
                              />
                              {selectedJob?.contactphone}
                            </Typography>
                          ) : isMatchLGL &&
                            selectedJobCompanyDetails?.contactphone !== null &&
                            selectedJobCompanyDetails?.contactphone !== "" &&
                            selectedJobCompanyDetails?.contactphone !==
                              undefined ? (
                            <Typography
                              variant="button"
                              paragraph
                              className="phone-text"
                              //  onClick={handlemessageShow}
                            >
                              <PhoneRounded
                                sx={{
                                  height: "12px",
                                  width: "12px",
                                  mr: "3px",
                                }}
                              />
                              {selectedJobCompanyDetails?.contactphone}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Box>
                      )}
                      {selectedJob.contactemail !== "" &&
                        selectedJob.contactemail !== null &&
                        selectedJob.contactemail &&
                        selectedJobCompanyDetails?.contactemail !== null &&
                        selectedJobCompanyDetails?.contactemail && (
                          <Box
                            sx={{
                              position: "absolute",
                              right: "5px",
                              top: "15px",
                              ".email-text": {
                                margin: 0,
                                fontSize: "11px",
                                color: "rgba(0,0,0,0.7)",
                                fontWeight: 500,
                                cursor: "pointer",
                                textTransform: "lowercase",
                              },
                              "&:hover": {
                                ".email-text": {
                                  color: "#406882",
                                  textDecoration: "underline",
                                },
                              },
                            }}
                          >
                            <Typography
                              variant="button"
                              paragraph
                              className="email-text"
                              // onClick={()=>handlemailShow(selectedJob,selectedJobCompanyDetails)}
                            >
                              <EmailRounded
                                sx={{
                                  height: "12px",
                                  width: "12px",
                                  mr: "3px",
                                }}
                              />
                              {/*  {selectedJob.contactemail} */}
                              {selectedJob.contactemail !== "" &&
                              selectedJob.contactemail !== null
                                ? selectedJob.contactemail
                                : selectedJobCompanyDetails?.contactemail !==
                                  null
                                ? selectedJobCompanyDetails?.contactemail
                                : ""}
                            </Typography>
                          </Box>
                        )}

                      <Paper
                        elevation={1}
                        sx={{
                          position: "absolute",
                          left: "105px",
                          right: 0,
                          top: "38px",
                          padding: "10px",
                          borderRadius: "6px",
                          width: "auto",
                          // bgcolor:'#406882',
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <h6
                                style={{
                                  margin: 0,
                                  maxWidth: "250px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  fontWeight: 800,
                                  color: "rgba(0,0,0,0.7)",
                                  textTransform: "capitalize",
                                }}
                              >
                                {selectedJob.contactname !== "" &&
                                selectedJob.contactname !== null
                                  ? selectedJob.contactname
                                  : selectedJobCompanyDetails?.contactname !==
                                    null
                                  ? selectedJobCompanyDetails?.contactname
                                  : ""}
                              </h6>
                            </Box>
                            <p
                              style={{
                                margin: 0,
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#406882",
                                maxWidth: "320px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {selectedJobRecruiterDetails.role}
                            </p>
                          </Box>
                          <Box
                            sx={{
                              border: "1px solid #ddd",
                              borderRadius: "6px",
                              "&:hover": {
                                bgcolor: "#406882",
                                ".inicon": {
                                  color: "#fff",
                                  cursor: "pointer",
                                },
                              },
                              ".inicon": {
                                color: "#406882",
                              },
                            }}
                          >
                            <TiSocialLinkedin
                              className="inicon"
                              style={{ height: "22px", width: "26px" }}
                            />
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  </CardContent>
                </Card>
              ) : (
                <Card>Recruiter Details Not Found...</Card>
              )}
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ boxShadow: "none", borderRadius: "8px" }}>
                <CardHeader
                  title={
                    <p style={{ margin: 0, fontWeight: 500, fontSize: "16px" }}>
                      Similar Jobs
                    </p>
                  }
                  action={
                    <MoreVertRounded
                      sx={{ color: "#757575", height: "20px", width: "20px" }}
                    />
                  }
                  sx={{ borderBottom: "1px solid #ddd" }}
                />
                <CardContent>
                  <List>
                    {similarJobs?.length > 0 ? (
                      similarJobs.map(
                        (job, index) => (
                          // <ListItem sx={{borderBottom:'1px solid #ddd'}}>
                          <ListItemButton
                            disableRipple
                            sx={{ borderBottom: "1px solid #ddd" }}
                            //onClick={() => jobCardClickHandler(job, index)}
                          >
                            <Grid container spacing={0}>
                              <Grid item xs={2}>
                                <Avatar
                                  src={`${Key.domain}/company/company_logo/${job?.companyid}/getcompanynamebyquery`}
                                  sx={{ height: "40px", width: "40px" }}
                                />
                              </Grid>
                              <Grid item xs={10}>
                                <Stack
                                  direction="column"
                                  sx={{ width: "100%" }}
                                >
                                  <Typography
                                    paragraph
                                    sx={{
                                      margin: 0,
                                      color: "rgba(0,0,0,0.7)",
                                      fontWeight: 600,
                                      fontSize: "0.85rem",
                                    }}
                                    onClick={() => redirectToJobView(job)}
                                  >
                                    {job?.title}
                                  </Typography>
                                  <Typography
                                    paragraph
                                    sx={{
                                      margin: 0,
                                      color: "rgba(0, 0, 0, 0.56)",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                    }}
                                    onClick={() =>
                                      redirectToCompanyView(job?.companyid)
                                    }
                                  >
                                    {job?.companyName}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <Typography
                                      paragraph
                                      sx={{
                                        margin: 0,
                                        color: "rgba(0,0,0,0.5)",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {`${
                                        job?.cityName &&
                                        job?.cityName !== "N/A" &&
                                        job?.cityName !== null &&
                                        job?.stateName &&
                                        job?.stateName !== "N/A" &&
                                        job?.stateName !== null &&
                                        job?.shortRegion &&
                                        job?.shortRegion !== "N/A" &&
                                        job?.shortRegion !== null
                                          ? job?.cityName +
                                            ", " +
                                            job?.stateName +
                                            ", " +
                                            job?.shortRegion
                                          : "(Remote)"
                                      }`}
                                      {/* {`${
                              job?.cityName &&
                              job?.cityName !== "N/A" &&
                              job?.cityName !== null
                                ? job?.cityName + ", "
                                : ""
                            } ${
                              job?.stateName &&
                              job?.stateName !== "N/A" &&
                              job?.stateName !== null
                                ? job?.stateName + ", "
                                : ""
                            } ${
                              job?.shortRegion &&
                              job?.shortRegion !== "N/A" &&
                              job?.shortRegion !== null
                                ? job?.shortRegion
                                : "(Remote)"
                            }`} */}
                                    </Typography>
                                    <Typography
                                      paragraph
                                      sx={{
                                        margin: 0,
                                        color: "rgba(0,0,0,0.5)",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                      }}
                                    >
                                      {job.modified !== undefined &&
                                      job.modified !== null
                                        ? moment(job.modified).fromNow()
                                        : moment(job.created).fromNow()}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Grid>
                            </Grid>
                          </ListItemButton>
                        )
                        // </ListItem>
                      )
                    ) : (
                      <></>
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
};

export default SearchJobs_list;
