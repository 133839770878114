import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "../searchJobs/searchJobPage.css";
// import  "../css/Middle.css";
const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";
/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const listyle = {
  // .pagination a.active {
  backgroundColor: "#4CAF50",
  color: "white",
  //}
};
class Pagination extends Component {
  state = {};
  constructor(props) {
    super(props);
    let { totalRecords = 0, pageLimit = 30, pageNeighbours = 0 } = props;
    console.log(totalRecords);

    if (isNaN(totalRecords)) {
      totalRecords = 0;
    }

    totalRecords = totalRecords;

    this.pageLimit = typeof pageLimit === "number" ? pageLimit : 30;
    this.totalRecords = typeof totalRecords === "number" ? totalRecords : 0;

    // pageNeighbours can be: 0, 1 or 2
    this.pageNeighbours =
      typeof pageNeighbours === "number"
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

    this.totalPages = Math.ceil(this.props.totalRecords / this.props.pageLimit);
    this.state = { currentPage: 1, solrJobsCount: "" };
  }

  /*componentDidUpdate(prevProps, prevState) {
      if (prevProps.results.solrJobsCount !== this.props.results.solrJobsCount) {
        return{ solrJobsCount: this.props.results.solrJobsCount };
      }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
      ////console.log(nextProps)
      ////console.log(prevState)
      return { totalRecords: nextProps.totalRecords }
    }*/

  componentDidUpdate(prevProps, prevState) {
    //console.log(this.props.totalRecords)
    if (prevProps.totalRecords !== this.props.totalRecords) {
      ////console.log(prevProps)
      // ////console.log(prevState)
      ////console.log(this.props.totalRecords)
      //  this.gotoPage(1)
      return { totalRecords: this.props.totalRecords };
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    //////console.log(nextProps)
    // ////console.log(prevState)
    sessionStorage.setItem("PaginationCount", prevState.totalRecords);
    return { totalRecords: nextProps.totalRecords };
  }

  componentDidMount() {
    ////console.log(sessionStorage.getItem("solrJobslength-LatestJobId"))

    console.log("pagination is called");
    console.log(this.state.currentPage);

    ////console.log("sessionStorage.getItem" + sessionStorage.getItem("currentsearchpage"))
    //sessionStorage.setItem("currentsearchpage",1)
    var pagenumbr = sessionStorage.getItem("currentsearchpage");
    if (pagenumbr !== null && pagenumbr !== undefined) {
      this.gotoPage(pagenumbr);
    } else {
      // alert(sessionStorage.getItem("currentpage"))

      this.gotoPage(1);
    }
  }

  gotoPage = (page) => {
    const { onPageChanged = (f) => f } = this.props;
    const currentPage = Math.max(0, Math.min(page, this.totalPages));
    ////console.log(this.totalPages)
    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords,
    };
    this.setState({ currentPage }, () => onPageChanged(paginationData));
  };

  handleClick = (page) => (evt) => {
    sessionStorage.setItem("currentsearchpage", page);
    evt.preventDefault();
    this.gotoPage(page);
    this.props.handlePaginationRefresh();
  };

  handleMoveLeft = (evt) => {
    evt.preventDefault();
    let pageleft = this.state.currentPage - this.pageNeighbours * 2 - 1;
    sessionStorage.setItem("currentsearchpage", pageleft);

    this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1);
  };

  handleMoveRight = (evt) => {
    evt.preventDefault();
    let pageRight = this.state.currentPage + this.pageNeighbours * 2 + 1;
    sessionStorage.setItem("currentsearchpage", pageRight);
    this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1);
  };

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  fetchPageNumbers = () => {
    const totalPages = Math.ceil(
      this.props.totalRecords / this.props.pageLimit
    );
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.pageNeighbours;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  render() {
    if (!this.totalRecords || this.totalPages === 1) return null;

    const { currentPage } = this.state;
    if (isNaN(currentPage)) {
      this.setState({ currentPage: 0 });
    }
    const pages = this.fetchPageNumbers();
    //console.log(this.props.totalRecords)
    return (
      <Fragment>
        <nav aria-label="Countries Pagination" style={{ paddingTop: "12px" }}>
          <div className="row">
            <div className="col-md-12">
              <ul className="pagination">
                {pages.map((page, index) => {
                  //////console.log("page"+page)
                  if (page === LEFT_PAGE)
                    return (
                      <li
                        key={index}
                        className="page-item"
                        // style={{ marginLeft: "15px" }}
                      >
                        <a
                          aria-label="Previous"
                          onClick={this.handleMoveLeft}
                          style={{
                            color:
                              this.state.currentPage === page
                                ? " #fff"
                                : "#406882",
                          }}
                        >
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only"></span>
                        </a>
                      </li>
                    );

                  if (page === RIGHT_PAGE)
                    return (
                      <li
                        key={index}
                        className="page-item"
                        // style={{ marginLeft: "15px" }}
                      >
                        <a
                          aria-label="Next"
                          onClick={this.handleMoveRight}
                          style={{
                            color:
                              this.state.currentPage === page
                                ? " #fff"
                                : "#406882",
                          }}
                        >
                          <span aria-hidden="true">&raquo;</span>
                          <span className="sr-only"></span>
                        </a>
                      </li>
                    );

                  return (
                    <li
                      key={index}
                      className="page-number"
                      style={{
                        backgroundColor:
                          this.state.currentPage === page ? "#406882" : "",
                        // marginLeft: "16px",
                        // cursor: "pointer",
                        // padding: "5px",
                      }} /*className={`page-item${currentPage === page ? ' active' : ''}`}*/
                    >
                      <a
                        style={{
                          backgroundColor:
                            this.state.currentPage === page ? " #406882" : "",
                          //   border: "none",
                          //   hover: "none",
                          color:
                            this.state.currentPage === page
                              ? " #fff"
                              : "#406882",
                        }}
                        href="#"
                        onClick={this.handleClick(page)}
                      >
                        {page}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </nav>
      </Fragment>
    );
  }
}

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  postslength: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
};

export default Pagination;
