import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Key from "../../clientVariables.json";
import {
  AssignmentTurnedInOutlined,
  AssignmentTurnedInRounded,
  BookmarkAddedOutlined,
  BookmarkAddedRounded,
  BusinessCenterOutlined,
  BusinessCenterRounded,
  BusinessRounded,
  CheckCircleOutline,
  CheckCircleRounded,
  CorporateFareRounded,
  DashboardOutlined,
  DashboardRounded,
  DescriptionOutlined,
  DescriptionRounded,

  Message,
  Notifications,
  PeopleOutlined,
  PeopleRounded,
  TopicOutlined,
  TopicRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Backdrop,
  Badge,
  Input,
  InputAdornment,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { styled, useTheme, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import userService from "../../services/user.service";
import Jssearchblock from "./Jssearchblock";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../Actions/loader.actions";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import SearchComponent from "./SearchComponent";


const drawerWidth = 240;


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    // width: drawerWidth,
    
    
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),

    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),

    }),
  }),
);
const ITEM_HEIGHT = 48;

const JSNavBar = ({ children }) => {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const openNotification = Boolean(anchorElNotification);
  const [formOpen, setFormOpen] = useState(false);
  const currentUser = useSelector((state) => state.UserReducer.user);
  const [numFound, setNumFound] = useState(0);
  const [recentJobs, setRecentJobs] = useState([]);
  const dispatch = useDispatch();
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [openSearchModal, setOpenSearchModal] = useState(false);

  const handleOpenSearchModal = () => {
    setOpenSearchModal(true);
  };

  const handleCloseSearchModal = () => {
    setOpenSearchModal(false);
  };

  
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops


  const appliedRedirection = () => {
   
    if (dashboardDetails.appliedJobsCount > 0) {
      getDashboardDetails()
      history.push('/jobs/applied');
    } else {
      showAlert('Applied');
    }
  };

  const savedRedirection = () => {
   
    if (dashboardDetails.savedJobsCount > 0) {
      getDashboardDetails()
      history.push('/jobs/saved');
    } else {
      showAlert('Saved');
    }
  };

  const shortlistedRedirection = () => {
   
    if (dashboardDetails.shortlistedCount > 0) {
      getDashboardDetails()
      history.push('/jobs/shortlisted');
    } else {
      showAlert('Shortlisted');
    }
  };

  const showAlert = (action) => {
    setAlertMessage(`You haven't ${action.toLowerCase()} for any jobs yet.`);
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    history.push('/jobs'); 
  };


  const handleClose = (v) => {
    console.log(v);
    setFormOpen(v);
  };
  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null);
  };
  const accountHandler = () => {
    setAnchorElUser(null);
  };
  const logoutHandler = () => {
    setAnchorElUser(null);
    userService.logout();
  };
  const handleIconClick = () => {
    sessionStorage.removeItem("inputjobtitle");
    history.push("/Jsdashboard")
  };
useEffect(() => {
  getDashboardDetails();
}, [])
  const getDashboardDetails = async() => {
    try{
      const response =await axios.post(`${Key.domain}/dashboard/get_js_dashboard_details`, {
        session_token: currentUser.session_token,
        user_id: currentUser.id
      });
      if(response.status === 200){
        // console.log('1122 navbar', response.data.data.userDetails.count[0]);
        setDashboardDetails(response.data.data.userDetails.count[0]);
      }else{
        console.error('response.status === 200 false : get_js_dashboard_details');
      }
    }catch (error) {
      if (error.response && error.response.status == 400) {
        if (
          error.response.data.data === "Access denied.. Please Login" ||
          error.response.data.data === "Access denied"
        ) {
          // setAlert(true);
          console.log('Access denied.. Please Login');
        }
      }
      console.log(error.response.data.data);
      console.error("Error fetching calculate_jsprofile_strength:", error);
    }
  }
  const viewAllRecentJobs = (index) => {
    dispatch(showLoader());
    setSelectedItem(index)
    const searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=*%3A*&fq=status:(1)&rows=6&start=0&sort=created%20desc&wt=json`;
    fetch(`${Key.domain}/solr/solr_jobs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: searchJobsUrl }),
    }).then((response) => {
      response.json().then((resdata) => {
        // console.log(resdata.data.response.docs);
        // console.log(resdata.data.response.numFound);

        setNumFound(resdata.data.response.numFound);

        setRecentJobs(resdata.data.response.docs);
    dispatch(hideLoader());

      });
    });
    sessionStorage.removeItem("filtered-jobtypes");
    sessionStorage.removeItem("filtered-visatypes");
    sessionStorage.removeItem("jobMappedDates");
    localStorage.removeItem("workModeFlages");
    sessionStorage.removeItem("filtered-workmode");
    localStorage.removeItem("jobtypeFlages");
    localStorage.removeItem("visatypeFlages");
    localStorage.removeItem("traveltypeFlages");
    history.push({
      pathname: "/JsMostRecentJobsList",
      state: {
        mostRecentJobs: recentJobs,
        numFound: numFound,
      },
    });
    dispatch(hideLoader());
  };
  const filledIconStyles = {
    fontSize: "20px",
  };
  const outlinedIconStyles = {
    fontSize: "20px",
    color: "#9e9e9e",
  };


  const navItems = [
    // {title:'Home', to:'/', icon1:<HomeRounded />, icon2:<HomeOutlined />},
    {
      title: "Dashboard",
      //to: "/Jsdashboard",
      onClick : handleIconClick ,
      icon1: (
        <DashboardRounded
        onClick =  {handleIconClick} 
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/Jsdashboard" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <DashboardOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Jobs",
      to: "/jobs",
      icon1: (
        <BusinessCenterRounded 
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/jobs" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <BusinessCenterOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Resumes",
      to: "/my-resumes",
      icon1: (
        <DescriptionRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/my-resumes" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <DescriptionOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Companies",
      to: "/H1Visa",
      icon1: (
        <BusinessRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/H1Visa" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <CorporateFareRounded sx={outlinedIconStyles} />,
    },
    {
      title: "Files",
      to: "/Filemanager",
      icon1: (
        <TopicRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/Filemanager" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <TopicOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Network",
      to: "/network",
      icon1: (
        <PeopleRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/network" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <PeopleOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Applied",
      onClick: appliedRedirection,
      icon1: (
        <AssignmentTurnedInRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/jobs/applied" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <AssignmentTurnedInOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Saved",
      onClick: savedRedirection,
      icon1: (
        <CheckCircleRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/jobs/saved" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <CheckCircleOutline sx={outlinedIconStyles} />,
    },
    {
      title: "Shortlisted",
      onClick: shortlistedRedirection,
      icon1: (
        <BookmarkAddedRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/jobs/shortlisted" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <BookmarkAddedOutlined sx={outlinedIconStyles} />,
    },
  ];
  const viewAllNotificationsHandler = () => {
    handleCloseNotificationMenu();
    history.push("/Notificationslist");
  };
  const updateNotificationReadStatus = (notificationid, job_id) => {
    // props.showLoader();

    axios
      .post(`${Key.domain}/Notifications/update_notification_readstatus`, {
        user_id: sessionStorage.getItem("id"),
        notificationId: notificationid,
      })
      .then((response) => {
        console.log(response.data);
        getNotifications();
        // history.push({
        //   pathname: "/job/view/Job_" + job_id,
        //   state:{
        //     notification:"navabar"}
        // });
      });

    setTimeout(() => {
      history.push("/job/view/Job_" + job_id);
    }, 1000);
    handleCloseNotificationMenu();
    // console.log(job_id);
  };
  const getNotifications = () => {
    axios
      .post(`${Key.domain}/Notifications/get_notifications`, {
        user_id: sessionStorage.getItem("id"),
      })
      .then((response) => {
        console.log(response.data.data);
        setNotifications(response.data.data.notifications);
        setNotificationsCount(response.data.data.notificationsCount);
        // props.hideLoader();
      });
  };
  const dashboardClicked = () => {
    sessionStorage.removeItem("jobtitle");
    sessionStorage.removeItem("cityname");
    sessionStorage.removeItem("skillNDescriptionSearch");
    sessionStorage.removeItem("filtered-jobtypes");
    sessionStorage.removeItem("filtered-visatypes");
    sessionStorage.removeItem("jobMappedDates");
    localStorage.removeItem("workModeFlages");
    sessionStorage.removeItem("filtered-workmode");
    localStorage.removeItem("jobtypeFlages");
    localStorage.removeItem("visatypeFlages");
    localStorage.removeItem("traveltypeFlages");
    // sessionStorage.removeItem("currentsearchpage")
    sessionStorage.removeItem("appliedJobsCount");
    sessionStorage.removeItem("appliedjobsPerPage");
    sessionStorage.removeItem("inputResumeTitle");
  };
  const mynetworkClicked = () => {
    sessionStorage.removeItem("myNetworkTabIndex");
  };
  // const navItemHandler = (title) => {
  //   setSelectIcon(title);
  //   if(title === "Dashboard") {
  //     dashboardClicked()
  //   }
  //   else if(title === "Network") {
  //     mynetworkClicked()
  //   }
  // }
  useEffect(() => {
    // dashboardClicked()
    // window.addEventListener("scroll", handleScroll);
    getNotifications();
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const selectedIndex = navItems.findIndex((item) => item.to === currentPath);
    setSelectedItem(selectedIndex !== -1 ? selectedIndex : null);
    if (location.pathname === "/Jsdashboard") {
      dashboardClicked();
    } else if (location.pathname === "/My_network") {
      mynetworkClicked();
    }
  }, [location.pathname, navItems]);

  const sidebarMobile = (
    <MuiDrawer
      variant={isMobile ? "temporary" : "permanent"}
      open={isMobile ? openDrawer : true}
      onClose={isMobile ? handleDrawerToggle : undefined}
      sx={{
        width: drawerWidth,
        zIndex: 1201,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
        },
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link to="/">
          <img
            src="/static/media/jobsnProfiles.54d22dc53e022b59b8b7.png"
            width={160}
          />
        </Link>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <Link to={item.to}>
              <ListItemButton onClick={handleDrawerToggle}>
                <ListItemIcon>{item.icon1}</ListItemIcon>
                <ListItemText
                  primary={item.title}
                  sx={{ color: "rgba(0, 0, 0, 0.7)" }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </MuiDrawer>
  );
  const sidebarDesktop = (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      open={isMobile ? openDrawer : true}
      onClose={isMobile ? handleDrawerToggle : undefined}
      sx={{
        // width: 200,
        // minWidth: '48px',
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          // width: 200,
          width: 'calc(65px)',
        },
      }}
    >
      <List sx={{ pt: "50px" }}>
        {navItems.map((item, index) => (
          // item?.title === 'Jobs'
        //   ? <ListItem key={item} disablePadding sx={{ display: "block" }}>
        //   <Tooltip title={item?.title} placement="right">
            
        //       <ListItemButton
        //         // onClick={() => navItemOnClickHandler(item?.title, index)}
        //         // onClick={() => viewAllRecentJobs(index)}
        //         key={index}
        //         selected={selectedItem === index}
        //         sx={{
        //           minHeight: 48,
        //           // justifyContent: open ? 'initial' : 'center',
        //           //   px: 2.5,
        //           display: "flex",
        //           flexDirection: "column",
        //           fontSize: "10px",
        //           color: "#9e9e9e",
        //           borderLeft:
        //             selectedItem === index ? "2px solid #2196f3" : "none",
        //           marginLeft: "5px",
        //           padding: "5px",
        //         }}
        //       >
        //         <ListItemIcon
        //           sx={{
        //             minWidth: 0,
        //             // mr: open ? 3 : 'auto',
        //             justifyContent: "center",
        //           }}
        //         >
        //           {selectedItem === index ? item.icon1 : item.icon2}
        //         </ListItemIcon>
        //         {/* <ListItemText primary={text} sx={{fontSize:'10px'}}
        //       // sx={{ opacity: open ? 1 : 0 }}
        //        /> */}
        //         {item.title}
        //       </ListItemButton>
            
        //   </Tooltip>
        // </ListItem>
        // : 
        <ListItem key={item} disablePadding sx={{ display: "block" }}>
        <Tooltip title={item?.title} placement="right">
         {
          item?.onClick
          ? <ListItemButton
          // onClick={() => navItemOnClickHandler(item?.title, index)}
          // onClick={() => setSelectedItem(index)}
          onClick={() => {
            setSelectedItem(index);
            item.onClick();
          }}
          key={index}
          selected={selectedItem === index}
          sx={{
            minHeight: 48,
            // justifyContent: open ? 'initial' : 'center',
            //   px: 2.5,
            display: "flex",
            flexDirection: "column",
            fontSize: "10px",
            color: "#9e9e9e",
            borderLeft:
              selectedItem === index ? "2px solid #2196f3" : "none",
            marginLeft: "5px",
            padding: "5px",
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              // mr: open ? 3 : 'auto',
              justifyContent: "center",
            }}
          >
            {selectedItem === index ? item.icon1 : item.icon2}
          </ListItemIcon>
          {/* <ListItemText primary={text} sx={{fontSize:'10px'}}
        // sx={{ opacity: open ? 1 : 0 }}
         /> */}
          {item.title}
        </ListItemButton>
          :  <Link to={item?.to}>
          <ListItemButton
            // onClick={() => navItemOnClickHandler(item?.title, index)}
            onClick={() => setSelectedItem(index)}
            key={index}
            selected={selectedItem === index}
            sx={{
              minHeight: 48,
              // justifyContent: open ? 'initial' : 'center',
              //   px: 2.5,
              display: "flex",
              flexDirection: "column",
              fontSize: "10px",
              color: "#9e9e9e",
              borderLeft:
                selectedItem === index ? "2px solid #2196f3" : "none",
              marginLeft: "5px",
              padding: "5px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                // mr: open ? 3 : 'auto',
                justifyContent: "center",
              }}
            >
              {selectedItem === index ? item.icon1 : item.icon2}
            </ListItemIcon>
            {/* <ListItemText primary={text} sx={{fontSize:'10px'}}
          // sx={{ opacity: open ? 1 : 0 }}
           /> */}
            {item.title}
          </ListItemButton>
        </Link>
         }
        </Tooltip>
      </ListItem>
        ))}
      </List>
    </Drawer>
  );



  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.9),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.6),
    },
    color: alpha(theme.palette.common.black, 0.9),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));
  
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: isMobile ? theme.spacing(0, 0.5) : theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding:  theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft:isMobile ? `calc(1em + ${theme.spacing(1)})` : `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: isMobile ? "13px" : null,
      [theme.breakpoints.up("md")]: {
        width: "40ch",
      },
    },
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "rgba(0,0,0,1)",
      },
    },
  }));
  
  return (
    <div sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          bgcolor: "#406882",
        }}
      >
        {/* #53778e */}
        <Toolbar sx={{ minHeight: "48px !important", pl: isMobile ? 0 : null }}>
          {/* Your header content */}

          {isMobile && (
            <Toolbar sx={{minHeight:'40px important', pl:0}}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{ ml: "auto", display: { sm: "block", md: "none" } }}
              >
                <MenuRoundedIcon />
              </IconButton>
            </Toolbar>
          )}
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img
              src="/static/media/jobsnProfiles.54d22dc53e022b59b8b7.png"
              alt="logo"
              style={{ cursor: "pointer", width: "100px" }}
            />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }} />
          <Search 
          onClick={() => setFormOpen(true)} 
          // onClick={handleOpenSearchModal} 
          sx={{ flexGrow: 1 }}>
            <SearchIconWrapper>
              <SearchIcon sx={{
      fontSize: isMobile ? "13px" : null,
              }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search for job title, skills, location and more"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <Box sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }} />
          <Modal open={formOpen} onClose={() => setFormOpen(false)}>
            <Jssearchblock formOpen={formOpen} handleClose={handleClose} />
          </Modal>
          <Box sx={{ display: { xs: "flex", md: "flex" } }}>
            {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <Mail />
              </Badge>
            </IconButton> */}
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleOpenNotificationMenu}
            >
              <Badge badgeContent={notificationsCount} color="error">
                <Notifications />
              </Badge>
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorElNotification}
              open={openNotification}
              onClose={handleCloseNotificationMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 5.5,
                  width: "40ch",
                  padding: "4px",
                },
              }}
              // title="Notifications"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>Notifications</p>
                <p
                  onClick={viewAllNotificationsHandler}
                  style={{ cursor: "pointer", color: "#406882" }}
                >
                  View All
                </p>
              </div>
              <Divider sx={{ margin: 0 }} />
              {notifications.map((notify) => (
                <MenuItem
                  key={notify.id}
                  // selected={option === 'Pyxis'}
                  onClick={() =>
                    notify.notification_subject !==
                    "Your recruiter deleted a Job"
                      ? updateNotificationReadStatus(notify.id, notify.job_id)
                      : ""
                  }
                >
                  <Stack direction="row" spacing={1}>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={notify.notification_read.includes(
                        sessionStorage.getItem("id")
                      )}
                    >
                      <Message sx={{ color: "rgba(0,0,0,0.4)" }} />
                    </Badge>
                    <Stack direction="column" spacing={0}>
                    <p style={{ overflowWrap: "break-word", whiteSpace: "normal", margin: 0 ,fontSize:"14px"}}>
                        {notify.notification_subject}
                      </p>
                      <small>{moment(notify.created).fromNow()}</small>
                    </Stack>
                  </Stack>
                </MenuItem>
              ))}
            </Menu>

            {/* <Box sx={{ flexGrow: 0 }}> */}
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={currentUser?.full_name}
                 // src={`${Key.files_domain}/dashboard/profile_image/${currentUser.id}/${currentUser.image}`}
                  src={`${Key.s3_files_url}/user_${sessionStorage.getItem("id")}/profileimages/${currentUser.image}`}
                  // src={
                  //   sessionStorage.getItem("profileimg") &&
                  //   sessionStorage.getItem("profileimg") !== "null" &&
                  //   `${Key.files_domain}/dashboard/profile_image/` +
                  //     sessionStorage.getItem("id") +
                  //     "/" +
                  //     sessionStorage.getItem("profileimg")
                  // }
                  sx={{height:'34px', width:'34px'}}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))} */}
              <MenuItem key="account" onClick={accountHandler}>
                <Typography textAlign="center"><Link to='/accountSettings'>Account</Link></Typography>
              </MenuItem>
              <MenuItem key="logout" onClick={logoutHandler}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
            {/* </Box> */}
          </Box>
        </Toolbar>
      </AppBar>
      {!isMobile && sidebarDesktop}
      {/* <div sx={{ flexGrow: 1, padding: (theme) => theme.spacing(3) }}>
        {!isMobile && <Toolbar />} 
        {children}
      </div> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // padding: (theme) => theme.spacing(3),
          marginLeft: isMobile ? 0 : openDrawer ? drawerWidth : 0,
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar sx={{display:{md:'none'}}} />
        <Dialog open={alertOpen} onClose={handleAlertClose} fullWidth>
        <DialogTitle>
          <AlertTitle>Info</AlertTitle>
        </DialogTitle>
        <DialogContent>
          <Alert severity="info">{alertMessage}</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
        <Box 
        sx={{ ml:isMobile ? 0 : "65px", mt: isTablet ? 0 : isMobile ? 0 : '58px' }}
        >{children}</Box>
      </Box>
      {isMobile && sidebarMobile}
      <BlurredModal open={openSearchModal} onClose={handleCloseSearchModal}>
         <Box sx={styleModal}>
         <SearchComponent />
        </Box>
      </BlurredModal>

    </div>
  );
};

export default JSNavBar;

// Custom styles for the backdrop with blur effect
const BlurredBackdrop = styled(Backdrop)(({ theme }) => ({
  backdropFilter: 'blur(8px)',
  backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjust transparency as needed
}));

// Your component
function BlurredModal({ open, onClose, children }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={BlurredBackdrop}
      BackdropProps={{
        sx: { backdropFilter: 'blur(2px)' }, // Apply blur to the backdrop itself
      }}
    >
      {children}
    </Modal>
  );
}

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0,
  borderRadius:'6px'
};

// import React from 'react';
// import {
//   AppBar,
//   Toolbar,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton,
// } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';

// const JSNavBar = () => {
//   const [mobileOpen, setMobileOpen] = React.useState(false);
//   const [sidebarOpen, setSidebarOpen] = React.useState(true);

//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };

//   return (
//     <div style={{ display: 'flex' }}>
//       <AppBar position="fixed">
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             edge="start"
//             onClick={handleDrawerToggle}
//             sx={{ mr: 2, display: { md: 'none' } }}
//           >
//             <MenuIcon />
//           </IconButton>
//           {/* Add logo or title */}
//           {/* <Typography variant="h6" noWrap>
//             Your Logo/Title
//           </Typography> */}
//         </Toolbar>
//       </AppBar>
//       <Drawer variant="temporary" open={mobileOpen} onClose={handleDrawerToggle}>
//         {/* ... Mobile drawer content */}
//         {/* Include navigation links */}
//       </Drawer>
//       <Drawer variant="permanent" open={sidebarOpen}>
//         <List>
//           {/* ... Sidebar content */}
//           {/* Include navigation links */}
//         </List>
//       </Drawer>
//            <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           padding: (theme) => theme.spacing(3),
//           marginLeft: isMobile ? 0 : (openDrawer ? drawerWidth : 0),
//           transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//           }),
//         }}
//       >
//         <Toolbar />
//         {children}
//       </Box>
//       {/* Add your main content here */}
//       {/* <main>
//         Your main content goes here
//       </main> */}
//     </div>
//   );
// };

// export default JSNavBar;
