// import {
//   Box,
//   Card,
//   Divider,
//   Input,
//   InputAdornment,
//   Toolbar,
//   Typography,
// } from "@mui/material";
// import React, { useState } from "react";
// import SearchIcon from "@mui/icons-material/Search";
// import axios from "axios";
// import Key from "../../clientVariables.json";

// const SearchComponent = () => {
//     const [searchValue, setSearchValue] = useState("");
//     const [jobTitles, setJobTitles] = useState([]);
//     const [jobLocations, setJobLocations] = useState([]);

//     const handleInputChange = (event) => {
//       setSearchValue(event.target.value);
//       console.log("search value", event.target.value);
//       getLocations(event.target.value);
//       getJobTitles(event.target.value);

//     };

//     const getLocations = async (value) => {
//         try {
//             const response = await axios.post(`${Key.domain}/Jobs/get_joblocations`, {statename : value });
//             console.log('getLocations',response.data);
//             setJobLocations(response.data.data);
//         } catch (error) {
//             console.error(error);
//         }
//     }

//     const getJobTitles = async (value) => {
//         try {
//             const response = await axios.post(`${Key.domain}/Jobs/get_jobtitles`, {jobtitle : value});
//             console.log('getJobTitles',response.data);
//             setJobTitles(response.data.data);

//         } catch (error) {
//             console.error(error);
//         }
//     }

//     const highlightMatch = (text) => {
//         const regex = new RegExp(`(${searchTerm})`, "gi");
//         return text.split(regex).map((part, index) =>
//           regex.test(part) ? (
//             <span key={index} style={{ color: "blue", textDecoration: "underline" }}>
//               {part}
//             </span>
//           ) : (
//             <span key={index}>{part}</span>
//           )
//         );
//       };

// console.log(jobTitles[0].title);
// console.log(jobLocations[0]);

//   return (
//     <>
//       <Toolbar
//         sx={{
//           minHeight: "60px !important",
//           position: "sticky",
//           top: 0,
//           zIndex: 3,
//           bgcolor: "#fff",
//           borderTopLeftRadius: "6px",
//           borderTopRightRadius: "6px",
//         }}
//       >
//         <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
//           <Box sx={{ flexGrow: 1 }}>
//             <Input
//               autoFocus
//               placeholder="Search by company name"
//               fullWidth
//               disableUnderline
//               value={searchValue}
//               onChange={handleInputChange}
//               startAdornment={
//                 <InputAdornment position="start">
//                   <SearchIcon color="primary" />
//                 </InputAdornment>
//               }
//               sx={{
//                 "& .MuiInput-underline:before": {
//                   borderBottom: "none",
//                 },
//                 "& .MuiInput-underline:after": {
//                   borderBottom: "none",
//                 },
//               }}
//             />
//           </Box>
//         </Box>
//       </Toolbar>
//       <Divider />
//       <Box sx={{ height: 400, overflow: "scroll", p: 2 }}>
//         <Typography
//           paragraph
//           sx={{
//             fontSize: "12px",
//             letterSpacing: "0.1rem",
//             color: "#576375",
//             fontWeight: 600,
//           }}
//         >
//           Job Titles
//         </Typography>
//         <Box sx={{height:200, overflow:'scroll'}}>
//         { jobTitles.length > 0 &&
//             jobTitles?.map((data, index) => <Card key={index} sx={{boxShadow:'none', width:'100%', border: '1px solid #f3f6f966'}}>
//             <Typography
//               paragraph
//               sx={{
//                 fontSize: "14px",
//                 letterSpacing: "0.1rem",
//                 color: "#000",
//                 fontWeight: 600,
//               }}
//             >
//               {highlightMatch(data.title)}
//             </Typography>
//             </Card>)
//         }
//         </Box>
//         <Typography
//           paragraph
//           sx={{
//             fontSize: "12px",
//             letterSpacing: "0.1rem",
//             color: "#576375",
//             fontWeight: 600,
//           }}
//         >
//           Job Locations
//         </Typography>
//         <Box sx={{height:200, overflow:'scroll'}}>
//         { jobLocations.length > 0 &&
//             jobLocations?.map((data, index) => <Card key={index} sx={{boxShadow:'none', width:'100%', border: '1px solid #f3f6f966'}}>
//             <Typography
//               paragraph
//               sx={{
//                 fontSize: "14px",
//                 letterSpacing: "0.1rem",
//                 color: "#000",
//                 fontWeight: 600,
//               }}
//             >
//               {`${highlightMatch(data.cityName)}, ${highlightMatch(data.stateName)}`}
//             </Typography>
//             </Card>)
//         }
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default SearchComponent;

// import React, { useState } from "react";
// import {
//   Box,
//   Card,
//   Divider,
//   Input,
//   InputAdornment,
//   Toolbar,
//   Typography,
// } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
// import axios from "axios";
// import Key from "../../clientVariables.json";

// const SearchComponent = () => {
//   const [searchValue, setSearchValue] = useState("");
//   const [jobTitles, setJobTitles] = useState([]);
//   const [jobLocations, setJobLocations] = useState([]);

//   const handleInputChange = (event) => {
//     setSearchValue(event.target.value);
//     console.log("search value", event.target.value);
//     getLocations(event.target.value);
//     getJobTitles(event.target.value);
//   };

//   const getLocations = async (value) => {
//     try {
//       const response = await axios.post(`${Key.domain}/Jobs/get_joblocations`, {
//         statename: value,
//       });
//       console.log("getLocations", response.data);
//       setJobLocations(response.data.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const getJobTitles = async (value) => {
//     try {
//       const response = await axios.post(`${Key.domain}/Jobs/get_jobtitles`, {
//         jobtitle: value,
//       });
//       console.log("getJobTitles", response.data);
//       setJobTitles(response.data.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const highlightMatch = (text) => {
//     const regex = new RegExp(`(${searchValue})`, "gi");
//     return text.split(regex).map((part, index) =>
//       regex.test(part) ? (
//         <span
//           key={index}
//           style={{ color: "blue", textDecoration: "underline" }}
//         >
//           {part}
//         </span>
//       ) : (
//         <span key={index}>{part}</span>
//       )
//     );
//   };

//   return (
//     <>
//       <Toolbar
//         sx={{
//           minHeight: "60px !important",
//           position: "sticky",
//           top: 0,
//           zIndex: 3,
//           bgcolor: "#fff",
//           borderTopLeftRadius: "6px",
//           borderTopRightRadius: "6px",
//         }}
//       >
//         <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
//           <Box sx={{ flexGrow: 1 }}>
//             <Input
//               autoFocus
//               placeholder="Search by company name"
//               fullWidth
//               disableUnderline
//               value={searchValue}
//               onChange={handleInputChange}
//               startAdornment={
//                 <InputAdornment position="start">
//                   <SearchIcon color="primary" />
//                 </InputAdornment>
//               }
//               sx={{
//                 "& .MuiInput-underline:before": {
//                   borderBottom: "none",
//                 },
//                 "& .MuiInput-underline:after": {
//                   borderBottom: "none",
//                 },
//               }}
//             />
//           </Box>
//         </Box>
//       </Toolbar>
//       <Divider />
//       <Box sx={{ height: 400, overflow: "scroll", p: 2 }}>
//         <Typography
//           paragraph
//           sx={{
//             fontSize: "12px",
//             letterSpacing: "0.1rem",
//             color: "#576375",
//             fontWeight: 600,
//           }}
//         >
//           Job Titles
//         </Typography>
//         <Box sx={{ height: 200, overflow: "scroll" }}>
//           {jobTitles.length > 0 &&
//             jobTitles?.map((data, index) => (
//               <Card
//                 key={index}
//                 sx={{ boxShadow: "none", width: "100%", border: "1px solid #f3f6f966" }}
//               >
//                 <Typography
//                   paragraph
//                   sx={{
//                     fontSize: "14px",
//                     letterSpacing: "0.1rem",
//                     color: "#000",
//                     fontWeight: 600,
//                   }}
//                 >
//                   {highlightMatch(data.title)}
//                 </Typography>
//               </Card>
//             ))}
//         </Box>
//         <Typography
//           paragraph
//           sx={{
//             fontSize: "12px",
//             letterSpacing: "0.1rem",
//             color: "#576375",
//             fontWeight: 600,
//           }}
//         >
//           Job Locations
//         </Typography>
//         <Box sx={{ height: 200, overflow: "scroll" }}>
//           {jobLocations.length > 0 &&
//             jobLocations?.map((data, index) => (
//               <Card
//                 key={index}
//                 sx={{ boxShadow: "none", width: "100%", border: "1px solid #f3f6f966" }}
//               >
//                 <Typography
//                   paragraph
//                   sx={{
//                     fontSize: "14px",
//                     letterSpacing: "0.1rem",
//                     color: "#000",
//                     fontWeight: 600,
//                   }}
//                 >
//                   {`${highlightMatch(data.cityName)}, ${highlightMatch(data.stateName)}`}
//                 </Typography>
//               </Card>
//             ))}
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default SearchComponent;

import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Key from "../../clientVariables.json";
import WorkIcon from "@mui/icons-material/Work";
import { ListItemIcon } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  BusinessCenterOutlined,
  BusinessCenterRounded,
  CloseRounded,
  HistoryOutlined,
  KeyboardReturnRounded,
  LocationOnOutlined,
  LocationOnRounded,
  StarBorderRounded,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { setURLjobSearchUrlActions } from "../Actions/jobSearchUrlActions";
import { useDispatch } from "react-redux";

const SearchComponent = () => {
  const [searchValue, setSearchValue] = useState("");
  const [jobTitles, setJobTitles] = useState([]);
  const [jobLocations, setJobLocations] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
    getLocations(event.target.value);
    getJobTitles(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    jobsPageHanlder(searchValue, null);
    console.log("Form submitted with value:", searchValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };


  const jobsPageHanlder = (title, searchtype) => {
    if(searchtype === 'title'){
        dispatch(setURLjobSearchUrlActions("", title, null, searchtype));
    }else if(searchtype === 'location'){
        dispatch(setURLjobSearchUrlActions("", null, title, searchtype));
    }else if (searchtype === "company"){
        dispatch(setURLjobSearchUrlActions("", title, null, searchtype));
    }
    else if(searchtype === null){
        if(jobLocations.length > 0 && jobTitles.length > 0){
            dispatch(setURLjobSearchUrlActions("", jobTitles[0].title, `${jobLocations[0].cityName}, ${jobLocations[0].stateName}`, searchtype));
        }else if(jobTitles.length > 0){
            dispatch(setURLjobSearchUrlActions("", jobTitles[0].title, null, searchtype));
        }else if(jobLocations.length > 0){
            dispatch(setURLjobSearchUrlActions("", null, `${jobLocations[0].cityName}, ${jobLocations[0].stateName}`, searchtype));
        }
    }
    
    history.push("/jobs");
  }


  const getLocations = async (value) => {
    try {
      const response = await axios.post(`${Key.domain}/Jobs/get_joblocations`, {
        statename: value,
      });
      setJobLocations(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getJobTitles = async (value) => {
    try {
      const response = await axios.post(`${Key.domain}/Jobs/get_jobtitles`, {
        jobtitle: value,
      });
      setJobTitles(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const highlightMatch = (text) => {
    const regex = new RegExp(`(${searchValue})`, "gi");
    console.log("regexc", regex);
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span
          key={index}
          className="highlighted" 
          style={{
            color: "#1E88E5",
            //   textDecoration: "underline"
          }}
        >
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  // const highlightMatch = (text) => {
  //     const regex = new RegExp(`(${searchValue})`, "gi");
  //     return text.replace(regex, (match) => (
  //       <span
  //         key={match} // Use the match as the key to ensure uniqueness
  //         style={{ color: "blue", textDecoration: "underline" }}
  //       >
  //         {match}
  //       </span>
  //     ));
  //   };

  // const highlightMatch = (text) => {
  //     const regex = new RegExp(`(${searchValue})`, "gi");
  //     return text.split(regex).map((part, index) =>
  //       regex.test(part) ? (
  //         <span
  //           key={index}
  //           style={{ color: "blue", textDecoration: "underline" }}
  //         >
  //           {part}
  //         </span>
  //       ) : (
  //         part
  //       )
  //     ).join('');
  //   };

  const highlightMatchLocations = (text) => {
    const regex = new RegExp(`(${searchValue})`, "gi");
    const parts = text.split(regex);
    return (
      <>
        {parts.map((part, index) => {
          if (regex.test(part)) {
            return (
              <span
                key={index}
                className="highlighted" 
                style={{
                  color: "#1E88E5",
                  // textDecoration: "underline"
                }}
              >
                {part}
              </span>
            );
          } else if (index % 2 === 0) {
            return part;
          } else {
            const cityState = JSON.parse(part);
            return (
              <span
                key={index}
                style={{
                  color: "#1E88E5",
                  // textDecoration: "underline"
                }}
              >
                {cityState.cityName}, {cityState.stateName}
              </span>
            );
          }
        })}
      </>
    );
  };

  console.log(jobTitles);
  console.log(jobLocations);

  const topTitles = [
    { title: "React Developer" },
    { title: "Python Developer" },
    { title: "Java Developer" },
    { title: "Devops Engineer" },
    { title: "Block Chain" },
  ];

  const topCompanies = [
    { company: "Nitya Software Solutions" },
    { company: "Adam It Corp" },
    { company: "Anna Accounting" },
    { company: "Rewa HR" },
    { company: "Vio Talk Technologies" },
  ];

  const recentSearches = [
    {title: "Python Developer"},
    {title: "React Developer"},
    {title: "Vio Talk Technologies"},
    {title: "New York City, New York"},
    {title: "Galx Virginia"},
  ];

  const favorites = [
    {title: "React Developer"},
    {title: "New York City, New York"},

  ]

  return (
    <>
      <Toolbar
        sx={{
          minHeight: "60px !important",
          position: "sticky",
          top: 0,
          zIndex: 3,
          bgcolor: "#fff",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
          <Box sx={{ flexGrow: 1 }} component='form' onSubmit={handleSubmit}>
            <Input
              autoFocus
              placeholder="Search by company name"
              fullWidth
              disableUnderline
              value={searchValue}
              onChange={handleInputChange}
            //   onKeyDown={handleKeyDown}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              }
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
              }}
            />
          </Box>
        </Box>
      </Toolbar>
      <Divider />
      <Box sx={{ height: 400, overflow: "scroll", p: 2 }}>
        {searchValue && searchValue !== "" ? (
          <>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Job Titles1
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {jobTitles.length > 0 &&
                jobTitles.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.title, "title")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      ".keyboardIcon": {
                        display: "none",
                      },
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        ".keyboardIcon": {
                          display: "block",
                        },
                        "& .highlighted": {
                          textDecoration: "underline",
                        },
                        "& .hoverColor": {
                            color:'#1E88E5'
                        },
                      },
                    }}
                  >
                    <BusinessCenterOutlined className="hoverColor"
                      sx={{ fontSize: 18, mr: 2, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatch(data.title)}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <KeyboardReturnRounded
                      sx={{ color: "#1E88E5" }}
                      className="keyboardIcon"
                    />
                  </ListItem>
                ))}
            </List>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Job Locations
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {jobLocations.length > 0 &&
                jobLocations.map((data, index) => (
                  <ListItem
                    key={`loc${index}`}
                    onClick={() => jobsPageHanlder(`${data.cityName}, ${data.stateName}`, "location")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      ".keyboardIcon": {
                        display: "none",
                      },
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        ".keyboardIcon": {
                          display: "block",
                        },
                        "& .highlighted": {
                          textDecoration: "underline",
                        },
                        "& .hoverColor": {
                            color:'#1E88E5'
                        },
                      },
                    }}
                  >
                    <LocationOnOutlined className="hoverColor"
                      sx={{ fontSize: 18, mr: 2, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatchLocations(
                        `${data.cityName}, ${data.stateName}`
                      )}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <KeyboardReturnRounded
                      sx={{ color: "#1E88E5" }}
                      className="keyboardIcon"
                    />
                  </ListItem>
                ))}
            </List>
          </>
        ) : (
          <>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Recent
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {recentSearches.length > 0 &&
                recentSearches.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.title, "title")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        "& .hoverColor": {
                            color:'#1E88E5'
                        },
                      },
                    }}
                  >
                    <HistoryOutlined className="hoverColor"
                      sx={{ fontSize: 18, mr: 1, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatch(data.title)}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    
                    <Box sx={{flexGrow:1}} />
                    <Tooltip title="Add to favorite" followCursor>
                    <StarBorderRounded sx={{color:'#1E88E5', fontSize: 18, mr:2}} />
                    </Tooltip>
                    <Tooltip title="Remove from recent" followCursor>
                    <CloseRounded sx={{color:'#1E88E5', fontSize: 18,}} />
                    </Tooltip>
                  </ListItem>
                ))}
            </List>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Favorites
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {favorites.length > 0 &&
                favorites.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.title, "title")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        "& .hoverColor": {
                            color:'#1E88E5'
                        },
                      },
                    }}
                  >
                    <StarBorderRounded className="hoverColor"
                      sx={{ fontSize: 18, mr: 1, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatch(data.title)}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    <Box sx={{flexGrow:1}} />
                    <Tooltip title="Remove from favorites" followCursor>
                    <CloseRounded sx={{color:'#1E88E5', fontSize: 18,}} />
                    </Tooltip>
                  </ListItem>
                ))}
            </List>
          </>
        )}

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Job Titles
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {topTitles.length > 0 &&
                topTitles.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.title, "title")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      color: "#1E88E5",
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                      },
                    }}
                  >
                    <BusinessCenterRounded
                      sx={{ fontSize: 14, mr: 1, color: "#2196f3" }}
                    />
                    <ListItemText
                      primary={data.title}
                      primaryTypographyProps={{ fontSize: "12px" }}
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Companies
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {topCompanies.length > 0 &&
                topCompanies.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.company, "company")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      color: "#1E88E5",
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                      },
                    }}
                  >
                    <BusinessCenterRounded
                      sx={{ fontSize: 14, mr: 1, color: "#2196f3" }}
                    />
                    <ListItemText
                      primary={data.company}
                      primaryTypographyProps={{ fontSize: "12px" }}
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Locations
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {topCompanies.length > 0 &&
                topCompanies.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.company, "company")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      color: "#1E88E5",
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                      },
                    }}
                  >
                    <LocationOnRounded
                      sx={{ fontSize: 14, mr: 1, color: "#2196f3" }}
                    />
                    <ListItemText
                      primary={data.company}
                      primaryTypographyProps={{ fontSize: "12px" }}
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SearchComponent;
