import {
  ArrowRightOutlined,
  AssignmentLateRounded,
  BusinessCenterRounded,
  EmailRounded,
  LocationCityOutlined,
  ManageAccountsRounded,
  PhoneRounded,
  WorkspacePremiumRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TiSocialLinkedin } from "react-icons/ti";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Key from "../clientVariables.json";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { hideLoader } from "./Actions/loader.actions";
import { toast } from "react-toastify";
//import ToastMessage from '../../ToastMessage';
import { useLocation } from "react-router-dom";
import ResumeProfileLoading from "./ResumeProfileLoading";

var openAISkills = require("./OpenAIComponent");

const Bullet = styled("span")({
  display: "inline-block",
  width: "3px",
  height: "3px",
  borderRadius: "50%",
  backgroundColor: "currentColor",
});



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ResumeProfile = ({
  resume,
  handlemailShow,
  handlemessageShow,
  requiredSkills,
  skillsUnselected,
  activeJobSkills,
  selectedJob,
}) => {
  console.log(resume)
  const [skillsOpen, setSkillsOpen] = useState(true);
  const [summaryOpen, setSummaryOpen] = useState(false);
  const [aiSkillsByResumeTitle, setAiSkillsByResumeTitle] = useState([]);
  const [aiDescriptionSkills, setAiDescriptionSkills] = useState([]);
  const [matchedSkillsList, setMatchedSkillsList] = useState([]);
  const [skillsInResume, setSkillsInResume] = useState([]);
  const [matchedSkillsOpen, setMatchedSkillsOpen] = useState(false);
  const [resumeFullDetails, setResumeFullDetails] = useState([]);
  const [resumeCertificationDetails, setResumeCertificationDetails] = useState(
    []
  );
  const currentUser = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [resumeSavedStatus, setResumeSavedStatus] = useState(false);
  const [resumeShortlistedStatus, setResumeShortlistedStatus] = useState(false);
  const location = useLocation();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [savedLoading, setSavedLoading] = useState(false);
  const [resumeDetails, setResumeDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops
  const [linkedinURL , setlinkedinURL] = useState("");

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      // let skillsRequirdArray = requiredSkills ? requiredSkills?.split(' OR ') : sessionStorage.getItem("skillNDescriptionSearch");
      let openJobSkills =
        (await typeof activeJobSkills) === "string"
          ? activeJobSkills?.split(",")
          : activeJobSkills;
      let skillNDescriptionSearch = sessionStorage.getItem(
        "skillNDescriptionSearch"
      );
      let treditionalSkills;

      if (typeof skillNDescriptionSearch === "string") {
        treditionalSkills =
          skillNDescriptionSearch.split(/\sAND\s|,\s*|\sand\s/i);
      }

      // let treditionalSkills =
      //   typeof sessionStorage.getItem("skillNDescriptionSearch") === "string"
      //     ? sessionStorage.getItem("skillNDescriptionSearch").split(" AND ")
      //     : sessionStorage.getItem("skillNDescriptionSearch");
      let skillsRequirdArray =
        (await requiredSkills) !== undefined
          ? requiredSkills
          : openJobSkills
          ? openJobSkills
          : treditionalSkills && treditionalSkills[0] !== ""
          ? treditionalSkills
          : [];
      let unSelectedSkills = []; //skillsUnselected ? skillsUnselected : [];
      const cleanedSkills = (await Array.isArray(skillsRequirdArray))
        ? skillsRequirdArray.map((skill) =>
            skill.replace(/%20/g, " ").replace(/%2/g, " ")
          )
        : skillsRequirdArray;

      console.log(
        "077",
        openJobSkills,
        requiredSkills,
        treditionalSkills,
        cleanedSkills
      );
      // function removeUnselectedSkills(requiredSkills, unselectedSkills) {
      //   if (!requiredSkills || !unselectedSkills) {
      //     return requiredSkills;
      //   }

      //   return requiredSkills.filter(
      //     (skill) => !unselectedSkills.includes(skill)
      //   );
      // }

      // // console.log("ppp", removeUnselectedSkills(cleanedSkills, unSelectedSkills));
      // const filteredSkills = removeUnselectedSkills(
      //   cleanedSkills,
      //   unSelectedSkills
      // );
      // if (Array.isArray(filteredSkills)) {
      //   if (filteredSkills.length > 0) {
      //     setAiDescriptionSkills(filteredSkills);
      //   } else if (filteredSkills[0] === "") {
      //     setAiDescriptionSkills([]);
      //   } else {
      //     setAiDescriptionSkills([]);
      //   }
      // } else {
      //   setAiDescriptionSkills([]);
      // }
      if (Array.isArray(cleanedSkills)) {
        if (cleanedSkills.length > 0) {
          await setAiDescriptionSkills(cleanedSkills);
        } else if (cleanedSkills[0] === "") {
          await setAiDescriptionSkills([]);
        } else {
          await setAiDescriptionSkills([]);
        }
      } else {
        await setAiDescriptionSkills([]);
      }
      //  await matchedSkills();
      const resumeSkills =
        (await resume) &&
        resume.ai_skills !== undefined &&
        resume.ai_skills !== null &&
        resume.ai_skills !== "null"
          ? resume?.ai_skills.split(",").map((skill, index) => skill.trim())
          : resume?.skillName !== undefined &&
            resume?.skillName !== null &&
            resume?.skillName !== "null"
          ? resume?.skillName?.map((skill, index) => skill.trim())
          : [];
      console.log("asas", typeof resumeSkills, resumeSkills);

      // function getMatches(array1, array2) {
      //   const matches = [];

      //   if (!array1 || !array2) {
      //     return matches;
      //   }

      //   array1.forEach((skill1) => {
      //     array2.forEach((skill2) => {
      //       const normalSkill1 = skill1.toLowerCase();
      //       const normalSkill2 = skill2.toLowerCase();

      //       if (
      //         normalSkill1 === normalSkill2 ||
      //         normalSkill2.includes(normalSkill1) ||
      //         normalSkill1.includes(normalSkill2)
      //       ) {
      //         // matches.push(skill1);
      //         matches.push(skill2);
      //       }
      //     });
      //   });
      //   console.log('077 get func', [...new Set(matches)])
      //   setMatchedSkillsList([...new Set(matches)]);
      //   // return [...new Set(matches)];
      // }
      if (!resumeSkills || !cleanedSkills) {
        // return matches;
        setMatchedSkillsList([]);
      }
      setMatchedSkillsList([]);
      resumeSkills.forEach((skill1) => {
        cleanedSkills.forEach((skill2) => {
          const normalSkill1 = skill1.toLowerCase();
          const normalSkill2 = skill2.toLowerCase();

          if (
            normalSkill1 === normalSkill2 ||
            normalSkill2.includes(normalSkill1) ||
            normalSkill1.includes(normalSkill2)
          ) {
            // matches.push(skill1);
            // matches.push(skill2);
            setMatchedSkillsList([...matchedSkillsList, skill2]);
          }
        });
      });
      // const finalMatches = await getMatches(resumeSkills, aiDescriptionSkills);
      // getMatches(resumeSkills, aiDescriptionSkills);
    };
    fetch();
    setLoading(false);
    console.log("requiredSkills11", aiDescriptionSkills);
    console.log("077 get func", matchedSkillsList);
  }, [
    requiredSkills,
    activeJobSkills,
    sessionStorage.getItem("skillNDescriptionSearch"),
  ]);

  //   useEffect(() => {
  //     getOpenAISkills(resume);
  //     getResumeFullDetails(resume?.id);
  //     console.log('resumeid', resume)
  //   }, [])

  useEffect(() => {
    // getOpenAISkills(resume);

    //     if(typeof resumeSkills === 'object' && resumeSkills !== null){
    //     if(typeof resumeSkills === 'string'){
    //        const skilArr = resumeSkills.split(",").map((skill, index) => skill.trim());
    //        setSkillsInResume(skilArr);
    //     }else if (Array.isArray(resumeSkills)) {
    //         setSkillsInResume(resumeSkills);
    //     }
    // } else {
    //         setSkillsInResume(resumeSkills);
    //     }
    // console.log('ffff',resumeSkills);
    // try {
    //     // const response = axios
    //     // .post(`${Key.domain}/resume/get_js_resume_details`, {
    //     //   user_id: sessionStorage.getItem("id"),
    //     //   resumeid: resume?.id,
    //     //   session_token: currentUser.session_token,
    //     // });
    //     // console.log('asas', response);
    //     // if(response.status === 200) {

    //         // setResumeAllDetails()
    //     }
    // } catch(error) {
    //     // console.log(error)
    // }
    const fetch = async () => {
      setLoading(true);
      setSkillsInResume([]);
      try {
        const skills = resume?.ai_skills
          ? resume?.ai_skills
          : resume?.skillName;
        const resumeSkills =
          (await resume) &&
          resume.ai_skills !== undefined &&
          resume.ai_skills !== null &&
          resume.ai_skills.trim() !== ""
            ? resume.ai_skills.split(",").map((skill, index) => skill.trim())
            : resume?.skillName !== undefined &&
              resume?.skillName !== null &&
              resume?.skillName !== "null"
            ? resume?.skillName.map((skill, index) => skill.trim())
            : [];

        if (Array.isArray(resumeSkills)) {
          if (resumeSkills.length > 0) {
            if (resumeSkills.length === 1) {
              if (typeof resumeSkills[0] === "string") {
                const skilArr = resumeSkills[0]
                  .split(",")
                  .map((skill, index) => skill.trim());
                setSkillsInResume(skilArr);
              }
            }
            // else if (resumeSkills === "") {
            //   setSkillsInResume([]);
            // }
            else {
              setSkillsInResume(resumeSkills);
            }
          }
        } else {
          console.log("fail");
        }
        console.log("099 resume skills", resumeSkills);
        //  const data =
        if (resume?.id || resume?.resume_id) {
          // console.log('okok ')
          await getResumeFullDetails(
            resume?.resume_id ? resume?.resume_id : resume?.id
          );
          getSaveResumeStatus(
            resume?.resume_id ? resume?.resume_id : resume?.id
          );
        }
        //  console.log('full resume details try ',data);
        // const dataSkillsMatched =
        await matchedSkills();
        // console.log('0002',matchedSkillsList);
      } catch (error) {
        console.error("full resume details catch", error);
      }
    };
    fetch();
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    
  }, [resume]);
  useEffect(() => {
    // matchedSkills();

    console.log("0001", matchedSkillsList);
  }, []);
  const getOpenAISkills = async (resume) => {
    if (resume) {
      var msg =
        "This is resume title can you give me major important advanced 15 technical skills of resume title given (in shortforms as list)} only, no other information and make it as json  " +
        resume.application_title +

        " and this resume has experience of " +
        resume.total_experience +
        "years";

      await openAISkills.openAI(msg, async (error, results) => {
        if (error) {
          console.log(error);
        } else {
          var data = results;
          console.log(
            "ai skills major",
            data.technical_skills
              ? data.technical_skills
              : data.skills
              ? data.skills
              : data.technicalSkills
              ? data.technicalSkills
              : results
          );
          // await resume?.application_title &&
          // setAiSkillsByResumeTitle(
          //   data.technical_skills ? data.technical_skills : data.skills ? data.skills :  data.technicalSkills ? data.technicalSkills : results
          // );

          var skillss = data && data.skills ? data.skills : [];

          /*if (Array.isArray(skillss)) {
            var skills =
              skillss.length > 0 ? skillss.map((s) => s).join(", ") : "";
          } 
          else {
            const skillString = skillss.split(",");
            var skills =
              skillString.length > 0
                ? skillString.map((s) => s).join(", ")
                : "";
          }*/
        }
      });
    } else {
      console.log("No Title Selected");
    }
  };
  // const handleClick = () => {
  //   console.log("Handle Click");
  //   if (linkedinURL !== null && linkedinURL !== undefined && linkedinURL !== "") {
  //     window.location.href = linkedinURL;
  //   }
  //   else {
  //     let msg = "Please upload LinkedinUrL";
  //     toast(
  //       <Alert variant="filled" severity="success">
  //             {msg}
  //           </Alert>
  //     );
  //   }
  // };
  // const handleClick = (event) => {
  //   if (!linkedinURL) {
  //     event.preventDefault(); 
  //     let msg = "Please upload LinkedinUrL";
  //     toast(
  //       <Alert variant="filled" severity="success">
  //             {msg}
  //           </Alert>
  //     );
  //   }
  // };
  const handleClick = () => {
    if (resume?.linkedin_url !== null && resume?.linkedin_url !== undefined && resume?.linkedin_url !== "") {
      let url = resume?.linkedin_url;
      if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
        url = 'https://' + url;
      }
      window.open(url, '_blank', 'noopener noreferrer');
    }  else {
      let msg = "Please upload LinkedinUrL";
      toast(
        <Alert variant="filled" severity="success">
              {msg}
            </Alert>
      );
    }
  };
  

  const matchedSkills = async () => {
    // dispatch(showLoader());
    const resumeSkills =
      (await resume) &&
      resume.ai_skills !== undefined &&
      resume.ai_skills !== null &&
      resume.ai_skills !== "null"
        ? resume?.ai_skills.split(",").map((skill, index) => skill.trim())
        : resume?.skillName !== undefined &&
          resume?.skillName !== null &&
          resume?.skillName !== "null"
        ? resume?.skillName?.map((skill, index) => skill.trim())
        : [];
    // console.log('asas',typeof resumeSkills)
    let openJobSkills =
      (await typeof activeJobSkills) === "string"
        ? activeJobSkills?.split(",")
        : activeJobSkills;
    let skillNDescriptionSearch = sessionStorage.getItem(
      "skillNDescriptionSearch"
    );
    let treditionalSkills;

    if (typeof skillNDescriptionSearch === "string") {
      treditionalSkills =
        skillNDescriptionSearch.split(/\sAND\s|,\s*|\sand\s/i);
    }

    // let treditionalSkills =
    //   typeof sessionStorage.getItem("skillNDescriptionSearch") === "string"
    //     ? sessionStorage.getItem("skillNDescriptionSearch").split(" AND ")
    //     : sessionStorage.getItem("skillNDescriptionSearch");
    let skillsRequirdArray =
      (await requiredSkills) !== undefined
        ? requiredSkills
        : openJobSkills
        ? openJobSkills
        : treditionalSkills && treditionalSkills[0] !== ""
        ? treditionalSkills
        : [];
    let unSelectedSkills = []; //skillsUnselected ? skillsUnselected : [];
    const cleanedSkills = (await Array.isArray(skillsRequirdArray))
      ? skillsRequirdArray.map((skill) =>
          skill.replace(/%20/g, " ").replace(/%2/g, " ")
        )
      : skillsRequirdArray;

    function getMatches(array1, array2) {
      const matches = [];

      if (!array1 || !array2) {
        return matches;
      }

      array1.forEach((skill1) => {
        array2.forEach((skill2) => {
          const normalSkill1 = skill1.toLowerCase();
          const normalSkill2 = skill2.toLowerCase();

          if (
            normalSkill1 === normalSkill2 ||
            normalSkill2.includes(normalSkill1) ||
            normalSkill1.includes(normalSkill2)
          ) {
            // matches.push(skill1);
            matches.push(skill2);
          }
        });
      });
      console.log("077 get func", [...new Set(matches)]);
      setMatchedSkillsList([...new Set(matches)]);
      return [...new Set(matches)];
    }

    const finalMatches = await getMatches(resumeSkills, cleanedSkills);
    // console.log("Common skills:", resumeSkills);
    // console.log("077", aiDescriptionSkills);
    console.log("077 cmn", finalMatches);
    // setMatchedSkillsList(finalMatches);
    dispatch(hideLoader());
    return finalMatches;
  };
  console.log();
  const getResumeFullDetails = async (resumeId) => {
    try {
      const response = await axios.post(
        `${Key.domain}/resume/get_js_resume_details`,
        {
          user_id: currentUser.id,
          resumeid: resumeId,
          session_token: currentUser.session_token,
        }
      );
      await setResumeFullDetails(response?.data?.data?.projectdetails);
      setResumeCertificationDetails(response?.data?.data?.certificatedetails);
      console.log("full resume details", response?.data?.data?.projectdetails);
      console.log("response?.data?.data?", response?.data?.data);
      await setResumeDetails(response?.data?.data?.resumedetails);
      setlinkedinURL(response?.data?.data?.resumedetails?.linkedin_url)
    } catch (error) {
      console.log("get_js_resume_details error: ", error);
    }
  };
  const insertShortlist = async (applicantId, jobId, cvId) => {
    try {
      setButtonLoading(true);
      const response1 = await axios.put(
        `${Key.domain}/Jobs/insert_or_update_shortlist_status`,
        {
          uid: currentUser.id,
          js_id: applicantId,
          jobid: jobId,
          cvid: cvId,
          status: 1,
          session_token: currentUser.session_token,
        }
      );

      // console.log("res sh", response1);

      if (response1.status === 200 && response1.data.data.affectedRows > 0) {
        try {
          setButtonLoading(true);
          const response2 = await axios.put(
            `${Key.domain}/Jobs/update_emp_appliedresumes_status`,
            {
              user_id: currentUser.id,
              js_user_id: applicantId,
              job_id: jobId,
              cvid: cvId,
              schedule_date: "24-12-2022",
              action_status: 7,
              session_token: currentUser.session_token,
            }
          );

          // Handle the response of the second PUT request
          // console.log("Response 2:", response2);
          getSaveResumeStatus(cvId);
          setButtonLoading(false);

          let msg = "Shortlisted Successfully";
          toast(
            <Alert variant="filled" severity="success">
              {msg}
            </Alert>
          );
          //getShortlistedByJobId(jobId)
        } catch (error) {
          setButtonLoading(false);
          if (error.response && error.response.status === 400) {
            if (
              error.response.data.data === "Access denied.. Please Login" ||
              error.response.data.data === "Access denied"
            ) {
              setAlert(true);
            } else {
              let msg = "Something went wrong with the second request!";
              toast(
                <Alert variant="filled" severity="error">
                  {msg}
                </Alert>
              );
            }
          }
          console.error(
            "Error fetching update_emp_appliedresumes_status:",
            error
          );
        }
      } else {
        let msg = "Something went wrong with the first request!";
        toast(
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        );
      }
    } catch (error) {
      setButtonLoading(false);
      if (error.response && error.response.status === 400) {
        if (
          error.response.data.data === "Access denied.. Please Login" ||
          error.response.data.data === "Access denied"
        ) {
          setAlert(true);
        } else {
          let msg = "Something went wrong with the network/server!";
          toast(
            <Alert variant="filled" severity="error">
              {msg}
            </Alert>
          );
        }
      }
      console.error("Error fetching insert_or_update_shortlist_status:", error);
    }

    // axios
    //   .put(`${Key.domain}/Jobs/insert_or_update_shortlist_status`, {
    //     uid: currentUser.id,
    //     js_id: applicantId,
    //     jobid: jobId,
    //     cvid: cvId,
    //     status: 1,
    //     session_token: currentUser.session_token,
    //   })
    //   .then((response) => {
    //     console.log("res sh", response);
    //     if (response.status === 200 && response.data.data.affectedRows > 0) {
    //       // update Status-----------------
    //       axios
    //         .put(`${Key.domain}/Jobs/update_emp_appliedresumes_status`, {
    //           user_id: currentUser.id,
    //           js_user_id: applicantId,
    //           job_id: jobId,
    //           cvid: cvId,
    //           schedule_date: "24-12-2022",
    //           action_status: 7,
    //           session_token: currentUser.session_token,
    //         })
    //         .then((response) => {
    //           // console.log(response);
    //         });
    //       let msg = "Status Updated Successfully";
    //       toast(
    //         <Alert variant="filled" severity="success">
    //           {msg}
    //         </Alert>
    //       );
    //       setJobContactCateTitle("Shortlisted");
    //     } else {
    //       let msg = "Something went wrong!";
    //       toast(
    //         <Alert variant="filled" severity="error">
    //           {msg}
    //         </Alert>
    //       );
    //     }
    //   });
  };
  const shortlistApplicantHandler = (applicantId, jobId, cvId) => {
    insertShortlist(applicantId, jobId, cvId);
  };

  /* const getShortlistedByJobId = async (jobId) => {
    try {
      const res = await axios.post(
        `${Key.domain}/Jobs/get_shortlisted_resumes_by_job`,
        {
          jobid: jobId,
        }
      ).then((res)=>{
        console.log("res.data.data",res.data.data)
      })
      
    } catch (error) {
      console.log("getShortlistedByJobId: error", error);
    }

    
  }; */
  /* const shortlistedResume = (js_id, cvid) => {
   // console.log(props.jobid)
    //console.log(action_status)
    axios
      .put(`${Key.domain}/Jobs/update_emp_appliedresumes_status`, {
        user_id: currentUser.id,
        js_user_id: js_id,
        //job_id: props.jobid,
        cvid: cvid,
        schedule_date: "24-12-2022",
        action_status: 7,
        session_token: currentUser.session_token,
      })
      .then((response) => {
        console.log(response);
        

        if (response.status === 200 && response.data.data.affectedRows > 0) {
          /* let msg = "Status Updated Successfully"
          toast(
            <ToastMessage message={msg} />
          ) 
        } else {
          /* let msg = "Something went wrong!"
          toast(
            <ToastMessage message={msg} />
          ) 
        }

      });
  }; */

  const getSaveResumeStatus = async (resId) => {
    const response = await axios.post(
      `${Key.domain}/resume/get_saved_and_shortlist_status`,
      {
        resume_id: resId,
      }
    );
    if (response.status === 200) {
      console.log(response.data.data);
      if (response.data.data.savedStatus) {
        setResumeSavedStatus(true);
      } else {
        setResumeSavedStatus(false);
      }
      if (response.data.data.shortListStatus) {
        setResumeShortlistedStatus(true);
      } else {
        setResumeShortlistedStatus(false);
      }
    } else {
      console.log(
        "response.status === 200 false : get_saved_and_shortlist_status"
      );
    }
  };

  const saveResume = async () => {
    setSavedLoading(true);
    const response = await axios.post(
      `${Key.domain}/resume/js_saveRecruiter_resume`,
      {
        user_id: currentUser.id,
        session_token: currentUser.session_token,
        resumeid: resume.id,
      }
    );
    if (response.status === 200) {
      setSavedLoading(false);
      let msg = "Saved Successfully";
      toast(
        <Alert variant="filled" severity="success">
          {msg}
        </Alert>
      );
      console.log(response);
      getSaveResumeStatus(resume.id);
    } else {
      setSavedLoading(false);
      let msg = "Something Went Wrong";
      toast(
        <Alert variant="filled" severity="success">
          {msg}
        </Alert>
      );
      console.log("response.status === 200 false : js_saveRecruiter_resume");
    }
  };

  const savedBtnS = {
    borderRadius: "4px",
    textTransform: "capitalize",
    backgroundImage:
      "linear-gradient(136deg, #406882 0%, #518aa7 50%, #6d9ec6 100%)",
  };

  const skillBtnStyles = {
    bgcolor: skillsOpen ? "rgba(64, 104, 130, 0.9)" : "",
    color: skillsOpen ? "#fff" : "rgba(0,0,0,0.6)",
    border: skillsOpen ? "none" : "1px solid #ddd",
  };
  const workBtnStyles = {
    bgcolor: summaryOpen ? "rgba(64, 104, 130, 0.9)" : "",
    color: summaryOpen ? "#fff" : "rgba(0,0,0,0.6)",
    border: summaryOpen ? "none" : "1px solid #ddd",
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(64, 104, 130, 0.9)",
      //  theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: isDesktopLarge ? 14 : 12,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: isDesktopLarge ? 14 : 12,
    },
  }));


  return (
    loading ? <ResumeProfileLoading />
    : <>
      <Box
        component={Paper}
        elevation={2}
        sx={{ height: window.innerHeight, overflow: "scroll" }}
      >
        <Box sx={{ position: "relative", padding: "4px", width: "100%" }}>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                borderLeft: "10px solid rgba(64, 104, 130, 0.8)",
                borderTop: "4px solid rgba(64, 104, 130, 0.8)",
                borderBottom: "4px solid rgba(64, 104, 130, 0.8)",
                borderRadius: "50%",
              }}
            >
              <Tooltip title={`Resume id: ${resume?.id}`} arrow placement="top">
                <Box
                  sx={{
                    borderLeft: "6px solid #fff",
                    borderTop: "3px solid #fff",
                    borderBottom: "3px solid #fff",
                    borderRadius: "50%",
                  }}
                >
                  <Avatar
                    // src="https://img.freepik.com/free-photo/horizontal-portrait-smiling-happy-young-pleasant-looking-female-wears-denim-shirt-stylish-glasses-with-straight-blonde-hair-expresses-positiveness-poses_176420-13176.jpg?w=996&t=st=1702271653~exp=1702272253~hmac=6df403e4dd80c55861bc496ced5b75e6bdc1f83019152ab036ed6467fcd8174a"
                    src={
                      `${Key.files_domain}/dashboard/profile_image/` +
                      resume?.uid
                    }
                    sx={{
                      height: isDesktopLarge ? "100px" : "80px",
                      width: isDesktopLarge ? "100px" : "80px",
                    }}
                  />
                </Box>
              </Tooltip>
            </Box>
          </Box>
          {resume?.home_phone !== "N/A" && (
            <Box
              sx={{
                position: "absolute",
                left: isDesktopLarge ? "115px" : null,
                right: isDesktopSmall ? "5px" : null,
                top: isDesktopSmall ? "2px" : "15px",
                marginLeft: isMobile ? "134px" : (isTablet ? "494px" : null),

                ".phone-text": {
                  margin: 0,
                  fontSize: isDesktopLarge ? "11px" : "10px",
                  color: "rgba(0,0,0,0.7)",
                  fontWeight: 500,
                  cursor: "pointer",
                  textTransform: "lowercase",
                },
                "&:hover": {
                  ".phone-text": {
                    color: "#406882",
                    textDecoration: "underline",
                  },
                },
              }}
            >
              <Typography
                variant="button"
                paragraph
                className="phone-text"
                onClick={handlemessageShow}
              >
                <PhoneRounded
                  sx={{ height: "12px", width: "12px", mr: "3px" }}
                />
                {resume?.home_phone}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              position: "absolute",
              right: "5px",
              top: "15px",
              ".email-text": {
                margin: 0,
                fontSize: isDesktopLarge ? "11px" : "10px",
                color: "rgba(0,0,0,0.7)",
                fontWeight: 500,
                cursor: "pointer",
                textTransform: "lowercase",
              },
              "&:hover": {
                ".email-text": {
                  color: "#406882",
                  textDecoration: "underline",
                },
              },
            }}
          >
            <Typography
              variant="button"
              paragraph
              className="email-text"
              onClick={() =>
                handlemailShow(resume?.email_address, resume?.full_name)
              }
            >
              <EmailRounded sx={{ height: "12px", width: "12px", mr: "3px" }} />
              {resume?.email_address}
            </Typography>
          </Box>
          <Paper
            elevation={1}
            sx={{
              position: "absolute",
              left: isDesktopLarge ? "105px" : "89px",
              right: 0,
              top: isDesktopLarge ? "38px" : "34px",
              padding: isDesktopLarge ? "10px" : "4px 4px 4px 6px",
              borderRadius: "6px",
              width: "auto",
              // bgcolor:'#406882',
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Tooltip
                    title={
                      <p style={{ fontSize: "10px", margin: 0 }}>
                        {resume?.full_name}
                      </p>
                    }
                  >
                    <Typography
                      paragraph
                      sx={{
                        margin: 0,
                        width: isDesktopLarge
                          ? "200px"
                          : isDesktopSmall
                          ? "170px"
                          : "140px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontWeight: 800,
                        color: "rgba(0,0,0,0.7)",
                        textTransform: "capitalize",
                        fontSize: isDesktopLarge ? "1.0rem" : "0.85rem",
                      }}
                    >
                      {resume?.full_name}
                    </Typography>
                  </Tooltip>

                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: isDesktopLarge ? "0.85rem" : "0.65rem",
                      color: "rgba(0,0,0,0.7)",
                      marginLeft: "5px",
                      marginBottom: 0,
                    }}
                  >
                    {" "}
                    #{resume?.resume_id ? resume?.resume_id : resume?.id}
                  </p>
                </Box>
              </Box>
              <Box
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  "&:hover": {
                    bgcolor: "#406882",
                    ".inicon": {
                      color: "#fff",
                      cursor: "pointer",
                    },
                  },
                  ".inicon": {
                    color: "#406882",
                  },
                }}
              >
                
<TiSocialLinkedin
  style={{ height: '22px', width: '26px', cursor: 'pointer' }}
  onClick={handleClick}
/>


              </Box>
            </Box>
          </Paper>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2, gap: 2 }}>
          {savedLoading === true ? (
            <Button
              variant="outlined"
              disabled
              size="small"
              onClick={() => saveResume()}
              sx={{ textTransform: "capitalize", borderRadius: "4px" }}
            >
              saving...
            </Button>
          ) : (
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: "capitalize", borderRadius: "4px" }}
              onClick={() => saveResume()}
              disabled={resumeSavedStatus}
            >
              {resumeSavedStatus ? "Resume Saved" : "Save Resume"}
            </Button>
          )}
          {location.pathname === "/myJobs" && (
            <Button
              variant="contained"
              size="small"
              sx={savedBtnS}
              disabled={resumeShortlistedStatus || buttonLoading}
              onClick={() =>
                shortlistApplicantHandler(
                  resume.uid,
                  selectedJob?.id,
                  resume.id
                )
              }
            >
              {buttonLoading
                ? "Shortlisting..."
                : resumeShortlistedStatus
                ? "Shortlisted"
                : "Shortlist"}
            </Button>
          )}
        </Box>
        <Divider />
        <Card sx={{ boxShadow: "none", padding: "10px" }}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Typography
                paragraph
                sx={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: isDesktopLarge ? "14px" : "12px",
                  color: "rgba(0,0,0,0.6)",
                }}
              >
                Role
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                paragraph
                sx={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: isDesktopLarge ? "14px" : "12px",
                  color: "rgba(0,0,0,0.9)",
                }}
              >
                {resume?.application_title}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Divider />
        <Card sx={{ boxShadow: "none", padding: "10px" }}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Typography
                paragraph
                sx={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: isDesktopLarge ? "14px" : "12px",
                  color: "rgba(0,0,0,0.6)",
                }}
              >
                Visa Type
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                paragraph
                sx={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: isDesktopLarge ? "14px" : "12px",
                  color: "rgba(0,0,0,0.9)",
                }}
              >
                {resumeDetails?.visatype_name
                  ? resumeDetails?.visatype_name
                  : resume?.res_visatypeName ? resume?.res_visatypeName : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Divider />
        <Card sx={{ boxShadow: "none", padding: "10px" }}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Typography
                paragraph
                sx={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: isDesktopLarge ? "14px" : "12px",
                  color: "rgba(0,0,0,0.6)",
                }}
              >
                Job Type
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                paragraph
                sx={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: isDesktopLarge ? "14px" : "12px",
                  color: "rgba(0,0,0,0.9)",
                }}
              >
                {resumeDetails.jobtypeNames
                  ? resumeDetails.jobtypeNames
                  : resume?.res_jobtypeName?.map((i) => i).join(", ") ? resume?.res_jobtypeName?.map((i) => i).join(", ") : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Divider />

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack direction="row" spacing={1} sx={{ p: "4px", mt: 1 }}>
            <Button
              variant="outlined"
              sx={{ ...btnStyles, ...skillBtnStyles }}
              startIcon={
                <ManageAccountsRounded
                  sx={{ height: "16px", width: "16px", minWidth: "10px" }}
                />
              }
              onClick={() => {
                setSummaryOpen(false);
                setSkillsOpen(true);
              }}
            >
              Skills
            </Button>
            <Button
              variant="outlined"
              startIcon={
                <BusinessCenterRounded sx={{ height: "16px", width: "16px" }} />
              }
              sx={{ ...btnStyles, ...workBtnStyles }}
              onClick={() => {
                setSkillsOpen(false);
                setSummaryOpen(true);
              }}
            >
              Work
            </Button>
            {/*  <Button
              variant="outlined"
              startIcon={
                <DescriptionRounded sx={{ height: "16px", width: "16px" }} />
              }
              sx={{ ...btnStyles }}
            >
              Notes
            </Button> */}
          </Stack>
        </Box>

        <Card sx={{ boxShadow: "none" }}>
          <CardContent>
            {skillsOpen ? (
              aiDescriptionSkills.length > 0 &&
              aiDescriptionSkills[0] !== "" ? (
                <Card
                  sx={{
                    boxShadow: "none",
                    border: "1px solid #ddd",
                    borderRadius: "6px",
                    p: isDesktopLarge ? 2 : 1,
                    // bgcolor:'#ffefcd'
                  }}
                >
                  <CardHeader
                    // sx={{borderBottom:'1px solid #ddd'}}
                    title={
                      <Typography
                        paragraph
                        style={{
                          fontSize: isDesktopLarge ? "18px" : "15px",
                          fontWeight: 400,
                          margin: 0,
                        }}
                      >
                        How{" "}
                        <span
                          style={{
                            fontWeight: 500,
                            // textDecoration: "underline",
                            color: "#e4a43c",
                          }}
                        >
                          {resume?.application_title}
                        </span>{" "}
                        match
                      </Typography>
                    }
                    // titleTypographyProps={{ variant: 'h6', fontSize: '14px' }}
                  />
                  <CardContent
                    sx={{
                      bgcolor: "#ffefcd",
                      // bgcolor:'#CFE4FF',
                      height: "auto",
                      maxheight: "40vh",
                      overflow: "scroll",
                      borderRadius: "6px",
                      p: 1,
                    }}
                  >
                    {matchedSkillsList?.length > 6 ? (
                      <Box
                        sx={{
                          display: "flex",
                          "&:hover": {
                            textu: {
                              textDecoration: "underline",
                              color: "blue",
                            },
                          },
                        }}
                      >
                        <WorkspacePremiumRounded
                          sx={{
                            color: "#e4a43c",
                            height: isDesktopLarge ? "36px" : "28px",
                            width: isDesktopLarge ? "36px" : "28px",
                            mr: "5px",
                          }}
                        />
                        <p
                          style={{
                            fontSize: isDesktopLarge ? "14px" : "12px",
                            margin: 0,
                          }}
                          // onClick={matchedSkills}
                          onClick={() => setMatchedSkillsOpen(true)}
                        >
                          <span
                            className={`${
                              matchedSkillsList?.length > 0 ? "match-text" : ""
                            }`}
                          >
                            {matchedSkillsList?.length} of{" "}
                            {aiDescriptionSkills?.length > 0
                              ? aiDescriptionSkills?.length
                              : 0}{" "}
                            skills matched it may be a good fit based on skills
                            with experience for&nbsp;
                          </span>
                          <span
                            style={{
                              background: "#e4a43c",
                              fontWeight: 500,
                              paddingInline: "7px",
                              color: "#fff",
                              borderRadius: "4px",
                              margin: 0,
                            }}
                          >
                            {resume?.application_title}
                          </span>
                        </p>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          "&:hover": {
                            ".match-text": {
                              color: "#406882",
                              textDecoration: "underline",
                              cursor: "pointer",
                            },
                          },
                        }}
                      >
                        <AssignmentLateRounded
                          sx={{
                            color: "#e4a43c",
                            height: isDesktopLarge ? "36px" : "28px",
                            width: isDesktopLarge ? "36px" : "28px",
                            mr: "5px",
                          }}
                        />
                        <Typography
                          variant="body1"
                          paragraph
                          style={{ fontSize: isDesktopLarge ? "14px" : "12px" }}
                          // onClick={matchedSkills}
                          onClick={() =>
                            matchedSkillsList?.length > 0 &&
                            setMatchedSkillsOpen(true)
                          }
                        >
                          <span
                            className={`${
                              matchedSkillsList?.length > 0 ? "match-text" : ""
                            }`}
                          >
                            {matchedSkillsList?.length} of{" "}
                            {aiDescriptionSkills?.length > 0
                              ? aiDescriptionSkills?.length
                              : 0}{" "}
                            skills matched based on skills with experience
                            for&nbsp;
                          </span>
                          <span
                            style={{
                              background: "#e4a43c",
                              fontWeight: 500,
                              paddingInline: "7px",
                              color: "#fff",
                              borderRadius: "4px",
                              margin: 0,
                            }}
                          >
                            {resume?.application_title}
                          </span>
                        </Typography>
                      </Box>
                    )}
                    {matchedSkillsOpen ? (
                      <List
                        sx={{
                          maxHeight: "200px",
                          overflow: "scroll",
                        }}
                      >
                        <Grid container spacing={0}>
                          {matchedSkillsList?.map((skill, index) => (
                            <Grid item xs={6}>
                              <ListItem key={index} sx={{ p: 0 }}>
                                <ArrowRightOutlined />{" "}
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "12px" }}
                                >
                                  {skill}
                                </Typography>
                              </ListItem>
                            </Grid>
                          ))}
                        </Grid>
                      </List>
                    ) : null}
                  </CardContent>
                  <CardContent sx={{p:0, mt:1}}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TableContainer
                          component={Paper}
                          sx={{ maxHeight: 400 }}
                        >
                          <Table
                            aria-label="customized table"
                            stickyHeader
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Resume Skills</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {skillsInResume?.map((row) => (
                                <StyledTableRow key={row}>
                                  <StyledTableCell component="th" scope="row">
                                    {row}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={6}>
                        <TableContainer
                          component={Paper}
                          sx={{ maxHeight: 400 }}
                        >
                          <Table
                            aria-label="customized table"
                            stickyHeader
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell >
                                  Required Skills
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {aiDescriptionSkills?.map((row) => (
                                <StyledTableRow key={row}>
                                  <StyledTableCell component="th" scope="row" >
                                    {row}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <TableContainer
                    component={Paper}
                    sx={{ maxHeight: 400, maxWidth: "400px" }}
                  >
                    <Table
                      aria-label="customized table"
                      stickyHeader
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            colSpan={2}
                            sx={{ textAlign: "center",}}
                          >
                            Resume Skills
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {skillsInResume.length > 0 ? (
                          skillsInResume.map((row, index) => {
                            if (index % 2 === 0) {
                              return (
                                <StyledTableRow key={index}>
                                  <TableCell sx={{fontSize: isDesktopLarge ? '14px' : '12px',}}>{row}</TableCell>
                                  {skillsInResume[index + 1] && (
                                    <TableCell sx={{fontSize: isDesktopLarge ? '14px' : '12px',}}>
                                      {skillsInResume[index + 1]}
                                    </TableCell>
                                  )}
                                </StyledTableRow>
                              );
                            }
                            return null;
                          })
                        ) : (
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              paddingTop: 2,
                              textAlign: "center",
                            }}
                          >
                            Skills Not found
                          </Typography>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )
            ) : summaryOpen ? (
              <>
                <Card sx={{ border: "1px solid #ddd" }}>
                  <CardHeader
                    title={
                      <Typography
                        paragraph
                        style={{ fontSize: isDesktopLarge ? "18px" : '15px', fontWeight: 500, margin: 0 }}
                      >
                        Experience
                      </Typography>
                    }
                    sx={{ borderBottom: "1px solid #ddd" }}
                  />
                  <CardContent>
                    {resumeFullDetails &&
                      resumeFullDetails.map((data, index) => (
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            bgcolor: "background.paper",
                            color: "text.secondary",
                            "& svg": {
                              m: 1.5,
                            },
                            border: "1px solid #ddd",
                            borderRadius: "6px",
                            p: 1,
                            mb: 1,
                          }}
                        >
                          <Avatar sx={{ height: isDesktopLarge ? "50px" : '40px', width: isDesktopLarge ? "50px" : '40px', }}>
                            <LocationCityOutlined
                              sx={{ height: isDesktopLarge ? "32px" : '26px', width: isDesktopLarge ? "32px" : '26px', }}
                            />
                          </Avatar>
                          <Box>
                            <Typography
                              variant="body2"
                              component="p"
                              // variant="subtitle1"
                              fontWeight={500}
                              sx={{
                                margin: 0,
                                fontSize: isDesktopLarge ? "1.0rem" : '0.85rem',
                                color: "rgba(0,0,0,0.8)",
                                lineHeight: 1.5,
                              }}
                            >
                              {data?.pro_role}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Breadcrumbs
                                separator="-"
                                aria-label="breadcrumb"
                              >
                                <Typography
                                  variant="body2"
                                  component="p"
                                  sx={{
                                    fontSize: isDesktopLarge ? "1.0rem" : '0.85rem',
                                    lineHeight: 1.42,
                                    color: "rgba(0,0,0,0.6)",
                                  }}
                                >
                                  {data?.pro_client}
                                </Typography>
                                {/* <Typography
                               variant="body2"
                               component="p"
                               sx={{
                                 fontSize: "1rem",
                                 lineHeight: 1.42,
                                 color: "rgba(0,0,0,0.6)",
                               }}
                             >
                               Full Time
                             </Typography> */}
                              </Breadcrumbs>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Breadcrumbs
                                separator={<Bullet />}
                                aria-label="breadcrumb"
                              >
                                <Typography
                                  variant="body2"
                                  component="p"
                                  sx={{
                                    fontSize: isDesktopLarge ? "0.85rem" : '0.75rem',
                                    color: "rgba(0,0,0,0.5)",
                                  }}
                                >
                                  {moment(data?.pro_startdate).format("ll")} -{" "}
                                  {moment(data?.pro_enddate).format("ll") ==
                                  "Invalid date"
                                    ? "Till Date"
                                    : moment(data?.pro_enddate).format("ll")}
                                </Typography>
                                {/* <Typography
                               variant="body2"
                               component="p"
                               sx={{ fontSize: "14px", color: "rgba(0,0,0,0.5)" }}
                             >
                               2 yrs 5 mos
                               {moment.duration(moment(data?.pro_enddate).diff(moment(data?.pro_startdate)))}
                             </Typography> */}
                              </Breadcrumbs>
                            </Box>
                            <Typography
                              variant="body2"
                              component="p"
                              sx={{
                                fontSize:isDesktopLarge ? "0.85rem" : '0.75rem',
                                color: "rgba(0,0,0,0.5)",
                              }}
                            >
                              {data?.cityname !== null &&
                              data?.statename !== null
                                ? `${data?.cityname}, ${data?.statename}, ${data?.shortRegion}`
                                : "Remote"}
                            </Typography>
                          </Box>
                        </Stack>
                      ))}

                  </CardContent>
                </Card>
                <Card sx={{ border: "1px solid #ddd", mt: 2 }}>
                  <CardHeader
                    title={
                      <Typography
                        paragraph
                        style={{ fontSize: isDesktopLarge ? "18px" : '15px', fontWeight: 500, margin: 0 }}
                      >
                        Certifications
                      </Typography>
                    }
                    sx={{ borderBottom: "1px solid #ddd" }}
                  />
                  <CardContent>
                    {resumeCertificationDetails.length > 0 ? (
                      <Stack direction="column">
                        {resumeCertificationDetails.map((cer, index) => (
                          <Typography paragraph sx={{ m: 0 , fontSize : isDesktopLarge ? "0.85rem" : '0.75rem',}}>
                            {`${index + 1}. `}
                            {cer.cer_name}
                          </Typography>
                        ))}
                      </Stack>
                    ) : (
                      <Card>No Certifications</Card>
                    )}
                  </CardContent>
                </Card>
              </>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ResumeProfile;

const btnStyles = {
  borderRadius: "25px",
  padding: "0px 15px 0px 15px",
  textTransform: "capitalize",
  border: "1px solid #ddd",
  color: "rgba(0,0,0,0.6)",
  "&:hover": {
    bgcolor: "#406882",
    color: "#fff",
    border: "1px solid #406882",
  },
};
