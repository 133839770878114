import React, { useState, useEffect, Suspense } from "react";
import "./App.css";
import { useLocation } from 'react-router-dom'

// import Certificationsdetailsview from "./Components/Jobseeker/Certificationsdetailsview";
import Resumeslist from "./Components/searchResumes/Resumelist";
import AdvancedResumeSearch from "./Components/AdvancedSearch/AdvancedSearchList";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ParserResumes from "./Parserresumeslist";
import ParserResumestabs from "./Parserresumestabs";
// import Jssupport from "./Components/Jssupport";
import Navbar2 from "./Components/Navbar2";
import Nav2 from "../src/Components/Navbar2";
import Loader from "../src/Components/Loader/loading";
import EmailVerification from "./Components/Employer/empEmailVerification";
import Empnavbar from "./Components/Employer/Empnavbar";
import Feedback from "./Components/feedback";
import ChatWidget from "./Components/Chat_Component/chatBot";
import LazyLoader from "../src/Components/Loader/lazyLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Resumecontext from "../src/resumeBuilder/Resumecontext";
import SearchResumeContext from "../src/resumeBuilder/SearchContext";
import Footer from "./Components/Jobseeker/Footer";
import JSNavBar from "./Components/Jobseeker/JSNavBar.js";

// const ChatWidget = React.lazy(() =>
//   retry(() => import("./Components/Chat_Component/chatBot"))
// );
// const Feedback = React.lazy(() => retry(() => import("./Components/feedback")));

const Jssupport = React.lazy(() =>
  retry(() => import("./Components/Jssupport"))
);
const MyContacts = React.lazy(() =>
  retry(() => import("./Components/Employer/myContacts/MyContacts.js"))
);

const Certificationsdetailsview = React.lazy(() =>
  retry(() => import("./Components/Jobseeker/Certificationsdetailsview"))
);

const Empsupport = React.lazy(() =>
  retry(() => import("./Components/Empsupport"))
);
const Recruiters = React.lazy(() =>
  retry(() => import("./Components/Employer/EmpRecruiters"))
);

const BsSignup = React.lazy(() =>
  retry(() => import("./Components/Benchsales/BsSignup"))
);

const BsLogin = React.lazy(() =>
  retry(() => import("./Components/Benchsales/BsLogin"))
);
const Navbar = React.lazy(() => retry(() => import("./Components/Navbar")));

const Jsdashboard = React.lazy(() =>
  retry(() => import("./Components/Jobseeker/Jsdashboard"))
);
const SearchByMap = React.lazy(() =>
  retry(() => import("./Components/SearchByMaps/Map"))
);

const Addnewcompany = React.lazy(() =>
  retry(() => import("./Components/Employer/Addnewcompany"))
);
const EmpShortListView = React.lazy(() =>
  retry(() => import("./Components/Employer/EmpShortListView"))
);

const EmpRecruiterView = React.lazy(() =>
  retry(() => import("./Components/Employer/EmpRecruiterView"))
);

const EmpScrenersquestions = React.lazy(() =>
  retry(() => import("./Components/Employer/EmpScreenersquestions"))
);

const UserActions = React.lazy(() =>
  retry(() => import("../src/controllers/user.controller"))
);

const Signup = React.lazy(() => retry(() => import("./Components/Signup")));

const Aboutus = React.lazy(() => retry(() => import("./Components/Aboutus")));

const Privacy = React.lazy(() =>
  retry(() => import("./Components/Privacypolicy"))
);

const Contactus = React.lazy(() => retry(() => import("./Components/Contact")));

const SolrContacts = React.lazy(() => retry(() => import("./Components/Employer/solrContacts/SolrContacts.js")));

const Support = React.lazy(() => retry(() => import("./Components/Support")));

const Layoffs = React.lazy(() => retry(() => import("./Components/Layoffs")));

const H1Visa = React.lazy(() => retry(() => import("./Components/H1Visa")));

const Contactsales = React.lazy(() =>
  retry(() => import("./Components/Contactsales"))
);


const Demolink = React.lazy(() =>
  retry(() => import("./Components/DemoLink"))
);


const PostFreeJob = React.lazy(() =>
  retry(() => import("./Components/Employer/postFreeJob"))
);

const Testimonial = React.lazy(() =>
  retry(() => import("./Components/Testimonals"))
);

const Previousjobfair = React.lazy(() =>
  retry(() => import("./Components/Previousjobfair"))
);

const VirtualJobFairs2023 = React.lazy(() =>
  retry(() => import("./Components/VirtualJobFairs2023"))
);

const VirtualJobFair2024 = React.lazy(() =>
  retry(() => import("./Components/VirtualJobFair2024"))
);


const Upcomingjobfair = React.lazy(() =>
  retry(() => import("./Components/Upcomingjobfair.js"))
);

const Blog = React.lazy(() => retry(() => import("./Components/blog")));

const Blogdetailview = React.lazy(() =>
  retry(() => import("./Components/blogdetailview"))
);

const SalaryCheck = React.lazy(() =>
  retry(() => import("./Components/SalaryCheck"))
);

const Wearehiring = React.lazy(() =>
  retry(() => import("./Components/Wearehiring"))
);
const Packages = React.lazy(() => retry(() => import("./Components/Packages")));

const Empsignup = React.lazy(() =>
  retry(() => import("./Components/Employer/Empsignup"))
);

const EmpForgotPassword = React.lazy(() =>
  retry(() => import("./Components/Employer/Empforgotpassword"))
);

const Login = React.lazy(() => retry(() => import("./Components/Login")));

const EmpLogin = React.lazy(() =>
  retry(() => import("./Components/Employer/EmpLogin"))
);

const EmpScreeners = React.lazy(() =>
  retry(() => import("./Components/Employer/EmpScreeners"))
);

const CourseraDetailsView = React.lazy(() =>
  retry(() => import("./Components/CourseraView"))
);

const UdacityView = React.lazy(() =>
  retry(() => import("./Components/UdacityView"))
);

const courseDetailsView = React.lazy(() =>
  retry(() => import("./Components/CourseDetailsView"))
);

const UdacityDetailsView = React.lazy(() =>
  retry(() => import("./Components/UdacityDetailsView"))
);

const Fullgallery = React.lazy(() =>
  retry(() => import("./Components/Fullgallery"))
);

const VirtualJobFairsGallery = React.lazy(() =>
  retry(() => import("./Components/VirtualJobFairsGallery"))
);


const Certifications = React.lazy(() =>
  retry(() => import("./Components/Jobseeker/Certifications"))
);

const SolrCourseDetailView = React.lazy(() =>
  retry(() => import("./Components/solrCoursesDetailView"))
);
const Unsubscribe = React.lazy(() =>
  retry(() => import("./Components/Unsubscribe"))
);




const Brandingdetailview = React.lazy(() =>
  import("./Components/Brandingdetailview")
);

const Sharelinkedinjobs = React.lazy(() =>
  import("./Components/searchJobs/Sharelinkedinjobs")
);

const SolrCourses = React.lazy(() => import("./Components/solrCourses"));

const CvBuilderPreview = React.lazy(() =>
  import("./Components/Jobseeker/CvBuilder/CvBuilderPreview")
);


const Homepage = React.lazy(() => retry(() => import("./Components/Homepage")));
const Appliedjob = React.lazy(() =>
  import("./Components/Employer/Jobapplicantsview")
);
const JsSimilarJobsList = React.lazy(() =>
  import("./Components/Jobseeker/JsSimilarJobsList")
);

const CompanyView = React.lazy(() =>
  retry(() => import("./Components/Employer/CompanyView"))
);
const EditJob = React.lazy(() => import("./Components/Employer/EditJob"));
const PostJob = React.lazy(() => import("./Components/Employer/PostJob"));
const MyJobs = React.lazy(() => import("./Components/Employer/myJobs/My_Jobs"));
// const LinkedInRoleResumes = React.lazy(() => import("./Components/Employer/LinkedInRoleResumes.js"));

const EmpAISearch = React.lazy(() => import("./Components/Employer/Searchblock"));
const Mycompanies = React.lazy(() =>
  import("./Components/Employer/mycompanies")
);
const PdfViewer = React.lazy(() =>
  import("./Components/Jobseeker/Filemanager/PdfViewer")
);
const Jobdetailsview = React.lazy(() =>
  retry(() => import("./Components/Jobseeker/Jobdetailsview"))
);
const Filemanager = React.lazy(() =>
  import("./Components/Jobseeker/Filemanager/Filemanager")
);





const MostRecentAllJobs = React.lazy(() =>
  import("./Components/Jobseeker/MostRecentAllJobs")
);

const SearchJobsListview = React.lazy(() =>
  import("./Components/searchJobs/SearchJobs_list")
);
const CRMCompanyView = React.lazy(() =>
  import("./Components/Employer/CRMCompanyView")
);



const Companiesjobs = React.lazy(() =>
  import("./Components/Jobseeker/companiesjobs")
);
const EditResume = React.lazy(() =>
  import("./Components/Jobseeker/ViewResume/EditResume")
);

const EmpViewResume = React.lazy(() =>
  import("./Components/searchResumes/EmpViewResume")
);

const CRMViewResume = React.lazy(() =>
  import("./Components/searchResumes/CRMViewResume.js")
);
const CRMJobDetailView = React.lazy(() =>
  import("./Components/Jobseeker/crmJobdetailsView")
);


const MysqlSearchResumesList = React.lazy(() =>
  import("./Components/searchResumes/mysqlSearchResumes_list")
);
const Empdashboard = React.lazy(() =>
  retry(() => import("./Components/Employer/Empdashboard"))
);
const SavedResumes = React.lazy(() =>
  retry(() => import("./Components/Employer/SavedResumes"))
);
const Calendar = React.lazy(() =>
  retry(() => import("./Components/Employer/Calender"))
);
const Todo = React.lazy(() =>
  retry(() => import("./Components/Employer/Todo/todo"))
);
const EmployeeAccountMain = React.lazy(() =>
  import("./Components/Employer/EmployeeAccountMain")
);
const Notificationslist = React.lazy(() =>
  import("./Components/Notificationslist")
);
const ForgotPassword = React.lazy(() =>
  import("./Components/ForgotPassword/forgotpasswordForm")
);
const ResetpasswordForm = React.lazy(() =>
  import("./Components/ForgotPassword/resetPasswordForm")
);
const SocialMediaEmailForm = React.lazy(() =>
  import("./Components/socialMediaSignup")
);
const Parser = React.lazy(() =>
  import("./Components/Jobseeker/Filemanager/Parsertabs")
);


const Resumetemplate = React.lazy(() => import("./Resumetemplate"));

const Jsappliedjobslist = React.lazy(() =>
  import("./Components/Jobseeker/Jsappliedjobslist")
);
const JsShortListedJobs = React.lazy(() =>
  import("./Components/Jobseeker/jsShortlistedList")
);
const Jssavedjobslist = React.lazy(() =>
  import("./Components/Jobseeker/Jssavedjobslistview")
);
const Jsrelatedjobslistview = React.lazy(() =>
  import("./Components/Jobseeker/Jsrelatedjobslistview")
);
const JobsPageJS = React.lazy(() =>
  import("./Components/Jobseeker/JS_Jobs.js")
);
const JsAppliedJobs = React.lazy(() =>
  import("./Components/Jobseeker/JS_Jobs.js")
);
const JsSavedJobs = React.lazy(() =>
  import("./Components/Jobseeker/JS_Jobs.js")
);
const JsShortlistedJobs = React.lazy(() =>
  import("./Components/Jobseeker/JS_Jobs.js")
);

const ApplyJobAndRegisterWithResume = React.lazy(() => import("./Components/applyJobAndRegisterWithResume/ApplyJob_Register_WithResume"))

const JSNetwork = React.lazy(() =>
  import("./Components/Jobseeker/Network.js")
);
const MyResumes = React.lazy(() =>
  import("./Components/Jobseeker/MyResumes")
);



const Mynetwork = React.lazy(() =>
  import("./Components/Jobseeker/Mynetwork/My_network")
);
const JobSeekerSettingHeader = React.lazy(() =>
  import("./Components/Jobseeker/AccountSettings/JobSeekerSettingHeader")
);
const ShowTemplatesNew = React.lazy(() =>
  import("./Components/Jobseeker/CvBuilder/ShowTemplatesNew")
);



const SearchResumeListView = React.lazy(() =>
  retry(() => import("./Components/searchResumes/TraditionalResumeListView.js"))
);

const ViewResumeInBin = React.lazy(() =>
  import("./Components/searchResumes/ViewResumeInBin")
);

const SkillShare = React.lazy(() => import("./Components/SkillShareView"));
const SkillShareDetailView = React.lazy(() =>
  import("./Components/SkillShareDetailView")
);

const UdemyView = React.lazy(() => import("./Components/UdemyView"));

const UdemyDetailView = React.lazy(() =>
  import("./Components/UdemyDetailsView")
);

const EdurekaView = React.lazy(() => import("./Components/EdurekaView"));

const EdurekaDetailView = React.lazy(() =>
  import("./Components/EdurekaDetailsView")
);

const ResumeBuilder = React.lazy(() =>
  import("../src/resumeBuilder/resumeEdit")
);

const PageRedirections = React.lazy(() =>
  import("../src/Components/PageRedirections")
);

const LoginSuccessRedirection = React.lazy(() =>
  import("../src/Components/AfterLoginPage")
);



function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

function App(props) {
  const [currentToken, setCurrentToken] = useState("");
  const [timer, setTimer] = useState(0);
  const [logout, setLogout] = useState(false);

  const location = useLocation();

  const [jobTitle, setJobTitle] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [resumeImage, setResumeImage] = useState("");
  const [resumeImageObject, setResumeImageObject] = useState("");
  const [summary, setSummary] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [degree, setDegree] = useState("");
  const [stream, setStream] = useState("");
  const [userId, setUserId] = useState("");
  const [institute, setInstitute] = useState("");
  const [year, setYear] = useState("");
  const [projecttitle, setProjecttitle] = useState("");
  const [role, setRole] = useState("");
  const [client, setClient] = useState("");
  const [country, setCountry] = useState("");
  const [countryLabel, setCountryLabel] = useState("United States");
  const [city, setCity] = useState("");
  const [visaType, setVisaType] = useState("");
  const [visaTypeLabel, setVisaTypeLabel] = useState("");
  const [jobType, setJobType] = useState("");
  const [jobTypeLabel, setjobTypeLabel] = useState("");
  const [cityLabel, setCityLabel] = useState("");
  const [state, setState] = useState("");
  const [stateLabel, setStateLabel] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [website, setWebsite] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [skillsToHighlight, setSkillsToHighlight] = useState("");

  const [searchResumeTitle, setSearchResumeTitle] = useState("");
  const [resumetemplate, setResumeTemplate] = useState("0");
  const [skills, setSkills] = useState([
    {
      categoryName: "",
      skillArray: [
        {
          skillName: "",
          skillId: "",
        },
      ],
    },
  ]);
  const [certificate, setCertificate] = useState([
    { certName: "", certYear: "" },
  ]);
  const [education, setEducation] = useState([
    { university: "", yearofpassing: "", degree: "" },
  ]);
  const [project, setProject] = useState([
    {
      projectTitle: "",
      country: "",
      country_id: "",
      state: "",
      state_id: "",
      city: "",
      city_id: "",
      responsibilities: "",
      date_start: "",
      organization: "",
      date_end: "",
      jobtitle: "",
      description: "",
    },
  ]);
  const [experience, setExperience] = useState([]);
  const [skillNames, setSkillNames] = useState([]);
  const [finish, setFinish] = useState(false);
  const [finishOne, setFinishOne] = useState(false);
  const [selectedFromSuggestions, setSelectedFromSuggestions] = useState(false);
  const [jobsCount, setJobsCount] = useState(0);
  const [certificateLogo, setCertificateLogo] = useState("");
  const [cRMView, setCRMView] = useState(false);
  const [statesList, setStatesList] = useState([]);
  useEffect(() => {
    if (props.currentUser === null) {
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("usertype");

      sessionStorage.removeItem("lastvisited");
      sessionStorage.removeItem("userToken");
    } else {
      let profileimgg = localStorage.getItem("profileimg");

      sessionStorage.setItem("profileimg", profileimgg);
    }

    sessionStorage.removeItem("1tab");
    sessionStorage.removeItem("2tab");
    sessionStorage.removeItem("3tab");
    sessionStorage.removeItem("4tab");
    sessionStorage.removeItem("5tab");
    sessionStorage.removeItem("6tab");
    sessionStorage.removeItem("7thtabstate");
    sessionStorage.removeItem("6thtabcity");
    sessionStorage.removeItem("7tab");
    sessionStorage.removeItem("8tab");
    sessionStorage.removeItem("tabvalue");
    sessionStorage.removeItem("nextshow");

    // window.scrollTo(0, 0);

    window.addEventListener("popstate", (event) => {
      if (window.location.pathname === "/Jsdashboard") {
        sessionStorage.removeItem("jobtitle");
        sessionStorage.removeItem("inputjobtitle");
        sessionStorage.removeItem("cityname");

        sessionStorage.removeItem("filtered-jobtypes");
        sessionStorage.removeItem("filtered-visatypes");
        sessionStorage.removeItem("jobMappedDates");
        localStorage.removeItem("workModeFlages");
        sessionStorage.removeItem("filtered-workmode");
        localStorage.removeItem("jobtypeFlages");
        localStorage.removeItem("visatypeFlages");
        localStorage.removeItem("traveltypeFlages");
      }
      if (window.location.pathname === "/Empdashboard") {
        // sessionStorage.removeItem("resumeTitle");
      }
    });
    if (window.location.pathname !== "/Login") {
      sessionStorage.removeItem("upSkillCourseUrl");
      sessionStorage.removeItem("jobDetailApplyUrl");
    }

    if (window.location.pathname === "/") {
      sessionStorage.removeItem("PaginationCount");
      sessionStorage.removeItem("startValue");
      sessionStorage.removeItem("currentsearchpage");
    }
    if (
      window.location.pathname !== "/Login" &&
      window.location.pathname !== "/My_network" &&
      window.location.pathname !== "/Jsdashboard"
    ) {
      sessionStorage.removeItem("findcompaniesurl");
    }

    if (
      window.location.pathname !== "/EmpLogin" &&
      window.location.pathname !== "/Empsignup"
    ) {
      sessionStorage.removeItem("redirectToResumeView");
    }

    if (
      window.location.pathname !== "/Empsignup" &&
      window.location.pathname !== "/EmpLogin" &&
      window.location.pathname !== "/ResumeSearch_Listview" &&
      window.location.pathname !== "/Empdashboard"
    ) {
      sessionStorage.removeItem("frompostFreeJobs");
      sessionStorage.removeItem("fromSearchResumesFree");
    }
    if(      window.location.pathname !== "/jobs" &&
    window.location.pathname !== "/searchJobs_listview"){
      sessionStorage.removeItem("inputjobtitle_home");
      sessionStorage.removeItem("cityname_home");

    }
  });

  useEffect(() => {
    const checktimer = 1000 * 60 * 60;
    console.log(checktimer);
    var Url = window.location.pathname;
    var currentUrl = Url.split("/");
    if (
      currentUrl.length > 3 &&
      currentUrl[1] === "resume" &&
      currentUrl[2] === "view"
    ) {
      setCRMView(true);
    } else {
      setCRMView(false);
    }
    setTimeout(() => {
      setTimer(timer + 1);
      lessThanFourHoursAgo();
    }, checktimer);
  }, [timer]);

  const lessThanFourHoursAgo = () => {
    const FOURHOURS = 1000 * 60 * 60;
    const fourHoursAgo = Date.now() - FOURHOURS;
    const lastvisiteddate = props.currentUser.lastvisitDate;
    // console.log(lastvisiteddate)
    var parseddate = Date.parse(lastvisiteddate);
    console.log(parseddate);
    console.log(fourHoursAgo);
    if (parseddate < fourHoursAgo) {
      //alert("idle for 1 minute")
      setLogout(true);
      props.logout();
    }
  };

  return (
    <SearchResumeContext.Provider
      value={{
        searchResumeTitle,
        updateSearchResumeTitle: (value) => setSearchResumeTitle(value),
        selectedFromSuggestions,
        updateselectedFromSuggestions: (value) =>
          setSelectedFromSuggestions(value),
        jobsCount,
        updateJobsCount: (value) => setJobsCount(value),
        skillsToHighlight,
        updateskillsToHighlight: (value) => setSkillsToHighlight(value),
      }}
    >
      <Resumecontext.Provider
        value={{
          jobTitle,
          updateJobTitle: (value) => setJobTitle(value),
          website,
          updateWebsite: (value) => setWebsite(value),
          portfolio,
          updatePortfolio: (value) => setPortfolio(value),
          name,
          updateName: (value) => setName(value),
          email,
          updateEmail: (value) => setEmail(value),
          phone,
          updatePhone: (value) => setPhone(value),
          summary,
          updateSummary: (value) => setSummary(value),
          degree,
          updateDegree: (value) => setDegree(value),
          resumeImage,
          updateResumeImage: (value) => setResumeImage(value),
          resumeImageObject,
          updateResumeImageObject: (value) => setResumeImageObject(value),
          institute,
          updateInstitute: (value) => setInstitute(value),
          stream,
          updateStream: (value) => setStream(value),
          year,
          updateYear: (value) => setYear(value),
          skills,
          updateSkills: (value) => setSkills(value),
          userId,
          updateUserId: (value) => setUserId(value),
          password,
          updatePassword: (value) => setPassword(value),
          confirmPassword,
          updateConfirmPassword: (value) => setConfirmPassword(value),
          projecttitle,
          updateProjecttitle: (value) => setProjecttitle(value),
          client,
          updateClient: (value) => setClient(value),
          role,
          updateRole: (value) => setRole(value),
          country,
          updateCountry: (value) => setCountry(value),
          countryLabel,
          updateCountryLabel: (value) => setCountryLabel(value),
          state,
          updateState: (value) => setState(value),
          stateLabel,
          updateStateLabel: (value) => setStateLabel(value),
          city,
          updateCity: (value) => setCity(value),
          visaType,
          updateVisaType: (value) => setVisaType(value),
          visaTypeLabel,
          updateVisaTypeLabel: (value) => setVisaTypeLabel(value),
          jobType,
          updateJobType: (value) => setJobType(value),
          jobTypeLabel,
          updateJobTypeLabel: (value) => setjobTypeLabel(value),
          cityLabel,
          updateCityLabel: (value) => setCityLabel(value),
          startdate,
          updateStartdate: (value) => setStartdate(value),
          enddate,
          updateEnddate: (value) => setEnddate(value),
          certificate,
          updateCertificate: (value) => setCertificate(value),
          education,
          updateEducation: (value) => setEducation(value),
          project,
          updateProject: (value) => setProject(value),
          experience,
          updateExperience: (value) => setExperience(value),
          skillNames,
          updateSkillNames: (value) => setSkillNames(value),
          resumetemplate,
          updateResumetemplate: (value) => setResumeTemplate(value),
          finish,
          updateFinish: (value) => setFinish(value),
          finishOne,
          updateFinishOne: (value) => setFinishOne(value),
          certificateLogo,
          updateCertificateLogo: (value) => setCertificateLogo(value),
          statesList,
          getStates: (value) => setStatesList(value),
        }}
      >
        <React.Fragment>
          {/* <ScrollToTop smooth component={<MySVG />} />   
          <Loader />*/}
          <Loader />
          <ToastContainer
            className="toaster-container"
            position="top-right"
            autoClose={2500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {props.currentUser === null ? (
            //before login
            <React.Fragment>
              {/*<Cookies />*/}
              <Suspense
                className="suspensestyle"
                fallback={
                  <React.Fragment>
                    <LazyLoader />{" "}
                  </React.Fragment>
                }
              >
                <Switch>
                  <Route
                    exact
                    path="/js/apply-job/:job_id"
                    component={ApplyJobAndRegisterWithResume}
                  />
                  <Route
                    exact
                    path="/mail/redirect/:resume_id_or_job_id"
                    component={PageRedirections}
                  />
                  <Route
                    exact
                    path="/Previousjobfair"
                    component={Previousjobfair}
                  />
                  <Route
                    exact
                    path="/VirtualJobFairs2023"
                    component={VirtualJobFairs2023}
                  />
                  <Route
                    exact
                    path="/DemoLink/:userId"
                    component={Demolink}
                  />
                  <Route
                    exact
                    path="/VirtualJobFair2024"
                    component={VirtualJobFair2024}
                  />
                  <Route
                    exact
                    path="/Sharejobs/:id/:title"
                    component={Sharelinkedinjobs}
                  />
                  <Route
                    exact
                    path="/Sharejobs/:id"
                    component={Sharelinkedinjobs}
                  />
                  <Route
                    exact
                    path="/Resumeslist/:keyword"
                    component={Resumeslist}
                  />
                  <Route exact path="/Resumeslist" component={Resumeslist} />
                  <Route
                    exact
                    path="/ParserResumeslist"
                    component={ParserResumes}
                  />
                  <Route
                    exact
                    path="/ParserResumestabs"
                    component={ParserResumestabs}
                  />
                  <Route exact path="/Support" component={Support} />
                  <Route exact path="/Layoffs" component={Layoffs} />
                  <Route exact path="/Jssupport" component={Jssupport} />
                  <Route exact path="/Empsupport" component={Empsupport} />
                  <Route
                    exact
                    path="/AdvancedResumeSearch"
                    component={AdvancedResumeSearch}
                  />
                  <Route exact path="/Aboutus" component={Aboutus} />
                  <Route exact path="/Privacypolicy" component={Privacy} />
                  <Route exact path="/Contact" component={Contactus} />
                  <Route exact path="/Contactsales" component={Contactsales} />
                  <Route exact path="/Wearehiring" component={Wearehiring} />
                  <Route exact path="/Packages" component={Packages} />
                  <Route
                    exact
                    path="/ResumeSearch_Listview"
                    component={SearchResumeListView}
                  />
                  <Route exact path="/Navbar" component={Navbar} />
                  <Route exact path="/Login" component={Login} />
                  <Route exact path="/Signup" component={Signup} />
                  <Route exact path="/createResume" component={ResumeBuilder} />
                  <Route exact path="/PostFreeJob" component={PostFreeJob} />
                  <Route
                    exact
                    path="/resume/view/:resumeid"
                    component={EmpViewResume}
                  />
                  <Route
                    exact
                    path="/searchJobs_listview"
                    component={SearchJobsListview}
                  />
                  <Route
                    exact
                    path="/job/view/:crmuid/:crmtoken/:jobid"
                    component={CRMJobDetailView}
                  />
                  <Route
                    exact
                    path="/job/view/:jobid"
                    component={Jobdetailsview}
                  />
                  <Route
                    exact
                    path="/forgotpassword"
                    component={ForgotPassword}
                  />
                  <Route
                    exact
                    path={`/ResetPassword/:key`}
                    component={ResetpasswordForm}
                  />
                  <Route
                    exact
                    path={`/socialMedia_signup`}
                    component={SocialMediaEmailForm}
                  />
                  <Route exact path="/parseresume" component={Parser} />
                  <Route
                    exact
                    path="/companiesjobs"
                    component={Companiesjobs}
                  />
                  <Route exact path="/Testimonial" component={Testimonial} />
                  <Route exact path="/Empsignup" component={Empsignup} />
                  <Route exact path="/Empsignup/SM" component={Empsignup} />
                  <Route exact path="/Empsignup/EM" component={Empsignup} />
                  <Route exact path="/Empsignup/GEN" component={Empsignup} />
                  <Route exact path="/EmpLogin" component={EmpLogin} />
                  <Route exact path="/courses" component={SolrCourses} />
                  <Route
                    exact
                    path="/courseDetailView"
                    component={SolrCourseDetailView}
                  />
                  <Route
                    exact
                    path="/Empforgotpassword"
                    component={EmpForgotPassword}
                  />
                  <Route
                    exact
                    path="/EmpVerifyEmail/:key"
                    component={EmailVerification}
                  />
                  <Route
                    exact
                    path="/resumesearch_listview"
                    component={MysqlSearchResumesList}
                  />
                  <Route
                    exact
                    path="/company/view/:companyid"
                    component={CompanyView}
                  />
                  <Route
                    exact
                    path="/crm/company/view/:companyid"
                    component={CRMCompanyView}
                  />
                  <Route
                    exact
                    path="/Addnewcompany"
                    component={Addnewcompany}
                  />
                  <Route exact path="/ourgallery" component={Fullgallery} />
                  <Route
                    exact
                    path="/VirtualJobFairsGallery"
                    component={VirtualJobFairsGallery}
                  />
                  <Route
                    exact
                    path="/Upcomingjobfair"
                    component={Upcomingjobfair}
                  />
                  <Route exact path="/SearchByMap" component={SearchByMap} />
                  <Route
                    exact
                    path="/ResumeBuilder"
                    component={ShowTemplatesNew}
                  />
                  <Route exact path="/blog" component={Blog} />
                  <Route
                    exact
                    path="/blogdetailview"
                    component={Blogdetailview}
                  />
                  <Route
                    exact
                    path="/salaryCheck/:avgsalary"
                    component={SalaryCheck}
                  />
                  <Route
                    exact
                    path="/similarJobsList"
                    component={JsSimilarJobsList}
                  />
                  <Route
                    exact
                    path="/courses/coursera"
                    component={CourseraDetailsView}
                  />
                  <Route
                    exact
                    path="/courses/udacity"
                    component={UdacityView}
                  />
                  <Route
                    exact
                    path="/courses/skillshare"
                    component={SkillShare}
                  />
                  <Route exact path="/courses/udemy" component={UdemyView} />
                  <Route
                    exact
                    path="/courses/edureka"
                    component={EdurekaView}
                  />
                  <Route
                    exact
                    path="/coursera/course-name"
                    component={courseDetailsView}
                  />
                  <Route
                    exact
                    path="/skillshare/course-name"
                    component={SkillShareDetailView}
                  />
                  <Route
                    exact
                    path="/udacity/course-name"
                    component={UdacityDetailsView}
                  />
                  <Route
                    exact
                    path="/udemy/course-name"
                    component={UdemyDetailView}
                  />
                  <Route
                    exact
                    path="/edureka/course-name"
                    component={EdurekaDetailView}
                  />
                  <Route
                    exact
                    path="/Brandingdetailview"
                    component={Brandingdetailview}
                  />
                  <Route
                    exact
                    path="/unsubscribe/:email"
                    component={Unsubscribe}
                  />
                  <Route exact path="/Aboutus" component={Aboutus} />
                  {/* <Route exact path="/payment" component={PaymentPermission}/> */}
                  <Route exact path="/bs_login" component={BsLogin} />
                  <Route exact path="/bs_signup" component={BsSignup} />
                  <Route
                    exact
                    path="/resume/view/:crmuid/:crmtoken/:resumeid"
                    component={CRMViewResume}
                  />
                  <Route exact path="/" component={Homepage} />
                  <Route exact path="/home" component={Homepage}>
                    {" "}
                    <Redirect to="/" />{" "}
                  </Route>

                  <Redirect from="/" to="/home" />
                </Switch>
              </Suspense>

              {/*<ChatWidget />*/}
            </React.Fragment>
          ) : props.currentUser.usertype === "5" ? (
            //jobseekar
            <React.Fragment>
              {window.location.pathname == "/404" && <></>}
              {/* // <Nav2 />  */}
              <JSNavBar >

                {/* <Feedback /> */}

                <Suspense
                  className="suspensestyle"
                  fallback={
                    <React.Fragment>
                      <LazyLoader />{" "}
                    </React.Fragment>
                  }
                >
                  <Switch>
                    <Route
                      exact
                      path="/network"
                      component={JSNetwork}
                    />
                    <Route
                      exact path="/Jsdashboard"
                      component={Jsdashboard} />
                    <Redirect exact from="/" to="/Jsdashboard" />
                    {/* <Route
                    exact
                    path="/"
                    component={Jsdashboard}
                  /> */}
                    <Route
                      exact
                      path="/my-resumes"
                      component={MyResumes}
                    />
                    <Route
                      exact
                      path="/jobs"
                      component={JobsPageJS}
                    />
                    <Route
                      exact
                      path="/jobs/applied"
                      component={JsAppliedJobs}
                    />
                    <Route
                      exact
                      path="/jobs/saved"
                      component={JsSavedJobs}
                    />
                    <Route
                      exact
                      path="/jobs/shortlisted"
                      component={JsShortlistedJobs}
                    />
                    <Route
                      exact
                      path="/mail/redirect/:resume_id_or_job_id"
                      component={PageRedirections}
                    />
                    <Route
                      exact
                      path="/Resumetemplate"
                      component={Resumetemplate}
                    />
                    <Route
                      exact
                      path="/Notificationslist"
                      component={Notificationslist}
                    />
                    <Route
                      exact
                      path="/Jsrelatedjobslistview"
                      component={Jsrelatedjobslistview}
                    />
                    <Route
                      exact
                      path="/ResumeSearch_Listview"
                      component={SearchResumeListView}
                    />
                    <Route
                      exact
                      path="/Sharejobs/:id"
                      component={Sharelinkedinjobs}
                    />
                    <Route
                      exact
                      path="/JsMostRecentJobsList"
                      component={MostRecentAllJobs}
                    />
                    <Route
                      exact
                      path="/Brandingdetailview"
                      component={Brandingdetailview}
                    />
                    <Route
                      exact
                      path="/certifications"
                      component={Certifications}
                    />
                    <Route
                      exact
                      path="/certificationsdetailsview"
                      component={Certificationsdetailsview}
                    />
                    <Route exact path="/courses" component={SolrCourses} />
                    <Route
                      exact
                      path="/courses/coursera"
                      component={CourseraDetailsView}
                    />
                    <Route
                      exact
                      path="/courses/udacity"
                      component={UdacityView}
                    />
                    <Route
                      exact
                      path="/courses/skillshare"
                      component={SkillShare}
                    />
                    <Route exact path="/courses/udemy" component={UdemyView} />
                    <Route
                      exact
                      path="/courses/edureka"
                      component={EdurekaView}
                    />
                    <Route
                      exact
                      path="/coursera/course-name"
                      component={courseDetailsView}
                    />
                    <Route
                      exact
                      path="/skillshare/course-name"
                      component={SkillShareDetailView}
                    />
                    <Route exact path="/createResume" component={ResumeBuilder} />
                    <Route
                      exact
                      path="/udacity/course-name"
                      component={UdacityDetailsView}
                    />
                    <Route
                      exact
                      path="/udemy/course-name"
                      component={UdemyDetailView}
                    />
                    <Route
                      exact
                      path="/Jssavedjobslist"
                      component={Jssavedjobslist}
                    />
                    <Route
                      exact
                      path="/Jsappliedjobslist"
                      component={Jsappliedjobslist}
                    />
                    <Route
                      exact
                      path="/Js_shortlisted"
                      component={JsShortListedJobs}
                    />
                    <Route
                      exact
                      path="/similarJobsList"
                      component={JsSimilarJobsList}
                    />
                    <Route exact path="/Filemanager" component={Filemanager} />
                    <Route
                      exact
                      path="/searchJobs_listview"
                      component={SearchJobsListview}
                    />
                    <Route
                      exact
                      path="/job/view/:jobid"
                      component={Jobdetailsview}
                    />
                    <Route exact path="/Nav2" component={Navbar2} />
                    <Route exact path="/My_network" component={Mynetwork} />
                    <Route
                      exact
                      path="/accountSettings"
                      component={JobSeekerSettingHeader}
                    />
                    <Route
                      exact
                      path="/resume/edit/:resumeid"
                      component={EditResume}
                    />
                    <Route
                      exact
                      path="/companiesjobs"
                      component={Companiesjobs}
                    />
                    {/* <Route exact path="/SearchJobs_listvieww" component={MysqlSearchJobs_list} /> */}
                    <Route
                      exact
                      path="/resumesearch_listview"
                      component={MysqlSearchResumesList}
                    />
                    <Route
                      exact
                      path="/resume/view/:resumeid"
                      component={EmpViewResume}
                    />
                    <Route
                      exact
                      path="/bin_resume/view/:resumeid"
                      component={ViewResumeInBin}
                    />
                    <Route exact path="/personalDetails" component={FormData} />
                    <Route
                      exact
                      path="/company/view/:companyid"
                      component={CompanyView}
                    />
                    {/* <Route exact path="/parser" component={Parser} /> */}
                    <Route exact path="/pdfView" component={PdfViewer} />
                    <Route
                      exact
                      path="/ResumeBuilder"
                      component={ShowTemplatesNew}
                    />
                    <Route
                      exact
                      path="/resumePreview"
                      component={CvBuilderPreview}
                    />
                    <Route exact path="/SearchByMap" component={SearchByMap} />
                    <Route
                      exact
                      path="/salaryCheck/:avgsalary"
                      component={SalaryCheck}
                    />
                    <Route exact path="/Contact" component={Contactus} />
                    <Route exact path="/Contactsales" component={Contactsales} />
                    <Route exact path="/blog" component={Blog} />
                    <Route
                      exact
                      path="/blogdetailview"
                      component={Blogdetailview}
                    />
                    <Route
                      exact
                      path="/recruiter/:rec_id"
                      component={EmpRecruiterView}
                    />
                    <Route exact path="/Testimonial" component={Testimonial} />
                    <Route exact path="/Aboutus" component={Aboutus} />
                    <Route exact path="/Privacypolicy" component={Privacy} />
                    <Route exact path="/Jssupport" component={Jssupport} />
                    <Route exact path="/H1Visa" component={H1Visa} />
                    <Route
                      exact
                      path="/resume/view/:crmuid/:crmtoken/:resumeid"
                      component={CRMViewResume}
                    />
                    <Route exact path="/home" component={Homepage}>
                      {" "}
                      <Redirect to="/" />{" "}
                    </Route>
                    <Route exact path="/" component={Homepage} />
                    <Route
                      exact
                      path="/loginRedirection"
                      component={LoginSuccessRedirection}
                    />
                    <Route exact path="/Jsdashboard" component={Jsdashboard} />

                    <Redirect from="/" to="/loginRedirection" />
                  </Switch>
                  {/* {
                location.pathname !== '/createResume' && 
                location.pathname !== '/SearchByMap' &&
                 <Footer />
              } */}
                </Suspense>
                {/* <div style={{position:'absolute', bottom:0, width:'100%',}} > */}

                {/* </div> */}
              </JSNavBar>
            </React.Fragment>
          ) : props.currentUser.usertype === "3" ? (
            //benchsales
            <></>
          ) : (
            // employer
            // props.currentUser.usertype === "2" ?

            <React.Fragment>
              {window.location.pathname == "/404" && <></>}
              <Empnavbar>
                {window.location.pathname === "/ResumeSearch_Listview" ? (
                  ""
                ) : (
                  <Feedback />
                )}
                <Suspense
                  className="suspensestyle"
                  fallback={
                    <React.Fragment>
                      <LazyLoader />{" "}
                    </React.Fragment>
                  }
                >
                  <Switch>
                    <Route
                      exact
                      path="/mail/redirect/:resume_id_or_job_id"
                      component={PageRedirections}
                    />
                    <Route
                      exact
                      path="/Empdashboard"
                      component={Empdashboard} />

                    <Redirect exact from="/" to="/Empdashboard" />
                    <Route
                      exact
                      path="/employer/ai-search"
                      component={EmpAISearch}
                    />
                    <Route
                      exact
                      path="/employer/js-contacts"
                      component={SolrContacts}
                    />
                    <Route
                      exact
                      path="/employer/saved-resumes"
                      component={SavedResumes}
                    />
                    <Route
                      exact
                      path="/recruiter/saved-resumes"
                      component={SavedResumes}
                    />

                    <Route
                      exact
                      path="/employer/my-contacts"
                      component={MyContacts}
                    />
                    <Route
                      exact
                      path="/calendar"
                      component={Calendar}
                    />
                    <Route
                      exact
                      path="/todo"
                      component={Todo}
                    />

                    <Route
                      exact
                      path="/Notificationslist"
                      component={Notificationslist}
                    />
                    <Route
                      exact
                      path="/ResumeSearch_Listview"
                      component={SearchResumeListView}
                    />
                    <Route
                      exact
                      path="/AdvancedResumeSearch"
                      component={AdvancedResumeSearch}
                    />
                    <Route exact path="/EmpRecruiters" component={Recruiters} />
                    <Route exact path="/Testimonial" component={Testimonial} />
                    <Route
                      exact
                      path="/Empaccountsettings"
                      component={EmployeeAccountMain}
                    />
                    <Route
                      exact
                      path="/resumesearch_listview"
                      component={MysqlSearchResumesList}
                    />
                    <Route
                      exact
                      path="/courses/coursera"
                      component={CourseraDetailsView}
                    />
                    <Route
                      exact
                      path="/courses/udacity"
                      component={UdacityView}
                    />
                    <Route
                      exact
                      path="/courses/skillshare"
                      component={SkillShare}
                    />
                    <Route exact path="/courses/udemy" component={UdemyView} />
                    <Route
                      exact
                      path="/courses/edureka"
                      component={EdurekaView}
                    />
                    <Route
                      exact
                      path="/coursera/course-name"
                      component={courseDetailsView}
                    />
                    <Route
                      exact
                      path="/skillshare/course-name"
                      component={SkillShareDetailView}
                    />
                    <Route
                      exact
                      path="/ScreenersQuestions"
                      component={EmpScrenersquestions}
                    />
                    <Route
                      exact
                      path="/Sharejobs/:id"
                      component={Sharelinkedinjobs}
                    />
                    <Route
                      exact
                      path="/udacity/course-name"
                      component={UdacityDetailsView}
                    />
                    <Route
                      exact
                      path="/udemy/course-name"
                      component={UdemyDetailView}
                    />
                    <Route
                      exact
                      path="/resume/view/:resumeid"
                      component={EmpViewResume}
                    />
                    <Route
                      exact
                      path="/resume/edit/:resumeid"
                      component={EditResume}
                    />
                    <Route
                      exact
                      path="/companiesjobs"
                      component={Companiesjobs}
                    />
                    <Route
                      exact
                      path="/searchJobs_listview"
                      component={SearchJobsListview}
                    />
                    <Route
                      exact
                      path="/job/view/:jobid"
                      component={Jobdetailsview}
                    />
                    <Route exact path="/pdfView" component={PdfViewer} />
                    <Route exact path="/mycompanies" component={Mycompanies} />
                    <Route exact path="/myJobs" component={MyJobs} />
                    {/* <Route exact path="/role-resumes" component={LinkedInRoleResumes} /> */}

                    <Route exact path="/postJob" component={PostJob} />
                    <Route
                      exact
                      path="/postJob/screeners"
                      component={EmpScreeners}
                    />
                    <Route exact path="/editJob/:jobId" component={EditJob} />
                    <Route
                      exact
                      path="/company/view/:companyid"
                      component={CompanyView}
                    />
                    <Route
                      exact
                      path="/Jobapplicantsview"
                      component={Appliedjob}
                    />
                    <Route
                      exact
                      path="/similarJobsList"
                      component={JsSimilarJobsList}
                    />
                    <Route
                      exact
                      path="/jobsShortList"
                      component={EmpShortListView}
                    />
                    <Route
                      exact
                      path="/recruiter/:rec_id"
                      component={EmpRecruiterView}
                    />
                    <Route
                      exact
                      path="/salaryCheck/:avgsalary"
                      component={SalaryCheck}
                    />
                    <Route exact path="/Contact" component={Contactus} />
                    <Route exact path="/Contactsales" component={Contactsales} />
                    <Route
                      exact
                      path="/Brandingdetailview"
                      component={Brandingdetailview}
                    />
                    <Route exact path="/Aboutus" component={Aboutus} />
                    <Route exact path="/Privacypolicy" component={Privacy} />
                    <Route exact path="/Empsupport" component={Empsupport} />
                    <Route exact path="/courses" component={SolrCourses} />
                    <Route exact path="/SearchByMap" component={SearchByMap} />
                    <Route exact path="/blog" component={Blog} />
                    <Route
                      exact
                      path="/blogdetailview"
                      component={Blogdetailview}
                    />
                    <Route
                      exact
                      path="/resume/view/:crmuid/:crmtoken/:resumeid"
                      component={CRMViewResume}
                    />
                    <Route exact path="/" component={Homepage} />
                    <Route exact path="/home" component={Homepage}>
                      {" "}
                      <Redirect to="/" />{" "}
                    </Route>
                    <Route
                      exact
                      path="/loginRedirection"
                      component={LoginSuccessRedirection}
                    />
                    <Route exact path="/Empdashboard" component={Empdashboard} />

                    <Redirect from="/" to="/loginRedirection" />
                  </Switch>
                </Suspense>
              </Empnavbar>
            </React.Fragment>
          )}
        </React.Fragment>
      </Resumecontext.Provider>
    </SearchResumeContext.Provider>
  );
}
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
    dialogs: state.UserReducer.dialogs,
  };
}

const mapDispatchToProps = function (dispatch) {
  return {
    logout: () => {
      UserActions.logout()(dispatch);
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
