import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, useMediaQuery } from '@mui/material';

const JobRowsSelect = ({ rowsPerPage, onRowsChange }) => {

  const isMatchLGL = useMediaQuery("(min-width:1025px)");
  const handleRowsChange = (event) => {
    onRowsChange(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: isMatchLGL ? 140 : 100 }} size="small">
  <InputLabel id="job-rows-label" sx={{ fontSize: isMatchLGL ? '0.85rem' : '0.75rem' }}>
    Rows per page
  </InputLabel>
  <Select
    labelId="job-rows-label"
    id="job-rows-select"
    value={rowsPerPage}
    onChange={handleRowsChange}
    input={<OutlinedInput label="Rows per page" />}
    sx={{ fontSize: isMatchLGL ? '0.85rem' : '0.75rem' }}
  >
    <MenuItem value={10}>10</MenuItem>
    <MenuItem value={20}>20</MenuItem>
    <MenuItem value={30}>30</MenuItem>
    <MenuItem value={50}>50</MenuItem>
    <MenuItem value={100}>100</MenuItem>
  </Select>
</FormControl>

  );
};

export default JobRowsSelect;
