import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Typography,
  Menu,
  Fade,
  Toolbar,
  IconButton,
  MenuItem,
} from "@mui/material";
import { Link, useHistory, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import MuiContainer from "@mui/material/Container";
import { ArrowDropDownRounded } from "@mui/icons-material";
import newGif from "../Images/newGif.gif";
import lightLog from "../Images/logo/logo-light-bg.png";
import Ampmenubar from "./Ampmenubar";
import { useMediaQuery } from '@mui/material';




const pages = ["Home", "Jobs", "Company", "File Manager", "Network"];

const Navbar = () => {
  const mobileView = useMediaQuery('(max-width:600px)');
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [scroll, setScroll] = useState(false);
  const history = useHistory();
  const location = useLocation(); // Use useLocation hook to get the current location
  const [showPostFreeJobSubmenu, setShowPostFreeJobSubmenu] = useState(false);
  const handlePostFreeJobClick = () => {
    setShowPostFreeJobSubmenu(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const HoverMenu = ({ children, submenu }) => {
    const [open, setOpen] = useState(false);
    const containerRef = useRef(null);

    const handleMouseEnter = () => {
      setOpen(true);
    };

    const handleMouseLeave = () => {
      setOpen(false);
    };

    return (
      <div
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
        {open && (
          <Menu
            anchorEl={containerRef.current}
            open={open}
            onClose={handleMouseLeave}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {submenu.map((sub, index) => (
              // <MenuItem key={index} onClick={handleMouseLeave}>
              //   <Link to={sub.to}>{sub.name}</Link>
              // </MenuItem>
              <MenuItem
                key={index}
                onClick={handleMouseLeave}
                component={Link}
                to={sub.to}
              >
                <Typography textAlign="center">{sub.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>
    );
  };
  const FadeMenu = ({ name, submenu }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          // endIcon={<KeyboardArrowDown />}
          sx={{
            my: 2,
            color: "#05264C",
            display: "block",
            textTransform: "capitalize",
            fontWeight: 400,
          }}
        >
          {name}
          <ArrowDropDownRounded sx={{ color: "#A0ABB8" }} />
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {submenu.map((sub, index) =>
            sub?.submenu ? (
              <HoverMenu submenu={sub?.submenu}>
                <MenuItem>{sub?.name}</MenuItem>
              </HoverMenu>
            ) : name === "Bench Sales" && sub?.name === "Sign in" ? (
              <MenuItem key={index} onClick={() => handleClose()}>
                <a href={sub?.to} target="_blank">
                  {sub?.name}
                </a>
              </MenuItem>
            ) : (
              // <MenuItem key={index} onClick={() => handleClose()}>
              //   <Link to={sub?.to}>{sub?.name}</Link>
              // </MenuItem>
              <MenuItem
                key={index}
                onClick={() => handleClose()}
                component={Link}
                to={sub.to}
              >
                <Typography textAlign="center">{sub.name}</Typography>
              </MenuItem>
            )
          )}
        </Menu>
      </div>
    );
  };

  return (
    <AppBar
      position={scroll ? "fixed" : "static"}
      sx={{
        backgroundColor: scroll ? "#fff" : "transparent",
        boxShadow: !scroll && "none",
      }}
    >
      <MuiContainer maxWidth="xl">
        <Toolbar disableGutters sx={{ px: 4 }}>
        <Box sx={{ flexGrow: 1, textAlign: { xs: "center", md: "left" } }}>
          <img src={lightLog} width="150px" />
          </Box>
          {mobileView ? (
          <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}>
            <Ampmenubar/>
          </Box>
        ) : (
          <Box sx={{ display: "none" }} /> // Hide Ampmenubar on larger screens
        )}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Button
              my={2}
              sx={{
                my: 2,
                color: scroll ? "#05264C" : "#05264C",
                display: "block",
                textTransform: "capitalize",
                fontWeight: 400,
              }}
              onClick={() => history.push("/")}
            >
              Home
            </Button>
            <div style={{ position: "relative" }}>
              <img
                src={newGif}
                alt="Job Fair GIF"
                style={{ position: "absolute", top: 13, left: 0, zIndex: 1 }}
              />
              <FadeMenu
                name="Job Fair"
                submenu={[
                  { name: "Upcoming Job Fair", to: "/Upcomingjobfair" },
                  { name: "Previous Job Fair", to: "/Previousjobfair" },
                  { name: "Virtual Job Fair 2023", to: "/VirtualJobFairs2023" },
                  { name: "Virtual Job Fair 2024", to: "/VirtualJobFair2024" },
                ]}
              />
            </div>
            <Button
              my={2}
              sx={{
                my: 2,
                color: scroll ? "#05264C" : "#05264C",
                display: "block",
                textTransform: "capitalize",
                fontWeight: 400,
              }}
              onClick={() => history.push("/layoffs")}
            >
              Layoff Data
            </Button>
            <FadeMenu
              name="Employer"
              submenu={[
                { name: "Sign up", to: "/Empsignup" },
                { name: "Sign in", to: "/EmpLogin" },
                {
                  name: "Post Jobs Free",
                  submenu: [
                    { name: "New Customer", to: "/PostFreeJob" },
                    { name: "Existing Customer", to: "/EmpLogin" },
                  ],
                },
              ]}
            />
            <FadeMenu
              name="Job Seeker"
              submenu={[
                { name: "Sign up", to: "/Signup" },
                { name: "Sign in", to: "/Login" },
              ]}
            />
            <FadeMenu
              name="Bench Sales"
              submenu={[
                { name: "Sign up", to: "/bs_signup" },
                {
                  name: "Sign in",
                  to: "https://bs.jobsnprofiles.com",
                  target: "_blank",
                },
              ]}
            />
          </Box>

       
        </Toolbar>
      </MuiContainer>
    </AppBar>
  );
};
export default Navbar;
