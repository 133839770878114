import React, { useState, useEffect, Fragment } from "react";
import "../../../src/Components/searchJobs/searchJobPage.css";
import axios from "axios";
//import Searchblock from "./mysqlResumeSearchBlock";
import Key from "../../clientVariables.json";
import moment from "moment";
import Sendcandidatetomail from "./sendcandidatetomail";

import Messagepopup from "./Messagepopup";
import Notepopup from "./Notepopup";
import PdfViewer from "./PdfViewer";

import imageOne from "../../Images/SearchJobs_Img/g-logo.png";
import imageTwo from "../../Images/SearchJobs_Img/image-2.png";
import imageThree from "../../Images/SearchJobs_Img/image-3.png";
import imageFour from "../../Images/SearchJobs_Img/image-4.png";
import imageFive from "../../Images/SearchJobs_Img/image-5.png";
import profileimg from "../../Images/profiledef.png";
import Nodata from "../Employer/Images/Nodata.jpg";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Pagination from "./ResumesPagination";
import Navbar from "../Employer/Empnavbar";
import HomeNavbar from "../Navbar";
import ShareResume from "../Employer/ShareResume";
import ShareResumeMultiple from "../Employer/ShareResumeMultiple";
import { toast } from "react-toastify";
import ToastMessage from "../../Components/ToastMessage";

import Packages from "../../Components/Employer/PackagesView";

import EmpResumeFilters from "./ResumeFilters";
//for loader
import { showLoader } from "../Actions/loader.actions";
import { hideLoader } from "../Actions/loader.actions";
//for alert popup
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import Highlighter from "react-highlight-words";
import { TiEdit, TiSocialTwitter } from "react-icons/ti";
import MetaTags from "react-meta-tags";
import states from "./stateslist";
import { postOptions } from "../../utils/httpConfig";
import request from "../../utils/request";
import { ENDPOINT } from "../../utils/endpoint";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { PersonOutlineOutlined } from "@mui/icons-material";
import MuiPaper from "@mui/material/Paper";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import ListItem from "@mui/material/ListItem";
import MuiTooltip from "@mui/material/Tooltip";

import ResumePdfViewer from "../Employer/ResumePdfViewer";
import "../Employer/Styles/my_jobs.css";
import { tabsClasses } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TiSocialLinkedin } from "react-icons/ti";
import ResumeProfile from "../ResumeProfile";
import SessionAlert from "../SessionAlert";
import nodataImg from "../../Images/nodata.jpg";

var openAISkills = require("../OpenAIComponent");

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(64, 104, 130, 0.9)",
    //  theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Bullet = styled("span")({
  display: "inline-block",
  width: "3px",
  height: "3px",
  borderRadius: "50%",
  backgroundColor: "currentColor",
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, height: "80vh", overflow: "scroll" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

var ind = 0;
const SearchJobsTwo = (props) => {
  // console.log(props);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [fileNotFound, setFileNotFOund] = React.useState(false);
  const [resumes, setResumes] = useState([]);
  const [singleResume, setSingleResume] = useState([]);
  const [start, setStart] = useState(6);
  const [rowsPerPage, setRowsPerPage] = useState(
    sessionStorage.getItem("resumesPerPage") !== null
      ? parseInt(sessionStorage.getItem("resumesPerPage"))
      : 10
  );
  const [groupIds, setGroupIds] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  // const [count, setCount] = useState(20);
  const [isJobtypeChecked, setIsJobtypeChecked] = useState(false);
  const [numFound, setNumFound] = useState(0);
  const [resumeChecked, setResumeChecked] = useState();
  const [selectedResumes, setSelectedResumes] = useState([]);
  const [selectedResume, setSelectedResume] = useState([]);
  const [totalViewsCount, setTotalViewsCount] = useState(0);
  const [allResumeViews, setAllResumeViews] = useState([]);
  const [groupByallResumeViews, setGroupByAllResumeViews] = useState([]);
  const [currentresid, setcurrentresid] = useState("");
  const [currentresfile, setcurrentresfile] = useState("");
  const [currentresuid, setcurrentresuid] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [isFilterChecked, setIsFilterChecked] = useState(false);
  const images = [imageOne, imageTwo, imageThree, imageFour, imageFive];
  const [show, setShow] = useState(false);
  const [showMultiple, setShowMultiple] = useState(false);
  const [tomail, settomail] = useState("");
  const [candfullname, setcandfullname] = useState("");
  const [jobs, setJobs] = useState([]);
  const [topJob, setTopJob] = useState({});
  const [resumeViewsLeft, setResumeViewsLEft] = useState(0);
  const [topJobs, setTopJobs] = useState([]);
  const [selectedResIds, setSelectedResids] = useState([]);
  const [noResumesMsg, setNoResumesMsg] = useState(true);
  const [messageshow, setmessageShow] = useState(false);
  const [noteShow, setNoteShow] = useState(false);
  const [mailshow, setmailShow] = useState(false);
  const [packageExpired, setPackageExpired] = useState(false);
  const [initalpackageValue, setInitialpackageValue] = useState("");
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [groupMembersIds, setGroupMemebersIds] = useState([]);
  const [packagesShow, setPackagesShow] = useState(false);
  const [noteResumeId, setNoteResumeId] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [selectedResumeID, setSelectedResumeID] = useState("");
  const [totalsDetails, setTotalDetails] = useState("");
  const [skillsArray, setSkillsArray] = useState("");
  const [searchSOLRquery, setSearchSOLRquery] = useState("");
  const [today, setToday] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [indexvalue, setIndexValue] = useState(0);
  const [spinnesLoad, setSpinnerLoad] = useState(false);
  const [openAImessage, setOpenAImessage] = useState("");
  const [skillss, setSkillss] = useState(
    sessionStorage.getItem("OpenAIMessage") &&
      sessionStorage.getItem("OpenAIMessage") !== undefined &&
      sessionStorage.getItem("OpenAIMessage") !== null &&
      sessionStorage.getItem("OpenAIMessage") !== "" &&
      sessionStorage.getItem("OpenAIMessage") !== " " &&
      sessionStorage.getItem("OpenAIskillsAI")
      ? sessionStorage.getItem("OpenAIskillsAI")
      : "* TO *"
  );
  const [roless, setRoless] = useState(
    sessionStorage.getItem("OpenAIMessage") &&
      sessionStorage.getItem("OpenAIMessage") !== undefined &&
      sessionStorage.getItem("OpenAIMessage") !== null &&
      sessionStorage.getItem("OpenAIMessage") !== "" &&
      sessionStorage.getItem("OpenAIMessage") !== " " &&
      sessionStorage.getItem("OpenAIrolesAI")
      ? sessionStorage.getItem("OpenAIrolesAI")
      : "* TO *"
  );

  const [loadingResults, setLoadingResults] = useState(false);
  const [noGivenData, setNoGivenData] = useState(false);

  const themeForTabs = useTheme();
  const [tabIndexValue, setTabIndexValue] = useState(0);
  const [skillsOpen, setSkillsOpen] = useState(true);
  const [summaryOpen, setSummaryOpen] = useState(false);
  const [aiSkillsByResumeTitle, setAiSkillsByResumeTitle] = useState([]);
  const [matchedSkillsList, setMatchedSkillsList] = useState([]);
  const [skillsInResume, setSkillsInResume] = useState([]);
  const [matchedSkillsOpen, setMatchedSkillsOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const tabhandleChange = (event, newValue) => {
    setTabIndexValue(newValue);
  };

  const handleChangeTabIndex = (index) => {
    setTabIndexValue(index);
  };

  const [anchorElMoreMenu, setAnchorElMoreMenu] = React.useState(null);
  const openMoreMenu = Boolean(anchorElMoreMenu);
  const handleClickMoreMenu = (event) => {
    setAnchorElMoreMenu(event.currentTarget);
  };
  const handleCloseMoreMenu = () => {
    setAnchorElMoreMenu(null);
  };

  // console.log(skillss);

  useEffect(() => {
    if (resumeViewsLeft > 0) {
      updateViewStatus(props && props.aidataa && props.aidataa[0]);
    }
  }, [props, resumeViewsLeft]);

  useEffect(() => {
    // console.log(resumes.length);
    if (props && props.redirectId) {
      setSpinnerLoad(true);
      axios
        .post(`${Key.domain}/resume/get_js_resume_details`, {
          user_id: null,
          resumeid: props.redirectId,
          session_token: null,
        })
        .then((response) => {
          //console.log(response.data.data)
          setSingleResume(response.data.data.resumedetails);

          setTotalDetails(response.data.data);
          console.log(response.data.data.resumedetails);
          setTimeout(() => {
            setSpinnerLoad(false);
          }, 3000);
        });
    }
    // console.log(singleResume)
  }, [props.redirectId]);

  useEffect(() => {
    // console.log(singleResume)
    if (singleResume) {
      // showLoader()
    }
    console.log(singleResume);
  }, [singleResume]);

  useEffect(() => {
    setSingleResume(resumes[indexvalue]);
    setIndexValue(indexvalue);
    setSelectedIndex(indexvalue);
    // console.log(indexvalue, selectedIndex);
    checkEmployerLimit();
  }, [indexvalue]);

  useEffect(() => {
    if (selectedIndex < 0) {
      setSelectedIndex(9);
      setIndexValue(9);
    } else if (selectedIndex < 0) {
      setSelectedIndex(resumes.length - 1);
      setIndexValue(resumes.length - 1);
    } else if (selectedIndex === resumes.length + 1) {
      setSelectedIndex(0);
      setIndexValue(0);
    }
  }, [selectedIndex, resumes.length]);

  useEffect(() => {
    if (resumeViewsLeft > 0) {
      updateViewStatus(singleResume && singleResume.id);
    }
  }, [singleResume, initalpackageValue]);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      setSelectedIndex((prevIndex) => (prevIndex - 1) % resumes.length);
      setIndexValue((prevIndex) => (prevIndex - 1) % resumes.length);
    } else if (
      indexvalue < 10 &&
      event.key === "ArrowDown" &&
      selectedIndex < 10
    ) {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % resumes.length);
      setIndexValue((prevIndex) => (prevIndex + 1) % resumes.length);

      ind = ind + 1;
      // console.log(ind);
      //console.log("se",index);
    }
  };
  // console.log(indexvalue, selectedIndex);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [resumes.length]);

  const handlemessageShow = () => {
    if (sessionStorage.getItem("id") !== null) {
      setmessageShow(true);
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const handleNoteShow = (resumeId) => {
    setNoteResumeId(resumeId);
    if (sessionStorage.getItem("id") !== null) {
      setNoteShow(true);
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };
  //const jobtype = sessionStorage.getItem("filtered-jobtypes");
  const history = useHistory();

  const handleCloseAlert = () => {
    setOpen(false);
  };

  // const showAlternateResults = async (resumesPerPage) => {
  //   let locationShortRegion = sessionStorage.getItem("OpenAIshortRegion") ? sessionStorage.getItem("OpenAIshortRegion") : ""

  //   let title = (parseInt(sessionStorage.getItem("withoutResumeTitle")) === 0) ? "*%3A*" : sessionStorage.getItem("OpenAIresumeTitle") && sessionStorage.getItem("OpenAIresumeTitle").replace(/['"]/g, "");
  //   const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection
  //     }/select?q=application_title:(${roless !== undefined && roless.length !== 0 ? roless : '* TO *'})&fq=res_stateName:(${sessionStorage.getItem("OpenAIresumeStateName") !== undefined && sessionStorage.getItem("OpenAIresumeStateName") !== "" ? sessionStorage.getItem("OpenAIresumeStateName") : "*:*"})&fq=res_shortRegion:(${locationShortRegion !== undefined && locationShortRegion !== "" ? locationShortRegion : "*:*"})&rows=${resumesPerPage}&start=${sessionStorage.getItem(
  //       "startValue"
  //     )}&fq=ai_skills:(${skillss !== undefined && skillss.length !== 0 ? skillss : '* TO *'})&fq=search_flag:(1)&wt=json`;
  //   const result = await request(
  //     `${ENDPOINT["SolrResumes"]}`,
  //     postOptions({
  //       url: searchResumesUrl,
  //     })
  //   );
  //   if (result.data.response && result.data.response.docs !== undefined) {
  //     setResumes(result.data.response.docs);
  //     setSingleResume(result.data.response.docs[0]);
  //     sessionStorage.setItem("OpenAIresumesCount", result.data.response.numFound);
  //     props.hideLoader();

  //   }

  // };

  const handleCloseResumeNotfound = () => {
    setFileNotFOund(false);
  };

  const checkEmployerLimit = (rid) => {
    //get user viewed resumes count and allowed resume views count
    axios
      .post(`${Key.domain}/users/check_employer_package_limit`, {
        user_id: props.currentUser.id,
        session_token: props.currentUser.session_token,
        action_to_check: "resume_views",
        subscriber_name: props.currentUser.full_name,
        subscriber_email: props.currentUser.username,
        package_id: props.currentUser.package_id,
      })
      .then((resdata) => {
        if (
          resdata.status === 200 &&
          resdata.data.success === 1 &&
          resdata.data.data !== undefined &&
          resdata.data.data !== null &&
          resdata.data.data !== undefined &&
          resdata.data.data !== "" &&
          resdata.data.data[0] !== null &&
          resdata.data.data[0] !== undefined &&
          resdata.data.data[0] !== "" &&
          resdata.data.data[0].resumes_viewed !== null &&
          resdata.data.data[0].resumes_viewed !== undefined &&
          resdata.data.data[0].resumes_viewed !== "" &&
          resdata.data.data[0].resumeviews_allowed !== null &&
          resdata.data.data[0].resumeviews_allowed !== undefined &&
          resdata.data.data[0].resumeviews_allowed !== ""
        ) {
          if (resdata.data.limitExceeded === true) {
            setInitialpackageValue(true);
            setPackageExpired(true);
            setOpen(true);
          } else {
            const leftResumeViews =
              parseInt(resdata.data.data[0].resumeviews_allowed) -
              parseInt(resdata.data.data[0].resumes_viewed);
            setResumeViewsLEft(leftResumeViews);

            let admin_id = sessionStorage.getItem("admin_id");
            let ca_id =
              admin_id !== "" &&
              admin_id !== 0 &&
              admin_id !== null &&
              admin_id !== undefined &&
              admin_id !== "0"
                ? admin_id
                : sessionStorage.getItem("id");
            if (resumeViewsLeft > 0) {
              axios
                .post(`${Key.domain}/resume/update_resume_viewedStatus`, {
                  user_id: sessionStorage.getItem("id"),
                  resumeid: rid,
                  ca_id: ca_id,
                  resume_type: "regular",
                  views: 1,
                  session_token: props.currentUser.session_token,
                })
                .then((response) => {
                  var resdata = response.data.data;
                });
            }
          }
        } else {
          throw new Error("No views yet");
        }
      })
      .catch((err) => {
        //console.log(err); // TODO: Error handling
      });
  };

  useEffect(() => {
    //get all recruiter under same employer API
    if (props.currentUser !== null) {
      checkEmployerLimit();

      axios
        .post(`${Key.domain}/resume/get_all_recruiters_ByUserGroup`, {
          user_id: props.currentUser.id,
          ca_id: props.currentUser.ca_id,
          session_token: props.currentUser.session_token,
        })
        .then((response) => {
          setGroupMemebersIds(response.data.data);
          if (
            response.status === 200 &&
            response.data &&
            response.data.success === 1 &&
            response.data.data &&
            response.data.data.length &&
            response.data.data.length > 0
          ) {
            let recruiters = response.data.data.filter(
              (res) => res !== props.currentUser.id && res !== 0
            );

            setGroupIds(recruiters);
          }
        });
    }

    return () => {
      sessionStorage.removeItem("resResumeTitle");
    };
  }, []);

  //console.log(singleResume)

  useEffect(async () => {
    setLoadingResults(true);
    // console.log(props.skills)
    const regex = /[`!@$^*()_\-=\[\]{};':"\\|\/?~]/g;

    try {
      var jobtitle = sessionStorage.getItem("OpenAIMessage");

      if (
        jobtitle &&
        jobtitle !== undefined &&
        jobtitle !== null &&
        jobtitle !== "" &&
        jobtitle !== " "
      ) {
        setNoGivenData(false);

        if (parseInt(props.title) === 1) {
          const regex = /[`!@$^*()_\-=\[\]{};':"\\|\/?~]/g;

          var dataa = props.aisearchresults ? props.aisearchresults : "";
          var messageData = encodeURIComponent(dataa.replace(regex, ""));
          let searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?&q=(${messageData})&rows=50&fq=search_flag:(1)&start=0&sort=lastmodified%20desc&wt=json`;

          setSearchSOLRquery(searchResumesUrl);
          console.log(searchResumesUrl);

          const result = await request(
            `${ENDPOINT["SolrResumes"]}`,
            postOptions({
              url: searchResumesUrl,
            })
          );
          if (result.data.response && result.data.response.docs !== undefined) {
            const items = result.data.response.docs;
            setResumes(items);
            sessionStorage.setItem("currentsearchpage", 1);
            setSingleResume(result.data.response.docs[0]);
            sessionStorage.setItem(
              "OpenAIresumesCount",
              result.data.response.numFound
            );
            sessionStorage.setItem("OpenAIsolrQuery", searchResumesUrl);
            saveAISearchRecord(
              searchResumesUrl,
              result.data.response.numFound,
              "error from openai"
            ).then((result) => {});

            props.hideLoader();
            setLoadingResults(false);
          }
        } else {
          setResumeChecked(false);
          let title = props.title ? props.title.replace(/['"]/g, "") : "";
          const state = props.locationState
            ? props.locationState
            : sessionStorage.getItem("OpenAIstatename")
            ? sessionStorage.getItem("OpenAIstatename")
            : "";
          let skillss = props.skills ? props.skills : "";
          let roless = props.roles ? props.roles : "";
          var jobTitlee1 = title ? '"' + title + '"' : "";
          var jobTitlee = title ? '"' + title + '"' : "*%3A*";
          let experiencee = props.experience
            ? props.experience.replace(regex, "")
            : sessionStorage.getItem("OpenAIexperience")
            ? sessionStorage.getItem("OpenAIexperience")
            : "";
          let city = props.locationCity
            ? props.locationCity
            : sessionStorage.getItem("OpenAIcityname")
            ? sessionStorage.getItem("OpenAIcityname")
            : "";
          let locationShortRegion = props.locationShortRegion
            ? props.locationShortRegion
            : sessionStorage.getItem("OpenAIshortRegion")
            ? sessionStorage.getItem("OpenAIshortRegion")
            : "";

          setSkillss(skillss);
          setRoless(roless);
          let searchResumesUrl = `${Key.Solr_url}/${
            Key.solrResumes_collection
          }/select?q=application_title:(${
            roless !== undefined && roless.length !== 0 ? roless : "* TO *"
          }) OR related_titles:(${
            roless !== undefined && roless.length !== 0 ? roless : "* TO *"
          })&fq=res_stateName:(${
            state !== undefined && state !== "" && state !== null
              ? state
              : "*:*"
          })&fq=res_cityName:(${
            city !== undefined && city !== "" && city !== null ? city : "*:*"
          })&fq=res_shortRegion:(${
            locationShortRegion !== undefined &&
            locationShortRegion !== "" &&
            locationShortRegion !== null
              ? locationShortRegion
              : "*:*"
          })&fq=total_experience:(${
            experiencee !== undefined &&
            experiencee !== "" &&
            experiencee !== null
              ? experiencee
              : "*:*"
          })&fq=ai_skills:(${
            skillss !== undefined && skillss.length !== 0 ? skillss : "* TO *"
          }) OR skillName:(${
            skillss !== undefined && skillss.length !== 0 ? skillss : "* TO *"
          })&fq=search_flag:(1)&rows=50&start=0&wt=json&sort=${
            noGivenData
              ? "lastmodified%20desc"
              : `if(exists(query({!v='application_title:${
                  props &&
                  props.title &&
                  props.title !== undefined &&
                  props.title !== "" &&
                  props.title !== null
                    ? props.title
                    : "*:*"
                }'})), 0, 1) asc, lastmodified%20desc`
          }`;
          console.log(searchResumesUrl);
          setSearchSOLRquery(searchResumesUrl);

          const result = await request(
            `${ENDPOINT["SolrResumes"]}`,
            postOptions({
              url: searchResumesUrl,
            })
          );
          if (
            result.data.response &&
            result.data.response.docs !== undefined &&
            parseInt(result.data.response.numFound) === 0
          ) {
            let searchResumesUrl = `${Key.Solr_url}/${
              Key.solrResumes_collection
            }/select?q=application_title:(${
              roless !== undefined && roless.length !== 0 ? roless : "* TO *"
            }) OR related_titles:(${
              roless !== undefined && roless.length !== 0 ? roless : "* TO *"
            })&fq=res_stateName:(${
              state !== undefined && state !== "" && state !== null
                ? state
                : "*:*"
            })&fq=res_cityName:(${
              city !== undefined && city !== "" && city !== null ? city : "*:*"
            })&fq=res_shortRegion:(${
              locationShortRegion !== undefined &&
              locationShortRegion !== "" &&
              locationShortRegion !== null
                ? locationShortRegion
                : "*:*"
            })&fq=search_flag:(1)&rows=50&start=0&wt=json&sort=${
              noGivenData
                ? "lastmodified%20desc"
                : `if(exists(query({!v='application_title:${
                    props &&
                    props.title &&
                    props.title !== undefined &&
                    props.title !== "" &&
                    props.title !== null
                      ? props.title
                      : "*:*"
                  }'})), 0, 1) asc, lastmodified%20desc`
            }`;
            console.log(searchResumesUrl);
            setSearchSOLRquery(searchResumesUrl);

            const result = await request(
              `${ENDPOINT["SolrResumes"]}`,
              postOptions({
                url: searchResumesUrl,
              })
            );
            if (
              result.data.response &&
              result.data.response.docs !== undefined &&
              result.data.response.numFound
            ) {
              var stateNamee1 =
                state !== undefined && state !== "" && state !== null
                  ? state
                  : "*:*";
              setResumes(result.data.response.docs);
              sessionStorage.setItem("currentsearchpage", 1);
              setSingleResume(result.data.response.docs[0]);
              sessionStorage.setItem(
                "OpenAIresumesCount",
                result.data.response.numFound
              );
              sessionStorage.setItem("OpenAIstatename", stateNamee1);
              sessionStorage.setItem("OpenAIcityname", city);
              sessionStorage.setItem("OpenAIexperience", experiencee);
              sessionStorage.setItem("OpenAIresumeStateName", stateNamee1);
              sessionStorage.setItem("OpenAIshortRegion", locationShortRegion);
              sessionStorage.setItem("OpenAIskillsAI", "");
              sessionStorage.setItem("OpenAIjobTitle", jobTitlee1);
              sessionStorage.setItem("OpenAIresumeTitle", jobTitlee1);
              sessionStorage.setItem("OpenAIinputResumeTitle", jobTitlee1);
              sessionStorage.setItem("OpenAIskillNDescriptionSearch", "");
              sessionStorage.setItem("OpenAIsolrQuery", searchResumesUrl);
              saveAISearchRecord(
                searchResumesUrl,
                result.data.response.numFound,
                "success without skills"
              ).then((result) => {});
              props.hideLoader();
              setLoadingResults(false);
            }
          } else {
            if (
              result.data.response &&
              result.data.response.docs !== undefined &&
              result.data.response.numFound
            ) {
              var stateNamee =
                state !== undefined && state !== "" && state !== null
                  ? state
                  : "*:*";
              sessionStorage.setItem("OpenAIcityname", city);
              sessionStorage.setItem("OpenAIexperience", experiencee);
              sessionStorage.setItem("OpenAIstatename", stateNamee);
              sessionStorage.setItem("OpenAIresumeStateName", stateNamee);
              sessionStorage.setItem(
                "OpenAIskillsAI",
                skillss !== undefined && skillss.length !== 0
                  ? skillss
                  : "* TO *"
              );
              sessionStorage.setItem("OpenAIjobTitle", jobTitlee);
              sessionStorage.setItem("OpenAIresumeTitle", jobTitlee);
              sessionStorage.setItem("OpenAIinputResumeTitle", jobTitlee);
              sessionStorage.setItem(
                "OpenAIskillNDescriptionSearch",
                skillss !== undefined && skillss.length !== 0
                  ? skillss
                  : "* TO *"
              );
              setResumes(result.data.response.docs);
              sessionStorage.setItem("currentsearchpage", 1);
              setSingleResume(result.data.response.docs[0]);
              sessionStorage.setItem(
                "OpenAIresumesCount",
                result.data.response.numFound
              );
              sessionStorage.setItem("OpenAIsolrQuery", searchResumesUrl);
              saveAISearchRecord(
                searchResumesUrl,
                result.data.response.numFound,
                "success"
              ).then((result) => {});
              props.hideLoader();
              setLoadingResults(false);
            }
          }
        }
      } else {
        setNoGivenData(true);

        const regex = /[`!@$^*()_\-=\[\]{};':"\\|\/?~]/g;

        let searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=*:*&rows=50&fq=search_flag:(1)&start=0&sort=lastmodified%20desc&wt=json`;
        setSearchSOLRquery(searchResumesUrl);

        console.log(searchResumesUrl);

        const result = await request(
          `${ENDPOINT["SolrResumes"]}`,
          postOptions({
            url: searchResumesUrl,
          })
        );
        if (
          result.data.response &&
          result.data.response.docs !== undefined &&
          result.data.response.numFound
        ) {
          const items = result.data.response.docs;
          setRoless("");
          setSkillss("");
          setResumes(items);
          sessionStorage.setItem("currentsearchpage", 1);
          setSingleResume(result.data.response.docs[0]);
          sessionStorage.setItem(
            "OpenAIresumesCount",
            result.data.response.numFound
          );
          sessionStorage.setItem("OpenAIsolrQuery", searchResumesUrl);
          saveAISearchRecord(
            searchResumesUrl,
            result.data.response.numFound,
            "Description not given"
          ).then((result) => {});
          props.hideLoader();
          setLoadingResults(false);
        }
      }
      if (props && props.filterChecked !== undefined) {
      }
      getAllResumeViews();
      sessionStorage.setItem("startValue", 0);
      sessionStorage.setItem("currentsearchpage", 1);
    } catch (error) {
      console.log(error);
      saveAISearchRecord(searchSOLRquery, "", "error", error).then(
        (result) => {}
      );
      setLoadingResults(false);
      setResumes([]);
      setSingleResume("");
    }
  }, [props.aiSearchRender]);

  const saveAISearchRecord = async (queryy, countt, status, err) => {
    axios
      .post(`${Key.domain}/crm/save_aisearch_record`, {
        uid: sessionStorage.getItem("id"),
        content: sessionStorage.getItem("OpenAIMessage"),
        openai_response: sessionStorage.getItem("OpenAIresponse"),
        solr_query:
          queryy && queryy !== ""
            ? queryy
            : sessionStorage.getItem("OpenAIsolrQuery")
            ? sessionStorage.getItem("OpenAIsolrQuery")
            : "",
        result_count: countt,
        status: status,
        error_msg: err,
      })
      .then((res) => {
        return res;
      });
  };

  useEffect(() => {}, [selectedResumeID]);

  const getAllResumeViews = () => {
    // showLoader();

    if (props.currentUser !== null) {
      if (props.currentUser.package_id === 1) {
        setTotalViewsCount(10);
      } else if (props.currentUser.package_id === 2) {
        setTotalViewsCount(750);
      } else if (props.currentUser.package_id === 3) {
        setTotalViewsCount(750);
      } else if (props.currentUser.package_id === 4) {
        setTotalViewsCount(750);
      } else if (props.currentUser.package_id === 5) {
        setTotalViewsCount(1000);
      }
      try {
        const response = axios.post(
          `${Key.domain}/resume/get_all_resume_views`,
          {
            user_id: sessionStorage.getItem("id"),
            session_token: props.currentUser.session_token,
          }
        );
        setAllResumeViews(response?.data?.map((data) => data?.resume_id));
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (
            error.response.data.data === "Access denied.. Please Login" ||
            error.response.data.data === "Access denied"
          ) {
            setAlert(true);
          }
        }
        console.error("Error fetching getEmpDashboardDetails:", error);
      }

      // fetch(`${Key.domain}/resume/get_all_resume_views`, {
      //   method: "POST", // *GET, POST, PUT, DELETE, etc.
      //   headers: {
      //     "Content-Type": "application/json",
      //     Accept: "application/json",
      //   },
      //   body: JSON.stringify({
      //     user_id: sessionStorage.getItem("id"),
      //     session_token: props.currentUser.session_token,
      //   }),
      // })
      //   .then((response) => response.json()) //converting data into json format
      //   .then((resdata) => {

      //   });

      fetch(`${Key.domain}/resume/get_all_resume_views_byGroup`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: sessionStorage.getItem("id"),
          session_token: props.currentUser.session_token,
          ca_id: props.currentUser.ca_id,
        }),
      })
        .then((response) => response.json()) //converting data into json format
        .then((resdata) => {
          //setGroupByAllResumeViews(resdata.data.map((data) => data.resume_id));
          //console.log(resdata.data)
          setGroupByAllResumeViews(resdata.data);
        });
    }
    props.hideLoader();
  };

  const onFilterChecked = (isJobtypeChecked) => {
    setIsFilterChecked(isJobtypeChecked);
  };
  const updateViewStatusMultiple = (ids) => {
    if (ids && ids !== undefined && ids.length > 0) {
      ids.map((id) => updateViewStatus(id));
    }
  };

  const updateViewStatus = (rid) => {
    checkEmployerLimit(rid);
    getAllResumeViews();
  };

  useEffect(() => {
    getOpenAISkills(singleResume);
    const resumeSkills =
      singleResume &&
      singleResume.ai_skills !== undefined &&
      singleResume.ai_skills !== null &&
      singleResume.ai_skills !== "null"
        ? singleResume.ai_skills.split(",").map((skill, index) => skill.trim())
        : [];
    setSkillsInResume(resumeSkills);
  }, [singleResume]);

  const getOpenAISkills = async (resume) => {
    if (resume) {
      var msg =
        "This is resume title can you give me major important advanced 15 technical skills of resume title given (in shortforms as list)} only, no other information and make it as json  " +
        resume.application_title +
        " and this resume has experience of " +
        resume.total_experience +
        "years";

      await openAISkills.openAI(msg, async (error, results) => {
        if (error) {
          console.log(error);
        } else {
          var data = results;
          console.log(
            "ai skills major",
            data.technical_skills ? data.technical_skills : data.skills
          );
          await setAiSkillsByResumeTitle(
            data.technical_skills ? data.technical_skills : data.skills
          );
          const dataSkillsMatched = matchedSkills();
          setMatchedSkillsList(dataSkillsMatched);

          var skillss = data && data.skills ? data.skills : [];

          /*if (Array.isArray(skillss)) {
            var skills =
              skillss.length > 0 ? skillss.map((s) => s).join(", ") : "";
          } 
          else {
            const skillString = skillss.split(",");
            var skills =
              skillString.length > 0
                ? skillString.map((s) => s).join(", ")
                : "";
          }*/
        }
      });
    } else {
      console.log("No Title Selected");
    }
  };
  const jobTitleSet = async (resume, idx) => {
    await getOpenAISkills(resume);

    setSpinnerLoad(true);
    axios
      .post(`${Key.domain}/resume/get_js_resume_details`, {
        user_id: null,
        resumeid: resume.id,
        session_token: null,
      })
      .then((response) => {
        setTotalDetails(response.data.data);
        setSpinnerLoad(false);
        //console.log(response.data.data)
      });
    if (
      props.currentUser !== null &&
      resumeViewsLeft < 1 &&
      !allResumeViews.join(",").includes(resume.id)
    ) {
      setOpen(true);
    } else {
      setIndexValue(idx);
      setSingleResume(resume);
      //updateViewStatus(resume.id);
    }
    // //console.log(resume)

    // setSelectedResumeID(resume.id)
  };
  const jobTitleClicked = (resume_id, res) => {
    var id = parseInt(resume_id);

    if (props.currentUser !== null) {
      axios
        .post(`${Key.domain}/resume/get_resumes_list_viewed_by_currentUser`, {
          user_id: props.currentUser.id,
          session_token: props.currentUser.session_token,
        })
        .then((response) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          if (
            response.data.success === 1 &&
            response.data.data !== undefined &&
            response.data.data !== null &&
            response.data.data !== undefined &&
            response.data.data !== ""
          ) {
            if (
              response.data.data &&
              response.data.data.length &&
              response.data.data.length > 0
            ) {
              //get user viewed resumes count and allowed resume views count
              axios
                .post(`${Key.domain}/users/check_employer_package_limit`, {
                  user_id: props.currentUser.id,
                  session_token: props.currentUser.session_token,
                  action_to_check: "resume_views",
                })
                .then((resdata) => {
                  if (
                    resdata.status === 200 &&
                    resdata.data.success === 1 &&
                    resdata.data.data !== undefined &&
                    resdata.data.data !== null &&
                    resdata.data.data !== undefined &&
                    resdata.data.data !== ""
                  ) {
                    var resumes = response.data.data;
                    var sortedResumes = resumes.some(
                      (res) => parseInt(res.resume_id) === parseInt(id)
                    );
                    //console.log(sortedResumes, id);

                    if (sortedResumes) {
                      checkLimit();
                    } else if (resdata.data.data[0].package_expired) {
                      setInitialpackageValue(true);
                      setPackageExpired(true);
                      setOpen(true);
                    } else {
                      /*  if (!sortedResumes && response.data.data.length >= parseInt(resdata.data.data[0].resumeviews_allowed)) {
                        setOpen(true)
                      } */
                      if (resdata.data.limitExceeded) {
                        setLimitExceeded(true);
                        setOpen(true);
                      } else {
                        checkLimit();
                      }
                    }
                  } else {
                    throw new Error("No views yet");
                  }
                })
                .catch((err) => {
                  //console.log(err); // TODO: Error handling
                  checkLimit();
                });
            } else {
              throw new Error("No views yet");
            }
          }
        })
        .catch((err) => {
          //console.log(err);
          checkLimit();
        });

      function checkLimit() {
        if (id !== null && id !== undefined) {
          axios
            .post(
              `${Key.domain}/resume/check_view_resumes_limit_based_on_package_id`,
              {
                ca_id: sessionStorage.getItem("id"),
                session_token: props.currentUser.session_token,
              }
            )
            .then((response) => {
              var resdata = response.data.data;
              //alert(resdata.length)
              if (resdata.length < totalViewsCount || resdata.length === 0) {
                history.push({
                  pathname: "/resume/view/resume_" + id,
                  state: {
                    skills: props.skills,
                    //filterChecked: props.filterChecked === true ? true : false
                  },
                });
                // updateViewStatus(id)  id is resumeid
              } else if (allResumeViews.includes(id)) {
                history.push({
                  pathname: "/resume/view/resume_" + id,
                  state: {
                    skills: props.skills,
                  },
                });
                // updateViewStatus(id) // id is resumeid
              } else {
                //alert("You exceeded your resumes view limit")
                setOpen(true);
              }
            });
        }
      }
    } else {
      sessionStorage.setItem(
        "redirectToResumeView",
        "/resume/view/resume_" + resume_id
      );
      sessionStorage.setItem("resume_id", resume_id);
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const multipleDownloadOrViewResume = () => {
    if (props.currentUser !== null) {
      if (resumeViewsLeft > 0) {
        if (resumeViewsLeft < selectedResumes.length) {
          let msg = "";
          if (resumeViewsLeft === 1) {
            msg = "You can download only " + resumeViewsLeft + " resume";
          } else {
            msg = "You can download only " + resumeViewsLeft + " resumes";
          }
          toast(<ToastMessage message={msg} />);
        } else {
          if (selectedResumes.length > 0) {
            for (let i = 0; i < selectedResumes.length; i++) {
              axios
                .post(`${Key.domain}/resume/get_js_resume_details`, {
                  user_id: sessionStorage.getItem("id"),
                  resumeid: selectedResumes[i].id,
                  session_token: props.currentUser.session_token,
                })
                .then((response) => {
                  let resdetails = response.data.data.resumedetails;
                  var filename = resdetails.filename;
                  var resumeid = resdetails.id;
                  var uploader_id = resdetails.uploader_id;

                  fetch(
                    `${Key.files_domain}/MyFiles/resume_attachments/${uploader_id}/${resumeid}/${filename}`,
                    {
                      method: "GET", // *GET, POST, PUT, DELETE, etc.
                      headers: {
                        "Content-Type": "application/json",
                        Authorization:
                          "Bearer " + window.localStorage["Access_Token"],
                      },
                    }
                  )
                    .then((resdata) => {
                      let msg = "resume downloaded";
                      toast(<ToastMessage message={msg} />);

                      return resdata.blob().then((b) => {
                        var a = document.createElement("a");
                        a.href = URL.createObjectURL(b);
                        const targetFilename =
                          b.type &&
                          b.type !== undefined &&
                          b.type.toLowerCase().includes("pdf")
                            ? filename.split(".").slice(0, -1).join(".") +
                              ".pdf"
                            : b.type.toLowerCase().includes("docx")
                            ? filename.split(".").slice(0, -1).join(".") +
                              ".docx"
                            : b.type.toLowerCase().includes("doc")
                            ? filename.split(".").slice(0, -1).join(".") +
                              ".doc"
                            : filename.split(".").slice(0, -1).join(".") +
                              ".pdf";
                        a.setAttribute(
                          "download",
                          targetFilename ? targetFilename : filename
                        );
                        a.click();
                        updateViewStatus(resumeid);
                      });
                    })
                    .catch((e) => {
                      //console.log(e);
                    });
                });
            }
          } else {
            let msg = "Select a resume to download";
            toast(<ToastMessage message={msg} />);
          }
        }
      } else {
        setOpen(true);
      }
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const downloadOrViewResume = (resumeId, uploader_id) => {
    if (props.currentUser !== null) {
      if (resumeViewsLeft > 0) {
        axios
          .post(`${Key.domain}/resume/update_download_status`, {
            user_id: sessionStorage.getItem("id"),
            resume_id: resumeId,
            ca_id: props.currentUser.ca_id,
            resume_type: "regular",
            views: 1,
          })
          .then((response) => {
            //console.log(response)
          });

        axios
          .post(`${Key.domain}/resume/get_js_resume_details`, {
            user_id: sessionStorage.getItem("id"),
            resumeid: resumeId,
            session_token: props.currentUser.session_token,
          })
          .then((response) => {
            let resdetails = response.data.data.resumedetails;
            var filename = resdetails.filename;
            //console.log(filename);
            //let extension = filename.split(".").pop();

            fetch(
              `${Key.files_domain}/MyFiles/resume_attachments/${uploader_id}/${resumeId}/${filename}`,
              {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer " + window.localStorage["Access_Token"],
                },
              }
            )
              .then((resdata) => {
                /*  if (extension === "pdf") {
                //Build a URL from the file
                const fileURL = resdata.url;

                //Open the URL on new Window
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                let msg = " resume downloaded";
          toast(<ToastMessage message={msg} />);
              } else {
                let msg = " resume downloaded";
          toast(<ToastMessage message={msg} />);
                return resdata.blob().then((b) => {
                  var a = document.createElement("a");
                  a.href = URL.createObjectURL(b);
                  const targetFilename=b.type&&b.type!==undefined&&b.type.toLowerCase().includes("pdf")?filename.split('.').slice(0, -1).join('.') + ".pdf":b.type.toLowerCase().includes("docx")?filename.split('.').slice(0, -1).join('.') + ".docx":b.type.toLowerCase().includes("doc")?filename.split('.').slice(0, -1).join('.') + ".doc":filename.split('.').slice(0, -1).join('.') + ".pdf"
                  a.setAttribute("download", targetFilename);
                  a.click();
                  updateViewStatus(resumeId)

                });
              } */

                let msg = "resume downloaded";
                toast(<ToastMessage message={msg} />);

                return resdata.blob().then((b) => {
                  var a = document.createElement("a");
                  a.href = URL.createObjectURL(b);
                  const targetFilename =
                    b.type &&
                    b.type !== undefined &&
                    b.type.toLowerCase().includes("pdf")
                      ? filename.split(".").slice(0, -1).join(".") + ".pdf"
                      : b.type.toLowerCase().includes("docx")
                      ? filename.split(".").slice(0, -1).join(".") + ".docx"
                      : b.type.toLowerCase().includes("doc")
                      ? filename.split(".").slice(0, -1).join(".") + ".doc"
                      : filename.split(".").slice(0, -1).join(".") + ".pdf";
                  //console.log(targetFilename)
                  a.setAttribute(
                    "download",
                    targetFilename
                      ? singleResume.full_name + ".pdf"
                      : filename.split
                  );
                  a.click();
                  updateViewStatus(resumeId);
                });
              })
              .catch((e) => {
                //console.log(e);
              });
          });
      } else {
        setOpen(true);
      }
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  /* const handleChange = (e, resumeId) => {
    const { name, value, checked } = e.target;
 
    if (name === "allCheck") {
      let resumeslist = resumes.map((list) => {
        setResumeChecked(checked);
        return { ...list, isChecked: checked };
      });
      setResumes(resumeslist);
      setSingleResume(resumeslist[0])
      let objs = resumeslist.filter((x) => x.isChecked === true);
      let filteredobjs = objs.filter((x) => x.filename !=='');
      var ids = filteredobjs.map(obj => obj.id)
      setSelectedResids(ids)

      setSelectedResumes(filteredobjs);
      
    } else if (name === String(resumeId)) {
      //console.log("comes here")
      let newResumes = resumes.map((list) =>
        list.id === resumeId ? { ...list, isChecked: checked } : list
      );
      setResumes(newResumes);
      setSingleResume(newResumes[0])
      let obj = newResumes.filter((x) => x.isChecked === true);
      let filteredobjs = obj.filter((x) => x.filename !=='');
      var ids = filteredobjs.map(obj => obj.id)
      setSelectedResids(ids)
      setSelectedResumes(filteredobjs);
      obj.length > 1 ? setResumeChecked(checked) : setResumeChecked(false);
    }
  }; */

  const handleRedirect = () => {
    // props.history.location.goBack();
    sessionStorage.removeItem("OpenAIinputResumeTitle");
    sessionStorage.removeItem("OpenAIresumeStateName");
    sessionStorage.removeItem("OpenAIresumeTitle");
    sessionStorage.removeItem("OpenAIstatename");

    history.push("/Empdashboard");
  };
  const onPageChanged = async (data) => {
    // props.showLoader()
    setResumeChecked(false);
    const { currentPage, totalPages, pageLimit } = data;
    const resumesPerPage =
      sessionStorage.getItem("resumesPerPage") !== null
        ? parseInt(sessionStorage.getItem("resumesPerPage"))
        : 10;
    setCurrentPage(currentPage);
    let pageChange = currentPage + 1;

    if (start == null) {
      // alert("start", start)
      // alert(start)

      if (sessionStorage.getItem("startValue") === null) {
        sessionStorage.setItem("startValue", 0);
      }

      // alert(start)
      // alert(parseInt(sessionStorage.getItem("startValue")))
      var increment =
        start + parseInt(sessionStorage.getItem("startValue"))
          ? parseInt(sessionStorage.getItem("startValue"))
          : 0;
      setStart(increment);
      // alert("increment", increment)
      // alert(increment)

      sessionStorage.setItem("startValue", increment);
    } else if (currentPage < pageChange) {
      var increment =
        pageChange * resumesPerPage - resumesPerPage - resumesPerPage;

      if (increment == -resumesPerPage) {
        increment = increment + resumesPerPage;
        setStart(increment);
        // alert("increment1", increment)
        // alert(increment)

        sessionStorage.setItem("startValue", increment);
      }

      setStart(increment);
      // alert("increment2", increment)
      // alert(increment)

      sessionStorage.setItem("startValue", increment);
    }

    var jobtype = sessionStorage.getItem("resumefiltered-jobtypes");
    var visatype = sessionStorage.getItem("resumefiltered-visatypes");
    var experience = sessionStorage.getItem("resumefiltered-experience");

    var OpenAIresumeTitle = sessionStorage.getItem("OpenAIresumeTitle")
      ? sessionStorage.getItem("OpenAIresumeTitle")
      : sessionStorage.getItem("OpenAIresumeTitle");
    if (OpenAIresumeTitle && OpenAIresumeTitle.includes(" / ")) {
      OpenAIresumeTitle.replace(" / ", "/");
    }
    var OpenAIinputResumeTitle =
      parseInt(sessionStorage.getItem("withoutResumeTitle")) === 0
        ? "*%3A*"
        : OpenAIresumeTitle !== "" &&
          OpenAIresumeTitle !== undefined &&
          OpenAIresumeTitle !== null
        ? OpenAIresumeTitle
        : "";
    var Mappeddates = sessionStorage.getItem("resumeMappedDates");
    var qualifications = sessionStorage.getItem("resumefiltered-qualification");
    var email =
      props && props.emailChecked !== undefined ? props.emailChecked : false;
    var phone =
      props && props.phoneChecked !== undefined ? props.phoneChecked : false;
    var willingToRelocate =
      props && props.willingToRelocate !== undefined
        ? props.willingToRelocate
        : false;
    var salaryType =
      '"' + sessionStorage.getItem("resumefiltered-salary") + '"';

    var salaryFrom = sessionStorage.getItem("resumeFiltered-salaryFrom");
    var OpenAIskillNDescriptionSearch =
      sessionStorage.getItem("OpenAIskillNDescriptionSearch") !== null
        ? sessionStorage.getItem("OpenAIskillNDescriptionSearch")
        : undefined; //props && props.skills !== undefined ? props.skills : '* TO *';

    if (
      OpenAIskillNDescriptionSearch &&
      OpenAIskillNDescriptionSearch.includes("#")
    ) {
      OpenAIskillNDescriptionSearch = OpenAIskillNDescriptionSearch.replace(
        "#",
        "%23"
      );
    } else if (
      OpenAIskillNDescriptionSearch &&
      OpenAIskillNDescriptionSearch.includes("++")
    ) {
      OpenAIskillNDescriptionSearch = OpenAIskillNDescriptionSearch.replace(
        "++",
        "%2B%2B"
      );
    } else if (
      OpenAIskillNDescriptionSearch &&
      OpenAIskillNDescriptionSearch.includes("&")
    ) {
      OpenAIskillNDescriptionSearch = OpenAIskillNDescriptionSearch.replace(
        "&",
        "%26"
      );
    } else if (
      OpenAIskillNDescriptionSearch &&
      OpenAIskillNDescriptionSearch.includes("/")
    ) {
      OpenAIskillNDescriptionSearch = OpenAIskillNDescriptionSearch.replace(
        " / ",
        "/"
      );
    }

    if (
      OpenAIinputResumeTitle === null ||
      OpenAIinputResumeTitle === undefined ||
      OpenAIinputResumeTitle === ""
    ) {
      sessionStorage.removeItem("jobtitle");
      localStorage.removeItem("jobtitle");
      sessionStorage.removeItem("inputjobtitle");
    }

    let stateName = sessionStorage.getItem("OpenAIstatename")
      ? sessionStorage.getItem("OpenAIstatename")
      : "";

    let locationShortRegion = sessionStorage.getItem("OpenAIshortRegion")
      ? sessionStorage.getItem("OpenAIshortRegion")
      : "";
    let locationCity = sessionStorage.getItem("OpenAIcityname")
      ? sessionStorage.getItem("OpenAIcityname")
      : "";
    let eexperience = sessionStorage.getItem("OpenAIexperience")
      ? sessionStorage.getItem("OpenAIexperience")
      : "";
    var state = stateName;
    var city = locationCity;

    if (props !== undefined) {
      if (props.filterChecked !== true) {
        var jobtype = props.homepageJobtypesChecked;
        var stateslist = states.states;
        var stateBoolean = stateslist
          .map((state) => state && state.toLowerCase())
          .includes(city && city.toLowerCase());

        if (stateBoolean === true) {
          // console.log(2)

          var searchResumesUrl = `${Key.Solr_url}/${
            Key.solrResumes_collection
          }/select?q=application_title:(${
            roless !== undefined && roless.length !== 0 ? roless : "*%3A*"
          }) OR related_titles:(${
            roless !== undefined && roless.length !== 0 ? roless : "*:*"
          })&fq=res_stateName:(${
            stateName !== undefined && stateName !== "" && stateName !== null
              ? stateName
              : "*:*"
          })&fq=res_cityName:(${
            locationCity !== undefined &&
            locationCity !== "" &&
            locationCity !== null
              ? locationCity
              : "*:*"
          })&fq=res_shortRegion:(${
            locationShortRegion !== undefined &&
            locationShortRegion !== "" &&
            locationShortRegion !== null
              ? locationShortRegion
              : "*:*"
          })&fq=total_experience:(${
            eexperience !== undefined &&
            eexperience !== "" &&
            eexperience !== null
              ? eexperience
              : "*:*"
          })&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=ai_skills:(${
            skillss !== undefined && skillss.length !== 0 ? skillss : "*:*"
          }) OR skillName:(${
            skillss !== undefined && skillss.length !== 0 ? skillss : "*:*"
          })&rows=${resumesPerPage}&start=${sessionStorage.getItem(
            "startValue"
          )}&fq=search_flag:(1)&wt=json&sort=${
            noGivenData
              ? "lastmodified%20desc"
              : `if(exists(query({!v='application_title:${
                  props &&
                  props.title &&
                  props.title !== undefined &&
                  props.title !== "" &&
                  props.title !== null
                    ? props.title
                    : "*:*"
                }'})), 0, 1) asc, lastmodified%20desc`
          }`;
        } else {
          // console.log(1)

          searchResumesUrl = `${Key.Solr_url}/${
            Key.solrResumes_collection
          }/select?q=application_title:(${
            roless !== undefined && roless.length !== 0 ? roless : "*:*"
          }) OR related_titles:(${
            roless !== undefined && roless.length !== 0 ? roless : "*:*"
          })&fq=res_stateName:(${
            stateName !== undefined && stateName !== "" && stateName !== null
              ? stateName
              : "*:*"
          })&fq=res_cityName:(${
            locationCity !== undefined &&
            locationCity !== "" &&
            locationCity !== null
              ? locationCity
              : "*:*"
          })&fq=res_shortRegion:(${
            locationShortRegion !== undefined &&
            locationShortRegion !== "" &&
            locationShortRegion !== null
              ? locationShortRegion
              : "*:*"
          })&fq=total_experience:(${
            eexperience !== undefined &&
            eexperience !== "" &&
            eexperience !== null
              ? eexperience
              : "*:*"
          })&fq=ai_skills:(${
            skillss !== undefined && skillss.length !== 0 ? skillss : "*:*"
          }) OR skillName:(${
            skillss !== undefined && skillss.length !== 0 ? skillss : "*:*"
          })&rows=${resumesPerPage}&start=${sessionStorage.getItem(
            "startValue"
          )}&fq=jobtype:(${
            jobtype ? jobtype : "*:*"
          })&fq=search_flag:(1)&wt=json&sort=${
            noGivenData
              ? "lastmodified%20desc"
              : `if(exists(query({!v='application_title:${
                  props &&
                  props.title &&
                  props.title !== undefined &&
                  props.title !== "" &&
                  props.title !== null
                    ? props.title
                    : "*:*"
                }'})), 0, 1) asc, lastmodified%20desc`
          }`;
        }

        localStorage.setItem("resumeQuery", searchResumesUrl);

        const result = await request(
          `${ENDPOINT["SolrResumes"]}`,
          postOptions({
            url: searchResumesUrl,
          })
        );
        props.hideLoader();

        if (result.data.response.docs.length === 0) {
          // showAlternateResults(resumesPerPage);
        } else {
          sessionStorage.setItem(
            "OpenAIresumesCount",
            result.data.response.numFound
          );

          // const items = result.data.response.docs
          // var dividedTitleStrings = props.title ? props.title.replace(/['"]/g, " ").split(" ") : ""

          // const rearrangedItems = [...items.filter(item => item.application_title.includes(dividedTitleStrings)),
          // ...items.filter(item => !item.application_title.includes(dividedTitleStrings))];
          // console.log(rearrangedItems)

          // setResumes(rearrangedItems);

          setResumes(result.data.response.docs);

          sessionStorage.setItem("currentsearchpage", 1);

          setSingleResume(result.data.response.docs[0]);
          await getOpenAISkills(result.data.response.docs[0]);
          props.hideLoader();
        }

        sessionStorage.setItem("OpenAIresumeTitle", OpenAIinputResumeTitle);
        sessionStorage.setItem("OpenAIresumeStateName", stateName);
      } else {
        var filterResumesUrl = `${Key.Solr_url}/${
          Key.solrResumes_collection
        }/select?q=application_title:(${
          roless !== undefined && roless.length !== 0 ? roless : "* TO *"
        }) OR related_titles:(${
          roless !== undefined && roless.length !== 0 ? roless : "* TO *"
        })&fq=res_stateName:(${
          state !== undefined ? state : "*:*"
        })&fq=res_cityName:(${
          city !== undefined && city !== "" && city !== null ? city : "*:*"
        })&fq=res_shortRegion:(${
          locationShortRegion !== undefined ? locationShortRegion : "*:*"
        })&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=res_visatypeName:(${
          visatype ? visatype : "*:*"
        })&fq=total_experience:(${
          experience ? experience : "*:*"
        })&fq=qualificationTitle:(${
          qualifications ? qualifications : "*:*"
        })&fq=lastmodified:[${
          Mappeddates !== null ? Mappeddates : "* TO *"
        }]&fq=email_address:(${email ? "* TO *" : "*:*"})&fq=home_phone:(${
          phone ? "* TO *" : "*:*"
        })&fq=willing_to_relocate:(${willingToRelocate ? "1" : "*:*"})
         &fq=res_salarrytypeName:(${
           sessionStorage.getItem("resumefiltered-salary") !== null
             ? salaryType
             : "*:*"
         })&fq=salary:(${[
          salaryFrom !== null ? salaryFrom + " TO " + "*" : "*:*",
        ]})&fq=search_flag:(1)&rows=10&start=${sessionStorage.getItem(
          "startValue"
        )}&fq=ai_skills:(${
          skillss !== undefined && skillss.length !== 0 ? skillss : "* TO *"
        }) OR skillName:(${
          skillss !== undefined && skillss.length !== 0 ? skillss : "* TO *"
        })&sort=lastmodified%20desc,id%20asc&wt=json`;

        localStorage.setItem("resumeQuery", filterResumesUrl);

        const result = await request(
          `${ENDPOINT["SolrResumes"]}`,
          postOptions({
            url: filterResumesUrl,
          })
        );
        const retrivedData = result.data.response.docs;
        sessionStorage.setItem(
          "OpenAIresumesCount",
          result.data.response.numFound
        );

        // const items = result.data.response.docs
        // var dividedTitleStrings = props.title ? props.title.replace(/['"]/g, " ").split(" ") : ""

        // const rearrangedItems = [...items.filter(item => item.application_title.includes(dividedTitleStrings)),
        // ...items.filter(item => !item.application_title.includes(dividedTitleStrings))];
        // console.log(rearrangedItems)

        // setResumes(rearrangedItems);

        setResumes(result.data.response.docs);

        sessionStorage.setItem("currentsearchpage", 1);

        props.hideLoader();
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleShow = (resid, resfile, resuid) => {
    if (sessionStorage.getItem("id") !== null) {
      // //console.log(packageExpired)
      if (resumeViewsLeft > 0) {
        //console.log(resumeViewsLeft)
        axios
          .post(`${Key.domain}/resume/get_js_resume_details`, {
            user_id: sessionStorage.getItem("id"),
            resumeid: resid,
            session_token: props.currentUser.session_token,
          })
          .then((response) => {
            let resdetails = response.data.data.resumedetails;
            //console.log(resdetails)
            if (
              resdetails.filename !== "" &&
              resdetails.filename !== null &&
              resdetails.filename !== undefined &&
              resdetails.filename !== "null"
            ) {
              setcurrentresid(resid);
              setcurrentresfile(resdetails.filename);
              setcurrentresuid(resuid);
              setShow(true);
            } else {
              setFileNotFOund(true);
            }
          })
          .catch((error) => {
            setFileNotFOund(true);
          });
      } else {
        setOpen(true);
      }
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const handleShowFilters = () => {
    setShowFilters(true);
  };

  // console.log(showFilters);
  const handleShowMultiple = () => {
    if (sessionStorage.getItem("id") !== null) {
      if (resumeViewsLeft > 0) {
        if (selectedResumes.length > 0) {
          if (resumeViewsLeft < selectedResumes.length) {
            let msg = "";
            if (resumeViewsLeft === 1) {
              msg = "You can share only " + resumeViewsLeft + " resume";
            } else {
              msg = "You can share only " + resumeViewsLeft + " resumes";
            }

            toast(<ToastMessage message={msg} />);
          } else {
            axios
              .post(`${Key.domain}/resume/get_resumefiles_multiple`, {
                user_id: sessionStorage.getItem("id"),
                resumeids: selectedResIds,
                session_token: props.currentUser.session_token,
              })
              .then((response) => {
                let resdetails = response.data.data;

                setSelectedResume(resdetails);
                // setcurrentresid(resid);
                // setcurrentresfile(resdetails.filename);
                // setcurrentresuid(resuid);
                setShowMultiple(true);
              });
          }
        } else {
          let msg = "Select a resume to share";
          toast(<ToastMessage message={msg} />);
        }
      } else {
        setOpen(true);
      }
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseMultiple = () => {
    setShowMultiple(false);
  };
  const handlemessageClose = () => {
    setmessageShow(false);
  };

  const handleNoteClose = () => {
    setNoteShow(false);
  };
  const handleFilterClose = () => {
    setShowFilters(false);
  };
  const handlemailClose = () => {
    setmailShow(false);
  };

  const handlemailShow = (mail, name) => {
    if (sessionStorage.getItem("id") !== null) {
      settomail(mail);
      setmailShow(true);
      setcandfullname(name);
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const changeRowsPerPage = (value) => {
    setRowsPerPage(value);
    sessionStorage.setItem("resumesPerPage", value);
  };

  const handlePackagesClose = () => {
    setPackagesShow(false);
  };
  // const textToHighlight = singleResume && singleResume.summary
  // var searchWords = sessionStorage.getItem("skillstoHighlight") !== undefined && sessionStorage.getItem("skillstoHighlight") !== null && sessionStorage.getItem("skillstoHighlight") !== ''? sessionStorage.getItem('skillstoHighlight').replace(/"/g, "").split(",") : []

  // if (searchWords && searchWords.includes("#") && searchWords.includes("++")) {
  //   searchWords = searchWords.replace("#", '%23').replace("++", '%2B%2B')
  // }else if (searchWords && searchWords.includes("#")) {
  //   searchWords = searchWords.replace("#", '%23')
  // } else if (searchWords && searchWords.includes("++")) {
  //   searchWords = searchWords.replace("++", '%2B%2B')
  // } else if (searchWords && searchWords.includes("&")) {
  //   searchWords = searchWords.replace("&", '%26')
  // } else if (searchWords && searchWords.includes("/")) {
  //   searchWords = searchWords.replace(" / ", '/')
  // }

  // const chunks = findAll({
  //   caseSensitive: false,
  //   searchWords,
  //   textToHighlight,
  // });
  // // convert chunks to string with highlighted keyword
  // // for html-react-parser
  // const formattedHTML = chunks
  //   .map((chunk) => {
  //     const { end, highlight, start } = chunk;
  //     const text = textToHighlight.substr(start, end - start);
  //     if (highlight) {
  //       return `<mark class="textHighlight" style="backgroundColor:rgb(249, 252, 63)">${text}</mark>`;
  //     } else {
  //       return text;
  //     }
  //   })
  //   .join("");
  console.log(singleResume);

  const submit = (aisearch) => {
    props.showLoader();

    //   if(parseInt(aisearch) === 1) {

    //     var jobtitle = sessionStorage.getItem('OpenAIjobTitle');

    //     var message = jobtitle? jobtitle.replace(/\n/g, '') : "";

    //     fetch(`${Key.domain}/openai/resumes/message`, {
    //       method: 'POST',
    //       headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //       },
    //       body: JSON.stringify({ message: message }),
    //     })
    //       .then((response) => {
    //         response.json().then((resdata) => {
    //           sessionStorage.setItem("OpenAIresumesCount", resdata.data.response.numFound)
    //           sessionStorage.setItem("OpenAIstatename", resdata.openAIdata.state);
    //           setCityName(resdata.openAIdata.state)

    //           setJobtitle(resdata.openAIdata.job_title);
    //           sessionStorage.setItem("OpenAIjobTitle", resdata.openAIdata.job_title);
    //           sessionStorage.setItem("OpenAIresumeTitle", resdata.openAIdata.job_title);
    //           sessionStorage.setItem("OpenAIinputResumeTitle", resdata.openAIdata.job_title);
    //           setSkills(resdata.openAIdata.skills)
    //           sessionStorage.setItem("OpenAIskillNDescriptionSearch", resdata.openAIdata.skills);
    //           props.hideLoader()

    //           if (resdata.data.response.docs.length === 0) {
    //             showAlternateResults(jobtype, skillstoHighlight)
    //           }

    //           setjobtitle1(true)
    //           setlocation1(true)
    //           setfulltime1(fulltime)
    //           setcontract1(contract)
    //           sethomepageJobtypesChecked1(jobtype)
    //           setsearchSkills1([])
    //           setskills1(resdata.openAIdata.skills)
    //           setresponse1(resdata.data.response.docs)

    // if(resdata.data.response.docs){
    // console.log(resdata.data.response.docs)

    // setAISearchShow(true)
    // }
    //         });

    //       })

    //   }
  };

  // ai skills major
  const matchedSkills = () => {
    const resumeSkills =
      singleResume &&
      singleResume.ai_skills !== undefined &&
      singleResume.ai_skills !== null &&
      singleResume.ai_skills !== "null"
        ? singleResume.ai_skills.split(",").map((skill, index) => skill.trim())
        : [];

    function getMatches(array1, array2) {
      const matches = [];

      array1?.forEach((skill1) => {
        array2?.forEach((skill2) => {
          const normalSkill1 = skill1.toLowerCase();
          const normalSkill2 = skill2.toLowerCase();
          if (normalSkill1 === normalSkill2) {
            matches.push(skill1);
          } else {
            const words1 = normalSkill1.split("/");
            const words2 = normalSkill2.split("/");
            words1.forEach((word1) => {
              words2.forEach((word2) => {
                if (word1 === word2) {
                  matches.push(skill1);
                }
              });
            });
          }
        });
      });

      return matches;
    }

    const finalMatches = getMatches(resumeSkills, aiSkillsByResumeTitle);
    console.log("Common skills:", resumeSkills);
    console.log("Common skills: ", aiSkillsByResumeTitle);
    console.log("Common skills:", finalMatches);

    return finalMatches;

    //   console.log('Common skills:', resumeSkills)
    //     console.log('Common skills: ',aiSkillsByResumeTitle);
    //     const commonSkills = aiSkillsByResumeTitle?.filter(item => resumeSkills?.includes(item));
    // console.log('Common skills:', commonSkills);
  };

  const skillBtnStyles = {
    bgcolor: skillsOpen ? "rgba(64, 104, 130, 0.9)" : "",
    color: skillsOpen ? "#fff" : "rgba(0,0,0,0.6)",
    border: skillsOpen ? "none" : "1px solid #ddd",
  };
  const workBtnStyles = {
    bgcolor: summaryOpen ? "rgba(64, 104, 130, 0.9)" : "",
    color: summaryOpen ? "#fff" : "rgba(0,0,0,0.6)",
    border: summaryOpen ? "none" : "1px solid #ddd",
  };
  return (
    <>
      {alert ? (
        <SessionAlert />
      ) : (
        <div>
          <MetaTags>
            <title>
              {" "}
              Recruiting made easier | Advanced AI IT Job portal in USA{" "}
            </title>
            <meta
              name="description"
              content="  Hire Faster & better with advanced AI based job portal for IT jobs in USA. "
            />
          </MetaTags>
          {props.currentUser !== null ? null : <HomeNavbar />}
          {sessionStorage.getItem("id") !== null ? (
            <Dialog open={show} onClose={handleClose} fullWidth>
              {/* <Modal.Header closeButton>
            <Modal.Title>Share </Modal.Title>
          </Modal.Header> */}
              <DialogTitle
                id="responsive-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <DialogContentText variant="h6">Share</DialogContentText>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <ShareResume
                  handlepopUpClose={handleClose}
                  filename={currentresfile}
                  res_id={currentresid}
                  user_id={currentresuid}
                  usermail={
                    props.currentUser.username !== null
                      ? props.currentUser.username
                      : ""
                  }
                  updateViewStatus={updateViewStatus}
                />
              </DialogContent>
            </Dialog>
          ) : null}
          <Dialog
            fullScreen={fullScreen}
            open={open}
            fullWidth
            onClose={handleCloseAlert}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {packageExpired ? "Package Expired!" : "Limit exceeded!"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {packageExpired ? (
                  <span>
                    Your package is expired.Please{" "}
                    <a
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                      onClick={() => setPackagesShow(true)}
                    >
                      ContactSales
                    </a>{" "}
                    to upgrade your package!`
                  </span>
                ) : (
                  <span>
                    You have exceeded your free limit to view, download or share
                    resumes.Please{" "}
                    <a
                      onClick={() => setPackagesShow(true)}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      ContactSales
                    </a>{" "}
                    to upgrade your package!
                  </span>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
          {/* for resume file not found alert message*/}
          <Dialog
            fullScreen={fullScreen}
            fullWidth
            open={fileNotFound}
            onClose={handleCloseResumeNotfound}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Attachment Not Found"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {"Resume Attachment not found"}
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>

          <Dialog
            open={packagesShow}
            onClose={handlePackagesClose}
            fullscreen
            fullWidth
            scrollable={true}
          >
            {/* <Modal.Header closeButton>
          <Modal.Title style={{ color: "#406882" }}></Modal.Title>
        </Modal.Header> */}

            <DialogTitle
              id="responsive-dialog-title"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <DialogContentText
                variant="h6"
                style={{ color: "#406882" }}
              ></DialogContentText>
              <IconButton aria-label="close" onClick={handlePackagesClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <Packages />
            </DialogContent>
          </Dialog>
          {sessionStorage.getItem("id") !== null ? (
            <Dialog
              open={mailshow}
              fullWidth
              centered
              size="lg"
              onClose={handlemailClose}
              style={{ borderRadius: "4px" }}
            >
              {/* <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "18px" }}>
              Send Mail To Candidate
            </Modal.Title>
          </Modal.Header> */}

              <DialogTitle
                id="responsive-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <DialogContentText variant="h6" style={{ fontSize: "18px" }}>
                  {" "}
                  Send Mail To Candidate
                </DialogContentText>
                <IconButton aria-label="close" onClick={handlemailClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent>
                <Sendcandidatetomail
                  handlemailClose={handlemailClose}
                  tomail={tomail}
                  candfullname={candfullname}
                  selectedResumes={selectedResumes}
                  fromempEmail={props.currentUser.username}
                />
              </DialogContent>
            </Dialog>
          ) : null}
          {sessionStorage.getItem("id") !== null ? (
            <Dialog open={showMultiple} onClose={handleCloseMultiple} fullWidth>
              {/* <Modal.Header closeButton>
                <Modal.Title>Share </Modal.Title>
              </Modal.Header> */}
              <DialogTitle
                id="responsive-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <DialogContentText variant="h6">Share</DialogContentText>
                <IconButton aria-label="close" onClick={handleCloseMultiple}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent>
                <ShareResumeMultiple
                  handleCloseMultiple={handleCloseMultiple}
                  // filename={currentresfile}
                  // res_id={currentresid}
                  // user_id={currentresuid}
                  selectedResumes={selectedResume}
                  usermail={
                    props.currentUser.username !== null
                      ? props.currentUser.username
                      : ""
                  }
                  updateViewStatus={updateViewStatusMultiple}
                />
              </DialogContent>
            </Dialog>
          ) : null}
          {/* -----------------------Main Content----------------------------------------- */}
          {loadingResults ? (
            <div>please wait we are getting results...</div>
          ) : !resumes.length > 0 ? (
            <div
              style={{
                height: window.innerHeight,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={nodataImg} width={400} />
            </div>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={2.5}>
                <MuiPaper elevation={2}>
                  {pageLoading ? null : singleResume &&
                    singleResume !== "" &&
                    props.redirectId ? (
                    <>
                      <List key={singleResume.id}>
                        <ListItemButton
                          key={singleResume.id}
                          onClick={() => jobTitleSet(singleResume)}
                        >
                          <div style={{ display: "flex", width: "100%" }}>
                            <Avatar>
                              <PersonOutlineOutlined />
                            </Avatar>
                            <div style={{ marginLeft: "10px", width: "100%" }}>
                              <p
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  margin: 0,
                                }}
                              >
                                {singleResume.full_name}
                              </p>
                              <p
                                className="card-text"
                                id="job__description"
                                style={{
                                  fontWeight: "600",
                                  marginBottom: "0",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "0",
                                  }}
                                >
                                  <Highlighter
                                    highlightStyle={{
                                      backgroundColor: "#F9FC3F",
                                    }}
                                    searchWords={
                                      sessionStorage.getItem(
                                        "skillstoHighlight"
                                      ) !== undefined &&
                                      sessionStorage.getItem(
                                        "skillstoHighlight"
                                      ) !== null &&
                                      sessionStorage.getItem(
                                        "skillstoHighlight"
                                      ) !== ""
                                        ? sessionStorage
                                            .getItem("skillstoHighlight")
                                            .replace(/"/g, "")
                                            .split(",")
                                        : []
                                    }
                                    autoEscape={true}
                                    style={{ fontSize: "13px" }}
                                    textToHighlight={
                                      singleResume.application_title
                                    }
                                  />
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "12px",
                                  margin: 0,
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span className="rol">
                                  Location:{" "}
                                  {singleResume.OpenAIstatename !==
                                  undefined ? (
                                    <span>
                                      {singleResume.OpenAIstatename}
                                      ,&nbsp; {singleResume.statename}
                                    </span>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>

                                <span className=" time">
                                  {moment(singleResume.lastmodified).format(
                                    "YYYY-MM-DD"
                                  ) === moment(today).format("YYYY-MM-DD")
                                    ? moment(singleResume.lastmodified).format(
                                        "LT"
                                      )
                                    : moment(singleResume.lastmodified).format(
                                        "YYYY-MM-DD HH:MM:SS"
                                      ) >
                                      moment(today)
                                        .subtract(1, "days")
                                        .format("YYYY-MM-DD HH:MM:SS")
                                    ? "a day ago"
                                    : moment(singleResume.lastmodified).fromNow(
                                        "LT"
                                      )}
                                </span>
                              </p>
                              {/* <p style={{ fontSize: "12px", margin: 0 }}>
                          Location: {data?.res_cityName},&nbsp;
                          {data?.res_stateName}
                        </p> */}
                            </div>
                          </div>
                        </ListItemButton>
                      </List>
                    </>
                  ) : (
                    <List sx={{ height: "80vh", overflow: "scroll" }}>
                      {!props.redirectId &&
                        resumes &&
                        singleResume &&
                        resumes.length > 0 &&
                        resumes.map((res, idx) => (
                          <ListItemButton
                            key={res.id}
                            selected={res.id === singleResume.id}
                            onClick={() => jobTitleSet(res, idx)}
                            sx={{
                              borderBottom: "0.5px solid #ddd",
                              padding: "10px",
                            }}
                          >
                            <div style={{ display: "flex", width: "100%" }}>
                              <Avatar>
                                <PersonOutlineOutlined />
                              </Avatar>
                              <div
                                style={{ marginLeft: "10px", width: "100%" }}
                              >
                                <p
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    margin: 0,
                                  }}
                                >
                                  {res.full_name}
                                </p>
                                <p
                                  className="card-text"
                                  id="job__description"
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "0",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      marginBottom: "0",
                                    }}
                                  >
                                    <Highlighter
                                      highlightStyle={{
                                        backgroundColor: "#F9FC3F",
                                      }}
                                      searchWords={
                                        sessionStorage.getItem(
                                          "skillstoHighlight"
                                        ) !== undefined &&
                                        sessionStorage.getItem(
                                          "skillstoHighlight"
                                        ) !== null &&
                                        sessionStorage.getItem(
                                          "skillstoHighlight"
                                        ) !== ""
                                          ? sessionStorage
                                              .getItem("skillstoHighlight")
                                              .replace(/"/g, "")
                                              .split(",")
                                          : []
                                      }
                                      autoEscape={true}
                                      style={{
                                        fontSize: "13px",
                                      }}
                                      textToHighlight={res.application_title}
                                    />
                                  </span>
                                </p>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    margin: 0,
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span className="rol">
                                    Location:{" "}
                                    {res.res_cityName !== undefined ? (
                                      <span>
                                        {res.res_cityName}
                                        ,&nbsp; {res.res_shortRegion}
                                      </span>
                                    ) : (
                                      "N/A"
                                    )}
                                  </span>

                                  <span className=" time">
                                    {moment(res.lastmodified).format(
                                      "YYYY-MM-DD"
                                    ) === moment(today).format("YYYY-MM-DD")
                                      ? moment(res.lastmodified).format("LT")
                                      : moment(res.lastmodified).format(
                                          "YYYY-MM-DD HH:MM:SS"
                                        ) >
                                        moment(today)
                                          .subtract(1, "days")
                                          .format("YYYY-MM-DD HH:MM:SS")
                                      ? "a day ago"
                                      : moment(res.lastmodified).fromNow()}
                                  </span>
                                </p>
                                {/* <p style={{ fontSize: "12px", margin: 0 }}>
                        Location: {data?.res_cityName},&nbsp;
                        {data?.res_stateName}
                      </p> */}
                              </div>
                            </div>
                          </ListItemButton>
                        ))}
                    </List>
                  )}
                  {numFound !== 0 ||
                  sessionStorage.getItem("OpenAIresumesCount") !== "0" ? (
                    <div className="row">
                      <div className="row col-sm-12 col-md-10">
                        {isJobtypeChecked !== true ? (
                          <div style={{ color: "#406882" }}>
                            Page {currentPage} of{" "}
                            {sessionStorage.getItem("OpenAIresumesCount")
                              ? numFound
                                ? numFound
                                : parseInt(
                                    sessionStorage.getItem("OpenAIresumesCount")
                                  )
                              : numFound}{" "}
                          </div>
                        ) : (
                          <div style={{ color: "#406882" }}>
                            Page {currentPage} of{" "}
                            {sessionStorage.getItem("OpenAIresumesCount")}
                          </div>
                        )}

                        <div
                          className="row"
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                        >
                          {isJobtypeChecked !== true ? (
                            <Pagination
                              totalRecords={
                                sessionStorage.getItem("OpenAIresumesCount")
                                  ? numFound
                                    ? numFound
                                    : parseInt(
                                        sessionStorage.getItem(
                                          "OpenAIresumesCount"
                                        )
                                      )
                                  : numFound
                              }
                              pageLimit={
                                sessionStorage.getItem("resumesPerPage") !==
                                null
                                  ? sessionStorage.getItem("resumesPerPage")
                                  : 10
                              }
                              pageNeighbours={1}
                              onPageChanged={onPageChanged}
                            />
                          ) : (
                            <Pagination
                              totalRecords={parseInt(
                                sessionStorage.getItem("OpenAIresumesCount")
                              )}
                              pageLimit={
                                sessionStorage.getItem("resumesPerPage") !==
                                null
                                  ? sessionStorage.getItem("resumesPerPage")
                                  : 10
                              }
                              pageNeighbours={1}
                              onPageChanged={onPageChanged}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className="col-sm-12 col-md-2"
                        style={{ marginLeft: "-39px" }}
                      >
                        <div
                          class="form-group"
                          id="rows-perpage"
                          // style={{ marginLeft: "40px" }}
                        >
                          <label
                            for="staticEmail"
                            class="col-sm-8 col-form-label"
                            style={{ width: "60px", fontSize: "13px" }}
                          ></label>
                          <div class="col-sm-2">
                            <select
                              class="form-select form-select mb-0"
                              aria-label=".form-select-lg example"
                              style={{
                                width: "63px",
                                fontSize: "10px",
                              }}
                              onChange={(e) => {
                                changeRowsPerPage(e.target.value);
                                onPageChanged(
                                  sessionStorage.getItem("currentsearchpage"),
                                  "",
                                  sessionStorage.getItem("resumesPerPage")
                                );
                              }}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group row"></div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </MuiPaper>
              </Grid>
              {numFound !== 0 ||
              sessionStorage.getItem("OpenAIresumesCount") !== "0" ? (
                <Dialog
                  open={messageshow}
                  centered
                  fullWidth
                  onClose={handlemessageClose}
                  className="col-4"
                  style={{ borderRadius: "4px" }}
                >
                  {/* <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "18px" }}>
                      Message
                    </Modal.Title>
                  </Modal.Header> */}
                  <DialogTitle
                    id="responsive-dialog-title"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <DialogContentText variant="h6">Message</DialogContentText>
                    <IconButton aria-label="close" onClick={handlemessageClose}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>

                  <DialogContent>
                    <Messagepopup handlemessageClose={handlemessageClose} />
                  </DialogContent>
                </Dialog>
              ) : (
                ""
              )}
              {numFound !== 0 ||
              sessionStorage.getItem("OpenAIresumesCount") !== "0" ? (
                <Dialog
                  open={noteShow}
                  centered
                  onClose={handleNoteClose}
                  size="sm"
                  fullWidth
                >
                  {/* <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "18px" }}>Note</Modal.Title>
                  </Modal.Header> */}

                  <DialogTitle
                    id="responsive-dialog-title"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <DialogContentText
                      variant="h6"
                      style={{ fontSize: "18px" }}
                    >
                      Note
                    </DialogContentText>
                    <IconButton aria-label="close" onClick={handleNoteClose}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>

                  <DialogContent>
                    <Notepopup
                      handleNoteClose={handleNoteClose}
                      noteResumeId={noteResumeId}
                    />
                  </DialogContent>
                </Dialog>
              ) : (
                ""
              )}

              <Dialog
                open={showFilters}
                centered
                fullWidth
                onClose={handleFilterClose}
                size="lg"
              >
                {/* <Modal.Header closeButton>
                  <Modal.Title style={{ fontSize: "18px" }}>
                    Filters
                  </Modal.Title>
                </Modal.Header> */}

                <DialogTitle
                  id="responsive-dialog-title"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <DialogContentText variant="h6" style={{ fontSize: "18px" }}>
                    Filters
                  </DialogContentText>
                  <IconButton aria-label="close" onClick={handleFilterClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent style={{ margin: "0px", padding: "0px" }}>
                  <EmpResumeFilters onFilterChecked={onFilterChecked} />
                </DialogContent>
              </Dialog>
              <Grid item xs={5.5}>
                <MuiPaper
                  elevation={2}
                  style={{ height: "85vh", overflow: "scroll" }}
                >
                  {props.currentUser !== null && resumeViewsLeft > 0 ? (
                    <>
                      {" "}
                      {(singleResume && singleResume.id) !== undefined ? (
                        props.currentUser !== null ? (
                          <PdfViewer
                            resumeDetails={singleResume}
                            sqlResumeDetails={totalsDetails.resumedetails}
                          />
                        ) : (
                          "Please login"
                        )
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </MuiPaper>
              </Grid>
              <Grid item sm={4}>
                <Box
                  component={MuiPaper}
                  elevation={2}
                  sx={{ height: "85vh", overflow: "scroll" }}
                >
                  <ResumeProfile
                    resume={singleResume}
                    handlemailShow={handlemailShow}
                    handlemessageShow={handlemessageShow}
                  />
                  {/* <Box sx={{position:'relative', padding:'4px', width:'100%' }}>
              <Box sx={{ display: "flex", }}>
                  <Box sx={{borderLeft:'10px solid rgba(64, 104, 130, 0.8)',borderTop:'4px solid rgba(64, 104, 130, 0.8)',borderBottom:'4px solid rgba(64, 104, 130, 0.8)', borderRadius:'50%', }}>
                  <Box sx={{borderLeft:'6px solid #fff',borderTop:'3px solid #fff',borderBottom:'3px solid #fff', borderRadius:'50%',}}><Avatar 
                  src="https://img.freepik.com/free-photo/horizontal-portrait-smiling-happy-young-pleasant-looking-female-wears-denim-shirt-stylish-glasses-with-straight-blonde-hair-expresses-positiveness-poses_176420-13176.jpg?w=996&t=st=1702271653~exp=1702272253~hmac=6df403e4dd80c55861bc496ced5b75e6bdc1f83019152ab036ed6467fcd8174a" 
                  sx={{ height: "100px", width: "100px", }} /></Box>
                  </Box>
                </Box>
                { singleResume?.home_phone !== "N/A" &&
                  <Box sx={{position:'absolute', left:'115px', top:'15px',
                  '.phone-text' : {
                    margin:0, fontSize:'10px', color:'rgba(0,0,0,0.7)', fontWeight:500, cursor:'pointer', textTransform:'lowercase'
                  },
                '&:hover' : {
                  '.phone-text' : {
                    color:'#406882',
                  textDecoration:'underline'
                  }
                }
                  }}>
                      <Typography variant="button" paragraph className="phone-text" onClick={handlemessageShow}>
                        <PhoneRounded sx={{height:'12px', width:'12px', mr:'3px'}} />{singleResume?.home_phone}
                        </Typography>
                    </Box>
                }
                <Box sx={{position:'absolute', right:'5px', top:'15px',
                '.email-text' : {
                  margin:0, fontSize:'10px', color:'rgba(0,0,0,0.7)', fontWeight:500, cursor:'pointer', textTransform:'lowercase'
                },
              '&:hover' : {
                '.email-text' : {
                  color:'#406882',
                textDecoration:'underline'
                }
              }
              }}>
                  <Typography variant="button" paragraph className="email-text"
                  onClick={() =>
                    handlemailShow(
                      singleResume?.email_address,
                      singleResume?.full_name
                    )
                  }
                  ><EmailRounded sx={{height:'12px', width:'12px', mr:'3px'}} />{singleResume?.email_address}
                  </Typography>
                </Box>
                <MuiPaper elevation={1} sx={{position:'absolute', left:'105px', right:0, top:'38px', padding:'10px', borderRadius:'6px', width:'auto', 
                // bgcolor:'#406882', 
                 }}>
                <h6 style={{margin:0,
                maxWidth: '320px',
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight:800,
                color:'rgba(0,0,0,0.7)',
                textTransform: 'capitalize',
                }}>{singleResume?.full_name}</h6>
                <p style={{margin:0, fontSize:'12px', fontWeight: 500,
                color:'#406882', 
                maxWidth: '320px',
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                }}>{singleResume?.application_title}</p>
                </MuiPaper>
                <Box sx={{position:'absolute', right:'34px', top:'105px',}}>
              <Box sx={{border:'1px solid #ddd', borderRadius:'6px', 
            '&:hover' : {
              bgcolor:'#406882',
              '.inicon': {
                color: '#fff',
                cursor:'pointer'
              }
            },
            '.inicon' : {
              color: '#406882',
            }
            }}><TiSocialLinkedin className="inicon" style={{height:'20px', width:'24px',}} /></Box>
              </Box>
              <Box sx={{position:'absolute', right:'5px', top:'105px',}}>
              <Box sx={{border:'1px solid #ddd', borderRadius:'6px', 
            '&:hover' : {
              bgcolor:'#406882',
              '.twicon': {
                color: '#fff',
                cursor:'pointer'
              }
            },
            '.twicon' : {
              color: '#406882',
            }
            }}><TiSocialTwitter className="twicon" style={{height:'20px', width:'24px',}}  /></Box>
              </Box>
              </Box> */}

                  {/* <Stack direction="row" spacing={1} sx={{p:'4px', mt:2}}>
      <Button variant="outlined" sx={{ ...btnStyles, ...skillBtnStyles}}
      startIcon={<ManageAccountsRounded sx={{height: '16px', width:'16px',minWidth:'10px'}} />} 
    
      onClick={() => {
        setSummaryOpen(false);
        setSkillsOpen(true);
      }}
      >
        Skills</Button>
      <Button variant="outlined"
      startIcon={<BusinessCenterRounded sx={{height: '16px', width:'16px',}} />} 
      sx={{...btnStyles, ...workBtnStyles}}
      onClick={() => {
        setSkillsOpen(false);
        setSummaryOpen(true);
      }}
      >
        Work</Button>
      <Button variant="outlined"
      startIcon={<DescriptionRounded sx={{height: '16px', width:'16px',}} />} 
      sx={{ ...btnStyles}}
      >
        Note</Button>
    </Stack> */}
                  {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                // textAlign: "center",
                width: "100%",
              }}
              >
                <Button></Button>
              </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {/* image--------------- */}
                    {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Avatar src="" sx={{ height: "80px", width: "80px" }} />
                </Box> */}
                    {/* name--------------- */}
                    {/* <Box>
                  <h4 style={{ margin: 0 }}>{singleResume?.full_name}</h4>
                  <p style={{ margin: 0, color: "#757575" }}>
                    {singleResume?.email_address}
                  </p>
                </Box> */}
                    {/* icons--------------------- */}
                    {/* <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                  <Box component={Stack} direction="column" sx={iconBoxStyles}>
                    <MuiTooltip title="Skills">
                      <Avatar
                        sx={iconAvatarStyles}
                        onClick={() => {
                          setSummaryOpen(false);
                          setSkillsOpen(true);
                        }}
                      >
                        <ManageAccountsRounded sx={iconStyles} />
                      </Avatar>
                    </MuiTooltip>
                    Skills
                  </Box>
                  <Box component={Stack} direction="column" sx={iconBoxStyles}>
                    <MuiTooltip title="Work">
                      <Avatar
                        sx={iconAvatarStyles}
                        onClick={() => {
                          setSkillsOpen(false);
                          setSummaryOpen(true);
                        }}
                      >
                        <BusinessCenterRounded sx={iconStyles} />
                      </Avatar>
                    </MuiTooltip>
                    Work
                  </Box>
                  
                  <Box component={Stack} direction="column" sx={iconBoxStyles}>
                    <MuiTooltip title='Text Message'>
                    <Avatar sx={iconAvatarStyles} onClick={handlemessageShow}>
                      <ChatRounded sx={iconStyles} />
                    </Avatar>
                    </MuiTooltip>
                    Text
                  </Box>
                  <Box component={Stack} direction="column" sx={iconBoxStyles}>
                    <MuiTooltip title='Email'>
                    <Avatar sx={iconAvatarStyles} onClick={() =>
                              handlemailShow(
                                singleResume?.email_address,
                                singleResume?.full_name
                              )
                            }>
                      <EmailRounded sx={iconStyles} />
                    </Avatar>
                    </MuiTooltip>
                    Email
                  </Box>

                  
                <Box component={Stack} direction='column' sx={iconBoxStyles}>
                  <MuiTooltip title='Note'>
                  <Avatar sx={iconAvatarStyles} onClick={() => handleNoteShow(singleResume?.id)}><DescriptionRounded sx={iconStyles} /></Avatar>
                  </MuiTooltip>
                  Note</Box>
                  <Box component={Stack} direction="column" sx={iconBoxStyles}>
                    <Avatar sx={iconAvatarStyles} 
                    id="demo-customized-button"
                    aria-controls={openMoreMenu ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMoreMenu ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    // onClick={handleClickMoreMenu}
                    >
                      <MoreHorizRounded sx={iconStyles} />
                    </Avatar>
                    More
                  </Box>
                  <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorElMoreMenu}
        open={openMoreMenu}
        onClose={handleCloseMoreMenu}
      >
        <MenuItem onClick={handleCloseMoreMenu} disableRipple>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={handleCloseMoreMenu} disableRipple>
          <FileCopyIcon />
          Duplicate
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleCloseMoreMenu} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        <MenuItem onClick={handleCloseMoreMenu} disableRipple>
          <MoreHorizIcon />
          More
        </MenuItem>
      </StyledMenu>
                </Box> */}
                  </Box>

                  {/* contact activity------------------------------------------- */}
                  {/* <Card sx={{ boxShadow: "none" ,}}>
                <CardContent>
                  {skillsOpen ? (
                    <Card
                      sx={{
                        boxShadow: "none",
                        border: "1px solid #ddd",
                        borderRadius: "6px",
                        p:2
                        // bgcolor:'#ffefcd'
                      }}
                    >
                      <CardHeader 
                      // sx={{borderBottom:'1px solid #ddd'}}
                        title={
                          <Typography paragraph style={{ fontSize: "18px", fontWeight: 400, margin:0,}}>
                            How{" "}
                            <span
                              style={{
                                fontWeight: 500,
                                // textDecoration: "underline",
                                color: "#e4a43c"
                              }}
                            >
                              {singleResume?.application_title}
                            </span>{" "}
                            match
                          </Typography>
                        }
                        // titleTypographyProps={{ variant: 'h6', fontSize: '14px' }}
                      />
                      <CardContent sx={{
                        bgcolor:'#ffefcd',
                        // bgcolor:'#CFE4FF',
                        height:'auto', maxheight:'40vh', overflow:'scroll', borderRadius:'6px'}}>
                        
                          
                          {matchedSkillsList?.length > 6
                            ? <Box sx={{ display: "flex" ,
                            '&:hover' : {
                              'textu' : {
                                textDecoration:'underline',
                                color:'blue',
                              }
                            }
                            }}>
                            <WorkspacePremiumRounded
                              sx={{
                                color: "#e4a43c",
                                height: "36px",
                                width: "36px",
                                mr: "5px",
                              }}
                            />
                            <p
                            style={{ fontSize: "14px", margin:0}} 
                            // onClick={matchedSkills}
                            onClick={() => setMatchedSkillsOpen(true)}
                          >
                            <span className={`${matchedSkillsList?.length > 0 ? 'match-text' : ''}`}>
                            {matchedSkillsList?.length} of{" "}
                            {aiSkillsByResumeTitle?.length > 0 ? aiSkillsByResumeTitle?.length : 0 } skills matched it
                            may be a good fit based on skills with experience
                            for&nbsp;
                            </span>
                            <span style={{background:'#e4a43c', fontWeight:500, paddingInline:'7px', color:'#fff', borderRadius:'4px', margin:0}}>{singleResume?.application_title}</span>
                          </p></Box>
                            : <Box sx={{ display: "flex" }}>
                            <AssignmentLateRounded
                          sx={{
                            color: "#e4a43c",
                            height: "36px",
                            width: "36px",
                            mr: "5px",
                          }}
                          />
                            <p 
                            style={{ fontSize: "14px" }}
                            // onClick={matchedSkills}
                            onClick={() => matchedSkillsList?.length > 0 && setMatchedSkillsOpen(true)}
                          >
                            <span className={`${matchedSkillsList?.length > 0 ? 'match-text' : ''}`}>
                            {matchedSkillsList?.length} of{" "}
                            {aiSkillsByResumeTitle?.length > 0 ? aiSkillsByResumeTitle?.length : 0 } skills matched based on skills with experience
                            for&nbsp;
                              </span><span style={{background:'#e4a43c', fontWeight:500, paddingInline:'7px', color:'#fff', borderRadius:'4px', margin:0}}>{singleResume?.application_title}</span>
                          </p></Box>
                          }
                        {
                          matchedSkillsOpen ? 
                          <List sx={{ height: "auto", maxHeight:'30vh', overflowY: "scroll" }}>
                          <Grid container spacing={0}>
                          {matchedSkillsList?.map((skill, index) => (
                            <Grid item xs={6}>
                              <ListItem key={index} sx={{p:0}}>
                              <ArrowRightOutlined/> <ListItemText primary={skill} />
                            </ListItem>
                            </Grid>
                          ))}
                          </Grid>
                        </List>
                        : null
                        }
                      </CardContent>
                      <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                        <TableContainer component={MuiPaper} sx={{ maxHeight: 400 }}>
      <Table aria-label="customized table" stickyHeader size="small">
      <TableHead>
          <TableRow>
            <StyledTableCell>Resume Skills</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          skillsInResume?.map((row) => (
            <StyledTableRow key={row} >
              <StyledTableCell component="th" scope="row">
                {row}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                        </Grid>
                        <Grid item xs={6}>
                        <TableContainer component={MuiPaper} sx={{ maxHeight: 400 }}>
      <Table aria-label="customized table" stickyHeader size="small" >
      <TableHead>
          <TableRow>
            <StyledTableCell>Required Skills</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {
          aiSkillsByResumeTitle?.map((row) => (
            <StyledTableRow key={row} >
              <StyledTableCell component="th" scope="row">
                {row}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                        </Grid>
                      </Grid>
                      </CardContent>
                    </Card>
                  ) : summaryOpen ? (
                    <Card>
                      <CardHeader title={
                          <Typography paragraph style={{ fontSize: "18px", fontWeight: 500, margin:0,}}>
                              Experience
                          </Typography>
                        } />
                      <CardContent>
      <Stack direction='row' spacing={1} sx={{
        bgcolor: 'background.paper',
        color: 'text.secondary',
        '& svg': {
          m: 1.5,
        },
        border:'1px solid #ddd',
        borderRadius:'6px',
        p:1,
        mb:1
      }}>
        <Avatar sx={{ height: '50px', width: '50px' }}>
          <LocationCityOutlined sx={{ height: '32px', width: '32px' }} />
        </Avatar>
        <Box>
          <Typography 
          variant="body2" component="p"
          // variant="subtitle1"
           fontWeight={500} sx={{ margin:0, fontSize:'16px', color:'rgba(0,0,0,0.8)',  lineHeight: 1.5 }}>
            Software Developer
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Breadcrumbs separator="-" aria-label="breadcrumb">
            <Typography variant="body2" component="p" sx={{ fontSize:'1rem', lineHeight: 1.42, color:'rgba(0,0,0,0.6)',  }}>
              Flaplive Innovations PVT LTD
            </Typography>
            <Typography variant="body2" component="p" sx={{ fontSize:'1rem', lineHeight: 1.42, color:'rgba(0,0,0,0.6)' }}>
              Full Time
            </Typography>
            </Breadcrumbs>
            
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Breadcrumbs separator={<Bullet />} aria-label="breadcrumb">
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.5)' }}>
              Aug 2021 - Present
            </Typography>
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.5)' }}>
              2 yrs 5 mos
            </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="body2" component="p" sx={{fontSize:'14px', color: 'rgba(0,0,0,0.5)'}} >
          Hyderabad, Telangana, India
          </Typography>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography variant="body2" component="p" sx={{  fontSize:'14px', color:'rgba(0,0,0,0.6)', fontWeight:500 }}>
              Skills&nbsp;:&nbsp;
            </Typography>
            <Box sx={{display:'flex', width:'100%',}}>
            <Breadcrumbs separator={<Bullet />} aria-label="breadcrumb">
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.6)' }}>
              Node.Js
            </Typography>
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.6)' }}>
              React.Js
            </Typography>
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.6)' }}>
              React Native
            </Typography>
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.6)' }}>
              Python
            </Typography>
            <Typography variant="body2" component="p" sx={{ marginLeft: '4px', fontSize:'14px', color:'rgba(0,0,0,0.6)' }}>
             Redux
            </Typography>
            </Breadcrumbs>
            </Box>
          </Box>
        </Box>
      </Stack>
      <Stack direction='row' spacing={1} sx={{
        bgcolor: 'background.paper',
        color: 'text.secondary',
        '& svg': {
          m: 1.5,
        },
        border:'1px solid #ddd',
        borderRadius:'6px',
        p:1,
      }}>
        <Avatar sx={{ height: '50px', width: '50px' }}>
          <LocationCityOutlined sx={{ height: '32px', width: '32px' }} />
        </Avatar>
        <Box>
          <Typography 
          variant="body2" component="p"
          // variant="subtitle1"
           fontWeight={500} sx={{ margin:0, fontSize:'16px', color:'rgba(0,0,0,0.8)',  lineHeight: 1.5 }}>
            Software Developer
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Breadcrumbs separator="-" aria-label="breadcrumb">
            <Typography variant="body2" component="p" sx={{ fontSize:'1rem', lineHeight: 1.42, color:'rgba(0,0,0,0.6)',  }}>
              Flaplive Innovations PVT LTD
            </Typography>
            <Typography variant="body2" component="p" sx={{ fontSize:'1rem', lineHeight: 1.42, color:'rgba(0,0,0,0.6)' }}>
              Full Time
            </Typography>
            </Breadcrumbs>
            
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Breadcrumbs separator={<Bullet />} aria-label="breadcrumb">
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.5)' }}>
              Aug 2021 - Present
            </Typography>
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.5)' }}>
              2 yrs 5 mos
            </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="body2" component="p" sx={{fontSize:'14px', color: 'rgba(0,0,0,0.5)'}} >
          Hyderabad, Telangana, India
          </Typography>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography variant="body2" component="p" sx={{  fontSize:'14px', color:'rgba(0,0,0,0.6)', fontWeight:500 }}>
              Skills&nbsp;:&nbsp;
            </Typography>
            <Box sx={{display:'flex', width:'100%',}}>
            <Breadcrumbs separator={<Bullet />} aria-label="breadcrumb">
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.6)' }}>
              Node.Js
            </Typography>
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.6)' }}>
              React.Js
            </Typography>
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.6)' }}>
              React Native
            </Typography>
            <Typography variant="body2" component="p" sx={{ fontSize:'14px', color:'rgba(0,0,0,0.6)' }}>
              Python
            </Typography>
            <Typography variant="body2" component="p" sx={{ marginLeft: '4px', fontSize:'14px', color:'rgba(0,0,0,0.6)' }}>
             Redux
            </Typography>
            </Breadcrumbs>
            </Box>
          </Box>
        </Box>
      </Stack>
                      </CardContent>
                      <CardHeader title={
                          <Typography paragraph style={{ fontSize: "18px", fontWeight: 500, margin:0,}}>
                              Certifications
                          </Typography>
                        } />
                      <CardContent>
                      </CardContent>
                    </Card>
                  ) : (<></>
                    // <Card>
                    //   <CardContent>
                    //     <p style={{ fontSize: "14px" }}>Tasks</p>
                    //     <div
                    //       style={{
                    //         display: "flex",
                    //         justifyContent: "space-between",
                    //       }}
                    //     >
                    //       <div>
                    //         <p style={{ fontSize: "14px", margin: 0 }}>
                    //           Call {singleResume?.full_name} and discuss
                    //           business plan.
                    //         </p>
                    //       </div>
                    //       <FactCheckOutlined
                    //         sx={{ color: "rgba(0,0,0,0.7)" }}
                    //       />
                    //     </div>
                    //     <p style={{ color: "#406882", fontSize: "12px" }}>
                    //       <b>+3 more tasks</b>
                    //     </p>
                    //   </CardContent>
                    // </Card>
                  )}
                </CardContent>
              </Card> */}
                </Box>
              </Grid>
              {/* <Grid item xs={4}>
            <Box
              component={MuiPaper}
              elevation={2}
              sx={{ bgcolor: "background.paper", borderRadius: "4px" }}
            >
              <MuiAppBar
                position="static"
                sx={{
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                  bgcolor: "#406882",
                }}
              >
                <MuiTabs
                  value={tabIndexValue}
                  onChange={tabhandleChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  // variant="fullWidth"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="visible arrows tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#e97d44",
                    },
                  }}
                >
                  <MuiTab label="Summary" {...a11yProps(0)} />
                  <MuiTab label="skills" {...a11yProps(1)} />
                  <MuiTab label="Projects" {...a11yProps(2)} />
                </MuiTabs>
              </MuiAppBar>
              <TabPanel
                value={tabIndexValue}
                index={0}
                dir={themeForTabs.direction}
              >
                {parse(`${singleResume.summary}`)}
              </TabPanel>
              <TabPanel
                value={tabIndexValue}
                index={1}
                dir={themeForTabs.direction}
              >
                <List>
                  {singleResume &&
                  singleResume.ai_skills !== undefined &&
                  singleResume.ai_skills !== null &&
                  singleResume.ai_skills !== "null"
                    ? singleResume.ai_skills.split(",").map((skill, index) => (
                        <ListItem key={index}>
                          <Highlighter
                            highlightStyle={{
                              backgroundColor: "#F9FC3F",
                            }}
                            searchWords={
                              sessionStorage.getItem("skillstoHighlight") !==
                                undefined &&
                              sessionStorage.getItem("skillstoHighlight") !==
                                null &&
                              sessionStorage.getItem("skillstoHighlight") !== ""
                                ? sessionStorage
                                    .getItem("skillstoHighlight")
                                    .replace(/"/g, "")
                                    .split(",")
                                : []
                            }
                            autoEscape={true}
                            sanitizetyle={{ fontSize: "13px" }}
                            textToHighlight={skill}
                          />
                        </ListItem>
                      ))
                    : totalsDetails &&
                      totalsDetails.resumedetails.ai_skills !== undefined &&
                      totalsDetails.resumedetails.ai_skills !== null &&
                      totalsDetails.resumedetails.ai_skills !== "null"
                    ? totalsDetails.resumedetails.ai_skills
                        .split(",")
                        .map((skill, index) => (
                          <ListItem key={index}>
                            <Highlighter
                              highlightStyle={{
                                backgroundColor: "#F9FC3F",
                              }}
                              searchWords={
                                sessionStorage.getItem("skillstoHighlight") !==
                                  undefined &&
                                sessionStorage.getItem("skillstoHighlight") !==
                                  null &&
                                sessionStorage.getItem("skillstoHighlight") !==
                                  ""
                                  ? sessionStorage
                                      .getItem("skillstoHighlight")
                                      .replace(/"/g, "")
                                      .split(",")
                                  : []
                              }
                              autoEscape={true}
                              sanitizetyle={{ fontSize: "13px" }}
                              textToHighlight={skill}
                            />
                          </ListItem>
                        ))
                    : null}
                </List>
              </TabPanel>
              <TabPanel
                value={tabIndexValue}
                index={2}
                dir={themeForTabs.direction}
              >
                {totalsDetails.projectdetails &&
                totalsDetails.projectdetails.length !== 0 ? (
                  totalsDetails.projectdetails.map((pro, i) => (
                    <div
                      style={{
                        borderBottom: "2px solid lightgray",
                        margin: "0px 0px 10px 0px",
                        paddingBottom: "10px",
                      }}
                    >
                      <div style={{ marginLeft: "10px" }}>
                        {pro.pro_title ? (
                          <div>
                            <p
                              style={{
                                marginBottom: "0",
                                lineHeight: "1.8",
                              }}
                              className="project-details"
                            >
                              <span
                                style={{
                                  color: "#406882",
                                  marginRight: "5px",
                                  fontWeight: "550",
                                }}
                              >
                                <BsFillLayersFill /> :
                              </span>
                              {pro.pro_title ? pro.pro_title : "Not available"}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        <p
                          style={{
                            marginBottom: "0",
                            lineHeight: "1.8",
                          }}
                          className="project-details"
                        >
                          <span
                            style={{
                              color: "#406882",
                              marginRight: "5px",
                              fontWeight: "550",
                            }}
                          >
                            <FaBuilding /> :
                          </span>
                          {pro.pro_client ? pro.pro_client : "Not available"}
                        </p>
                        <p
                          style={{
                            marginBottom: "0",
                            lineHeight: "1.8",
                          }}
                          className="project-details"
                        >
                          <span
                            style={{
                              color: "#406882",
                              marginRight: "5px",
                              fontWeight: "550",
                            }}
                          >
                            <IoPersonSharp /> :
                          </span>
                          {pro.pro_role ? pro.pro_role : "Not available"}
                        </p>
                        <p
                          style={{
                            marginBottom: "0",
                            lineHeight: "1.8",
                          }}
                          className="project-details"
                        >
                          <span
                            style={{
                              color: "#406882",
                              marginRight: "5px",
                              fontWeight: "550",
                            }}
                          >
                            <GoLocation /> :
                          </span>
                          {pro.OpenAIstatename
                            ? pro.OpenAIstatename + ", " + pro.shortRegion
                            : "Not available"}
                        </p>
                        <p
                          style={{
                            marginBottom: "0",
                            lineHeight: "1.8",
                          }}
                          className="project-details"
                        >
                          <span
                            style={{
                              color: "#406882",
                              marginRight: "5px",
                              fontWeight: "550",
                            }}
                          >
                            <AiFillCalendar /> :
                          </span>
                          {pro.pro_startdate
                            ? moment(pro.pro_startdate).format("MMM YYYY")
                            : "Not available"}
                          <span className="mx-2">to</span>
                          {pro.pro_enddate !== "Till date" &&
                          pro.pro_enddate !== "null" &&
                          pro.pro_enddate !== "" &&
                          pro.pro_enddate !== undefined &&
                          pro.pro_enddate !== null
                            ? moment(pro.pro_enddate).format("MMM YYYY")
                            : "Till date"}
                        </p>
                      </div>
                      <div className="card-title fw-bold fs-6 mt-2">
                       
                      </div>
                      {pro.pro_description === "null" ||
                      pro.pro_description === undefined ||
                      pro.pro_description === "" ? (
                        ""
                      ) : (
                        <div>
                          <div style={{ marginLeft: "10px" }}>
                            <p
                              style={{
                                color: "#406882",
                                fontWeight: "550",
                                marginBottom: "0",
                              }}
                            >
                              Description
                            </p>

                            <ul className="list-group ">
                              <li
                                className="list-tem"
                                style={{ listStyle: "none" }}
                              >
                                <p>
                                  {pro.pro_description &&
                                  pro.pro_description !== "undefined"
                                    ? parse(pro.pro_description)
                                    : "Description not available"}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}

              
                    </div>
                  ))
                ) : (
                  <div className=" mt-4 mb-3">
                    Project details not available
                  </div>
                )}
              </TabPanel>
            </Box>
          </Grid> */}
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

//Get props from state
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchJobsTwo)
);

//!res.viewed_by_group
//?

//   <Tooltip
//     content="Group Members Not Viewed"
//     direction="left"
//   >
//     <img
//       src={viewdbygroupIcon}
//       className="mx-2"
//     />
//   </Tooltip>
// ) : (
//   <Tooltip
//     content="Group Member Viewed"
//     direction="left"
//   >
//     <AiFillEye
//       className="mx-2"
//       style={{ color: "#406882" }}
//     />
//   </Tooltip>
// )}*/

const iconBoxStyles = {
  fontSize: "14px",
};
const iconAvatarStyles = {
  bgcolor: "#efefef",
};
const iconStyles = {
  color: "rgba(0,0,0,0.7)",
};

const btnStyles = {
  borderRadius: "25px",
  padding: "0px 15px 0px 15px",
  textTransform: "capitalize",
  border: "1px solid #ddd",
  color: "rgba(0,0,0,0.6)",
  "&:hover": {
    bgcolor: "#406882",
    color: "#fff",
    border: "1px solid #406882",
  },
};
