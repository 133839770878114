import React, { Component } from 'react';
import { Container, TextField, Button, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Key from '../../clientVariables.json';

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Enter a valid email').required('Email is Required'),
    fullname: Yup.string().required('Fullname is Required'),
    subject: Yup.string().required('Subject is Required'),
});

export default class fr extends Component {
    state = {
        submit: false,
    };

    start = (values) => {
        this.setState({ submit: true });

        fetch(`${Key.domain}/users/insert_chat_bot_data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                name: values.fullname,
                email: values.email,
                subject: values.subject,
                chat_history: '',
            }),
        })
            .then((response) => response.json())
            .then((resdata) => {
                console.log(resdata);
                if (resdata.success === 1 && resdata.data.affectedRows > 0) {
                    var chatUser = {
                        name: values.fullname,
                        email: values.mail,
                        subject: values.subject,
                    };
                    localStorage.setItem('chat_user', JSON.stringify(chatUser));
                    var element = document.getElementsByClassName('rcw-sender');
                    console.log(element);
                    element[0].classList.remove('mystyle2');
                    element[0].classList.add('mystyle');
                    var message = `Welcome ${values.fullname}, We are excited to offer you messaging, allowing you to respond to us in your own time. Click here to get started.`;
                    this.props.handlemsg(message);
                }
            });
    };

    render() {
        var currentUser = JSON.parse(localStorage.getItem('chat_user'));
        return (
            <div>
                {this.state.submit && localStorage.getItem('chat_user') && currentUser !== null ? (
                    <div>
                        {/* <p>Name: {this.state.name}</p>
                        <p>Email: {this.state.mail}</p> */}
                    </div>
                ) : (
                    <div>
                        <Container>
                            <Formik
                                initialValues={{ fullname: '', subject: '', email: '' }}
                                validationSchema={LoginSchema}
                                onSubmit={(values) => {
                                    this.start(values);
                                }}
                            >
                                {({ touched, errors, isSubmitting, values }) => (
                                    <Form>
                                        <div>
                                            <Typography variant="h6" gutterBottom>
                                                Contact Us
                                            </Typography>
                                            <Field
                                                name="fullname"
                                                type="text"
                                                label="Full Name"
                                                placeholder="Full Name"
                                                autoComplete="off"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                                helperText={<ErrorMessage name="fullname" />}
                                                error={touched.fullname && Boolean(errors.fullname)}
                                            />
                                            <Field
                                                name="email"
                                                type="email"
                                                label="Email"
                                                placeholder="Email"
                                                autoComplete="off"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                                helperText={<ErrorMessage name="email" />}
                                                error={touched.email && Boolean(errors.email)}
                                            />
                                            <Field
                                                name="subject"
                                                type="text"
                                                label="Subject"
                                                placeholder="Subject"
                                                autoComplete="off"
                                                multiline
                                                rows={4}
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                                helperText={<ErrorMessage name="subject" />}
                                                error={touched.subject && Boolean(errors.subject)}
                                            />
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                style={{ marginTop: '1rem' }}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Container>
                    </div>
                )}
            </div>
        );
    }
}
