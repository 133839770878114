import React, { Component } from "react"
import Key from '../../clientVariables.json'
import axios from "axios";
import { TiTick } from 'react-icons/ti'
import { Formik, Field, Form, ErrorMessage } from "formik";

export default class fr extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      mail: '',
      subject: '',
      submit: false,
      email: [],
      skillsList: [],
      skillIds: [],
      skillTitles: [],
      disabledAll: false,
      state: "",
      states: []
    }
  }

  componentDidMount = () => {
    console.log(this.props, localStorage.getItem("chat_user"))
    var element = document.getElementsByClassName('rcw-sender');
    element[0].classList.add('mystyle2');


    axios
      .post(`${Key.domain}/commonAPIs/get_states_by_countryid`, {
        country_id: 233,
      })
      .then((response) => {
        console.log(response);
        this.setState({ states: response.data.data });
      });
  }
  handleSubmitStates = () => {
    this.setState({ disableAll: true })
    this.props.handleStatesFunction(this.state.state)
  }
  render() {
    return (
      <Formik
        render={({ errors, status, touched, values }) => (
          <Form id="addresume" className="addresume2">
            <div style={{ width: '100%' }} className="row">
              {!this.state.disableAll && <>
                <div className={this.state.state !== '' ? "col-md-11" : "col-md-12"}>
                  <Field
                    name="state"
                    as="select"
                    className={
                      "form-select form-control"

                    }
                    onChange={(e) => this.setState({ state: e.target.value })}
                  >
                    <option>Select state</option>
                    {this.state.states.map((item, index) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </Field>

                </div>
                {this.state.state !== '' &&
                  <div className={this.state.state !== '' ? "col-md-1" : "col-md-1"}>
                    <TiTick style={{ fontSize: "35px", cursor: "pointer" }} onClick={() => this.handleSubmitStates()} />
                  </div>}
              </>

              }
            </div>
          </Form>
        )}/>
        )
        }

}