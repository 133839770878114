import moment from "moment";
import Key from "../clientVariables.json";

export const buildSolrQuery = ({
  role,
  company,
  skills,
  id,
  location,
  filterValues,
  pageStartNum,
  rowsPerPage,
  redirectionSearchJobsUrl,
  primaryResumeDetails,
  searchType,
}) => {
  // const queryParams = {
  //   role: "desired_role",
  //   company: "desired_company",
  //   skills: "desired_skills",
  //   id: "desired_id",
  //   location: "desired_location",
  //   job_type: "desired_job_type",
  //   date: "desired_date",
  //   visa_type: "desired_visa_type",
  //   relocation: "desired_relocation",
  // };

  const skillRelatedWords = [
    "skill",
    "technology",
    "developer",
    "programmer",
    "architect",
    "designer",
    "consultant",
    "administrator",
    "coach",
    "lead",
    "manager",
    "master",
    "specalist",
    "modeler",
    "director",
    "hacker",
    "engineer",
    "analyst",
    "dba",
    "tester",
    "senior",
    "sr",
    "junior",
    "jr",
    "knowledge",
    "or",
    "with",
    "to",
    "and",
    "front",
    "end",
    "back",
    "frontend",
    "backend",
  ];

  function getMappedDates(dateOption) {
    let mappedDates;
    const today = new Date();

    if (dateOption === "1") {
      let startdate = moment(today)
        .subtract(1, "d")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      mappedDates = startdate + " TO NOW";
    } else if (dateOption === "2") {
      let startdate = moment(today)
        .subtract(7, "d")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      mappedDates = startdate + " TO NOW";
    } else if (dateOption === "3") {
      let startdate = moment(today)
        .subtract(30, "d")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      mappedDates = startdate + " TO NOW";
    } else {
      mappedDates = null;
    }

    return mappedDates;
  }

  try {
    let searchUrl;
    // let short_region = null;
    // let state_name = null;
    // let city_name = null;

    // if (
    //   (redirectionSearchJobsUrl.shouldRedirect &&
    //     redirectionSearchJobsUrl.location !== "") ||
    //   location
    // ) {
    //   const loc =
    //     redirectionSearchJobsUrl?.location !== null
    //       ? redirectionSearchJobsUrl.location.split(/[,]+/)
    //       : location && location !== ""
    //       ? location
    //       : "";
    //   if (loc?.length > 1) {
    //     city_name = loc[0];
    //     if (loc[1].trim().length === 2) {
    //       short_region = loc[1];
    //     } else {
    //       state_name = loc[1];
    //     }
    //   } else {
    //     if (loc[0]?.length === 2) {
    //       short_region = loc[0];
    //     } else {
    //       city_name = loc[0];
    //     }
    //   }
    // }

    let locationFilter = "";
    console.log("location 111", location);
    if (
      (redirectionSearchJobsUrl?.location &&
        redirectionSearchJobsUrl?.location !== null &&
        redirectionSearchJobsUrl?.location !== "") ||
      location
    ) {
      console.log("loc 1 redirectionSearchJobsUrl?.location", redirectionSearchJobsUrl?.location);
      const loc =
        redirectionSearchJobsUrl?.location?.split(/[,]+/) ||
        location?.split(/[,]+/);

      if (loc.length > 1) {
        const city = loc[0].trim();
        const regionOrState = loc[1].trim();

        if (regionOrState.length === 2) {
          locationFilter = `&fq=shortRegion:(${regionOrState})&fq=cityName:(${city})`;
        } else {
          locationFilter = `&fq=stateName:(${regionOrState})&fq=cityName:(${city})`;
        }
      } else {
        const singleLocation = loc[0].trim();
        if (singleLocation.length === 2) {
          locationFilter = `&fq=shortRegion:(${singleLocation})`;
        } else {
          locationFilter = `&fq=cityName:(${singleLocation})`;
        }
      }

      console.log("loc 1 locationFilter", redirectionSearchJobsUrl?.location, loc, locationFilter);
    }

    let searchText = redirectionSearchJobsUrl.shouldRedirect
      ? redirectionSearchJobsUrl.searchText &&
        redirectionSearchJobsUrl.searchText !== ""
        ? redirectionSearchJobsUrl.searchText
        : primaryResumeDetails?.application_title
      : null;

      // console.log("title 1st", searchText);

    if (skills) {
      const wordRegex = /\b\w+\b/g;
      const words = searchText.match(wordRegex);
      const filteredWords = words.filter(
        (word) => !skillRelatedWords.includes(word.toLowerCase())
      );
      const filteredText = filteredWords.join(" ");
      searchText = filteredText;
    }
    // console.log("title 2nd", searchText);

    let mainQuery = "*:*";
    let completeInputTextQuery = "*:*";
    let completeInputTextQuery2 = "*:*";
    let separateTitleQuery = "*:*";

    if (searchText) {
      const title = redirectionSearchJobsUrl.shouldRedirect
        ? redirectionSearchJobsUrl.searchText &&
          redirectionSearchJobsUrl.searchText !== ""
          ? redirectionSearchJobsUrl.searchText
          : primaryResumeDetails?.application_title
        : "";

      // const searchTextArray = title
      //   .replace(/[^\w\s]/g, "")
      //   .split(/\s+/)
      //   .filter((word) => !skillRelatedWords.includes(word.toLowerCase()));

      const searchTextArray = title
  .replace(/[^\w\s\-_]/g, "")
  .split(/\s+/)
  .filter((word) => !skillRelatedWords.includes(word.toLowerCase()));

      // console.log("searchTextArray", searchTextArray);
      const combinations = [];

      let hasDeveloper = false;

      for (let i = 0; i < searchTextArray.length; i++) {
        if (i < searchTextArray.length - 1) {
          combinations.push(`${searchTextArray[i]} ${searchTextArray[i + 1]}`);
        }

        if (i > 0) {
          combinations.push(`${searchTextArray[i - 1]} ${searchTextArray[i]}`);
        }

        // Check if the word is "developer" and append it to combinations
        if (title.toLowerCase().includes("developer")) {
          hasDeveloper = true;
        }
      }

      // If "developer" is found in searchText, append it to combinations
      if (hasDeveloper) {
        for (let i = 0; i < combinations.length; i++) {
          combinations[i] = `${combinations[i]} developer`;
        }
      }

      // console.log("combinations", combinations);
      const filteredCombinations = combinations.filter(
        (combination) => !combination.toLowerCase().includes("lead")
      );

      console.log("filteredCombinations 1", filteredCombinations);

      const uniqueCombinations = [...new Set(filteredCombinations)];

      console.log("filteredCombinations 2", uniqueCombinations);

      completeInputTextQuery = uniqueCombinations.join(" OR ");

      completeInputTextQuery2 = uniqueCombinations.map((word) => `"${word}"`).join(" OR ")

      // if (redirectionSearchJobsUrl?.searchType === "title") {
      //   mainQuery = `(${searchText})`;
      // } else {
      //   mainQuery = `(${searchText}) OR companyName:"${searchText}" OR id:(${searchText})`;
      // }
      if (searchText) {
        const cleanedText = searchText.replace(/[^\w\s]/g, "");
        const splitTitle = !cleanedText.toLowerCase().includes("developer")
          ? cleanedText.split(/\s+/).join(" OR ") 
          : cleanedText;
          separateTitleQuery = splitTitle?.split(" OR ").map((word) => `"${word}"`).join(" OR ");
          // console.log("separateQuery", separateQuery);
        const titleQuery = `title:(${splitTitle})^10`;
        const companyNameQuery = `companyName:(${searchText})`;
        const idQuery = `id:(${searchText})`;

        // console.log("title query 1", cleanedText)
        // console.log("title query 2", splitTitle)
        // console.log("title query 3", titleQuery)

        if (redirectionSearchJobsUrl?.searchType === "company") {
          mainQuery = `${companyNameQuery}`;
        } else {
          mainQuery = `${titleQuery} OR ${companyNameQuery} OR ${idQuery}`;
        }
      }
    }

    //  if ( redirectionSearchJobsUrl?.location &&
    //   redirectionSearchJobsUrl?.location !== null &&
    //   redirectionSearchJobsUrl?.location !== "") {
    //     mainQuery += ` ${locationFilter}`;
    // }

    if (!filterValues) {
      searchUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=${mainQuery}&fq=${completeInputTextQuery2}&${locationFilter}&rows=${rowsPerPage}&start=${pageStartNum}&fq=status:(1)&sort=modified%20desc&wt=json`;
    } else {
      console.log("filter values", filterValues); //&fq=workmode:(true)
      const sortBy = filterValues?.["Sort By"] === "2" ? "relavance" : "recent";
      const jobTypes = (filterValues?.["Job Type"] || []).join(" OR ");
      const visaTypes = (filterValues?.["Visa Type"] || []).join(" OR ");
      const travelTypes = (filterValues?.["Travel"] || []).join(" OR ");
      const mappedDates = getMappedDates(filterValues["Posted Date"]);
      const workMode = filterValues?.["Work Mode"] === "1" ? true : false;
      const sortByParam =
        sortBy === "relavance" ? "score%20desc" : "modified%20desc";
console.log("workmode", workMode);
      searchUrl = `${Key.Solr_url}/${
        Key.solrJobs_collection
      }/select?q=${mainQuery}&fq=${completeInputTextQuery2}&${workMode ? "*:*" : locationFilter}&fq=jobtype:(${
        jobTypes ? jobTypes : "*:*"
      })&fq=workmode:(${workMode})&fq=workpermitName:(${
        visaTypes ? visaTypes : "*:*"
      })&fq=requiredtravel:(${
        travelTypes ? travelTypes : "*:*"
      })&&fq=created: [${
        mappedDates !== null ? mappedDates : "* TO *"
      }]&fq=status:(1)&rows=${rowsPerPage}&start=${pageStartNum}&sort=${sortByParam}&wt=json`;
    }
    return searchUrl;
  } catch (error) {
    console.error("Error building Solr query:", error);
    return null;
  }
};
