import React, { Component } from 'react';
import { Paper, Button, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Jobseeker/Footer';
import axios from 'axios';
import Key from '../../clientVariables.json';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../Actions/loader.actions';

class EmailVerification extends Component {
    state = {
        emailVerified: false,
        userUpdated: false,
        errorContent: 'Sorry something went wrong!',
        userdata: {},
    };

    componentDidMount() {
        this.props.showLoader();

        var Url = window.location.pathname;
        var currentUrl = Url.split('/');
        var key = currentUrl[2].split('_');
        var username = key[0];
        //action 1 for mail
        axios.post(`${Key.domain}/users/check_activekey`, { email: username, Active_key: key[1], action: 1 }).then((resdata) => {
            // console.log(resdata)
            if (resdata.data.success === 1 && resdata.data.data.affectedRows > 0) {
                this.setState({ emailVerified: true, Email: username, userUpdated: true });
                this.props.hideLoader();

                axios.post(`${Key.domain}/solr/solr_update_job_post_free_job`, {
                    email: username,
                }).then((resdata) => {});

                axios.post(`${Key.domain}/users/employer_register_alert`, { email: username }).then((resdata) => {
                    console.log(resdata.data.data);
                    console.log(resdata.data.data.results);

                    console.log(resdata.data.data.results[0]);
                    this.setState({ userdata: resdata.data.data.results[0] });
                });
            } else {
                this.setState({ errorContent: resdata.data.data });
                this.props.hideLoader();
            }
        });
    }

    render() {
        return (
            <div>
                <Navbar />
                {this.state.emailVerified && this.state.userUpdated ? (
                    <div>
                        <Paper elevation={3} style={{ marginLeft: '20%', marginRight: '20%', marginTop: '15%', marginBottom: '19%', padding: '20px' }}>
                            <Typography variant="h5" gutterBottom>
                                Your account is successfully activated!!
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Click the button below to login.
                            </Typography>
                            <Button variant="contained" onClick={() => window.location.href = parseInt(this.state.userdata && this.state.userdata.user_type_id) === 3 ? '/bs_login' : '/EmpLogin'}>
                                Click here to Login
                            </Button>
                        </Paper>
                    </div>
                ) : (
                    <div>
                        <Paper elevation={3} style={{ marginLeft: '20%', marginRight: '20%', marginTop: '15%', marginBottom: '28%', padding: '20px' }}>
                            <Alert variant="danger">{this.state.errorContent}</Alert>
                        </Paper>
                    </div>
                )}
                {/* footer */}
                <div className="col-12">
                    <Footer />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
    };
};
export default withRouter(connect(null, mapDispatchToProps)(EmailVerification));
