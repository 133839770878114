import React, { useState } from "react";
import { BsXLg } from "react-icons/bs";
import "../Styles/feedback.css";
//images
import smile from "../Images/smile.png";
import neutral from "../Images/emoji-neutral.png";
import smile2 from "../Images/smile2.png";
import sad from "../Images/sad.png";
import sad2 from "../Images/sad2.png";

import axios from "axios";
import Key from "../clientVariables.json";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
const data = [
  {
    id: 1,
    image: smile,
    emojiType: "Very Happy",
    question: "What did you like? Any comments?",
    rating: 5,
  },
  {
    id: 2,
    image: smile2,
    emojiType: "Happy",
    question: "What did you like? Any comments?",
    rating: 4,
  },
  {
    id: 3,
    image: neutral,
    emojiType: "netural",
    question: "Do You have any comments?",
    rating: 3,
  },
  {
    id: 4,
    image: sad2,
    emojiType: "sad",
    question: "What didn't you like?",
    rating: 2,
  },
  {
    id: 5,
    image: sad,
    emojiType: "very sad",
    question: "What didn't you like?",
    rating: 1,
  },
];
const FeedbackNew = (props) => {
  const [textarea, setTextarea] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [submitFeedback, setSubmitFeedback] = useState(false);
  const [emojiData, setEmojiData] = useState(data);
  const [emojiRating, setEmojiRating] = useState(0);
  const [emojiQuestion, setEmojiQuestion] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (feedback !== "") {
      setButtonLoading(true);
      setTextarea(true);
      axios
        .put(`${Key.domain}/commonAPIs/send_feedback`, {
          user_id: props.currentUser.id,
          session_token: props.currentUser.session_token,
          email: props.currentUser.username,
          name: props.currentUser.full_name,
          description: feedback,
          rating: emojiRating,
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200 && res.data.data.insertId) {
            axios
              .post(`${Key.domain}/empdashboard/send_feedback_mail`, {
                user_id: props.currentUser.id,
                session_token: props.currentUser.session_token,
                description: feedback,
                name: props.currentUser.full_name,
                email: props.currentUser.username,
                rating: emojiRating,
              })
              .then((res) => {
                console.log(res);
                setButtonLoading(false);
                if (res && res.status) {
                  // props.sidebar=false;
                  // props.handleSidebar(false)
                  // alert("Mail Sent!");
                  props.handleSidebar(false);
                  setTextarea(false);
                  setSubmitFeedback(true);
                  setFeedback("");
                  setEmojiRating();
                  setEmojiQuestion("");
                } else {
                  // alert("Mail not sent!");
                  setTextarea(false);
                  setSubmitFeedback(true);
                  setFeedback("");
                  setEmojiRating();
                  setEmojiQuestion("");
                }
              });
          } else {
            setButtonLoading(false);
            // alert("Error while sending feedback!");
          }
        });
    }
    // else
    //  alert("Please give feedback")
    console.log(feedback, emojiRating);
  };
  return (
    <div className="feedback_form_area_inner">
      <span
        style={{ float: "right", cursor: "pointer" }}
        onClick={() => {
          props.handleSidebar(false);
          setSubmitFeedback(false);
          setTextarea(false);
          setFeedback("");
          setEmojiRating();
          setEmojiQuestion("");
        }}
      >
        <BsXLg />
      </span>
      {textarea? <div style={{marginTop:"100px",marginLeft:"5px",height:"200px",fontWeight:"bold",fontSize:"18px"}}>Thank you for your valuable feedback</div>
      :
      <>
      <h5>Give us feedback</h5>
      <p>Help us to improve our product</p>
      <div style={{ marginBottom: "8px", marginLeft: "15px" }}>
        {emojiData.map((emoji) => {
          return (
            <span
              key={emoji.id}
              style={{
                color: "yellow",
                fontSize: "20px",
                marginRight: "11px",
                cursor: "pointer",
              }}
              onClick={() => {
                // setTextarea(!textarea)
                setEmojiRating(emoji.rating);
                setEmojiQuestion(emoji.question);
              }}
            >
              <img
                src={emoji.image}
                className={
                  emoji.rating === emojiRating ? "emoji active" : "emoji"
                }
              />
            </span>
          );
        })}
      </div> 
      
      {/* <h5>Give us feedback</h5>
      <p>Help us to improve our product</p>
      <div style={{ marginBottom: "8px", marginLeft: "15px" }}>
        {emojiData.map((emoji) => {
          return (
            <span
              key={emoji.id}
              style={{
                color: "yellow",
                fontSize: "20px",
                marginRight: "11px",
                cursor: "pointer",
              }}
              onClick={() => {
                // setTextarea(!textarea)
                setEmojiRating(emoji.rating);
                setEmojiQuestion(emoji.question);
              }}
            >
              <img
                src={emoji.image}
                className={
                  emoji.rating === emojiRating ? "emoji active" : "emoji"
                }
              />
            </span>
          );
        })}
      </div> */}
       
        
      <>
        <p style={{ marginBottom: "0px", marginTop: "25px" }}>
          {emojiQuestion}
        </p>
        <form onSubmit={handleSubmit}>
          <textarea
            class="form-control"
            rows="3"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          ></textarea>
          <div>
            {buttonLoading ? (
              <>
                <button className="btn btn-outline" id="a1" disabled>
                  submitting...
                </button>
              </>
            ) : (
              <button className="btn btn-outline" id="a1" type="submit">
                submit feedback
              </button>
            )}
          </div>
        </form>
      </>
      </>
      }
    </div>
  );
};
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

export default withRouter(connect(mapStateToProps, null)(FeedbackNew));
