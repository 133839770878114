import React, { useState, useEffect, useRef } from "react";
import "../../Styles/Jsdashboard.css";
import { Container, Grid } from "@mui/material";
import Key from "../../clientVariables.json";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../Components/Actions/loader.actions";
import { hideLoader } from "../../Components/Actions/loader.actions";
import { GoLocation } from "react-icons/go";
import "../../../src/Styles/Search_jobs.css";
import axios from "axios";
import { toast } from "react-toastify";
import ToastMessage from "../../Components/ToastMessage";
import { AiOutlineSearch } from "react-icons/ai";

import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { lowercasedUsStatesArray } from "./UsStates";
import { setURLjobSearchUrlActions } from "../Actions/jobSearchUrlActions";

function Jssearchblock(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops

  const history = useHistory();
  const [jobs, setJobs] = useState([]);
  const [jobtitle, setJobtitle] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [jobtype, setJobtype] = useState(false);
  const [fulltime, setFullTime] = useState(false);
  const [parttime, setPartTime] = useState(false);
  const [remote, setRemote] = useState(false);
  const [showTitleSuggestions, setShowTitleSuggestions] = useState(false);
  const [titleSuggestions, setTitleSuggestions] = useState([]);
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const suggestionListRef = useRef(null);
  const dispatch = useDispatch();
  const primaryResumeDetails = useSelector(
    (state) => state.primaryResumeReducer.resume
  );
  const pageStartValue = useSelector(
    (state) => state.pageStartValReducer.pageStartValue
  );

  useEffect(() => {
    setFullTime(props.fulltime !== undefined ? props.fulltime : false);
    setPartTime(props.parttime !== undefined ? props.parttime : false);
    setRemote(props.remote);
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/Jsdashboard" &&
      props.onSubmit !== undefined
    ) {
      props.onSubmit(jobs);
    }
    setJobtitle(
      sessionStorage.getItem("inputjobtitle") !== null
        ? sessionStorage.getItem("inputjobtitle").replace(/"/g, "")
        : ""
    );
    setCityName(
      sessionStorage.getItem("cityname")
        ? sessionStorage.getItem("cityname").replace(/"/g, "")
        : ""
    );
    console.log(props.jobtitle, sessionStorage.getItem("inputjobtitle"));
    setJobtype(props.fulltime ? true : false);
  }, [jobs]);

  const fulltimeChange = () => {
    setFullTime(!fulltime);
  };

  const getJobtitles = (value) => {
    setShowTitleSuggestions(true);
    axios
      .post(`${Key.domain}/Jobs/get_jobtitles`, {
        jobtitle: value,
      })
      .then((response) => {
        console.log(response.data.data);
        setTitleSuggestions(
          response.data.data
            .filter((res) => res.title !== "business analysis")
            .map((res) => res.title)
        );
      });
  };

  const onTextChanged = (value) => {
    setJobtitle(value);
  };

  const replacements = {
    "#": "%23",
    "++": "%2B%2B",
    "&": "%26",
    " / ": "/",
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function replaceSpecialCharacters(inputString, replacementMap) {
    let modifiedString = inputString;

    for (const [specialChar, replacement] of Object.entries(replacementMap)) {
      const regex = new RegExp(escapeRegExp(specialChar), "g");
      modifiedString = modifiedString.replace(regex, replacement);
    }

    return modifiedString;
  }

  const clearSessionValues = () => {
    sessionStorage.removeItem("currentsearchpage");
    sessionStorage.removeItem("filtered-jobtypes");
    sessionStorage.removeItem("filtered-visatypes");
    sessionStorage.removeItem("jobMappedDates");
    localStorage.removeItem("workModeFlages");
    sessionStorage.removeItem("filtered-workmode");
    localStorage.removeItem("jobtypeFlages");
    localStorage.removeItem("visatypeFlages");
    localStorage.removeItem("traveltypeFlages");
    localStorage.removeItem("searchJobsChecked");
    localStorage.removeItem("searchJobsByMapChecked");
    localStorage.removeItem("jobsSortBy");
    sessionStorage.setItem("currentsearchpage", 1);
    localStorage.removeItem("jobsSortByDate");
  };

  const submit = async (e) => {
    e.preventDefault();
    sessionStorage.setItem("startValue", 0);
    localStorage.setItem("filterResetValue", 1);
    props.showLoader();
    await clearSessionValues();

    var inputjobtitle =
      jobtitle !== "" ? '"' + jobtitle.replace(/"/g, "") + '"' : null;
    if (inputjobtitle !== null) {
      const modifiedString = replaceSpecialCharacters(
        inputjobtitle,
        replacements
      );
      inputjobtitle = modifiedString;
    }
    var searchJobsUrl = '';
    var searchLocationUrl = '';


    var location = cityName; //this includes both cityaname and statename

    let short_region = null;
    let state_name = null;
    let city_name = null;

    const loc = location ? location?.split(/[,]+/) : "";
    if (loc?.length > 1) {
      city_name = loc[0];

      if (loc[1].trim().length === 2) {
        console.log(loc[1].trim().length);
        short_region = loc[1];
      } else {
        console.log(loc[1].trim().length);
        state_name = loc[1];
      }
    } else {
      if (loc[0]?.length === 2) {
        console.log(loc);
        short_region = loc[0];
      } else {
        console.log(loc);
        city_name = loc[0];
      }
    }

    let role_ = inputjobtitle ? inputjobtitle : primaryResumeDetails?.application_title;

    let search_url =
      `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=${role_ ? `"${role_}"` : "*:*"
      } OR companyName:(${role_ ? '"' + role_ + '"' : "*:*"}) OR id:(${role_ ? '"' + role_ + '"' : "*:*"}) &rows=${10}&start=${0}&fq=cityName:(${city_name ? city_name : "*:*"
      })&fq=stateName:(${state_name ? state_name : "*:*"
      })&fq=shortRegion:(${short_region ? short_region : "*:*"
      })&fq=status:(1)&sort=modified%20desc&wt=json`;


    var inputlocationcity = cityName;

    var job_title;
    var location;
    if (inputlocationcity !== "" && inputjobtitle !== null) {
      // var searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection
      //   }/select?q=title:${inputjobtitle}&fq=cityName:(${city ? city : "*:*"
      //   })&fq=stateName:(${state ? state : "*:*"})&fq=shortRegion:(${shortRegion ? shortRegion : "*:*"
      //   })&fq=status:(1)&rows=10&sort=modified%20desc&wt=json`;
      job_title = true;
      location = true;
    } else if (inputlocationcity === "" && inputjobtitle !== null) {
      searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=title:${inputjobtitle} OR companyName:${inputjobtitle} OR id:${inputjobtitle}&fq=status:(1)&rows=10&sort=modified%20desc&wt=json`;
      job_title = true;
      location = false;
    } else if (
      inputlocationcity !== "" &&
      (inputjobtitle === "" ||
        inputjobtitle === null ||
        inputjobtitle === undefined)
    ) {
      searchJobsUrl = searchLocationUrl;
      job_title = false;
      location = true;
    } else {
      var default_Job_role = sessionStorage.getItem("usertype")
        ? sessionStorage.getItem("usertype") === "5"
          ? '"' + sessionStorage.getItem("job_role") + '"'
          : "*%3A*"
        : "*%3A*";
      const role =
        sessionStorage.getItem("usertype") &&
        sessionStorage.getItem("usertype") === "5" &&
        primaryResumeDetails.application_title !== null
          ? `"${primaryResumeDetails.application_title}"`
          : "*%3A*";
      searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=${role}&fq=status:(1)&rows=10&sort=modified%20desc&wt=json`;
      console.log("searchJobsUrl", searchJobsUrl);
      job_title = false;
      location = false;
    }

    if (props.currentUser === null) {
      fetch(`${Key.domain}/solr/solr_jobs`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: search_url }),
      }).then((response) => {
        response.json().then((resdata) => {
          if (resdata.data.error) {
            props.hideLoader();
            props.handleClose(false);
            toast(<ToastMessage message="Invalid Response" />);
          } else {
            sessionStorage.setItem("statename", stateName);
            sessionStorage.setItem("cityname", cityName);
            sessionStorage.setItem("jobtitle", jobtitle);
            sessionStorage.setItem("jobsCount", resdata.data.response.numFound);
            history.push({
              pathname: "/searchJobs_listview",
              state: {
                response: resdata.data.response.docs,
                jobtitle: job_title,
                location: location,
              },
            });
            props.hideLoader();
            props.handleClose(false);
          }
        });
      });
    } else {
      
      const title = inputjobtitle ? inputjobtitle : ""
     
      dispatch(
        setURLjobSearchUrlActions(search_url, sessionStorage.getItem("inputjobtitle"), sessionStorage.getItem("cityname"))
      );
      console.log('searchblock check title', title)
      props.handleClose(false);
      
      sessionStorage.setItem("statename", stateName);
      sessionStorage.setItem("cityname", cityName);
      history.push("/jobs");
      props.hideLoader();
    }
  };

  const getCityNames = (value) => {
    setShowSuggestions(true);
    axios
      .post(`${Key.domain}/Jobs/get_joblocations`, {
        statename: value,
      })
      .then((response) => {
        if (response.data.data.length === 0) {
          // setErrorLocation(true);
          setShowSuggestions(false);
        } else {
          setErrorLocation(false);
          setCitySuggestions(
            response.data.data.map((res) =>
              res.cityName.concat(" , ", res.stateName)
            )
          );
        }
      });
  };

  const pushToMaps = () => {
    sessionStorage.removeItem("googleMapJobs");
    sessionStorage.removeItem("googleMapJobIds");
    sessionStorage.removeItem("jobsCount");

    sessionStorage.removeItem("filtered-jobtypes");
    sessionStorage.removeItem("filtered-visatypes");
    sessionStorage.removeItem("jobMappedDates");
    localStorage.removeItem("workModeFlages");
    sessionStorage.removeItem("filtered-workmode");
    localStorage.removeItem("jobtypeFlages");
    localStorage.removeItem("visatypeFlages");
    localStorage.removeItem("traveltypeFlages");
    sessionStorage.removeItem("currentsearchpage");
    localStorage.removeItem("jobsSortBy");
    sessionStorage.setItem("currentsearchpage", 1);
    localStorage.removeItem("jobsSortByDate");
    localStorage.setItem("filterResetValue", 1);

    history.push("/SearchByMap");
  };
  const scrollToIndex = (index) => {
    if (suggestionListRef.current) {
      const suggestionItemHeight = 30; // Adjust this value as needed
      suggestionListRef.current.scrollTop = index * suggestionItemHeight;
    }
  };
  return (
    <>
      <div
        className="Search__bg" /* style={{marginTop: props.currentUser ? '-49px' : (isMatchLGS ? '60px' : '47px')}} */
        style={{
          marginTop:
            window.location.pathname.toLowerCase() === "/bsdashboard"
              ? "60px"
              : window.location.pathname === "/searchJobs_listview" &&
                parseInt(props.currentUser?.usertype) === 3
              ? "60px"
              : window.location.pathname === "/searchJobs_listview" &&
                parseInt(props.currentUser?.usertype) === 5
              ? "0px"
              : window.location.pathname === "/searchJobs_listview"
              ? isMobile || isTablet
                ? "10px"
                : "0px"
              : window.location.pathname === "/" &&
                parseInt(props.currentUser?.usertype) === 5
              ? "0px"
              : window.location.pathname === "/" &&
                parseInt(props.currentUser?.usertype) === 3
              ? "60px"
              : "0px",
        }}
      >
        <Container>
          <div className="jsmain_body">
            <Grid container spacing={2}>
              {/* Form */}
              <Grid item xs={12}>
                <div className="Searchblocks">
                  <form autoComplete="on" onSubmit={(e) => submit(e)}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={6} md={4}>
                        <input
                          type="text"
                          style={{
                            height: "50px",
                            borderRadius: "8px",
                            backgroundColor: "aliceblue",
                            width: "100%",
                          }}
                          onBlur={(e) => {
                            setTimeout(() => {
                              setShowTitleSuggestions(false);
                            }, [300]);
                          }}
                          class="form-control"
                          value={
                            jobtitle !== "" &&
                            jobtitle !== undefined &&
                            jobtitle !== null
                              ? jobtitle.replace(/"/g, "")
                              : ""
                          }
                          placeholder="Job title, Skill, Company"
                          onChange={(e) => {
                            onTextChanged(e.target.value);
                            console.log(e.target.value);
                            getJobtitles(e.target.value);
                            sessionStorage.setItem(
                              "inputjobtitle",
                              e.target.value
                            );
                            setCurrentIndex(-1);
                          }}
                          onKeyDown={(e) => {
                            if (titleSuggestions.length > 0) {
                              if (e.key === "ArrowUp" && currentIndex > 0) {
                                setCurrentIndex(currentIndex - 1);
                                scrollToIndex(currentIndex - 1);
                              } else if (
                                e.key === "ArrowDown" &&
                                currentIndex < titleSuggestions.length - 1
                              ) {
                                setCurrentIndex(currentIndex + 1);
                                scrollToIndex(currentIndex + 1);
                              } else if (
                                e.key === "Enter" &&
                                currentIndex !== -1
                              ) {
                                // Handle Enter key press (select suggestion)
                                e.preventDefault();

                                let entertitle =
                                  '"' + titleSuggestions[currentIndex] + '"';
                                setJobtitle(entertitle);
                                console.log("enter", entertitle);
                                setShowTitleSuggestions(false);
                                sessionStorage.setItem(
                                  "inputjobtitle",
                                  '"' + titleSuggestions[currentIndex] + '"'
                                );
                              }
                            }
                          }}
                        />
                        {/* Title suggestions */}
                        {showTitleSuggestions &&
                          titleSuggestions.length > 0 && (
                            <ul
                              className="suggestionList"
                              style={{
                                maxHeight: "150px",
                                maxWidth: "400px",
                                overflowY: "auto",
                              }}
                            >
                              {titleSuggestions.map((suggestion, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    setJobtitle(suggestion);
                                    sessionStorage.setItem(
                                      "inputjobtitle",
                                      suggestion
                                    );
                                    setShowTitleSuggestions(false);
                                    setCurrentIndex(-1);
                                    scrollToIndex(-1);
                                  }}
                                  style={{
                                    backgroundColor:
                                      index === currentIndex ? "#406882" : "",
                                    color:
                                      index === currentIndex
                                        ? "white"
                                        : "black",
                                  }}
                                >
                                  {suggestion}
                                </li>
                              ))}
                            </ul>
                          )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <input
                          style={{
                            height: "50px",
                            borderRadius: "8px",
                            backgroundColor: "aliceblue",
                            width: "100%",
                          }}
                          type="text"
                          class="form-control"
                          value={cityName}
                          placeholder="Location"
                          onBlur={(e) => {
                            setTimeout(() => {
                              setShowSuggestions(false);
                            }, [300]);
                          }}
                          onChange={(e) => {
                            setCityName(e.target.value);
                            sessionStorage.setItem("cityname", e.target.value);
                            getCityNames(e.target.value);
                            setActiveSuggestion(-1);
                          }}
                          onKeyDown={(e) => {
                            if (citySuggestions.length > 0) {
                              if (
                                e.key === "ArrowDown" &&
                                activeSuggestion < citySuggestions.length - 1
                              ) {
                                setActiveSuggestion(activeSuggestion + 1);
                                scrollToIndex(activeSuggestion + 1);
                                e.preventDefault();
                              } else if (
                                e.key === "ArrowUp" &&
                                activeSuggestion > 0
                              ) {
                                setActiveSuggestion(activeSuggestion - 1);
                                scrollToIndex(activeSuggestion - 1);
                                e.preventDefault();
                              } else if (
                                e.key === "Enter" &&
                                activeSuggestion >= 0 &&
                                activeSuggestion < citySuggestions.length
                              ) {
                                e.preventDefault();
                                // Handle Enter key press to select a suggestion
                                setCityName(citySuggestions[activeSuggestion]);
                                setShowSuggestions(false);
                                sessionStorage.setItem(
                                  "cityname",
                                  citySuggestions[activeSuggestion]
                                );
                              }
                            }
                          }}
                        />
                        {/* City suggestions */}
                        {showSuggestions && citySuggestions.length > 0 && (
                          <ul
                            className="suggestionList"
                            style={{
                              maxHeight: "150px",
                              maxWidth: "400px",
                              overflowY: "auto",
                            }}
                          >
                            {citySuggestions.map((suggestion, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "cityname",
                                    suggestion
                                  );
                                  setCityName(suggestion);
                                  setShowSuggestions(false);
                                  setActiveSuggestion(-1);
                                  scrollToIndex(-1);
                                }}
                                style={{
                                  backgroundColor:
                                    index === activeSuggestion ? "#406882" : "",
                                  color:
                                    index === activeSuggestion
                                      ? "white"
                                      : "black",
                                }}
                              >
                                {suggestion}
                              </li>
                            ))}
                          </ul>
                        )}
                        {/* Error message */}
                        {errorLocation && (
                          <em style={{ color: "white" }}>
                            Please enter the correct location
                          </em>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        {/* Search button */}
                        <button
                          type="submit"
                          class="btn btn-button "
                          id="_button"
                          style={{
                            height: "50px",
                            borderRadius: "8px",
                            width: "100%",
                          }}
                        >
                          <AiOutlineSearch />
                          Find Jobs
                        </button>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        {/* Search by map */}
                        <p
                          style={{
                            color: "#FFF",
                            fontSize: "14px",
                            cursor: "pointer",
                            margin: 0,
                            textAlign: "center",
                          }}
                          onClick={() => pushToMaps()}
                        >
                          <GoLocation style={{ fontSize: "14px" }} />
                          &nbsp;Search By Map
                        </p>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Jssearchblock)
);
