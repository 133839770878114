import React, { useState, useEffect } from 'react'
import { Viewer } from '@react-pdf-viewer/core';
import { themePlugin } from '@react-pdf-viewer/theme';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Worker } from '@react-pdf-viewer/core';
import Key from '../../clientVariables.json'
import axios from 'axios'
import { useMemo } from 'react';

const PdfViewer = (props) => {
  const [defaultPdfFile, setDefaultFile] = useState(`${Key.s3_files_url}/user_${props.resumeDetails.uid}/resume_${props.resumeDetails.id}/${props.resumeDetails.res_fileName}`)
  const [isFileFound, setFileFound] = useState(true)
  const [loading, setLoading] = useState(false)

  const themePluginInstance = themePlugin();


  if (sessionStorage.getItem('skillNDescriptionSearch') !== null) {

    // Your input string
    const inputString = sessionStorage.getItem('skillNDescriptionSearch');

    // Define a regular expression pattern to split the input string
    const pattern = /\bAND\b|\bOR\b|\(|\)/g;

    // Split the input string based on the pattern
    const parts = inputString.split(pattern);

    // Remove empty and whitespace-only elements from the array, and remove quotes and leading/trailing spaces
    var result  = parts
      .filter(part => part.trim() !== '') // Remove empty and whitespace-only elements
      .map(part => part.replace(/['"]+/g, '').trim().toLowerCase()); // Remove quotes and trim spaces
  }

  const results = useMemo(() => {
    return result
  }, [result])


  useEffect(() => {
    var filename = props.resumeDetails.res_fileName !== undefined ? props.resumeDetails.res_fileName : props.resumeDetails.filename;
    setDefaultFile(`${Key.s3_files_url}/user_${props.resumeDetails.uid}/resume_${props.resumeDetails.id}/${filename}`)

    axios.get(`${Key.s3_files_url}/user_${props.resumeDetails.uid}/resume_${props.resumeDetails.id}/${filename}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
    })
      .then((resdata) => {
        setFileFound(true)

      }).catch((err) => {
        setFileFound(false)

      });
  }, [props.resumeDetails.res_fileName])

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)

    }, 1500);
  }, [sessionStorage.getItem('skillNDescriptionSearch')])

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    toolbarPlugin: {
      searchPlugin: {
        keyword: results,
        highlightKeyword: true, // Highlight the keyword
        onHighlightKeyword: (props) => {
          props.highlightEle.style.backgroundColor = 'rgb(255 66 0)';
        },
      },
    },
  });



  return (
    isFileFound === true ?
      <div className='pdf-container'>
        {/* show pdf conditionally (if we have one)  */}
        {defaultPdfFile &&
          loading ? "" :
          <> <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">

            <Viewer fileUrl={defaultPdfFile} plugins={[defaultLayoutPluginInstance, themePluginInstance]} theme="white" />

          </Worker></>

        }

      </div > : <div>Resume Not Available</div>
  )
}

export default PdfViewer;



