import React, { useEffect, useState } from "react";
import axios from "axios";
import Key from "../../clientVariables.json";
import "./packages.css";
import { useHistory } from "react-router-dom";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Purchase from "./Purchase";
import { toast } from "react-toastify";
import ToastMessage from "../../Components/ToastMessage";
import { showLoader } from "../../Components/Actions/loader.actions";
import { hideLoader } from "../../Components/Actions/loader.actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTheme, useMediaQuery } from "@mui/material";
import { IoCheckmarkOutline } from "react-icons/io5";
import Slider from "react-slick";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="slick-next"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className="slick-prev" style={{ ...style, display: "block" }} />;
}

function PackagesView(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large
  const [packageData, setPackageData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [packdetails, setpackDetails] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [newpackageDetails, setnewPackageDetails] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [packageDetails, setPackageDetails] = useState([]);
  const [salesPersonDetails, setsalesPersonDetails] = useState([]);
  const [totaldetails, settotaldetails] = useState([]);

  const history = useHistory();

  useEffect(() => {
    getAllDefaultPackages();
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };
  const handleopen = (pack) => {
    console.log("pack", pack);
    axios
      .post(
        `${Key.Crm_domain}/customer/jnp_insert_or_update_customer_subscription`,
        {
          userid: props.currentUser.id,
          admin_id: props.currentUser.id,
          //"empidsub": 1,
          company_id: props.currentUser.companyid,
          package_id: pack.id,
          jobs_allowed: parseInt(pack.jobs_allowed),
          resumeviews_allowed: parseInt(pack.resumeViews_allowed),
          users_allowed: parseInt(pack.users_allowed),

          jobs_addon: 0,
          resumeviews_addon: 0,
          users_addon: 0,
          companies_allowed: parseInt(pack.companies_allowed),
          status: 1,
          action: packageDetails !== undefined ? "1" : "0",
          session_token: props.currentUser.userToken,
          package_expires_in_days: pack.package_expires_in_days,
          price: pack.price,
        }
      )
      .then((response) => {
        if (response.data.success === 1) {
          setShowModal(true);
          setpackDetails(pack);
        }
      });
  };

  const getAllDefaultPackages = () => {
    axios
      .get(`${Key.domain}/commonAPIs/get_all_emp_packages`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((response) => {
        var data = response.data.data;
        console.log(data);
        setPackageData(data);
        console.log(data);
      });
  };
  useEffect(() => {
    getGetCustomerDetails();
  }, []);

  const getGetCustomerDetails = () => {
    if (
      sessionStorage.getItem("id") !== "" &&
      sessionStorage.getItem("id") !== null &&
      sessionStorage.getItem("id") !== undefined &&
      sessionStorage.getItem("id") !== 0
    ) {
      axios
        .post(`${Key.Crm_domain}/customer/get_upgrade_packagedetails`, {
          customer_id: sessionStorage.getItem("id"),
        })
        .then((response) => {
          console.log(response.data.data);
          settotaldetails(response.data.data);
          setCustomerDetails(response.data.data.userDetails);
          setCompanyDetails(response.data.data.companyDetails);
          setPackageDetails(response.data.data.packageDetails);
          setsalesPersonDetails(response.data.data.salesPersonDetails);
          console.log("user", response.data.data.userDetails);
          console.log("user1", response.data.data.companyDetails);
          console.log("user2", response.data.data.packageDetails);
        });
    }
  };
  const handleSendMail = () => {
    setButtonLoading(true);
    axios
      .post(`${Key.Crm_domain}/customer/get_upgrade_packagedetails`, {
        customer_id: sessionStorage.getItem("id"),
      })
      .then((response) => {
        setnewPackageDetails(response.data.data.packageDetails);
        sessionStorage.setItem(
          "newpackage",
          JSON.stringify(response.data.data)
        );
        sessionStorage.setItem(
          "newAmount",
          response.data.data.packageDetails.Amount
        );

        axios
          .post(`${Key.Crm_domain}/payment/jnp_send_payment_mail`, {
            crm_userid: salesPersonDetails.user_id,
            session_token: sessionStorage.getItem("userToken"),
            customer_id: sessionStorage.getItem("id"),
            customer_email: customerDetails.username,
            agreementData: totaldetails,
            price: packdetails.price,
          })
          .then((response) => {
            setButtonLoading(false);
            if (response.data.data && response.data.data === "mail sent") {
              // trackStatusUpdateForAdmin(3)
              let msg = "Mail sent to customer successfully!";
              toast(<ToastMessage message={msg} />);
              //sessionStorage.setItem("sendmail",true)
              //history.goBack();
              // history("/dashboard/user")
              setShowModal(false);
              props.setShow(false);
            } else {
              let msg = "Sending mail failed!";
              toast(<ToastMessage message={msg} />);
            }
          });
      });
  };

  const tick = {
    color: "rgb(17 74 109)",
    fontSize: "20px",
    fontWeight: "bold",
  };
  const pricebut = {
    padding: "6px",
    background: "linear-gradient(to right, #406882, rgb(17 74 109))",
    border: "none",
    borderRadius: "10px",
    marginBottom: "-10px",
    bottom: "30px",
    position: "absolute",
    fontSize: "12px",
  };
  const colors = ["#808080", "#CD7F32", "#916f06", "#DAA520", "pink"];
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // infinite: true,
          // dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const redirectToContactsales = () => {
    history.push("/Contactsales");
  };
  return (
    <div>
      {(isDesktopLarge || isDesktopSmall) && (
        <div style={{ backgroundColor: "#f2f6fd" }}>
          <div className="container">
            <div
              className="row "
              style={{ marginTop: "0%", paddingTop: "17px" }}
            >
              <div style={{ textAlign: "center" }}>
                <p style={{ color: "#5a67d8" }}>PRICING PLANS</p>
                <p className="text-muted">Choose the best plan for you</p>
                <h3 style={{ fontSize: "25px" }}>
                  No placement within 90 days? 100% refund.
                </h3>
              </div>

              {packageData
                .filter((pack) => pack.id !== 1)
                .map((pack, index) => (
                  <div
                    className=" col-lg-2 my-4 "
                    style={{
                      width: "20%",
                      // boxShadow: "0 0 10px black !important"
                    }}
                    onClick={() => handleopen(pack)}
                    /* onClick={() => history.push({
                                    pathname: "/EmpLogin",
                                    state: {
                                        selected_package: pack.title,
                                        selected_package_id: pack.id
                                    },
                                   
                                })}  */
                  >
                    <div>
                      <div
                        className={`pricing-box px-3 ${
                          sessionStorage.getItem("packid") !== null &&
                          sessionStorage.getItem("packid") !== undefined &&
                          parseInt(sessionStorage.getItem("packid")) ===
                            pack?.id
                            ? "scale-effect"
                            : ""
                        }`}
                        style={{
                          height: "350px",
                          position: "relative",
                          //borderTop: `3px solid ${colors[index]}`,

                          boxShadow:
                            sessionStorage.getItem("packid") !== null &&
                            sessionStorage.getItem("packid") !== undefined &&
                            parseInt(sessionStorage.getItem("packid")) ===
                              pack?.id
                              ? "0px 2px 10px black"
                              : "",
                        }}
                      >
                        <div
                          style={{
                            width: "11px",
                            height: "7px",
                            backgroundColor: `${colors[index]}`,
                            position: "absolute",
                            left: "-7px",
                            top: "46px",
                            borderRadius: " 0px 0px 0px 10px",
                          }}
                        ></div>
                        <div
                          style={{
                            width: "150px",
                            height: "38px",
                            backgroundColor: `${colors[index]}`,
                            borderRadius: "5px 4px 4px 0px",
                            position: "absolute",
                            left: "-13px",
                            padding: "9px",
                            transform: "skewX(17deg) ",
                            overflow: "hidden",
                          }}
                        >
                          <h5
                            style={{
                              fontSize: "18px",
                              color: "white",
                              transform: "skewX(-20deg) ",
                              paddingLeft: "18px",
                            }}
                          >
                            {pack.title.replace(/Package/gi, "")}
                          </h5>
                        </div>
                        <i className="mdi mdi-account h1" />
                        {/*  */}
                        {/* <div
                              key={index}
                              style={{
                                fontSize: "34px",
                                color: `${colors[index]}`,
                              }}
                            >
                              {icons}
                            </div> */}
                        {/* <IoDiamond  style={{fontSize:"45px",color:"green"}}/>
                            <h5 style={{ fontSize: "19px", color: "#406882" }}>
                              {pack.title}{" "}
                            </h5> */}

                        <div className=" pt-5" style={{ fontSize: "15px" }}>
                          <p className="mb-2 f-14">Features</p>

                          <p
                            className="mb-2"
                            style={{
                              borderBottom: "1px solid grey",
                              paddingBottom: "5%",
                            }}
                            id="p1"
                          >
                            {/* <i className="mdi mdi-checkbox-marked-circle text-success f-15 mr-2" /> */}
                            <IoCheckmarkOutline style={{ color: "green" }} />
                            &nbsp;
                            <b>
                              <span></span>
                              {"Unlimited"}{" "}
                            </b>
                            Jobs Allowed
                          </p>
                          <p
                            className="mb-2"
                            id="p1"
                            style={{
                              borderBottom: "1px solid grey",
                              paddingBottom: "5%",
                            }}
                          >
                            <IoCheckmarkOutline style={{ color: "green" }} />
                            &nbsp;
                            <b>
                              <span></span>
                              {pack.resumeViews_allowed}{" "}
                            </b>
                            Resume Views Allowed
                          </p>
                          <p
                            className="mb-2"
                            id="p1"
                            style={{
                              borderBottom: "1px solid grey",
                              paddingBottom: "5%",
                            }}
                          >
                            {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                            <IoCheckmarkOutline style={{ color: "green" }} />
                            &nbsp;
                            <b>
                              <span></span>
                              {pack.users_allowed}{" "}
                            </b>
                            Users Allowed
                          </p>

                          {index === 0 ? (
                            <div>
                              <p className="mb-2" id="p1">
                                <i className="mdi mdi-close-circle text-danger f-15 mr-2" />
                                <b> </b>{" "}
                              </p>
                              <p className="mb-2">
                                <i className="mdi mdi-close-circle text-danger f-15 mr-2" />
                                <b> </b>{" "}
                              </p>
                              <br />
                              <br />
                              <br />
                              <br />
                            </div>
                          ) : index === 1 ? (
                            <div>
                              {/*  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Company Branding</p> */}
                              <p className="mb-2" id="p1">
                                <IoCheckmarkOutline
                                  style={{ color: "green" }}
                                />
                                &nbsp;
                                <span></span>Dedicated Person/Support{" "}
                              </p>
                              <p className="mb-2">
                                <i className="mdi mdi-close-circle text-danger f-15 mr-2" />{" "}
                              </p>
                              <br />
                            </div>
                          ) : index === 2 ? (
                            <div>
                              {/*  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Company Branding </p>
                                                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Messaging/Emails</p> */}
                              <p className="mb-2" id="p1">
                                {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                                <IoCheckmarkOutline
                                  style={{ color: "green" }}
                                />
                                &nbsp;
                                <span></span>Dedicated Person/Support{" "}
                              </p>
                            </div>
                          ) : index === 3 ? (
                            <div>
                              <p className="mb-2" id="p1">
                                {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                                <IoCheckmarkOutline
                                  style={{ color: "green" }}
                                />
                                &nbsp;
                                <span></span>Dedicated Person/Support{" "}
                              </p>
                              {/* <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Company Branding </p>
                                                            
                                                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Messaging/Emails</p>
                                                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Bulk Emailing</p> */}
                              <br />
                            </div>
                          ) : index === 4 ? (
                            <div>
                              <p className="mb-2" id="p1">
                                {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                                <IoCheckmarkOutline
                                  style={{ color: "green" }}
                                />
                                &nbsp;
                                <span></span>Dedicated Person/Support{" "}
                              </p>

                              {/* <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Company Branding </p>
                                                                <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Messaging/Emails</p>
                                                                <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Bulk Emailing</p>
                                                                <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Personal Suggestions</p>
     */}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <button
                          className="btn btn-primary btn-sm "
                          style={{
                            padding: "4px 30px",
                            //background: "linear-gradient(to right, #406882, rgb(17 74 109))",
                            backgroundColor: `${colors[index]}`,
                            border: "none",
                            borderRadius: "50px",
                            marginBottom: "-10px",
                            bottom: "20px",
                            position: "absolute",
                            fontSize: "12px",
                          }}
                        >
                          <h5 className="text-muted">
                            <span
                              className="plan pl-3 text-white"
                              style={{ fontSize: "small" }}
                            >
                              {"$" + pack.price + ".00"}
                            </span>
                          </h5>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

              <div className=" col-lg-2 my-4" style={{ width: "20%" }}>
                <div
                  className="pricing-box"
                  style={{
                    position: "relative",
                    height: "350px",
                    // borderTop: "3px solid #0b499f",
                  }}
                >
                  <i className="mdi mdi-account h1" />
                  {/* <PiCube/> */}
                  {/* <IoDiamond style={{ fontSize: "34px", color: "#067891" }} /> */}
                  <div
                    style={{
                      width: "11px",
                      height: "7px",
                      backgroundColor: "#0b499f",
                      position: "absolute",
                      left: "-7px",
                      top: "46px",
                      borderRadius: " 0px 0px 0px 10px",
                    }}
                  ></div>

                  <div
                    style={{
                      width: "150px",
                      height: "38px",
                      backgroundColor: "#0b499f",
                      borderRadius: "5px 4px 4px 0px",
                      position: "absolute",
                      left: "-13px",
                      padding: "9px",
                      transform: "skewX(17deg) ",
                      overflow: "hidden",
                    }}
                  >
                    <h5
                      style={{
                        fontSize: "18px",
                        color: "white",
                        transform: "skewX(-20deg) ",
                        paddingLeft: "18px",
                      }}
                    >
                      Custom
                    </h5>
                  </div>

                  <div className=" pt-5">
                    <p className="mb-2 f-14">Features</p>

                    <p
                      className="mb-2"
                      id="p1"
                      style={{
                        borderBottom: "1px solid grey",
                        paddingBottom: "5%",
                      }}
                    >
                      {/* <i className="mdi mdi-checkbox-marked-circle text-success f-15 mr-2" /> */}
                      <IoCheckmarkOutline style={{ color: "green" }} />
                      &nbsp;
                      <b>{"Unlimited"} </b>
                      <span> Jobs Allowed</span>
                    </p>
                    <p
                      className="mb-2"
                      id="p1"
                      style={{
                        borderBottom: "1px solid grey",
                        paddingBottom: "5%",
                      }}
                    >
                      <IoCheckmarkOutline style={{ color: "green" }} />
                      &nbsp;
                      {/* <i className="mdi mdi-checkbox-marked-circle text-success f-15 mr-2" /> */}
                      <b>
                        <span></span>
                      </b>
                      Resume Views Allowed
                    </p>
                    <p
                      className="mb-2"
                      id="p1"
                      style={{
                        borderBottom: "1px solid grey",
                        paddingBottom: "5%",
                      }}
                    >
                      <IoCheckmarkOutline style={{ color: "green" }} />
                      &nbsp;
                      {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                      <b>
                        <span></span>
                      </b>
                      Users Allowed
                    </p>

                    <div>
                      <p className="mb-2" id="p1">
                        <IoCheckmarkOutline style={{ color: "green" }} />
                        &nbsp;
                        {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                        <span></span>Dedicated Person/Support{" "}
                      </p>
                      {/*  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Company Branding </p>
                               
                                <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Messaging/Emails</p>
                                <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Bulk Emailing</p>
                                <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Personal Suggestions</p>
     */}
                    </div>
                  </div>

                  <button
                    className="btn btn-primary btn-sm"
                    style={{
                      padding: "4px 30px",

                      backgroundColor: "#0b499f",
                      border: "none",
                      borderRadius: "50px",
                      marginBottom: "-10px",
                      bottom: "20px",
                      position: "absolute",
                      fontSize: "12px",
                    }}
                    onClick={redirectToContactsales}
                  >
                    {" "}
                    <h6 className="text-muted">
                      <span
                        className="plan pl-3 text-white"
                        style={{ fontSize: "small" }}
                      >
                        {"$ " + "Custom"}
                      </span>
                    </h6>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Dialog open={showModal} onClose={handleClose} fullWidth fullscreen>
            {/* <Modal.Header closeButton style={{ color: '#406882',margin:'40px 0px 0px 50px' }}>
                        {!buttonLoading ?
                        <div>&nbsp;&nbsp;
                            <Button autoFocus color="inherit" onClick={handleSendMail} >
                                Receive an email to finalize the order
                            </Button>
                            </div>
                            :
                            <Button autoFocus color="inherit" onClick={handleSendMail} disabled>
                                Sending...
                            </Button>
                        }
                    </Modal.Header> */}

            <DialogTitle
              id="responsive-dialog-title"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <DialogContentText
                variant="h6"
                style={{ color: "#406882", margin: "40px 0px 0px 50px" }}
              >
                {" "}
                {!buttonLoading ? (
                  <div>
                    &nbsp;&nbsp;
                    <Button autoFocus variant="contained" color="inherit" onClick={handleSendMail}>
                      Receive an email to finalize the order
                    </Button>
                  </div>
                ) : (
                  <Button
                    autoFocus
                    color="inherit"
                    onClick={handleSendMail}
                    disabled
                    variant="contained"
                  >
                    Sending...
                  </Button>
                )}
              </DialogContentText>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              {packdetails && (
                <Purchase
                  price={packdetails.price}
                  customerid={sessionStorage.getItem("id")}
                  jobsAllowed={packdetails.jobsAllowed}
                  resumesAllowed={packdetails.resumeViews_allowed}
                  packageTitle={packdetails.title}
                  packageDetails={packdetails}
                  customerDetails={customerDetails}
                  companyDetails={companyDetails}
                  currentUser={props.currentUser}
                />
              )}
            </DialogContent>
            <DialogActions>
              {/* <Button
                    onClick={handleClose}
                    style={{
                      backgroundColor: '#406882',
                      color: 'white',
                      margin: '10px',
                    }}
                  >
                    Close
                  </Button> */}
            </DialogActions>
          </Dialog>
        </div>
      )}
      {/* Tablet Devices*/}
      {isTablet && (
        <div>
          <div className="container" style={{ textAlign: "left" }}>
            <div className="row" style={{ padding: "12px" }}>
              <br />
              <p style={{ color: "#5a67d8" }}>PRICING PLANS</p>
              <h3 style={{ fontSize: "20px" }}>Choose the best plan for you</h3>
              <p>No placement within 90 days? 100% refund.</p>
            </div>
          </div>

          <div style={{ backgroundColor: "#f2f6fd" }}>
            <div className="container">
              <div
                className="row "
                style={{ marginTop: "0%", paddingTop: "17px" }}
              >
                <Slider {...settings}>
                  {packageData
                    .filter((pack) => pack.id !== 1)
                    .map((pack, index) => (
                      <div
                        className=" col-sm-12 my-1 "
                        // style={{ width: "20%" }}
                        onClick={() => handleopen(pack)}
                      >
                        <div>
                          <div
                            className="pricing-box px-3 "
                            style={{
                              height: "350px",
                              position: "relative",
                              //borderTop: `3px solid ${colors[index]}`,
                            }}
                          >
                            <i className="mdi mdi-account h1" />
                            {/*  */}
                            {/*  <div
                            key={index}
                            style={{
                              fontSize: "34px",
                              color: `${colors[index]}`,
                            }}
                          >
                            {icons}
                          </div> */}
                            {/* <IoDiamond  style={{fontSize:"45px",color:"green"}}/> */}
                            <div
                              style={{
                                width: "7px",
                                height: "7px",
                                backgroundColor: `${colors[index]}`,
                                position: "absolute",
                                left: "-1px",
                                top: "46px",
                                borderRadius: " 0px 0px 0px 10px",
                              }}
                            ></div>
                            <div
                              style={{
                                width: "150px",
                                height: "38px",
                                backgroundColor: `${colors[index]}`,
                                borderRadius: "5px 4px 4px 0px",
                                position: "absolute",
                                left: "-3px",
                                padding: "9px",
                                transform: "skewX(5deg) ",
                                overflow: "hidden",
                              }}
                            >
                              <h5
                                style={{
                                  fontSize: "18px",
                                  color: "white",
                                  transform: "skewX(-20deg) ",
                                  paddingLeft: "18px",
                                }}
                              >
                                {pack.title.replace(/Package/gi, "")}
                              </h5>
                            </div>
                            <div className=" pt-2" style={{ fontSize: "15px" }}>
                              <p className="mb-2 f-14">Features</p>

                              <p
                                className="mb-2"
                                style={{
                                  borderBottom: "1px solid grey",
                                  paddingBottom: "5%",
                                  fontSize: "14px",
                                }}
                              >
                                {/* <i className="mdi mdi-checkbox-marked-circle text-success f-15 mr-2" /> */}
                                <IoCheckmarkOutline
                                  style={{ color: "green" }}
                                />
                                &nbsp;
                                <b>
                                  <span></span>
                                  {"Unlimited"}{" "}
                                </b>
                                Jobs Allowed
                              </p>
                              <p
                                className="mb-2"
                                // id="p1"
                                style={{
                                  borderBottom: "1px solid grey",
                                  paddingBottom: "5%",
                                  fontSize: "14px",
                                }}
                              >
                                <IoCheckmarkOutline
                                  style={{ color: "green" }}
                                />
                                &nbsp;
                                <b>
                                  <span></span>
                                  {pack.resumeViews_allowed}{" "}
                                </b>
                                Resume Views Allowed
                              </p>
                              <p
                                className="mb-2"
                                // id="p1"
                                style={{
                                  borderBottom: "1px solid grey",
                                  paddingBottom: "5%",
                                  fontSize: "14px",
                                }}
                              >
                                {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                                <IoCheckmarkOutline
                                  style={{ color: "green" }}
                                />
                                &nbsp;
                                <b>
                                  <span></span>
                                  {pack.users_allowed}{" "}
                                </b>
                                Users Allowed
                              </p>

                              {index === 0 ? (
                                <div>
                                  <p
                                    className="mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <i className="mdi mdi-close-circle text-danger f-15 mr-2" />
                                    <b> </b>{" "}
                                  </p>
                                  <p
                                    className="mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <i className="mdi mdi-close-circle text-danger f-15 mr-2" />
                                    <b> </b>{" "}
                                  </p>
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                </div>
                              ) : index === 1 ? (
                                <div>
                                  {/*  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Company Branding</p> */}
                                  <p
                                    className="mb-2 mt-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <IoCheckmarkOutline
                                      style={{ color: "green" }}
                                    />
                                    &nbsp;
                                    <span></span>Dedicated Person/Support{" "}
                                  </p>
                                  <p
                                    className="mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <i className="mdi mdi-close-circle text-danger f-15 mr-2" />{" "}
                                  </p>
                                  <br />
                                </div>
                              ) : index === 2 ? (
                                <div>
                                  {/*  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Company Branding </p>
                                                    <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Messaging/Emails</p> */}

                                  <p
                                    className="mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                                    <IoCheckmarkOutline
                                      style={{ color: "green" }}
                                    />
                                    &nbsp;
                                    <span></span>Dedicated Person/Support{" "}
                                  </p>
                                  <br />
                                </div>
                              ) : index === 3 ? (
                                <div>
                                  <p
                                    className="mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                                    <IoCheckmarkOutline
                                      style={{ color: "green" }}
                                    />
                                    &nbsp;
                                    <span></span>Dedicated Person/Support{" "}
                                  </p>
                                  {/* <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Company Branding </p>
                                                        
                                                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Messaging/Emails</p>
                                                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Bulk Emailing</p> */}
                                  <br />
                                </div>
                              ) : index === 4 ? (
                                <div>
                                  <p
                                    className="mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                                    <IoCheckmarkOutline
                                      style={{ color: "green" }}
                                    />
                                    &nbsp;
                                    <span></span>Dedicated Person/Support{" "}
                                  </p>

                                  {/* <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Company Branding </p>
                                                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Messaging/Emails</p>
                                                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Bulk Emailing</p>
                                                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Personal Suggestions</p>
 */}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <button
                              className="btn btn-primary btn-sm "
                              style={{
                                padding: "4px 30px",
                                //background: "linear-gradient(to right, #406882, rgb(17 74 109))",
                                backgroundColor: `${colors[index]}`,
                                border: "none",
                                borderRadius: "50px",
                                marginBottom: "-10px",
                                bottom: "20px",
                                position: "absolute",
                                fontSize: "12px",
                              }}
                            >
                              <h5 className="text-muted">
                                <span
                                  className="plan pl-3 text-white"
                                  style={{ fontSize: "small" }}
                                >
                                  {"$" + pack.price + ".00"}
                                </span>
                              </h5>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className=" col-sm-12 col-md-12 my-1">
                    <div
                      className="pricing-box"
                      style={{
                        position: "relative",
                        height: "350px",
                        //borderTop: "3px solid #0b499f",
                      }}
                    >
                      <i className="mdi mdi-account h1" />
                      {/* <PiCube/> */}
                      <div
                        style={{
                          width: "7px",
                          height: "7px",
                          backgroundColor: "#0b499f",
                          position: "absolute",
                          left: "-1px",
                          top: "46px",
                          borderRadius: " 0px 0px 0px 10px",
                        }}
                      ></div>

                      <div
                        style={{
                          width: "150px",
                          height: "38px",
                          backgroundColor: "#0b499f",
                          borderRadius: "5px 4px 4px 0px",
                          position: "absolute",
                          left: "-3px",
                          padding: "9px",
                          transform: "skewX(5deg) ",
                          overflow: "hidden",
                        }}
                      >
                        <h5
                          style={{
                            fontSize: "18px",
                            color: "white",
                            transform: "skewX(-20deg) ",
                            paddingLeft: "18px",
                          }}
                        >
                          Custom
                        </h5>
                      </div>

                      <div className=" pt-2">
                        <p className="mb-2 f-14">Features</p>

                        <p
                          className="mb-2"
                          style={{
                            borderBottom: "1px solid grey",
                            paddingBottom: "5%",
                            fontSize: "14px",
                          }}
                        >
                          {/* <i className="mdi mdi-checkbox-marked-circle text-success f-15 mr-2" /> */}
                          <IoCheckmarkOutline style={{ color: "green" }} />
                          &nbsp;
                          <b>{"Unlimited"} </b>
                          <span> Jobs Allowed</span>
                        </p>
                        <p
                          className="mb-2"
                          style={{
                            borderBottom: "1px solid grey",
                            paddingBottom: "5%",
                            fontSize: "14px",
                          }}
                        >
                          <IoCheckmarkOutline style={{ color: "green" }} />
                          &nbsp;
                          {/* <i className="mdi mdi-checkbox-marked-circle text-success f-15 mr-2" /> */}
                          <b>
                            <span></span>
                          </b>
                          Resume Views Allowed
                        </p>
                        <p
                          className="mb-2"
                          // id="p1"
                          style={{
                            borderBottom: "1px solid grey",
                            paddingBottom: "5%",
                            fontSize: "14px",
                          }}
                        >
                          <IoCheckmarkOutline style={{ color: "green" }} />
                          &nbsp;
                          {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                          <b>
                            <span></span>
                          </b>
                          Users Allowed
                        </p>

                        <div>
                          <p className="mb-2" style={{ fontSize: "14px" }}>
                            <IoCheckmarkOutline style={{ color: "green" }} />
                            &nbsp;
                            {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                            <span></span>Dedicated Person/Support{" "}
                          </p>
                          {/*  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Company Branding </p>
                           
                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Messaging/Emails</p>
                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Bulk Emailing</p>
                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Personal Suggestions</p>
 */}
                        </div>
                      </div>

                      <button
                        className="btn btn-primary btn-sm"
                        style={{
                          padding: "4px 30px",

                          backgroundColor: "#0b499f",
                          border: "none",
                          borderRadius: "50px",
                          marginBottom: "-10px",
                          bottom: "20px",
                          position: "absolute",
                          fontSize: "12px",
                        }}
                        onClick={redirectToContactsales}
                      >
                        {" "}
                        <h6 className="text-muted">
                          <span
                            className="plan pl-3 text-white"
                            style={{ fontSize: "small" }}
                          >
                            {"$ " + "Custom"}
                          </span>
                        </h6>
                      </button>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Mobile Devices*/}
      {isMobile && (
        <div>
          <div className="container" style={{ textAlign: "left" }}>
            <div className="row" style={{ padding: "12px" }}>
              <br />
              <p style={{ color: "#5a67d8" }}>PRICING PLANS</p>
              <h3 style={{ fontSize: "20px" }}>Choose the best plan for you</h3>
              <p>No placement within 90 days? 100% refund.</p>
            </div>
          </div>

          <div style={{ backgroundColor: "#f2f6fd" }}>
            <div className="container">
              <div
                className="row "
                style={{ marginTop: "0%", paddingTop: "17px" }}
              >
                <Slider {...settings}>
                  {packageData
                    .filter((pack) => pack.id !== 1)
                    .map((pack, index) => (
                      <div
                        className=" col-sm-12 my-1 "
                        // style={{ width: "20%" }}
                        onClick={() => handleopen(pack)}
                      >
                        <div>
                          <div
                            className="pricing-box px-3 "
                            style={{
                              height: "350px",
                              position: "relative",
                              //borderTop: `3px solid ${colors[index]}`,
                            }}
                          >
                            <i className="mdi mdi-account h1" />
                            {/*  */}
                            {/* <div
                            key={index}
                            style={{
                              fontSize: "34px",
                              color: `${colors[index]}`,
                            }}
                          >
                            {icons}
                          </div> */}
                            {/* <IoDiamond  style={{fontSize:"45px",color:"green"}}/> */}
                            <div
                              style={{
                                width: "11px",
                                height: "7px",
                                backgroundColor: `${colors[index]}`,
                                position: "absolute",
                                left: "-2px",
                                top: "46px",
                                borderRadius: " 0px 0px 0px 10px",
                              }}
                            ></div>
                            <div
                              style={{
                                width: "150px",
                                height: "38px",
                                backgroundColor: `${colors[index]}`,
                                borderRadius: "5px 4px 4px 0px",
                                position: "absolute",
                                left: "-2px",
                                padding: "9px",
                                transform: "skewX(-2deg) ",
                                overflow: "hidden",
                              }}
                            >
                              <h5
                                style={{
                                  fontSize: "18px",
                                  color: "white",
                                  transform: "skewX(-20deg) ",
                                  paddingLeft: "18px",
                                }}
                              >
                                {pack.title.replace(/Package/gi, "")}
                              </h5>
                            </div>

                            <div className=" pt-2" style={{ fontSize: "15px" }}>
                              <p className="mb-2 f-14">Features</p>

                              <p
                                className="mb-2"
                                style={{
                                  borderBottom: "1px solid grey",
                                  paddingBottom: "5%",
                                  fontSize: "14px",
                                }}
                                id="p1"
                              >
                                {/* <i className="mdi mdi-checkbox-marked-circle text-success f-15 mr-2" /> */}
                                <IoCheckmarkOutline
                                  style={{ color: "green" }}
                                />
                                &nbsp;
                                <b>
                                  <span></span>
                                  {"Unlimited"}{" "}
                                </b>
                                Jobs Allowed
                              </p>
                              <p
                                className="mb-2"
                                id="p1"
                                style={{
                                  borderBottom: "1px solid grey",
                                  paddingBottom: "5%",
                                  fontSize: "14px",
                                }}
                              >
                                <IoCheckmarkOutline
                                  style={{ color: "green" }}
                                />
                                &nbsp;
                                <b>
                                  <span></span>
                                  {pack.resumeViews_allowed}{" "}
                                </b>
                                Resume Views Allowed
                              </p>
                              <p
                                className="mb-2"
                                id="p1"
                                style={{
                                  borderBottom: "1px solid grey",
                                  paddingBottom: "5%",
                                  fontSize: "14px",
                                }}
                              >
                                {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                                <IoCheckmarkOutline
                                  style={{ color: "green" }}
                                />
                                &nbsp;
                                <b>
                                  <span></span>
                                  {pack.users_allowed}{" "}
                                </b>
                                Users Allowed
                              </p>

                              {index === 0 ? (
                                <div>
                                  <p
                                    className="mb-2"
                                    id="p1"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <i className="mdi mdi-close-circle text-danger f-15 mr-2" />
                                    <b> </b>{" "}
                                  </p>
                                  <p
                                    className="mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <i className="mdi mdi-close-circle text-danger f-15 mr-2" />
                                    <b> </b>{" "}
                                  </p>
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                </div>
                              ) : index === 1 ? (
                                <div>
                                  {/*  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Company Branding</p> */}
                                  <p
                                    className="mb-2"
                                    id="p1"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <IoCheckmarkOutline
                                      style={{ color: "green" }}
                                    />
                                    &nbsp;
                                    <span></span>Dedicated Person/Support{" "}
                                  </p>
                                  <p
                                    className="mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <i className="mdi mdi-close-circle text-danger f-15 mr-2" />{" "}
                                  </p>
                                  <br />
                                </div>
                              ) : index === 2 ? (
                                <div>
                                  {/*  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Company Branding </p>
                                                    <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Messaging/Emails</p> */}

                                  <p
                                    className="mb-2"
                                    id="p1"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                                    <IoCheckmarkOutline
                                      style={{ color: "green" }}
                                    />
                                    &nbsp;
                                    <span></span>Dedicated Person/Support{" "}
                                  </p>
                                  <br />
                                </div>
                              ) : index === 3 ? (
                                <div>
                                  <p
                                    className="mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                                    <IoCheckmarkOutline
                                      style={{ color: "green" }}
                                    />
                                    &nbsp;
                                    <span></span>Dedicated Person/Support{" "}
                                  </p>
                                  {/* <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Company Branding </p>
                                                        
                                                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Messaging/Emails</p>
                                                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Bulk Emailing</p> */}
                                  <br />
                                </div>
                              ) : index === 4 ? (
                                <div>
                                  <p
                                    className="mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                                    <IoCheckmarkOutline
                                      style={{ color: "green" }}
                                    />
                                    &nbsp;
                                    <span></span>Dedicated Person/Support{" "}
                                  </p>

                                  {/* <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Company Branding </p>
                                                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Messaging/Emails</p>
                                                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Bulk Emailing</p>
                                                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span ></span>Personal Suggestions</p>
 */}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <button
                              className="btn btn-primary btn-sm "
                              style={{
                                padding: "4px 30px",
                                //background: "linear-gradient(to right, #406882, rgb(17 74 109))",
                                backgroundColor: `${colors[index]}`,
                                border: "none",
                                borderRadius: "50px",
                                marginBottom: "-10px",
                                bottom: "20px",
                                position: "absolute",
                                fontSize: "12px",
                              }}
                            >
                              <h5 className="text-muted">
                                <span
                                  className="plan pl-3 text-white"
                                  style={{ fontSize: "small" }}
                                >
                                  {"$" + pack.price + ".00"}
                                </span>
                              </h5>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className=" col-sm-12 col-md-12 my-1">
                    <div
                      className="pricing-box"
                      style={{
                        position: "relative",
                        height: "350px",
                        //borderTop: "3px solid #0b499f",
                      }}
                    >
                      <i className="mdi mdi-account h1" />
                      {/* <PiCube/> */}
                      <div
                        style={{
                          width: "11px",
                          height: "7px",
                          backgroundColor: "#0b499f",
                          position: "absolute",
                          left: "-2px",
                          top: "46px",
                          borderRadius: " 0px 0px 0px 10px",
                        }}
                      ></div>

                      <div
                        style={{
                          width: "150px",
                          height: "38px",
                          backgroundColor: "#0b499f",
                          borderRadius: "5px 4px 4px 0px",
                          position: "absolute",
                          left: "-2px",
                          padding: "9px",
                          transform: "skewX(-2deg) ",
                          overflow: "hidden",
                        }}
                      >
                        <h5
                          style={{
                            fontSize: "18px",
                            color: "white",
                            transform: "skewX(-20deg) ",
                            paddingLeft: "18px",
                          }}
                        >
                          Custom
                        </h5>
                      </div>

                      <div className=" pt-2">
                        <p className="mb-2 f-14">Features</p>

                        <p
                          className="mb-2"
                          id="p1"
                          style={{
                            borderBottom: "1px solid grey",
                            paddingBottom: "5%",
                            fontSize: "14px",
                          }}
                        >
                          {/* <i className="mdi mdi-checkbox-marked-circle text-success f-15 mr-2" /> */}
                          <IoCheckmarkOutline style={{ color: "green" }} />
                          &nbsp;
                          <b>{"Unlimited"} </b>
                          <span> Jobs Allowed</span>
                        </p>
                        <p
                          className="mb-2"
                          id="p1"
                          style={{
                            borderBottom: "1px solid grey",
                            paddingBottom: "5%",
                            fontSize: "14px",
                          }}
                        >
                          <IoCheckmarkOutline style={{ color: "green" }} />
                          &nbsp;
                          {/* <i className="mdi mdi-checkbox-marked-circle text-success f-15 mr-2" /> */}
                          <b>
                            <span></span>
                          </b>
                          Resume Views Allowed
                        </p>
                        <p
                          className="mb-2"
                          id="p1"
                          style={{
                            borderBottom: "1px solid grey",
                            paddingBottom: "5%",
                            fontSize: "14px",
                          }}
                        >
                          <IoCheckmarkOutline style={{ color: "green" }} />
                          &nbsp;
                          {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                          <b>
                            <span></span>
                          </b>
                          Users Allowed
                        </p>

                        <div>
                          <p
                            className="mb-2"
                            id="p1"
                            style={{ fontSize: "14px" }}
                          >
                            <IoCheckmarkOutline style={{ color: "green" }} />
                            &nbsp;
                            {/* <i className="mdi mdi-close-circle text-danger f-15 mr-2" /> */}
                            <span></span>Dedicated Person/Support{" "}
                          </p>
                          {/*  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Company Branding </p>
                           
                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Messaging/Emails</p>
                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Bulk Emailing</p>
                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-15 mr-2" /><span></span>Personal Suggestions</p>
 */}
                        </div>
                      </div>

                      <button
                        className="btn btn-primary btn-sm"
                        style={{
                          padding: "4px 30px",

                          backgroundColor: "#0b499f",
                          border: "none",
                          borderRadius: "50px",
                          marginBottom: "-10px",
                          bottom: "20px",
                          position: "absolute",
                          fontSize: "12px",
                        }}
                        onClick={redirectToContactsales}
                      >
                        {" "}
                        <h6 className="text-muted">
                          <span
                            className="plan pl-3 text-white"
                            style={{ fontSize: "small" }}
                          >
                            {"$ " + "Custom"}
                          </span>
                        </h6>
                      </button>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PackagesView)
);
