import { combineReducers } from 'redux';
import UserReducer from './user.reducer';
import DashboardReducer from './dashboard.Reducer'
import loaderReducer  from './loader.reducer';
import companyReducer from './company.reducer';
import myJobsReducer from './myJobsReducer';
import primaryResumeReducer from './primaryResumeReducer';
import jobSearchUrlReducer from './jobSearchUrlReducer';
import pageStartValReducer from './pageChange.reducer';
const reducers = combineReducers({
    UserReducer,
    DashboardReducer,
    loaderReducer,
    companyReducer,
    myJobsReducer,
    primaryResumeReducer,
    jobSearchUrlReducer,
    pageStartValReducer
});

export default reducers;
