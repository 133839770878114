import React from "react";
import {
  Box,
  Tooltip,
  Typography,
  Skeleton,
  useTheme,
  useMediaQuery,
  Card, Grid, Divider
} from "@mui/material";


const LoadingSkeleton = () => (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={32}
      sx={{ marginBottom: 2 }}
    />
  );

const ResumeProfileLoading = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
    const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
    const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops
  



  return (
    <>
    <Box
      sx={{
        position: "relative",
        padding: "4px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            borderLeft: "10px solid rgba(64, 104, 130, 0.8)",
            borderTop: "4px solid rgba(64, 104, 130, 0.8)",
            borderBottom: "4px solid rgba(64, 104, 130, 0.8)",
            borderRadius: "50%",
          }}
        >
          <Tooltip title={`Resume id: Loading`} arrow placement="top">
            <Box
              sx={{
                borderLeft: "6px solid #fff",
                borderTop: "3px solid #fff",
                borderBottom: "3px solid #fff",
                borderRadius: "50%",
              }}
            >
              <Skeleton
                variant="circular"
                width={100}
                height={100}
                animation="wave"
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <Skeleton
        variant="text"
        width={100}
        height={10}
        sx={{
          position: "absolute",
          left: "115px",
          top: "15px",
        }}
      />
      <Skeleton
        variant="text"
        width={100}
        height={10}
        sx={{
          position: "absolute",
          right: "5px",
          top: "15px",
        }}
      />
      <Skeleton
        variant="rectangular"
        width='100%'
        height={40}
        sx={{
          position: "absolute",
          left: "105px",
          right: 0,
          top: "38px",
          borderRadius: "6px",
        }}
      />
    </Box>
      {[...Array(6)].map((_, index) => (
        <Card key={index} sx={{ boxShadow: "none", padding: "10px" }}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Typography
                paragraph
                sx={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: isDesktopLarge ? "14px" : "12px",
                  color: "rgba(0,0,0,0.6)",
                }}
              >
                <LoadingSkeleton />
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                paragraph
                sx={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: isDesktopLarge ? "14px" : "12px",
                  color: "rgba(0,0,0,0.9)",
                }}
              >
                <LoadingSkeleton />
              </Typography>
            </Grid>
          </Grid>
        </Card>
      ))}
      <Divider />
      </>
  );
};

export default ResumeProfileLoading;