const { Configuration, OpenAIApi } = require("openai");
const { openai_key } = require("../clientVariables.json")

export const openAI = async (message, callback) => {

    const configuration = new Configuration({
        apiKey: openai_key,
    });


    var prompt = [{ role: "user", content: message }];

    try {
        const openai = new OpenAIApi(configuration);
        const chatCompletion = await openai.createChatCompletion({
            model: "gpt-3.5-turbo",
            messages: prompt,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
            temperature: 0,
        });
        console.log(chatCompletion.data.choices[0].message.content)
        const result = chatCompletion.data.choices[0].message.content;

        var aidata = JSON.parse(result);
        console.log(aidata)

        var data = aidata && aidata.data ? aidata.data : (aidata ? aidata : {})

        return callback(null, data);
    } catch (e) {

        return callback(e)
    }

};
