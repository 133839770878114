import React, { useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Core styles
import "@react-pdf-viewer/default-layout/lib/styles/index.css"; // Default layout styles
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { searchPlugin } from "@react-pdf-viewer/search";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { bookmarkPlugin } from "@react-pdf-viewer/bookmark";
import styled, { ThemeProvider } from "styled-components";
import "./Styles/resumePdfViewer.css";
import Key from "../../clientVariables.json";
import { useState } from "react";

// Define your custom theme
const customTheme = {
  viewer: {
    backgroundColor: "lightgray", // Change the background color of the viewer
  },
  toolbar: {
    backgroundColor: "darkblue", // Change the toolbar background color
    color: "white", // Change the toolbar icon color
  },
  // You can add more customizations here for different parts of the layout
};

// Style the Viewer component using styled-components
const StyledViewer = styled(Viewer)`
  background-color: ${({ theme }) => theme.viewer.backgroundColor};
`;

const ResumePdfViewer = (props) => {
  // Initialize the plugins
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const searchPluginInstance = searchPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const zoomPluginInstance = zoomPlugin();
  const bookmarkPluginInstance = bookmarkPlugin();
  const [defaultFile, setDefaultFile] = useState();

  useEffect(() => {
    console.log(props?.resumeDetails);
    if (props?.resumeDetails) {
      if (parseInt(props.resumeDetails?.resume_id ? props?.resumeDetails?.resume_id : props?.resumeDetails?.id) < 594500) {
        setDefaultFile(
          `${Key.s3_files_url}/resume_${props?.resumeDetails?.resume_id
            ? props?.resumeDetails?.resume_id
            : props?.resumeDetails?.id
          }/${props?.resumeDetails?.filename
            ? props?.resumeDetails?.filename?.split('.').slice(0, -1).join('.') + ".pdf"
            : props?.resumeDetails?.res_fileName?.split('.').slice(0, -1).join('.') + ".pdf"
          }`
        )
      } else {
        setDefaultFile(
          `${Key.s3_files_url}/user_${props?.resumeDetails?.js_id
            ? props?.resumeDetails?.js_id
            : props?.resumeDetails?.uid
          }/resume_${props?.resumeDetails?.resume_id
            ? props?.resumeDetails?.resume_id
            : props?.resumeDetails?.id
          }/${props?.resumeDetails?.filename
            ? props?.resumeDetails?.filename?.split('.').slice(0, -1).join('.') + ".pdf"
            : props?.resumeDetails?.res_fileName?.split('.').slice(0, -1).join('.') + ".pdf"
          }`
        )
      }


    }

    // axios.get(`${Key.files_domain}/MyFiles/resume_attachments/${props.resumeDetails.uid}/${props.resumeDetails.id}/${props.resumeDetails.filename ? props.resumeDetails.filename : props.resumeDetails.res_fileName}`, {
    //     method: 'GET', // *GET, POST, PUT, DELETE, etc.
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    //     },
    // })
    //     .then((resdata) => {
    //         console.log(resdata)
    //     }).catch((err) => {
    //         console.log(err)
    //         setFileFound(false)
    //     });
  }, [props]);
  console.log(defaultFile)
  return (
      <ThemeProvider theme={customTheme}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <StyledViewer
            fileUrl={defaultFile}
            plugins={[
              defaultLayoutPluginInstance,
              searchPluginInstance,
              toolbarPluginInstance,
              zoomPluginInstance,
              bookmarkPluginInstance,
            ]}
          />
        </Worker>
      </ThemeProvider>
  );
};

export default ResumePdfViewer;
